import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Button, MenuItem, Popover, Grid } from "@material-ui/core";
import {
  getReseller,
  getProvince,
  getCustomer,
  exportResellerXLS,
} from "../../services/axios";
import Loading from "../../components/loading";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import TambahData from "./tambah-data";
import EditData from "./edit-data";
import ResellerTable from "./table/reseller";
import { fetchPic } from "utils/picData";

const CustomerReseller = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    filteredResellers: null,
    filteredCustomers: null,
    resellers: null,
    customers: null,
    provinces: null,
    pics: null,
    openTambahData: false,
    openEditData: false,
    dataEdit: null,
    role: null,
    created_between: `${moment()
      .add(-30, "days")
      .format("YYYY-MM-DD 00:00:00")},${moment().format(
      "YYYY-MM-DD 00:00:00"
    )}`,
    resellerRole: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const fetch = async () => {
      const resReseller = await getReseller(
        `?filter[created_between]=${state.created_between || ""}`
      );
      const resCustomer = await getCustomer();
      const resProvinces = await getProvince();
      const resPics = await fetchPic();
      setState((state) => ({
        ...state,
        filteredCustomers: resCustomer.data,
        filteredResellers: resReseller.data,
        resellers: resReseller,
        customers: resCustomer,
        provinces: resProvinces.data,
        pics: resPics,
      }));
    };
    fetch();
  }, []);

  const handleRefreshData = () => {
    setState({
      ...state,
      filteredResellers: [],
      resellers: [],
    });
    const fetch = async () => {
      const resReseller = await getReseller(
        `?filter[created_between]=${state.created_between || ""}`
      );
      const resCustomer = await getCustomer();
      const resProvinces = await getProvince();
      const resPics = await fetchPic();
      setState({
        ...state,
        openTambahData: false,
        openEditData: false,
        filteredCustomers: resCustomer.data,
        filteredResellers: resReseller.data,
        resellers: resReseller,
        customers: resCustomer,
        provinces: resProvinces.data,
        pics: resPics,
      });
    };
    fetch();
  };

  const handleUpdateData = (resellers, filteredResellers) => {
    setState({
      ...state,
      filteredResellers: filteredResellers,
      resellers: resellers,
    });
  };

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  const handleExportReseller = async () => {
    const res = await exportResellerXLS({
      "filter[created_between]": state.created_between,
      "filter[level]": state.role,
    });

    const url = window.URL.createObjectURL(
      new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Excel Reseller`
    );
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleEditData = (data, role) => {
    setState({ ...state, openEditData: true, dataEdit: data, role: role });
  };

  return (
    <Fragment>
      {state.openTambahData ? (
        <Fragment>
          <TambahData
            handleBack={() => {
              handleRefreshData();
            }}
          />
        </Fragment>
      ) : state.openEditData ? (
        <Fragment>
          <EditData
            handleBack={() => {
              handleRefreshData();
            }}
            resApi={state.dataEdit}
            role={state.role}
          />
        </Fragment>
      ) : (
        <Fragment>
          {!state.resellers ? (
            <Loading />
          ) : (
            <Fragment>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6">
                    <b>Data Reseller</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.tambahOrder}
                      style={{ marginRight: 20 }}
                      onClick={() => handleChangeState("openTambahData", true)}
                    >
                      + Tambah Data
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.other}
                      onClick={handleClick}
                    >
                      ...
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleExportReseller();
                        }}
                      >
                        <SystemUpdateAltIcon />{" "}
                        <Typography style={{ paddingLeft: 10 }}>
                          Download Excel
                        </Typography>
                      </MenuItem>
                    </Popover>
                  </div>
                </Grid>
              </Grid>
              <ResellerTable
                resellers={state.resellers}
                filteredResellers={state.filteredResellers}
                provinces={state.provinces}
                pics={state.pics}
                handleEditData={handleEditData}
                handleRefreshData={handleRefreshData}
                handleUpdateData={handleUpdateData}
                handleDateBetween={(newDate) =>
                  setState({ ...state, created_between: newDate })
                }
                handleRole={(newRole) => setState({ ...state, role: newRole })}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

export default CustomerReseller;
