const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tambahOrder: {
    borderRadius: 30,
    textTransform: "capitalize",
  },
  // other: {
  //   borderRadius: 30,
  // },
  filterWrapper: {
    paddingTop: 20,
  },
  filterOutlined: {
    border: `1px solid ${theme.palette.green.main}`,
    color: theme.palette.green.main,
    textTransform: "capitalize",
    borderRadius: 30,
  },
  filterContained: {
    backgroundColor: theme.palette.green.main,
    borderRadius: 30,
    textTransform: "capitalize",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#35bd82",
    },
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: "0 10px 10px 0",
    backgroundColor: theme.palette.white.tertiary,
    width: "fit-content",
    padding: "0 10px",
  },
  filter: {
    borderRadius: 30,
    backgroundColor: theme.palette.white.tertiary,
    padding: `10px 30px`,
    margin: '0 10px',
    textTransform: "capitalize",
  },
  downloadAllBtn: {
    borderRadius: 4,
    backgroundColor: theme.palette.white.tertiary,
    padding: `10px 10px`,
    margin: '0 5px',
    textTransform: "capitalize",
  },
  SearchIcon: {
    fill: "#bbbbbb",
  },
  select: {
    width: "fit-content",
    borderRadius: 30,
    marginLeft: 10,
    backgroundColor: theme.palette.white.tertiary,
    padding: "0 10px",
  },
  containerPagination: {
    paddingTop: 40,
    paddingBottom: 10,
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  filterOrderWrapper: {
    borderRadius: 15,
    backgroundColor: theme.palette.white.tertiary,
    padding: 20,
    marginTop: 20,
  },
  filterDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#B6CAFF",
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 30,
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  calendarIcon: {
    "& path": {
      fill: "#fff",
    },
  },
  filterTanggal: {
    display: "flex",
    paddingTop: 10,
  },
  notFound: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
  notFound2: {
    textAlign: "center",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    backgroundColor: "#B6CAFF",
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  searchSupplier: {
    display: "flex",
    // justifyContent: 'center'
  },
  wrapperSupplier: {
    borderRadius: 30,
    backgroundColor: theme.palette.white.tertiary,
    display: "flex",
    alignItems: "center",
    cursor: 'pointer',
    paddingRight: 20,
  },
  centerLoading: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 20,
  },
  fab: {
    position: 'fixed',
    bottom: 80,
    right: theme.spacing(5),
    backgroundColor: theme.palette.green.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#35bd82",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export default style;
