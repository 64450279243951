import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControlLabel,
  Box, 
  RadioGroup, 
  Radio, 
  Typography 
} from "@material-ui/core";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from 'moment/moment'

import { DateRangePicker } from "react-date-range";

const DialogExportTransaction = withStyles(style)((props) => {
  const {
    onClose,
    open,
    supplier
  } = props;
  const [payload, setPayload] = useState({
    status: "",
  });
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().subtract(29, 'days').toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ])

  console.log(supplier, "supplier")

  const handleChangePayload = (valueState, value) => {
    setPayload({ ...payload, [valueState]: value });
  };

  const handleSubmit = async () => {
    const params = {
      'filter[status]': payload.status,
      'filter[start_date]' : moment(selectionRange[0].startDate).format("YYYY-MM-DD"),
      'filter[end_date]' : moment(selectionRange[0].endDate).format("YYYY-MM-DD")
    }
    fetch(`https://api.idejualan.com/api/v2/suppliers/${supplier}/wallet/export?` + 
      new URLSearchParams(params), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
      },
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", `Transaksi-${supplier}-${payload.status}.xlsx`);
        a.click();
      });
    });

  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          Export Transaksi
        </DialogTitle>
        <DialogContent>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                        {/* filter date */}
                
              <Typography color="primary" variant="body">Pilih Tanggal</Typography>
                <Box sx={{ mx: 'auto'}}>
                <DateRangePicker
                  onChange={item => setSelectionRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  showMonthAndYearPickers={false}
                  editableDateInputs={false}
                  months={2}
                  ranges={selectionRange}
                  direction="vertical"
                />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
              <div>
              <Typography color="primary" variant="body">Pilih Status</Typography>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                defaultValue=""
                name="status">
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      onChange={(e) => handleChangePayload("status", e.target.value)}
                    />
                  }
                  label="Semua"
                />
                <FormControlLabel
                  value="OUT"
                  control={
                    <Radio
                      onChange={(e) => handleChangePayload("status", e.target.value)}
                    />
                  }
                  label="Keluar"
                />
                <FormControlLabel
                  value="IN"
                  control={
                    <Radio
                      onChange={(e) => handleChangePayload("status", e.target.value)}
                    />
                  }
                  label="Masuk"
                />
                <FormControlLabel
                  value="ONGOING"
                  control={
                    <Radio
                      onChange={(e) => handleChangePayload("status", e.target.value)}
                    />
                  }
                  label="On Going"
                />
              </RadioGroup>
            </div>
              </Grid>  
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Export Excel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default DialogExportTransaction;
