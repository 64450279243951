import { withStyles  } from "@material-ui/core/styles";
import React from 'react'
import style from "./style";

const AnalyticV2 = withStyles(style)((props) => {
    // metabase
    const jwt = require("jsonwebtoken");

    const METABASE_SITE_URL = "https://metabase.idejualan.com";
    const METABASE_SECRET_KEY = "e7bdca3d0944133dbc3784b9c219db735b1a14423c78633ebeba054ea8868632";

    const payload = {
        resource: { dashboard: 3 },
        params: {},
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };
    const token = jwt.sign(payload, METABASE_SECRET_KEY);

    const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

    return (
        <div>
            {/* metabase analityc v2 */}
            <div 
                style={{
                    width: '100%',
                    height: 'calc(100vh - 120px)'
                    }}
            >
                <iframe
                    title='metabaseAnalitycV2'
                    src={iframeUrl}
                    width='100%'
                    height='100%'
                    frameborder="0"
                    allowtransparency
                    allowfullscreen
                />
            </div>
        </div>
    )
})

export default AnalyticV2