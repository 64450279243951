import { Button, FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, useEffect } from "react";
import { getCitiesByProvinceId, getDistrictsByCityId, getProvinces, getSubDistrictsByDisctrictId } from "services/axios";
import { createProductDropship } from "services/dropship.service";
import swal from "sweetalert";
import styles from "./style.module.css";

const CreateProductDropship = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setstate] = useState({
      "name": "",
      "description": '',
      "weight_gram": '',
      "image": '',
      "urlImage": '',
      "price": '',
      "commission": '',
      "phone": '',
      "address": '',
      "location": {
        "province": '',
        "city": '',
        "district": '',
        "subdistrict": ''
      },
      "location_id": '',
      "recommendation_locations": [],
      "min_age": '',
      "max_age": '',
      "placement": '',
      "note": '',
      "interest": '',
      "ad_text": '',
      "images": [''],
      "videos": ['']
  })
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listSubdistrict, setListSubdistrict] = useState([]);

  // location manage
  useEffect(() => {
    const fetch = async () => {
      const res = await getProvinces();
      setListProvince(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    fetch();
  }, []);

  const handleChangeProvince = async (event) => {
    const res = await getCitiesByProvinceId(event.id);
    setListCity(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setstate({ ...state, location: {...state.location, province: event}})
  };

  const handleChangeCity = async (event) => {
    const res = await getDistrictsByCityId(event.id);
    setListDistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setstate({ ...state, location: {...state.location, city: event}})
  };

  const handleChangeDistrict = async (event) => {
    const res = await getSubDistrictsByDisctrictId(event.id);
    setListSubdistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setstate({ ...state, location: {...state.location, district: event}})
  };

  const handleChangeSubdistrict = (event) => {
    setstate({ ...state, location: {...state.location, subdistrict: event}, location_id: event.id})
  };

  // management input
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setstate({ ...state, [name]: value });
  };

  const handleSubmit = async() => {
    const dataToUpload = JSON.parse(JSON.stringify(state))

    // check recomendations is empty
    if (dataToUpload.recommendation_locations.length === 0) {
      swal("Error", 'Maaf anda harus mengisi minimal satu lokasi rekomendasi', "error");
    }else{
      // combine min_age and max_age
      dataToUpload.age = `${dataToUpload.min_age}-${dataToUpload.max_age}`
      delete dataToUpload.min_age
      delete dataToUpload.max_age

      // delete data url image
      delete dataToUpload.urlImage

      // delete data location
      delete dataToUpload.location

      // change type recomendation location from array to string
      dataToUpload.recommendation_locations = dataToUpload.recommendation_locations.toString()

      // convert to formdata
      const form_data = new FormData();
      for (let key in dataToUpload) {
        if (typeof(dataToUpload[key]) === 'object') {
          if (key === 'image') {
            form_data.append(key, state.image);
          }else{
            for (let index = 0; index < dataToUpload[key].length; index++) {
              form_data.append(`${key}[${index}]`, dataToUpload[key]);
            }
          }
        }else{
          form_data.append(key, dataToUpload[key]);
        }
      }

      setIsLoading(true);
      
      // upload to api
      await createProductDropship(form_data)
      .then((result) => {
        swal("Success", "Berhasil menambahkan Product", "success").then(() => {
          window.history.back();
        });
        setIsLoading(false);
      })
      .catch((error) => {
        swal("Error", error.response.data.message, "error");
        setIsLoading(false);
      });
    }
  }

  console.log(state)

  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Tambah Product Dropship</b>
      </Typography>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit()
      }}>
        <Grid container spacing={3} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap' }}>

          <div className={styles.innerContainer}>
            {/* image product */}
            <Grid item xs={12}>
              <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                <div className={styles.photo}>
                  {state.urlImage !== '' ? (
                    <img src={state.urlImage} alt="Preview" />
                  ) : (
                    <>
                      <div>Image Product</div>
                      <Add />
                    </>
                  )}
                  <input
                    name="image"
                    accept="image/*"
                    type="file"
                    required
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const url = URL.createObjectURL(file);
                      setstate({...state, image: file, urlImage: url})
                    }}
                  />
                </div>
                <div style={{marginLeft: 10}}>
                  {
                    state.urlImage !== '' &&
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setstate({...state, image: '', urlImage: '' })
                      }}
                    >
                      Hapus
                    </Button>
                  }
                </div>
              </div>
            </Grid>

            <br/>

            {/* nama product */}
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nama Product"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* harga jual */}
            <Grid item xs={12}>
              <TextField
                name="price"
                label="Harga Jual"
                type="number"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* komisi */}
            <Grid item xs={12}>
              <TextField
                name="commission"
                label="Komisi"
                type="number"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* Phone */}
            <Grid item xs={12}>
              <TextField
                name="phone"
                label="No. Hp"
                type="number"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* alamat */}
            <Grid item xs={12}>
              <TextField
                name="address"
                label="Alamat"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* desc product */}
            <Grid item xs={12}>
              <TextareaAutosize
                name="description"
                placeholder="Deskripsi Product"
                variant="outlined"
                style={{ 
                  width: "93%", 
                  padding: '18.5px 14px', 
                  height: 50, 
                  fontFamily: `"Roboto", "Helvetica", "Arial", 'sans-serif'`, 
                  fontSize: '1rem' 
                }}
                required
                onChange={handleInputChange}
              />
            </Grid>
            <br/>

            {/* weight gram */}
            <Grid item xs={12}>
              <TextField
                name="weight_gram"
                label="Berat (gram)"
                type="number"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* lokasi */}
            <>
              <FormLabel>Lokasi</FormLabel>
              <br/><br/>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">Provinsi</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={
                    state.location.province.name ? '' : state.location.province.name
                  }
                  label="Provinsi"
                  required
                >
                  {listProvince !== null &&
                    listProvince.map((res) => {
                      return (
                        <MenuItem
                          key={res.id}
                          value={res.name}
                          onClick={() => handleChangeProvince(res)}
                        >
                          {res.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <br/><br/>

              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">Kota</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.location.city.name ? '' : state.location.city.name}
                  label="Kota"
                  required
                >
                  {listCity !== null &&
                    listCity.map((res) => {
                      return (
                        <MenuItem
                          key={res.id}
                          value={res.name}
                          onClick={() => handleChangeCity(res)}
                        >
                          {res.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <br/><br/>

              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Kecamatan
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={
                    state.location.district.name ? '' : state.location.district.name
                  }
                  label="Kecamatan"
                  required
                >
                  {listDistrict.map((res) => {
                    return (
                      <MenuItem
                        key={res.id}
                        value={res.name}
                        onClick={() => handleChangeDistrict(res)}
                      >
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <br/><br/>

              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Desa/Kelurahan
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.location.subdistrict.name ? '' : state.location.subdistrict.name}
                  label="Desa/Kelurahan"
                  required
                >
                  {listSubdistrict.map((res) => {
                    return (
                      <MenuItem
                        key={res.id}
                        value={res.name}
                        onClick={() => handleChangeSubdistrict(res)}
                      >
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
            <br/><br/>
          </div>

          <div className={styles.innerContainer}>
            {/* umur */}
            <>
              <FormLabel>Umur</FormLabel>
              <br/><br/>
              <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                <TextField
                  name="min_age"
                  label="Umur Minimal"
                  type="number"
                  variant="outlined"
                  style={{ width: "50%" }}
                  inputProps={{ min: 0, max: 100 }}
                  required
                  onChange={handleInputChange}
                />

                <>-</>

                <TextField
                  name="max_age"
                  label="Umur Maksimal"
                  type="number"
                  variant="outlined"
                  style={{ width: "50%" }}
                  disabled={
                    state.min_age === undefined ||
                    state.min_age === ''
                  }
                  inputProps={{ min: Number(state.min_age), max: 100 }}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </>

            <br/>

            {/* placement */}
            <Grid item xs={12}>
              <TextField
                name="placement"
                label="Placement"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* interest */}
            <Grid item xs={12}>
              <TextField
                name="interest"
                label="Interest"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* lokasi rekomendasi */}
            <Grid item xs={12}>
              <TextField
                name="recommendation_locations"
                label="Lokasi Rekomendasi"
                variant="outlined"
                style={{ width: "100%" }}
                onKeyUp={(event) => {
                  if (event.key === ",") {
                    setstate({...state, 'recommendation_locations': [...state.recommendation_locations, event.target.value.toLowerCase().split(',').join('')]})
                    event.target.value = ''
                  }
                }}
              />
              <Typography variant="caption" component="div">
                <i>Pisahkan antar lokasi dengan koma ","</i>
              </Typography>

              {/* loop value */}
              <div style={{display: 'flex', justifyContent: 'start', marginTop: 5, flexWrap: 'wrap'}}>
                {
                  state.recommendation_locations.map((res, index) => 
                    <div 
                      key={'lokasiRekomend'+ index} 
                      style={{
                        marginLeft: 5,
                        marginTop: 3, 
                        borderRadius: 10, 
                        backgroundColor: '#FDCA40', 
                        color: 'white', 
                        padding: 8, 
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        maxWidth: 430
                      }}
                    >
                      <div style={{maxWidth: 430}}>{res}</div> 
                      <div 
                        style={{
                          marginLeft: 5, 
                          display: 'flex', 
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          const dataLokasiRekomend = state.recommendation_locations
                          dataLokasiRekomend.splice(index, 1)
                          setstate({...state, 'recommendation_locations': dataLokasiRekomend})
                        }}
                      >
                        <CloseIcon style={{color: '#ff6060'}}/>
                      </div>
                    </div>
                  )
                }
              </div>
            </Grid>

            <br/>

            {/* note */}
            <Grid item xs={12}>
              <TextareaAutosize
                name="note"
                placeholder="Note"
                variant="outlined"
                style={{ 
                  width: "93%", 
                  padding: '18.5px 14px', 
                  height: 50, 
                  fontFamily: `"Roboto", "Helvetica", "Arial", 'sans-serif'`, 
                  fontSize: '1rem' 
                }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* teks iklan */}
            <Grid item xs={12}>
              <TextareaAutosize
                name="ad_text"
                placeholder="Teks Iklan"
                variant="outlined"
                style={{ 
                  width: "93%", 
                  padding: '18.5px 14px', 
                  height: 50, 
                  fontFamily: `"Roboto", "Helvetica", "Arial", 'sans-serif'`, 
                  fontSize: '1rem' 
                }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* Materi Gambar */}
            <Grid item xs={12}>
              <FormLabel>Materi Gambar</FormLabel>
              <br/><br/>
              {
                state.images.map((res, index) => 
                  <div key={'link' + index} style={{display: 'flex', justifyContent: 'start', alignItems: 'start', marginBottom: 10}}>
                    <TextField
                      label={`Link ${index+1}`}
                      variant="outlined"
                      style={{ width: "85%"}}
                      required
                      value={state.images[index]}
                      onChange={(e) => {
                        const allFields = state.images
                        allFields[index] = e.target.value
                        setstate({...state, images: allFields})
                      }}
                    />

                      {
                        index !== 0 &&
                          index === state.images.length-1 &&
                          <div style={{marginLeft: 5}}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                const dataMateriGambar = state.images
                                dataMateriGambar.pop()
                                setstate({...state, 'images': dataMateriGambar})
                              }}
                            >
                              Hapus
                            </Button>
                          </div>
                      }
                  </div>
                )
              }
              <br/>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {setstate({...state, 'images': [...state.images, '']})}}
              >
                Tambah Link
              </Button>
              <br/><br/>
            </Grid>

            <br/>

            {/* Materi Video */}
            <Grid item xs={12}>
              <FormLabel>Materi Video</FormLabel>
              <br/><br/>
              {
                state.videos.map((res, index) => 
                  <div key={'link' + index} style={{display: 'flex', justifyContent: 'start', alignItems: 'start', marginBottom: 10}}>
                    <TextField
                      label={`Link ${index+1}`}
                      variant="outlined"
                      style={{ width: "85%"}}
                      required
                      value={state.videos[index]}
                      onChange={(e) => {
                        const allMateriVideo = state.videos
                        allMateriVideo[index] = e.target.value
                        setstate({...state, videos: allMateriVideo})
                      }}
                    />

                      {
                        index !== 0 &&
                          index === state.videos.length-1 &&
                          <div style={{marginLeft: 5}}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                const dataMateriGambar = state.videos
                                dataMateriGambar.pop()
                                setstate({...state, 'videos': dataMateriGambar})
                              }}
                            >
                              Hapus
                            </Button>
                          </div>
                      }
                  </div>
                )
              }
              <br/>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {setstate({...state, 'videos': [...state.videos, '']})}}
              >
                Tambah Link
              </Button>
              <br/><br/><br/>
            </Grid>
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default CreateProductDropship;
