import Analytic from "../pages/analytic";
import Report from "../pages/report";
import Info from "../pages/info";
import Order from "../pages/order";
import Promo from "../pages/promo";
import Products from "../pages/product/products";
import ProductsCategory from "../pages/product/category-product";
import EProductsCategory from "../pages/product/category-e-product";
import ECourseCategory from "../pages/product/category-e-course";
import Customer from "../pages/customer";
import Supplier from "../pages/supplier";
import UserAdmin from "../pages/user-admin";
import Reseller from "../pages/reseller";
import Annoucement from "../pages/pengumuman";
import Settings from "../pages/settings";
import FlashsalePage from "pages/promo/flashsale";
import CreateFlashsale from "pages/promo/flashsale/tambah-flashsale";
import EditFlashsale from "pages/promo/flashsale/edit-flashsale";
import VoucherPage from "pages/promo/voucher";
import CreateVoucher from "pages/promo/voucher/tambah-voucher";
import EditVoucher from "pages/promo/voucher/edit-voucher";
import Bank from "pages/bank";
import ECourse from "pages/ecourse";
import PIC from "pages/pic";
import CreatePIC from "pages/pic/Create/Create";
import EditPIC from "pages/pic/Edit/Edit";
import Marketplace from "pages/marketplace";
import Payment from "pages/Payment";
import ResellerOmzet from "pages/reseller-omzet";
import OrderUpgrade from "pages/order-upgrade";
import ShopChannel from "pages/shop-channel";
import PreSupplier from "pages/pre-supplier";
import Metabase from "pages/metabase";
import Counter from "pages/counter";
import SupplierTransaction from "pages/supplier-transaction";
import ResellerTransaction from "pages/reseller-transaction";
import Penalty from "pages/penalty";
import HistoryTopupDompetJualan from "pages/history-topup-dompet-jualan";
import Voucher from "pages/voucher";
import AnalyticV2 from "pages/analyticv2";
import Campaign from "pages/campaign";
import CreateCampaign from "pages/campaign/Create/Create";
import EditCampaign from "pages/campaign/Edit/Edit";
import AnalyticDropship from "pages/analytic-dropship";
import ProductDropship from "pages/product-dropship";
import CreateProductDropship from "pages/product-dropship/Create/Create";
import EditProductDropship from "pages/product-dropship/Edit/Edit";
import DropshipperPage from "pages/dropshipper";
import CreateDropshipper from "pages/dropshipper/Create/Craete";
import EditDropshipper from "pages/dropshipper/Edit/Edit";
import CreateInvestor from "pages/investors/Create/Create";
import InvestorsPage from "pages/investors";
import EditInvestor from "pages/investors/Edit/Edit";
import InvestorsProductsPage from "pages/investors-products";
import CreateInvestorProducts from "pages/investors-products/Create/Craete";
import EditInvestorProducts from "pages/investors-products/Edit/Edit";

const listRoute = [
  {
    name: "ANALYTIC",
    path: "/analytic",
    component: Analytic,
  },
  {
    name: "REPORT",
    path: "/report",
    component: Report,
  },
  {
    name: "INFO",
    path: "/info",
    component: Info,
  },
  {
    name: "ORDER",
    path: "/order",
    component: Order,
  },
  {
    name: "FLASHSALE",
    path: "/promo/flashsale/new",
    component: CreateFlashsale,
  },
  {
    name: "FLASHSALE",
    path: "/promo/flashsale/edit/:id",
    component: EditFlashsale,
  },
  {
    name: "FLASHSALE",
    path: "/promo/flashsale",
    component: FlashsalePage,
  },
  {
    name: "VOUCHER",
    path: "/promo/voucher/new",
    component: CreateVoucher,
  },
  {
    name: "VOUCHER",
    path: "/promo/voucher/edit/:id",
    component: EditVoucher,
  },
  {
    name: "VOUCHER",
    path: "/promo/voucher",
    component: VoucherPage,
  },
  {
    name: "PROMO",
    path: "/promo",
    component: Promo,
  },
  {
    name: "PRODUCTS",
    path: "/product/products",
    component: Products,
  },
  {
    name: "PRODUCT-CATEGORY",
    path: "/product/category-product",
    component: ProductsCategory,
  },
  {
    name: "PRODUCT-E-CATEGORY",
    path: "/product/category-e-product",
    component: EProductsCategory,
  },
  {
    name: "PRODUCT-E-COURSE",
    path: "/product/category-e-course",
    component: ECourseCategory,
  },
  {
    name: "E-COURSE",
    path: "/product/ecourse",
    component: ECourse,
  },
  {
    name: "CUSTOMER",
    path: "/data-customer",
    component: Customer,
  },
  {
    name: "RESELLER",
    path: "/data-reseller",
    component: Reseller,
  },
  {
    name: "PIC",
    path: "/data-pic/create",
    component: CreatePIC,
  },
  {
    name: "PIC",
    path: "/data-pic/edit/:id",
    component: EditPIC,
  },
  {
    name: "PIC",
    path: "/data-pic",
    component: PIC,
  },
  {
    name: "SUPPLIER",
    path: "/supplier",
    component: Supplier,
  },
  {
    name: "USER-ADMIN",
    path: "/user-admin",
    component: UserAdmin,
  },
  {
    name: "Bank",
    path: "/bank",
    component: Bank,
  },
  {
    name: "Marketplace Form",
    path: "/marketplace",
    component: Marketplace,
  },
  {
    name: "ANNOUCEMENT",
    path: "/annoucement",
    component: Annoucement,
  },
  {
    name: "Settings",
    path: "/settings",
    component: Settings,
  },
  {
    name: "Pembayaran",
    path: "/payment",
    component: Payment,
  },
  {
    name: "Reseller Omzet",
    path: "/data-reseller-omzet",
    component: ResellerOmzet,
  },
  {
    name: "Order Upgrade",
    path: "/order-upgrade",
    component: OrderUpgrade,
  },
  {
    name: "Shop Channel",
    path: "/shop-channel",
    component: ShopChannel,
  },
  {
    name: "Pendaftar Supplier",
    path: "/pre-supplier",
    component: PreSupplier,
  },
  {
    name: "METABASE",
    path: "/metabase",
    component: Metabase,
  },
  {
    name: "COUNTER",
    path: "/counter",
    component: Counter,
  },
  {
    name: "Supplier Transaction",
    path: "/supplier-transaction",
    component: SupplierTransaction,
  },
  {
    name: "Reseller Transaction",
    path: "/reseller-transaction",
    component: ResellerTransaction,
  },
  {
    name: "PENALTY",
    path: "/penalty",
    component: Penalty,
  },
  {
    name: 'History Topup Dompet jualan',
    path: '/history-topup-dompet-jualan',
    component: HistoryTopupDompetJualan,
  },
  {
    name: 'VOUCHER',
    path: '/voucher',
    component: Voucher,
  },
  {
    name: 'AnalyticV2',
    path: '/analyticv2',
    component: AnalyticV2,
  },
  {
    name: "Campaign",
    path: "/campaign/create",
    component: CreateCampaign,
  },
  {
    name: "Campaign",
    path: "/campaign/edit/:slug",
    component: EditCampaign,
  },
  {
    name: "Campaign",
    path: "/campaign",
    component: Campaign,
  },
  {
    name: "Analytic Dropship",
    path: "/analytic-dropship",
    component: AnalyticDropship,
  },
  {
    name: "Create Product Dropship",
    path: "/product-dropship/create",
    component: CreateProductDropship,
  },
  {
    name: "Edit Product Dropship",
    path: "/product-dropship/Edit/:slug",
    component: EditProductDropship,
  },
  {
    name: "Product Dropship",
    path: "/product-dropship",
    component: ProductDropship,
  },
  {
    name: "Create Dropshipper",
    path: "/dropshipper/create",
    component: CreateDropshipper,
  },
  {
    name: "Edit Dropshipper",
    path: "/dropshipper/Edit/:slug",
    component: EditDropshipper,
  },
  {
    name: "Dropshipper",
    path: "/dropshipper",
    component: DropshipperPage,
  },
  {
    name: "Create Investor",
    path: "/investors/create",
    component: CreateInvestor,
  },
  {
    name: "Edit Dropshipper",
    path: "/investors/edit/:slug",
    component: EditInvestor,
  },
  {
    name: "Create Investor Product",
    path: "/investor-products/create",
    component: CreateInvestorProducts,
  },
  {
    name: 'Investors',
    path: '/investor-products/edit/:slug',
    component: EditInvestorProducts,
  },
  {
    name: 'Investors',
    path: '/investor-products',
    component: InvestorsProductsPage,
  },
  {
    name: 'Investors',
    path: '/investors',
    component: InvestorsPage,
  },
];

export default listRoute;
