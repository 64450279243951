import { Button, Grid, TextField, Typography } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import { createInvestor } from "services/dropship.service";
import NumberFormat from 'react-number-format';
import swal from "sweetalert";
import styles from "./style.module.css";

const CreateInvestor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setstate] = useState({
    name: '',
    email: '',
    phone: '',
    invest_amount: '',
    password: '',
    password_confirmation: ''
  })

  // management input
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setstate({ ...state, [name]: value });
  };

  const handleSubmit = async() => {
    const dataToUpload = JSON.parse(JSON.stringify(state))

    // check data upload invest amount
    if (dataToUpload.invest_amount.includes(',')) {
      dataToUpload.invest_amount = dataToUpload.invest_amount.split(',').join('')
    }

    console.log(dataToUpload)

    setIsLoading(true);

    // upload to api
    await createInvestor(dataToUpload)
    .then((result) => {
      swal("Success", "Berhasil menambahkan Investor", "success").then(() => {
        window.history.back();
      });
      setIsLoading(false);
    })
    .catch((error) => {
      swal("Error", error.response.data.message, "error");
      setIsLoading(false);
    });
  }

  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Tambah Investor</b>
      </Typography>

      <br/>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit()
      }}>
        <Grid container spacing={3} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap' }}>

          <div className={styles.innerContainer}>
            {/* nama */}
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nama"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* email */}
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                style={{ width: "100%" }}
                type={'email'}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* Phone */}
            <Grid item xs={12}>
              <TextField
                name="phone"
                label="No. Hp"
                type="number"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* Invest amount */}
            <Grid item xs={12}>
              <NumberFormat 
                customInput={TextField} 
                name='invest_amount'
                label='Invest Amount'
                style={{ width: "100%" }}
                variant="outlined"
                required
                thousandSeparator={true} 
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Grid>

            <br/><br/>
          </div>

          <div className={styles.innerContainer}>
            {/* password */}
            <Grid item xs={12}>
              <TextField
                name="password"
                label="Password"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* password_confirmation */}
            <Grid item xs={12}>
              <TextField
                name="password_confirmation"
                label="Password Confirmation"
                variant="outlined"
                style={{ width: "100%" }}
                required
                onChange={handleInputChange}
              />
            </Grid>

            <br/><br/>
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default CreateInvestor;
