const style = (theme) => ({
  searchField: {
    border: `1px solid #e5e5e5`,
    borderRadius: 5,
    font: 13,
    color: "#8888",
  },
  cardMedia: {
    height: 140,
  },
});

export default style;
