import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { addECourseCategory } from "../../../../services/axios";
import swal from "sweetalert";

const TambahKategori = withStyles(style)((props) => {
  const { classes, category, onClose } = props;
  const [state, setState] = useState({
    name: "",
  });

  const handleSimpan = () => {
    const formData = new FormData();
    formData.append("name", state.name);
    if (state.name.length === 0){
      swal("Oops!", "Input Nama Kategori", "warning") 
    }else {
      addECourseCategory(formData);
      swal("Success!", "Kategori berhasil ditambahkan", "success").then(() => onClose())
    }
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Typography>
          <b>Tambah Kategori Produk</b>
        </Typography>
        <div style={{ paddingTop: 20 }}>
          <Typography variant="body2" style={{ paddingLeft: 10 }}>
            Nama Kategori
          </Typography>
          <div className={classes.inputStyle}>
            <TextField
              InputProps={{ disableUnderline: true }}
              fullWidth={true}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </div>
        </div>
        <div className={classes.divButton}>
          <Button className={classes.buttonGreen} onClick={handleSimpan}>Simpan</Button>
        </div>
      </div>
    </Fragment>
  );
});

export default TambahKategori;
