import React, { Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  Divider,
  Avatar,
  Grid,
  Checkbox,
  Box,
  Dialog,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import customDate from "../../../../utils/customDate";
import numberSeparator from "../../../../utils/numberSeparator";
import { downloadLabel, getTracking, confirmationOrder, doneOrder } from "../../../../services/axios";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckIcon from "@material-ui/icons/Check";
import { ReactComponent as PremiumIcon } from "assets/icons/icon_premium.svg";
import { ButtonCustom } from "components";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

// icon
import WarningIcon from "@material-ui/icons/Warning";
import DownloadIcon from "@material-ui/icons/GetApp";
import IconProfile from '../../../../assets/icons/Profile.png'
import IconSend from '../../../../assets/icons/Send.png'
import IconUser from '../../../../assets/icons/User.png'
import IconLocation from '../../../../assets/icons/Location.png'
import IconNote from '../../../../assets/icons/Note.png'
import IconEllipse from '../../../../assets/icons/Ellipse.png'
import IconSupplier from '../../../../assets/icons/supplier.png'
import IconHistory1 from '../../../../assets/icons/history/image1.png'
import IconHistory2 from '../../../../assets/icons/history/image2.png'
import IconHistory3 from '../../../../assets/icons/history/image3.png'
import IconHistory4 from '../../../../assets/icons/history/image4.png'
import IconHistory5 from '../../../../assets/icons/history/image5.png'
import IconDolar from '../../../../assets/icons/payments/dollar.svg'
import IconNavigation from '../../../../assets/icons/payments/navigation.svg'
import IconPackage from '../../../../assets/icons/payments/package.svg'
import IconSale from '../../../../assets/icons/payments/sale.svg'
import IconTelevision from '../../../../assets/icons/payments/television.svg'
import IconWA from '../../../../assets/icons/payments/whatsapp.svg'
import swal from "sweetalert";

const allIconHistory = [IconHistory1, IconHistory2, IconHistory3, IconHistory4, IconHistory5]

class CardOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLabelDownloaded: props.data.is_label_downloaded,
      isOpenDialogTracking: false,
      isLoadingTracking: false,
      manifest: [],
      uiStatus: [
        [1, 2, 3, 4, 5],
        [1, 2, 3, 4, 5, 6],
      ],
      middleScreen: window.matchMedia("(min-width: 2300px)").matches,
      litteScreen: window.matchMedia("(min-width: 840px)").matches,
      miniScreen: window.matchMedia("(min-width: 460px)").matches
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.isLabelChecked !== nextProps.isLabelChecked ||
      this.props.data !== nextProps.data ||
      this.state.isLabelDownloaded !== nextState.isLabelDownloaded ||
      this.state.isOpenDialogTracking !== nextState.isOpenDialogTracking ||
      this.state.manifest !== nextState.manifest ||
      this.state.isLoadingTracking !== nextState.isLoadingTracking
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        isLabelDownloaded: this.props.data,
      });
    }
  }

  render() {
    const {
      classes,
      data,
      handleUbahPesanan,
      index,
      isLabelChecked,
      onLabelDownload,
      onToggleDownloadQueue,
      theme,
      onFinishOrder,
      isMobile,
      setIsRefetch
    } = this.props;

    const { isOpenDialogTracking, manifest, middleScreen, litteScreen, miniScreen } = this.state;

    const actionChangeDownloadLabel = async (params) => {
      onLabelDownload(true);
      const response = await downloadLabel(params.id);
      const url = window.URL.createObjectURL(
        new Blob([response], { type: "application/pdf" })
      );
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Order #${params.id}`);
      document.body.appendChild(link);
      link.click();
      this.setState({
        isLabelDownloaded: true,
      });
      onLabelDownload(false);
    };

    const getTotalSummary = (data) => {
      let returnData = 0;
      data.forEach((res) => {
        returnData = returnData + res.reseller_price_idr * res.qty;
      });
      return returnData;
    };

    const sendWhatsappMessageSupplier = (data) => {
      const mutatedPhoneNum = data.supplier.phone.replace(/^0*/, "62");
      const message =
        "Hai supplier " +
        data.supplier.name +
        ", kami dari inventori idejualan ingin memberitahukan bahwa pembayaran pesanan " +
        data.id +
        " telah terkonfirmasi. Mohon segera kirimkan pesanan maksimal 2 hari.";
      window.location.href =
        `https://api.whatsapp.com/send/?phone=` +
        mutatedPhoneNum +
        `&text=` +
        message;
    };

    const sendWhatsappMessageReseller = (data) => {
      const mutatedPhoneNum =
        data.reseller.phone.charAt(0) === "0"
          ? data.reseller.phone.replace(/^0*/, "62")
          : data.reseller.phone;

      const message =
        "Halo kak " +
        data.reseller.name +
        ", Kami bantu ingatkan untuk segera melakukan pembayaran pesanan kakak dengan Order ID " +
        data.id +
        " kakak di idejualan ya. Terimakasih.";
      window.location.href =
        `https://api.whatsapp.com/send/?phone=` +
        mutatedPhoneNum +
        `&text=` +
        message;
    };

    const handleDialogTracking = async (isOpen) => {
      this.setState({
        isOpenDialogTracking: isOpen,
      });

      if (isOpen) {
        try {
          this.setState({
            isLoadingTracking: true,
          });
          const res = await getTracking({
            waybill: data.resi,
            courier: data.courier.code,
          });
          this.setState({
            manifest: res.manifest,
            isLoadingTracking: false,
          });
        } catch (error) {
          this.setState({
            isLoadingTracking: false,
          });
        }
      } else {
        this.setState({
          manifest: [],
        });
      }
    };

    const getDay = (data) => {
      const date = new Date(data);
      const Month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      return `${date.getDate()} ${Month[date.getMonth()]
        } ${date.getFullYear()}`;
    };

    const newCard = () => {
      return(
        <div className={classes.container}>
          <div className={classes.flexBetween}>
            <div>
              <div style={{ fontWeight: 'bold' }}>Order ID <span style={{ color: '#FECF1A' }}>#{data.id}</span></div>
              <div style={{ color: "#a2a2a2" }}>{customDate(new Date(data.created_at))}</div>
            </div>
            <div>
              <Grid
                container
                alignItems="center"
                spacing={1}
                style={{
                  justifyContent:'flex-end',
                  cursor:
                    new RegExp("batal").test(
                      data.histories[data.histories.length - 1]?.message
                    ) && "no-drop",
                }}
              >
                <Grid item>
                  <Checkbox
                    checked={isLabelChecked}
                    onChange={() => onToggleDownloadQueue(data.id)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      data.is_cod && !data.resi
                        ? "Masih menunggu resi COD, silahkan hubungi admin"
                        : ""
                    }
                  >
                    <Button
                      style={{ backgroundColor: '#FECF1A' }}
                      //onClick={() => actionChangeDownloadLabel(data)}
                      variant="outlined"
                      href={
                        "https://api.idejualan.com/printlabel/preview/" +
                        data.id
                      }
                      target="_blank"
                      disabled={data.is_cod && !data.resi}
                    >
                      {this.state.isLabelDownloaded ? (
                        <CheckIcon />
                      ) : (
                        <GetAppIcon />
                      )}
                      Download Label
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleUbahPesanan(data)}
                    style={{ border: '1px solid #FECF1A' }}
                    disabled={
                      new RegExp("batal").test(
                        data.histories[data.histories.length - 1]?.message
                      )
                        ? true
                        : false
                    }
                  >
                    <EditIcon className={classes.EditIcon} />
                    Ubah Pesanan
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.ubahPesanan}
                    onClick={() => sendWhatsappMessageSupplier(data)}
                    style={{ backgroundColor: "#00A884" }}
                    disabled={
                      new RegExp("batal").test(
                        data.histories[data.histories.length - 1]?.message
                      )
                        ? true
                        : false
                    }
                  >
                    WA Supplier
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.ubahPesanan}
                    onClick={() => sendWhatsappMessageReseller(data)}
                    style={{
                      backgroundColor: "#00A884",
                    }}
                    disabled={
                      new RegExp("batal").test(
                        data.histories[data.histories.length - 1]
                          ?.message
                      )
                        ? true
                        : false
                    }
                  >
                    WA Reseller
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>

          <hr style={{ color: '#E6E6E6', border: '.5px solid' }} />

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap' }}>
            <div style={{ width: '40%', minWidth: 340, marginBottom: 5}}>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap:'wrap' }}>
                <div className={classes.flexCenter}>
                  <img style={{ marginRight: 15 }} src={IconSupplier} alt='icon profie' />
                  <div>
                    <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>Supplier</div>
                    <div>{data.supplier.name}</div>
                  </div>
                </div>

                <div className={classes.flexCenter}>
                  <img style={{ marginRight: 15 }} src={IconProfile} alt='icon profie' />
                  <div>
                    <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>Pemesan</div>
                    <div>{data.reseller.name} {data.reseller.activated && (
                      <PremiumIcon
                        width="20"
                        fill={theme.palette.yellow.primary}
                      />
                    )}</div>
                  </div>
                </div>

                <div className={classes.flexCenter}>
                  <img style={{ marginRight: 15 }} src={IconSend} alt='icon profie' />
                  <div>
                    <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>Dikirim Kepada</div>
                    <div>{data.customer ? data.customer.name : "Tidak ada data"}</div>
                  </div>
                </div>

              </div>
              
              {/* card */}
              <div>
                <div style={{ border: '1px dashed black', padding: 8, borderRadius: 6, marginTop: 20 }}>
                  <div className={classes.flexBetween}>
                    <div style={{ width: '26%', height: 100, borderRight: '1px dashed rgba(0,0,0,0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ transform: 'rotate(270deg)' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '.9rem' }}>Order ID</div>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#FECF1A' }}>#{data.id}</div>
                      </div>
                    </div>
                    <div style={{ width: '70%' }}>
                      <div style={{ backgroundColor: '#FECF1A', borderRadius: 6, padding: 20, }}>
                        <div style={{ padding: 5, border: '1px dashed black', marginBottom: 2, borderRadius: 6 }}>
                          Total Bayar: <span style={{ color: '#c51162', fontWeight: 'bold' }}>Rp. {numberSeparator(getTotalSummary(data.items))}</span>
                        </div>
                        <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                          {data.histories[data.histories.length - 1].message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: '25%', display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
              {/* alamat */}
              <div>
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <img style={{ marginRight: 15 }} src={IconLocation} alt='icon location' />
                  <div>Alamat</div>
                </div>
                <div>
                  {data.customer.village ? (
                    <Typography
                      style={{ lineHeight: "initial" }}
                    >
                      {
                        data.customer.village.name + ', '
                        +
                        data.customer.village.district.name + ', '
                        +
                        data.customer.village.district.city.name + ', '
                        +
                        data.customer.village.district.city.province.name + '.'
                      }
                    </Typography>
                  )
                    :
                    (
                      <Typography
                        style={{ lineHeight: "initial" }}
                      >
                        {data.customer.address}
                        {data.customer.subdistrict ?
                          ", " +
                          data.customer.subdistrict?.name +
                          ", " +
                          data.customer.subdistrict?.city?.name +
                          ", " +
                          data.customer.subdistrict?.province?.name
                          :
                          "."
                        }
                      </Typography>
                    )
                  }
                </div>
              </div>

              {/* kurir */}
              <div style={{ marginTop: 10 }}>
                <div>Kurir</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <div style={{ color: '#999', fontSize: '.8rem' }}>
                      {data.shipping.courier.name}
                      <br />
                      {data.shipping.courier_type &&
                        "Layanan : " + data.shipping.courier_type}
                      <br />
                      {data.shipping.estimated && "Est : " + data.shipping.estimated}
                      <br />
                      {data.is_cod ? "Order COD" : "Tidak Menggunakan COD"}
                      <br />
                      {"Tipe Pengiriman : " + data.shipping.shipment_type}
                    </div>

                    <div>
                      {data.resi_upload && (
                        <Button
                          variant="contained"
                          size="small"
                          href={data.resi_upload}
                          target="_blank"
                          color="primary"
                          style={{
                            marginTop: 10,
                            color: "#fff",
                            textTransform: "none",
                            fontWeight: 500,
                          }}
                          endIcon={<DownloadIcon />}
                        >
                          Download Resi Dropship
                        </Button>
                      )}
                    </div>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <div>
                      <img
                        src={data.shipping.courier.icon_url}
                        alt=""
                        height={50}
                        width={50}
                      />
                    </div>

                    <div><span style={{ color: '#c51162', fontWeight: 'bold' }}>Rp. {numberSeparator(data.shipping.cost)}</span></div>
                  </div>
                </div>
              </div>
            </div>
            
            <div style={{ width: '30%', display: 'flex', justifyContent: 'start', marginLeft: 20 }}>
              <div className={classes.stepperWrapper}>
                {this.state.uiStatus[data.histories.length === 6 ? 1 : 0].map((res, index) => (
                  <Fragment key={res}>
                    <div
                      className={classes.roundStepper}
                      style={{
                        backgroundImage: !data.histories[index] && `url(${IconEllipse})`,
                      }}
                    />
                    {index !== (data.histories.length === 6 ? 5 : 4) && (
                      <div
                        className={classes.stepper}
                        style={{
                          border: !data.histories[index + 1] && '.1px dashed #d3d3d3',
                        }}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
              <div style={{ display: "flex", flexDirection: 'column', marginLeft: 10 }}>
                {data.histories.map((res, index) => (
                  <div style={{ width: '100%', height: 70, overflow: 'hidden' }} key={res.id}>
                    <Typography variant="caption">{res.message}</Typography>
                    <Typography
                      variant="caption"
                      style={{ color: "#a2a2a2", display: "block" }}
                    >
                      {getDay(res.timestamp)}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Order summary */}
          <br />
          <div>
            <div>
              Order Summary
            </div>
            <div>
              {data.items.map((res, index) => {
                let productSKU = null;
                let productBundling = null;

                if (res.sku && res.sku !== null) {
                  productSKU = (
                    <Grid
                      container
                      justify="flex-end"
                      key={res.id}
                      spacing={2}
                      style={{
                        color: "#a2a2a2",
                        paddingTop: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Avatar
                            alt="Produk"
                            src={res?.sku?.image_url}
                            className={classes.large}
                            style={{ marginRight: 10 }}
                          />

                          <Grid item>
                            <div style={{ textAlign: "right" }}>
                              <span>
                                {res?.sku?.product.name.length > 43
                                  ? res?.sku?.product.name.slice(0, 43) + "..."
                                  : res?.sku?.product.name}
                              </span>
                              <Typography>
                                ( {res?.sku?.properties?.material},
                                {res?.sku?.properties?.color},
                                {res?.sku?.properties?.size} )
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <span>X{res.qty}</span>
                      </Grid>
                      <Grid item>
                        <span>{numberSeparator(res.reseller_price_idr)}</span>
                        {data.is_cod ? (
                          <span>
                            <br></br>(COD Price :{" "}
                            {numberSeparator(res.consumer_price_idr)})
                          </span>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  );
                }

                if (res.bundling && res.bundling !== null) {
                  productBundling = res.bundling.items.map((bundling) => (
                    <Grid
                      container
                      key={res.id + bundling.id}
                      style={{
                        color: "#a2a2a2",
                        paddingTop: 10,
                        display: "flex",
                        justifyContent: "flex-end",
                        fontWeight: "bold",
                      }}
                      spacing={2}
                    >
                      <Grid item>
                        <Grid container spacing={1}>
                          <Avatar
                            alt="Produk"
                            src={bundling.sku.image}
                            className={classes.large}
                            style={{ marginRight: 10 }}
                          />
                        </Grid>
                        <Grid item>
                          <div style={{ textAlign: "right" }}>
                            <span>
                              {bundling.name.length > 43
                                ? bundling.name.slice(0, 43) + "..."
                                : bundling.name}
                            </span>
                            <Typography>
                              ( {bundling.sku.properties.material},
                              {bundling.sku.properties.color},
                              {bundling.sku.properties.size} )
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <span>X{res.qty}</span>
                      </Grid>
                      <Grid item>
                        <span>
                          {numberSeparator(bundling.reseller_price_idr)}
                        </span>
                      </Grid>
                    </Grid>
                  ));
                }

                return (
                  <Fragment key={res.id}>
                    {productSKU}
                    {productBundling}
                  </Fragment>
                );
              })}
            </div>
          </div>
          <br />

          <div className={classes.flexBetween} style={{ marginTop: 10, alignItems: 'start', flexWrap:'wrap' }}>
            {/* notes */}
            <div>
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <img style={{ marginRight: 15 }} src={IconNote} alt='icon note' />
                <div>Order Note</div>
              </div>
              <div style={{ color: '#d3d3d3' }}>
                {data.customer_note ? data.customer_note : "-"}
              </div>
            </div>

            {/* tipe pembayaran */}
            <div>
              <div >
                Tipe pembayaran
              </div>
              <Typography
                variant="body2"
                style={{ color: "#999" }}
              >
                {data.payment && data.payment.payment_type
                  ? data.payment.payment_type.replace("_", " ")
                  : "Tidak ada data"}
              </Typography>
              <div>
                Jumlah Koin
              </div>
              <Typography
                variant="body2"
                style={{ color: "#999" }}
              >
                {data.payment && data.payment.coin
                  ? data.payment.coin
                  : "Tidak ada koin digunakan"}
              </Typography>
            </div>

            {/* catatan pembatalan */}
            <div>
              <div>
                Catatan pembatalan
              </div>
              <Typography
                variant="body2"
                style={{ color: "#999" }}
              >
                {data.cancel_note || "-"}
              </Typography>
            </div>

            {/* Voucher */}
            <Grid item>
              <div>
                Voucher
              </div>
              <Typography
                variant="body2"
                style={{ color:'#999' }}
              >
                {data.voucher
                  ? data.voucher.name + "(" + data.voucher.type + ")"
                  : "Tidak Pakai Voucher"}
                <br></br>
                {data.voucher
                  ? "Rp. " + numberSeparator(data.voucher.amount)
                  : ""}
              </Typography>
            </Grid>

            {/* dropship */}
            <div>
              <Grid item>
                <div>
                  Dropship
                </div>
                <img
                  alt="MP"
                  width={50}
                  src={
                    data.is_dropship
                      ? data.marketplace.icon
                      : "https://i.pinimg.com/736x/4f/11/e6/4f11e623f48c7cb463ea2f60deea1e40.jpg"
                  }
                  className={classes.large}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ color: "#999" }}
                >
                  {data.is_dropship
                    ? data.marketplace.code
                    : "Tidak Pakai Dropship"}
                </Typography>
              </Grid>
              {data.status === 40 && (
                <Box mt={2}>
                  <Button
                    onClick={() => handleDialogTracking(true)}
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Lacak
                  </Button>
                </Box>
              )}
            </div>

            <Dialog
              open={isOpenDialogTracking}
              onClose={() => handleDialogTracking(false)}
              fullWidth
              maxWidth="sm"
            >
              <Box p={4}>
                {manifest.length > 0 ? (
                  <Stepper orientation="vertical">
                    {manifest.map((step) => (
                      <Step key={step.manifest_code} active>
                        <StepLabel>{step.city_name}</StepLabel>
                        <StepContent>
                          <Typography variant="caption">
                            {step.manifest_date} : {step.manifest_time}
                          </Typography>
                          <Typography>{step.manifest_description}</Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  "Resi tidak valid"
                )}
              </Box>
            </Dialog>
          </div>

        </div>
      )
    }

    const orderConfirmationCompleted = async (id) => {
      try {
        const response = await confirmationOrder({
          order_id: id
        })
        if (response) {
          swal("Order success dikonfirmasi !", {
            icon: "success",
          }).then(setIsRefetch(true))
        }
      } catch (error) {
        swal(error.message);
      }
    }

    const orderConfirmationDone = async (id) => {
      try {
        const response = await doneOrder({
          order_id: id
        })
        if (response) {
          swal("Order success diselesaikan !", {
            icon: "success",
          }).then(setIsRefetch(true))
        }
      } catch (error) {
        swal(error.message);
      }
    }

    return (
      <div 
      style={
        litteScreen ? 
        {position: 'relative', marginTop: 30}
        :
        miniScreen ?
          {position: 'relative', marginTop: 124}
          :
          {position: 'relative', marginTop: 154}
      }
      >
        {/* header */}
        <div className={
          litteScreen ? 
          classes.headerBox
          :
          miniScreen ?
            classes.headerBoxMobile
            :
            classes.headerBoxMobileMini
        }
        style={{backgroundColor: data.is_maklon ? '#6941C6' : '#FDCA40' }}
        >
          <div class={classes.se}></div>
          <div class={classes.sw}></div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 25,marginLeft: 25, flexWrap: 'wrap', width: '100%'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography variant="h6">
                <b>#{index}</b>
              </Typography>
              <div style={{ paddingLeft: 10 }}>
                <Typography variant="body2" style={{fontSize: '1rem'}}>
                  Order ID <b style={{color: 'white'}}>#{data.id}</b>
                </Typography>
                <Typography variant="body2" style={{ color: "#fff", fontSize: '.6rem' }}>
                  {customDate(new Date(data.created_at))}
                </Typography>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
              <Grid item>
                <Checkbox
                  checked={isLabelChecked}
                  onChange={() => onToggleDownloadQueue(data.id)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    data.is_cod && !data.resi
                      ? "Masih menunggu resi COD, silahkan hubungi admin"
                      : ""
                  }
                >
                  <Button
                    style={{ marginRight: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#6941C6', color: 'white', padding: 3, borderRadius: 7 }}
                    // onClick={() => actionChangeDownloadLabel(data)}
                    variant="outlined"
                    href={
                      "https://api.idejualan.com/printlabel/preview/" +
                      data.id
                    }
                    target="_blank"
                    disabled={data.is_cod && !data.resi}
                  >
                    {this.state.isLabelDownloaded ? (
                      <CheckIcon style={{width: 20}} />
                    ) : (
                      <GetAppIcon style={{width: 20}} />
                    )}
                    Download Label
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                {data.status === 45 && (
                  <Button
                    style={{ marginRight: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'white', borderRadius: 7, backgroundColor: '#2F80ED', padding: 3 }} 
                    onClick={() => {orderConfirmationDone(data.id)}}
                    variant="outlined"
                    >
                    Selesaikan Pesanan
                  </Button>
                )}
              </Grid>

              <Grid item>
                <Button
                  className={classes.ubahPesanan}
                  onClick={() => handleUbahPesanan(data)}
                  style={{ marginRight: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: '#EB5757', padding: 3, borderRadius: 7, color: 'white' }}
                  disabled={
                    new RegExp("batal").test(
                      data.histories[data.histories.length - 1]?.message
                    )
                      ? true
                      : false
                  }
                >
                  <EditIcon style={{width: 20}} />
                  Ubah Pesanan
                </Button>
              </Grid>

              {/* button konfirmasi pesanan */}
              <Grid item>
                {data.status === 40 && (
                  <Button
                    style={{ marginRight: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'white', borderRadius: 7, backgroundColor: 'hsla(242,88.4%,66.3%,1)', padding: 3 }} 
                    onClick={() => {orderConfirmationCompleted(data.id)}}
                    variant="outlined"
                    >
                    Konfirmasi Pesanan Sampai
                  </Button>
                )}
              </Grid>
            </div>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.ne}></div>
          <div className={classes.nw}></div>

          {/* body */}
          <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 10, width: '100%'}}>
            {/* left */}
            <div style={
              middleScreen ? 
              {minWidth: 240 ,width: '75%'}
              :
              litteScreen ? 
              {minWidth: 240 ,width: '70%'}
              :
              {minWidth: 240 ,width: '100%'}
            }>
              <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                <div 
                  style={ 
                    middleScreen ?
                    {display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minWidth: 240, width: '20%'}
                    :
                    {display: 'flex', flexDirection: 'column', minWidth: 240, width: '100%'}
                  }
                >
                  {/* perhatian */}
                  <div>
                    {data.automatic_cancel_at && (
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ backgroundColor: "#F2D6D3", color: '#C03221',borderRadius: 4, border: '1px solid #C03221', margin: 5, padding: 3 }}
                      >
                        <Box mr={1}>
                          <WarningIcon style={{ fill: "red", fontSize: 10 }} />
                        </Box>
                        <Box style={{ fontSize: 12 }}>
                          <b>PENTING : </b>{" "}
                          {data.status === 30 && (
                            <span>
                              Mohon kirim sebelum tanggal{" "}
                              <b>
                                {moment(data.automatic_cancel_at).format("DD MMMM YYYY")}
                              </b>
                              , jika melebihi tanggal yang di tentukan, maka pesanan akan
                              otomatis dibatalkan
                            </span>
                          )}
                          {data.status === 20 && (
                            <span>
                              Mohon diproses sebelum tanggal{" "}
                              <b>
                                {moment(data.automatic_cancel_at).format("DD MMMM YYYY")}
                              </b>
                              , jika melebihi tanggal yang di tentukan, maka pesanan akan
                              otomatis dibatalkan
                            </span>
                          )}
                        </Box>
                      </Box>
                    )}
                  </div>

                  {/* customer details */}
                  <div style={
                    {height: '100%', background: 'white', borderRadius: 4, padding: 10, margin: 3,boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'}
                  }>
                    <div>Customer Details</div>
                    <hr/>
                    
                    <div style={{display: 'flex', flexDirection: 'column', padding: 10}}>
                      {/* pemesan */}
                      <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <img style={{ marginRight: 20 }} src={IconProfile} alt='icon profie' />
                        <div>
                          <div style={{fontSize: '.9rem', color: 'rgba(0,0,0,0.3)'}}>Pemesan</div>
                          <div 
                            style={{cursor: 'pointer', textDecoration: 'underline'}}
                            onClick={() => sendWhatsappMessageReseller(data)} 
                          >
                            {data.reseller.name} {data.reseller.activated && (
                              <PremiumIcon
                                width="20"
                                fill={theme.palette.yellow.primary}
                              />
                            )}
                            <span><img src={IconWA} alt='icon wa'/></span>
                          </div>
                        </div>
                      </div>

                      <br/>

                      {/* supplier */}
                      <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <img style={{ marginRight: 15 }} src={IconSupplier} alt='icon profie' />
                        <div>
                          <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>Supplier</div>
                          <div 
                            style={{cursor: 'pointer', textDecoration: 'underline'}}
                            onClick={() => sendWhatsappMessageSupplier(data)}
                            disabled={
                              new RegExp("batal").test(
                                data.histories[data.histories.length - 1]?.message
                              )
                                ? true
                                : false
                            }
                          >
                            {data.supplier.name}
                            <span><img src={IconWA} alt='icon wa'/></span>
                          </div>
                        </div>
                      </div>

                      <br/>

                      {/* PIC */}
                      {
                        data.reseller_pic ? 
                        <div style={{display: 'flex',alignItems: 'center'}}>
                          <img style={{ marginRight: 15 }} src={IconSupplier} alt='icon profie' />
                          <div>
                            <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>PIC</div>
                            <div>{data.reseller_pic.name}</div>
                          </div>
                        </div>
                        :
                        <>Tidak ada PIC</>
                      }

                      <br/>

                      {/* penerima */}
                      <div style={{display: 'flex',alignItems: 'center'}}>
                        <img style={{ marginRight: 15 }} src={IconProfile} alt='icon profie' />
                        <div>
                          <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>Dikirim Kepada</div>
                          <div>{data.customer ? data.customer.name : "Tidak ada data"}</div>
                        </div>
                      </div>

                      <br/>

                      {/* alamat */}
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                          <img style={{ marginRight: 15 }} src={IconLocation} alt='icon location' />
                          <div>
                            <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>Alamat</div>
                            <div>
                              {data.customer.village ? (
                                <Typography
                                  variant="caption"
                                  style={{ lineHeight: "initial" }}
                                >
                                  {
                                    data.customer.village.name + ', '
                                    +
                                    data.customer.village.district.name + ', '
                                    +
                                    data.customer.village.district.city.name + ', '
                                    +
                                    data.customer.village.district.city.province.name + '.'
                                  }
                                </Typography>
                              )
                                :
                                (
                                  <Typography
                                    variant="caption"
                                    style={{ lineHeight: "initial" }}
                                  >
                                    {data.customer.address}
                                    {data.customer.subdistrict ?
                                      ", " +
                                      data.customer.subdistrict?.name +
                                      ", " +
                                      data.customer.subdistrict?.city?.name +
                                      ", " +
                                      data.customer.subdistrict?.province?.name
                                      :
                                      "."
                                    }
                                  </Typography>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>                      
                      <br/>

                      {/* no telp */}
                      <div style={{display: 'flex',alignItems: 'center'}}>
                        <img style={{ marginRight: 15 }} src={IconSend} alt='icon profie' />
                        <div>
                          <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }}>No Telp</div>
                          <div>{data.customer ? data.customer.phone : "Tidak ada data"}</div>
                        </div>
                      </div>

                      <br/>
                    </div>
                  </div>
                </div>
                <div 
                  style={
                    middleScreen ? 
                    {minWidth: 240 ,background: 'white', borderRadius: 4, padding: 10, margin: 3,boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', width: '78%'}
                    :
                    {minWidth: 240 ,background: 'white', borderRadius: 4, padding: 10, margin: 3,boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', width: '100%'}
                  }
                >
                  <div>Ordered Item</div>

                  <hr/>

                  {/* tabel produk */}
                  <div style={{ overflowX: 'scroll'}}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell>QTY</TableCell>
                          <TableCell>Harga</TableCell>
                          <TableCell>Total Harga</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.items.map((res, index) => {
                          let productSKU = null;
                          let productBundling = null;

                          if (res.sku && res.sku !== null) {
                            productSKU = (
                              <>
                                <TableCell>
                                  <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                                    <img src={res?.sku?.image_url} alt={res?.sku?.product.name} style={{ width: 50, borderRadius: 8, marginRight: 5}}/>

                                    <div>
                                      <div style={{fontSize: '.8rem', color: '#EB5757'}}>
                                        {res?.sku?.product.category.name}
                                      </div>
                                      <div style={{fontSize: '.9rem'}}>
                                        {res?.sku?.product.name.length > 30
                                          ? res?.sku?.product.name.slice(0, 30) + "..."
                                          : res?.sku?.product.name} 
                                      </div>
                                      <div style={{fontSize: '.9rem'}}>
                                        ( {res?.sku?.properties?.material},
                                        {res?.sku?.properties?.color},
                                        {res?.sku?.properties?.size} )
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <span>X{res.qty}</span>
                                </TableCell>
                                <TableCell>
                                  <span>{numberSeparator(res.reseller_price_idr)}</span>
                                  {data.is_cod ? (
                                    <span>
                                      <br></br>(COD Price :{" "}
                                      {numberSeparator(res.consumer_price_idr)})
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell>
                                  <span>{numberSeparator(res.reseller_price_idr * res.qty)}</span>
                                  {data.is_cod ? (
                                    <span>
                                      <br></br>(COD Price :{" "}
                                      {numberSeparator(res.consumer_price_idr * res.qty)})
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </>
                            );
                          }

                          if (res.bundling && res.bundling !== null) {
                            productBundling = res.bundling.items.map((bundling) => (
                              <>
                                <TableCell>
                                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={res?.sku?.image_url} alt={res?.sku?.product.name} style={{ width: 50, borderRadius: 8, marginRight: 5}}/>

                                    <div>
                                      <div style={{fontSize: '.8rem', color: '#EB5757'}}>
                                        {res?.sku?.product.category.name}
                                      </div>
                                      <div style={{fontSize: '.9rem'}}>
                                        {res?.sku?.product.name.length > 30
                                          ? res?.sku?.product.name.slice(0, 30) + "..."
                                          : res?.sku?.product.name} 
                                      </div>
                                      <div style={{fontSize: '.9rem'}}>
                                        ( {res?.sku?.properties?.material},
                                        {res?.sku?.properties?.color},
                                        {res?.sku?.properties?.size} )
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <span>X{res.qty}</span>
                                </TableCell>
                                <TableCell>
                                  <span>
                                    {numberSeparator(bundling.reseller_price_idr)}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span>
                                    {numberSeparator(bundling.reseller_price_idr * res.qty)}
                                  </span>
                                </TableCell>
                              </>
                            ));
                          }

                          return (
                            <TableRow key={res.id}>
                              {productSKU}
                              {productBundling}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>

                  <br/>

                  {/* total bayar dan note pelanggan */}
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'start'}}>
                    {/* order note */}
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'start', borderRadius: '4px', padding: 6, border: data.is_maklon ? '2px solid red' : 'none' }}>
                      <div>Order Note : {" "}</div>
                      <div style={{ fontSize: '.8rem', color: 'rgba(0,0,0,0.3)' }}>{data.customer_note ? data.customer_note : "-"}</div>
                    </div>

                    {/* total bayar */}
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '.9rem', color: 'rgba(0,0,0,30%)' }}>
                        <div>Total :</div>
                        <div style={{ marginLeft: 5 }}>
                          Rp. {numberSeparator(getTotalSummary(data.items))}
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '.9rem', color: 'rgba(0,0,0,30%)' }}>
                        <div>Perkiraan Ongkir :</div>
                        <div style={{ marginLeft: 5 }}>Rp. {numberSeparator(data.shipping_cost)}</div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '.9rem', color: 'rgba(0,0,0,30%)' }}>
                        <div>Koin Yang Dipakai :</div>
                        <div style={{ marginLeft: 5 }}>
                          {data.payment && data.payment.coin
                            ? data.payment.coin
                            : "-"}
                        </div>
                      </div>

                      <hr/>

                      <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>Subtotal :</div>
                        <b style={{ marginLeft: 5 }}>
                          Rp. {numberSeparator(getTotalSummary(data.items) + data.shipping_cost)}
                        </b>
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              {/* payment details */}
              <div style={{background: 'white', borderRadius: 4, padding: 10, margin: 3,boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'}}>
                <div>Payment Details</div>

                <hr/>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                  
                  {/* tipe pembayaran */}
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <img style={{ marginRight: 10, width: 15 }} src={IconDolar} alt='icon location' />
                    <div>
                      <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }} >Tipe Pembayaran</div>
                      <div>
                        {data.payment && data.payment.payment_type
                          ? data.payment.payment_type.replace("_", " ")
                          : "Tidak ada data"}
                      </div>
                    </div>
                  </div>

                  {/* dropship */}
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ marginRight: 10, width: 15 }} src={IconPackage} alt='icon location' />
                    <div>
                      <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }} >Dropship</div>
                      <div>
                        {data.is_dropship
                          ? data?.marketplace?.code
                          : "Tidak Dropship"}
                      </div>
                    </div>
                  </div>

                  {/* COD */}
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ marginRight: 10, width: 15 }} src={IconNavigation} alt='icon location' />
                    <div>
                      <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }} >COD</div>
                      <div>
                        {data.is_cod ? "Order COD" : "Tidak COD"}
                      </div>
                    </div>
                  </div>

                  {/* voucher */}
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ marginRight: 10, width: 15 }} src={IconSale} alt='icon location' />
                    <div>
                      <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }} >VOUCHER</div>
                      <div>
                      {data.voucher
                        ? data.voucher.name + "(" + data.voucher.type + ")"
                        : "Tidak Voucher"}
                      </div>
                    </div>
                  </div>

                  {/* maklon */}
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ marginRight: 10, width: 15 }} src={IconTelevision} alt='icon location' />
                    <div>
                      <div style={{ fontSize: '.9rem', color: 'rgba(0,0,0,0.3)' }} >MAKLON</div>
                      <div>
                      {data.is_maklon ? "Maklon" : "Tidak Maklon"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* right */}
            <div style={
              litteScreen ? 
              {background: 'white', borderRadius: 4, padding: 10, margin: 3,boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', minWidth: 240, width: '20%'}
              :
              {background: 'white', borderRadius: 4, padding: 10, margin: 3,boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', minWidth: 240, width: '100%'}
              }>
              <div>Order History</div>

              <hr/>

              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'inherit', flexDirection: 'column', height: '80%'}}>
                {/* history */}
                <div style={{display: 'flex', justifyContent: 'start', marginLeft: 20 }}>
                  <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'column', marginRight: 10 }}>
                    {
                      allIconHistory.map((res, index) => 
                        <div key={index + 'iconhistory'} style={{height: 35}}>
                          <img src={res} alt='icon history' style={{width: 20}}/>
                        </div>
                      )
                    }
                  </div>
                  <div className={classes.stepperWrapper}>
                    {this.state.uiStatus[data.histories.length === 6 ? 1 : 0].map((res, index) => (
                      <Fragment key={res}>
                        <div
                          className={classes.roundStepper}
                          style={{
                            backgroundImage: !data.histories[index] && `url(${IconEllipse})`,
                          }}
                        />
                          {index !== (data.histories.length === 6 ? 5 : 4) && (
                            <div
                              className={classes.stepper}
                              style={{
                                border: !data.histories[index + 1] && '.1px dashed #d3d3d3',
                              }}
                            />
                          )}
                      </Fragment>
                    ))}
                  </div>
                  <div style={{ display: "flex", flexDirection: 'column', marginLeft: 10 }}>
                    {data.histories.map((res, index) => (
                      <div style={{ width: '100%', height: 132, overflow: 'hidden' }} key={res.id}>
                        <Typography style={{fontSize: 15}}>{res.message}</Typography>
                        <Typography
                          style={{ color: "#a2a2a2", display: "block", fontSize: 14 }}
                        >
                          {getDay(res.timestamp)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>

                <br/>

                {/* kurir */}
                <div>
                  <hr/>

                  <div>Kurir</div>

                  <br/>

                  <div style={{margin: 'auto', textAlign: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img
                        src={data.shipping.courier.icon_url}
                        alt=""
                        width={100}
                      />

                      <div style={{marginLeft: 10}}>
                        {data.shipping.courier_type &&
                          "Layanan : " + data.shipping.courier_type}
                        <br />
                        {data.shipping.estimated && "Est : " + data.shipping.estimated}
                        <br />
                      </div>
                    </div>

                    <br/>

                    {/* resi */}
                    <div>
                      <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <div>RESI: </div>
                        <div>
                          {
                          data.resi ? 
                            data.histories[data.histories.length - 1].status === 30 ?
                              <div onClick={() => handleUbahPesanan(data)} style={{cursor: 'pointer'}}>
                                <div style={{color: '#FFCB00', textDecoration: 'underline'}}>{data.resi}</div>
                              </div>
                              :
                              data.resi
                            : 
                            data.histories[data.histories.length - 1].status === 30 ?
                              <div onClick={() => handleUbahPesanan(data)} style={{cursor: 'pointer'}}>
                                <div style={{color: '#FFCB00', textDecoration: 'underline'}}>belum ada resi</div>
                              </div>
                              :
                              'belum ada resi'
                          }
                        </div>
                      </div>
                      {
                        data.histories[data.histories.length - 1].status === 30 &&
                        <div style={{marginTop: 5, color: '#969696', fontSize: '.8rem'}}>*Click Untuk Update Resi</div>
                      }
                    </div>

                    <br/>
                    
                    {data.status === 40 && (
                      <Box mt={2}>
                        <Button
                          onClick={() => handleDialogTracking(true)}
                          size="small"
                          variant="contained"
                          color="primary"
                        >
                          Lacak
                        </Button>
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={isOpenDialogTracking}
          onClose={() => handleDialogTracking(false)}
          fullWidth
          maxWidth="sm"
        >
          {this.state.isLoadingTracking ? (
            <Box p={4} display="flex" justifyContent="center">
              <CircularProgress size={40} />
            </Box>
          ) : (
            <Box p={4}>
              {manifest.length > 0 ? (
                <Stepper orientation="vertical">
                  {manifest.map((step) => (
                    <Step key={step.manifest_code} active>
                      <StepLabel>{step.city_name}</StepLabel>
                      <StepContent>
                        <Typography variant="caption">
                          {step.manifest_date} : {step.manifest_time}
                        </Typography>
                        <Typography>{step.manifest_description}</Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                "Resi tidak valid"
              )}
            </Box>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(style)(withTheme(CardOrder));
