import React from "react";
import moment from "moment";

import { formatRupiah } from "../../../utils/currencyFormat";

import {
  Grid,
  Box,
  Paper,
  Typography,
  Chip,
  Badge,
  Avatar,
  Button,
} from "@material-ui/core";

const CardOrder = ({ data: { payment, reseller } }) => {
  return (
    <Paper elevation={2} style={{ marginBottom: 20, padding: 20 }}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box>
          <Box mb={1}>
            <Typography variant="caption">
              Tanggal upgrade:{" "}
              <b>
                {moment(reseller.upgraded_at).format("DD MMMM YYYY hh:mm:ss")}
              </b>
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="caption">
              Metode pembayaran: <b>{payment.payment_type}</b>
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="caption">
              Jumlah: <b>Rp.{formatRupiah(payment.amount)}</b>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Chip
            color="primary"
            label={reseller.activated ? "Aktif" : "Tidak aktif"}
          />
        </Box>
      </Box>

      <Box mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar src={reseller.avatar} />
          </Grid>
          <Grid item>
            <Badge color="primary" badgeContent={reseller.level.id}>
              <Box pr={1}>{reseller.name}</Box>
            </Badge>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="subtitle1">Email</Typography>
            <Typography>{reseller.email}</Typography>
            <br></br>
            <Typography variant="subtitle1">PIC</Typography>
            <Typography>
              {reseller.pic ? reseller.pic.name : "Belum Punya PIC"}
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="subtitle1">No.Telepon</Typography>
            <a href={`https://wa.me/${reseller.phone}`} target="_blank">
              <Button variant="contained" color="primary">
                Whatsapp - {reseller.phone}
              </Button>
            </a>
            <br></br>
            <br></br>
            <Typography variant="subtitle1">Follow Up</Typography>
            <a
              href={`https://wa.me/${reseller.phone}?text=Hai kak ${reseller.name}, selamat datang di idejualan 😊 Perkenalkan saya CS Support idejualan yang akan membantu kakak. Apabila kakak punya pertanyaan atau kendala lain bisa kakak tanyakan ke saya yaa😊 _Ingin update info produk dan promo idejualan? Balas pesan ini dengan angka 1 untuk mendapatkan link grup WA reseller idejualan_`}
              target="_blank"
            >
              <Button variant="contained" color="secondary">
                Whatsapp - {reseller.phone}
              </Button>
            </a>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CardOrder;
