import { Button, makeStyles, Typography } from '@material-ui/core'
import { AddIcon } from '@material-ui/data-grid';
import React from 'react'
import { Link } from 'react-router-dom';
import Table from './Table/Table';

const useStyles = makeStyles({
  title: {
    marginBottom: '30px',
  },
  button: {
    marginBottom: '30px',
  }
});

const Campaign = () => {
  const classes = useStyles();
  const jwt = require("jsonwebtoken");

  const METABASE_SITE_URL = "https://metabase.idejualan.com";
  const METABASE_SECRET_KEY = "e7bdca3d0944133dbc3784b9c219db735b1a14423c78633ebeba054ea8868632";

  const payload = {
    resource: { dashboard: 5 },
    params: {},
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };
  const token = jwt.sign(payload, METABASE_SECRET_KEY);

  const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        Data Campaign
      </Typography>

      <Link to="/campaign/create">
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddIcon />}
        >
          Tambah Campaign
        </Button>
      </Link>

      {/* metabase */}
      <div style={{margin: '10px 0 10px 0'}}>
        <iframe
              title='metabase_campaign'
              src={iframeUrl}
              width='100%'
              style={{minHeight: 650}}
              frameborder="0"
              allowtransparency
              allowfullscreen
          ></iframe>
      </div>

      <Table />
    </div>
  )
}

export default Campaign
