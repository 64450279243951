import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import BankTable from './BankTable/BankTable';

const useStyles = makeStyles({
  title: {
    marginBottom: '30px',
  },
});

const Bank = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        Akun Bank
      </Typography>

      <BankTable />
    </div>
  )
}

export default Bank
