const style = (theme) => ({
  root: {
    padding: 20,
  },
  inputStyle: {
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  buttonGreen: {
    backgroundColor: theme.palette.green.main,
    textTransform: "capitalize",
    paddingLeft: 30,
    paddingRight: 30,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#44bf8a",
    },
  },
  divButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
  },
});

export default style;
