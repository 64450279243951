import { axiosInstance } from "../config/fetchAxios";

export const getCourierRecomendation = async () => {
    const response = axiosInstance.get(`/api/v2/options/courier-recommendation`);
    return response;
};

export const editCourierRecomendation = async (data) => {
    const response = axiosInstance.post(`/api/v2/options/courier-recommendation`, data);
    return response;
};