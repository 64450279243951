import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";

const useTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    minWidth: "200px",
  },
});

const Table = () => {
  const classes = useTableStyles();
  const [filterSearch, setFilterSearch] = React.useState("");

  return (
    <Paper elevation={3}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item>
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Cari reseller..."
                value={filterSearch}
                onChange={(event) => setFilterSearch(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      {/* Table */}
      <TableContent filterSearch={filterSearch} />
    </Paper>
  );
};

export default Table;
