import React, { useState, useEffect } from 'react'
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { getReseller } from 'services/axios';
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Toolbar, Typography } from "@material-ui/core";

const headCells = [
    { id: "id", label: "No" },
    { id: "name", label: "Reseller" },
    { id: 'order_id', label: 'Order Id' },
    { id: "jumlah", label: "Jumlah" },
    { id: "status", label: "Status" },
];

const useToolbarStyles = makeStyles((theme) => ({
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const PenaltyTable = ({ dataPenalties, paginate, page, setpage, setpaginate, total }) => {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setpage(newPage+1);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setpaginate(parseInt(event.target.value, 10));
        setpage(1);
      };

    return (
        <div>

            <TableToolbar />

            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                >
                    <TableHeader />
                    <TableBody>
                        {
                            dataPenalties.map((res, index) =>
                                <TableRow key={index + 'tablePenaltynew'}>
                                    <TableCell>{res.id}</TableCell>
                                    {
                                        
                                        <TableCell key={index + 'cellReseller'}>{res.reseller ? res.reseller?.name + ' ( ' + res.reseller?.email + ' )': '-'}</TableCell>
                                    }
                                    <TableCell>{res.order_id}</TableCell>
                                    <TableCell>{res.amount}</TableCell>
                                    <TableCell>{res.status}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={paginate}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}

const TableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={clsx(classes.root)}>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Penalty
            </Typography>
        </Toolbar>
    );
};

function TableHeader() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default PenaltyTable