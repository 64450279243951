const style = (theme) => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexFullCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
  },
  tambahGambar: {
    border: `1px solid ${theme.palette.green.main}`,
    height: 125,
    width: 125,
    cursor: 'pointer',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%'
  },
  inputGambar: {
    opacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  tambahGambar1: {
    height: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 50,
    color: theme.palette.green.main
  },
  tambahGambar2: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.green.main,
  },
  buttonGreen: {
    backgroundColor: theme.palette.green.main,
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#44bf8a',
    }
  },
  komisi: {
    display: "flex",
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
    width: "100%",
    justifyContent: 'flex-end'
  },
  textKomisi: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 15,
    width: 50,
    alignItems: "center",
    justifyContent: 'center',
    backgroundColor: theme.palette.green.main,
    borderRadius: "0 30px 30px 0",
    color: "#ffffff",
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#3db581",
    }
  },
})

export default style;