import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from "@material-ui/core";
import styles from "./styles";
import React, { useState, useEffect } from "react";

const Modal = withStyles(styles)((props) => {
  const { open, onClose, classes, onSubmit, selectedItem } = props;

  const [state, setState] = useState({
    payload: {
      id: null,
      value: "",
    },
  });

  const handleChange = (event) => {
    const target = event.target;
    setState({
      ...state,
      payload: {
        ...state.payload,
        [target.name]: target.value,
      },
    });
  };

  const submit = (event) => {
    onClose();
    onSubmit(state.payload);
  };

  useEffect(() => {
    if (!open) return;
    setState({
      payload: {
        method: selectedItem ? "edit" : "add",
        id: selectedItem ? selectedItem.id : null,
        value: selectedItem ? selectedItem.value : "",
      },
    });
  }, [open, selectedItem]);

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{!selectedItem ? "Tambah" : "Edit"} Daily coin</DialogTitle>
        <DialogContent>
          <div style={{ maxWidth: 500 }}>
            <div>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Value"
                value={state.payload.value}
                variant="outlined"
                name="value"
                onChange={handleChange}
                error={state.titleError}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button onClick={onClose} color="secondary">
            Batal
          </Button>
          <Button variant="contained" color="secondary" onClick={submit}>
            {!selectedItem ? "Tambah" : "Edit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default Modal;
