import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { Paper, Typography } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { getChartDaily, getChartMonthly } from "../../../../services/axios";

const SummaryPenjualan = withStyles(styles)((props) => {
  const [chartDaily, setChartDaily] = useState({});
  const [filterDailyMonth, setFilterDailyMonth] = useState("Harian");
  const { classes } = props;

  useEffect(() => {
    if (filterDailyMonth === "Harian") {
      const fetch = async () => {
        const report = await getChartDaily();
        setChartDaily(report);
        console.log("getChartDaily", report);
      };
      fetch();
    }
  }, [filterDailyMonth]);

  useEffect(() => {
    if (filterDailyMonth === "Bulanan") {
      const fetch = async () => {
        const report = await getChartMonthly();
        setChartDaily(report);
        console.log("getChartDaily", report);
      };
      fetch();
    }
  }, [filterDailyMonth]);
  console.log(chartDaily.incomes);

  function handleChange(e) {
    setFilterDailyMonth(e.target.value);
  }

  const data = {
    labels:
      chartDaily.incomes &&
      Object.keys(chartDaily.incomes && chartDaily.incomes),

    datasets: [
      {
        label: "Pemasukan",
        // lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        fill: true,
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        // pointBorderWidth: 1,
        // pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        // pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartDaily.incomes && Object.values(chartDaily.incomes),
      },
      // {
      //   label: "Pengeluaran",
      //   fill: false,
      //   // lineTension: 0.1,
      //   backgroundColor: "rgb(147 75 192)",
      //   borderColor: "rgb(147 75 192)",
      //   borderCapStyle: "butt",
      //   borderDash: [],
      //   borderDashOffset: 0.0,
      //   borderJoinStyle: "miter",
      //   pointBorderColor: "rgb(147 75 192)",
      //   pointBackgroundColor: "rgb(147 75 192)",
      //   // pointBorderWidth: 1,
      //   // pointHoverRadius: 5,
      //   pointHoverBackgroundColor: "rgb(147 75 192)",
      //   pointHoverBorderColor: "rgb(147 75 192)",
      //   // pointHoverBorderWidth: 2,
      //   pointRadius: 1,
      //   pointHitRadius: 10,
      //   data: [3000, 5000, 900, 5500, 4200, 6200],
      // },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
  };

  return (
    <Fragment>
      <Typography className={classes.title}>
        <b>Statistik</b>
      </Typography>
      <Paper className={classes.wrapper}>
        <div className={classes.bag1}>
          <Typography>Analitik Keuangan</Typography>
          <select onChange={handleChange} className={classes.filter}>
            <option>Harian</option>
            <option>Bulanan</option>
          </select>
        </div>

        <Line data={data} height={250} options={options} />
      </Paper>
    </Fragment>
  );
});

export default SummaryPenjualan;
