import React from "react";
import { ButtonCustomWrap } from "./style";

const ButtonCustom = ({
  onClick,
  icon,
  color,
  children,
  withBorder = false,
  bgColor,
  style,
  isDisabled = false
}) => {
  return (
    <ButtonCustomWrap
      bgColor={bgColor}
      color={color}
      onClick={onClick}
      withBorder={withBorder}
      style={style}
      disabled={isDisabled}
    >
      {icon && <img src={icon} alt="button-custom" />}
      <p>{children}</p>
    </ButtonCustomWrap>
  );
};

export default ButtonCustom;
