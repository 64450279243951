import { Grid, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons';
import FormVoucher from 'containers/FormVoucher';
import React from 'react'

const CreateVoucher = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingRight: 20 }}>
          <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}>
            <ArrowBack
              style={{ paddingRight: 10, cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            <b>Buat Voucher</b>
          </Typography>
          <div style={{ paddingTop: 16 }}>
          <FormVoucher {...props} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateVoucher