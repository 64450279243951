const style = (theme) => ({
  container: {
    background: theme.palette.yellow.primary,
    boxShadow: "0px 4px 20px rgba(141, 106, 198, 0.1)",
    borderRadius: 5,
    padding: "30px 20px",
    display: "flex",
    justifyContent: "space-between",
  },
  photoprofile: {
    height: 80,
    width: 80,
    borderRadius: "50%",
  },
  username: {
    color: theme.palette.white.primary,
    fontWeight: "bold",
  },
  titlename: {
    color: theme.palette.white.primary,
  },
  verticaldot: {
    paddingLeft: 20,
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  house: {
    position: 'absolute',
    marginLeft: -200,
    marginTop: -50,
    height: 160,
  },
  storeImg: {
    borderRadius: '50%',
    objectFit: 'cover'
  },
  flexCenterVertical: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonEdit: {
    backgroundColor: theme.palette.red.main,
    borderRadius: 30,
    color: '#ffffff',
    padding: '6px 40px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#d45352',
    }
  }
});

export default style;
