import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  TextField,
  Input,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { editECourseCategory } from "../../../../services/axios";
import swal from "sweetalert";

const EditKategori = withStyles(style)((props) => {
  const { classes, category, onClose, data, parrent } = props;
  const [state, setState] = useState({
    name: data.name,
  });


  const handleSimpan = async () => {
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("_method", "PATCH");
    if (state.name.length === 0) {
      swal("Oops!", "Input Nama Kategori", "warning");
    } else {
      const response = await editECourseCategory(data.id, formData);
      if (response.updated){
        swal("Success!", "Kategori berhasil diupdate", "success").then(() =>
        onClose()
      );
      }
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Typography>
          <b>Edit Kategori E-Course</b>
        </Typography>
        <div style={{ marginTop: 20 }}>
          {data.children && (
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              color="primary"
              onClick={() => setState({ ...state, is_root_node: 1 })}
            >
              Kategori Utama
            </Button>
          )}
        </div>
        <div style={{ paddingTop: 20 }}>
          <Typography variant="body2" style={{ paddingLeft: 10 }}>
            Nama Kategori
          </Typography>
          <div className={classes.inputStyle}>
            <TextField
              InputProps={{ disableUnderline: true }}
              fullWidth={true}
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </div>
        </div>

        <div className={classes.divButton}>
          <Button className={classes.buttonGreen} onClick={handleSimpan}>
            Simpan
          </Button>
        </div>
      </div>
    </Fragment>
  );
});

export default EditKategori;
