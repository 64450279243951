import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Table from "./Table";

const useStyles = makeStyles({
  title: {
    marginBottom: "30px",
  },
});

const Bank = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        History Toupup Dompet Jualan
      </Typography>

      <Table />
    </div>
  );
};

export default Bank;
