import { objToParam } from "utils/params";
import { axiosInstance } from "../config/fetchAxios";

// Authorize
// ===========

export const login = async (data) => {
  const response = axiosInstance.post(`/api/v1/auth/admin/login`, data);
  return response;
};

// GET

export const getSupplierCategories = async (slug) => {
  const response = axiosInstance.get(`/api/v1/suppliers/${slug}/categories`);
  return response;
};

export const getAnnoucements = async (filter) => {
  const response = axiosInstance.get(`/api/v1/announcements${filter || ""}`);
  return response;
};

export const getNotification = async (pagination) => {
  const response = axiosInstance.get(
    `/api/v1/admins/1/notifications?paginate=${pagination || 10}`
  );
  return response;
};

export const getReportSummary = async (date) => {
  const response = axiosInstance.get(`api/v1/reports/summary${date ? `?between=${date},${date}`:''}`);
  return response;
};

export const getReportSummaryFilter = async (toDates, endDates) => {
  const response = axiosInstance.get(
    `api/v1/reports/summary?between=${toDates},${endDates}`
  );
  return response;
};

export const getChartDaily = async () => {
  const response = axiosInstance.get(`api/v1/chart/daily`);
  return response;
};

export const getChartMonthly = async () => {
  const response = axiosInstance.get(`api/v1/chart/monthly`);
  return response;
};

export const getChartSummaryGrafik = async () => {
  const response = axiosInstance.get(`api/v1/reports/summary`);
  return response;
};

export const getResellerStatistic = async (mode = "daily") => {
  const response = axiosInstance.get(`api/v1/chart/reseller-statistic/${mode}`);
  return response;
};

export const getAllCategories = async () => {
  const response = axiosInstance.get(`/api/v1/categories`);
  return response;
};

export const getAllECategories = async () => {
  const response = axiosInstance.get(`/api/v2/e-categories`);
  return response;
};

export const getAllECourseCategories = async () => {
  const response = axiosInstance.get(`/api/v2/ecourse-categories`);
  return response;
};

export const getAllProducts = async (filter) => {
  if (typeof filter == "object") {
    filter = "?" + objToParam(filter);
  }

  const response = axiosInstance.get(`/api/v1/products${filter || ""}`);
  return response;
};

export const getAllOrders = async (filter) => {
  const response = axiosInstance.get(`/api/v1/orders${filter || "?page=1"}&include=cart.customer.village.district.city.province`);
  return response;
};

export const confirmationOrder = async (data) => {
  const response = axiosInstance.put(`/api/v1/orders/delivered`, data);
  return response;
};

export const doneOrder = async (data) => {
  const response = axiosInstance.put(`/api/v1/orders/done`, data);
  return response;
};

export const getReseller = async (filter) => {
  if (typeof filter == "object") {
    filter = "?" + objToParam(filter);
  }

  const response = axiosInstance.get(`/api/v1/resellers${filter || ""}`);
  return response;
};

export const getSupplier = async (filter) => {
  const response = axiosInstance.get(
    `/api/v1/suppliers?include=village.district.city.province&${filter || ""}`
  );
  return response;
};

export const exportSupplier = async (filter) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/export-suppliers${filter}`;
  const response = axiosInstance.get(url, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("TOKEN"),
    },
  });
  return response;
};

export const getProvince = async () => {
  const response = axiosInstance.get(`/api/v1/support/location/provinces`);
  return response;
};

export const getCity = async (id_province) => {
  const response = axiosInstance.get(
    `/api/v1/support/location/cities?filter[province.id]=${id_province}`
  );
  return response;
};

export const getAllCity = async () => {
  const response = axiosInstance.get(`/api/v1/support/location/cities`);
  return response;
};

export const getSubdistrict = async (id) => {
  const response = axiosInstance.get(
    `/api/v1/support/location/subdistricts?city_id=${id}`
  );
  return response;
};

export const getProvinces = async () => {
  const response = axiosInstance.get(`/api/v2/support/location/provinces`);
  return response;
};

export const getCitiesByProvinceId = async (provinceId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/cities/?filter[province.id]=${provinceId}`
  );
  return response;
};

export const getDistrictsByCityId = async (cityId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/districts/?filter[city.id]=${cityId}`
  );
  return response;
};

export const getSubDistrictsByDisctrictId = async (districtId) => {
  const response = axiosInstance.get(
    `/api/v2/support/location/subdistricts/?filter[district.id]=${districtId}`
  );
  return response;
};

export const getCustomer = async (filter) => {
  const response = axiosInstance.get(`/api/v1/customers${filter || ""}`);
  return response;
};

export const getAdmin = async (filter) => {
  const response = axiosInstance.get(`/api/v1/admins${filter || ""}`);
  return response;
};

export const getCouriers = async () => {
  const response = axiosInstance.get(`/api/v1/support/shipping/couriers`);
  return response;
};

export const getPrice = async () => {
  const response = axiosInstance.get(`/api/v1/options/upgrade-account`);
  return response;
};

// DELETE

export const deleteProducts = async (slug) => {
  const response = axiosInstance.delete(`/api/v1/products/${slug}`);
  return response;
};

export const deleteReseller = async (id) => {
  const response = axiosInstance.delete(`/api/v1/resellers/${id}`);
  return response;
};

export const deleteSupplier = async (id) => {
  const response = axiosInstance.delete(`/api/v1/suppliers/${id}`);
  return response;
};

export const deleteCustomer = async (id) => {
  const response = axiosInstance.delete(`/api/v1/customers/${id}`);
  return response;
};

export const deleteAdmin = async (id) => {
  const response = axiosInstance.delete(`/api/v1/admins/${id}`);
  return response;
};

export const deleteCategory = async (id) => {
  const response = axiosInstance.delete(`/api/v1/categories/${id}`);
  return response;
};

export const deleteECategory = async (id) => {
  const response = axiosInstance.delete(`/api/v2/e-categories/${id}`);
  return response;
};

export const deleteECourseCategory = async (id) => {
  const response = axiosInstance.delete(`/api/v2/ecourse-categories/${id}`);
  return response;
};

export const deleteAnnoucements = async (id) => {
  const response = axiosInstance.delete(`/api/v1/announcements/${id}`);
  return response;
};

// POST

export const createAnnoucements = async (body) => {
  const response = axiosInstance.post(`/api/v1/announcements`, body);
  return response;
};

export const updateAnnoucements = async (id, body) => {
  const response = axiosInstance.post(`/api/v1/announcements/${id}`, body);
  return response;
};

export const addSupplier = async (data) => {
  const response = axiosInstance.post(`/api/v1/suppliers`, data);
  return response;
};

export const addReseller = async (data) => {
  const response = axiosInstance.post(`/api/v1/resellers`, data);
  return response;
};

export const addCustomer = async (data) => {
  const response = axiosInstance.post(`/api/v1/customers`, data);
  return response;
};

export const editReseller = async (id, data) => {
  const response = axiosInstance.post(`/api/v1/resellers/${id}`, data);
  return response;
};

export const editCustomer = async (id, data) => {
  const response = axiosInstance.post(`/api/v1/customers/${id}`, data);
  return response;
};

export const editAdmin = async (id, data) => {
  const response = axiosInstance.post(`/api/v1/admins/${id}`, data);
  return response;
};

export const editSupplier = async (id, data) => {
  const response = axiosInstance.post(`/api/v1/suppliers/${id}`, data);
  return response;
};

export const editSubscription = async (id, data) => {
  const response = axiosInstance.post(
    `/api/v2/resellers/${id}/set-subscription`,
    data
  );
  return response;
};

export const addAdmin = async (data) => {
  const response = axiosInstance.post(`/api/v1/admins`, data);
  return response;
};

export const addProduct = async (data) => {
  const response = axiosInstance.post(`/api/v1/products`, data);
  return response;
};

export const editProduct = async (slug, data, isPost) => {
  const response = axiosInstance[isPost ? "post" : "patch"](
    `/api/v1/products/${slug}`,
    data
  );
  return response;
};

export const editProductCustom = async (slug, data) => {
  const response = axiosInstance.put(`/api/v1/products/${slug}`, data);
  return response;
};

export const editSkus = async (slug, code, data) => {
  const response = axiosInstance.patch(
    `/api/v1/products/${slug}/skus/${code}`,
    data
  );
  return response;
};

export const addSkus = async (slug, data) => {
  const response = axiosInstance.post(`/api/v1/products/${slug}/skus`, data);
  return response;
};

export const deleteSkus = async (slug, code) => {
  const response = axiosInstance.delete(
    `/api/v1/products/${slug}/skus/${code}`
  );
  return response;
};

export const addCategory = async (data) => {
  const response = axiosInstance.post(`/api/v1/categories`, data);
  return response;
};

export const addECategory = async (data) => {
  const response = axiosInstance.post(`/api/v2/e-categories`, data);
  return response;
};

export const addECourseCategory = async (data) => {
  const response = axiosInstance.post(`/api/v2/ecourse-categories`, data);
  return response;
};

export const editCategory = async (id, data) => {
  const response = axiosInstance.post(`/api/v1/categories/${id}`, data);
  return response;
};

export const editECategory = async (id, data) => {
  const response = axiosInstance.post(`/api/v2/e-categories/${id}`, data);
  return response;
};
export const editECourseCategory = async (id, data) => {
  const response = axiosInstance.post(`/api/v2/ecourse-categories/${id}`, data);
  return response;
};

export const syncCourierSupplier = async (slug, data) => {
  const response = axiosInstance.post(
    `/api/v1/suppliers/${slug}/couriers`,
    data
  );
  return response;
};

export const updateOrder = async (slug, code, data) => {
  const response = axiosInstance.post(
    `/api/v1/suppliers/${slug}/orders/${code}`,
    data
  );
  return response;
};

export const downloadLabel = async (code) => {
  const response = axiosInstance.get(`/api/v1/orders/downloadLabel/${code}`, {
    responseType: "arraybuffer",
  });
  return response;
};

export const getAppBanner = async (body) => {
  const response = axiosInstance.get(`/api/v1/options/app-banner`);
  return response;
};

export const createAppBanner = async (body) => {
  const response = axiosInstance.post(`/api/v1/options/app-banner`, body);
  return response;
};

export const editAppBanner = async (id, data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(`/api/v1/options/app-banner/${id}`, data);
  return response;
};

export const deleteAppBanner = async (id) => {
  const response = axiosInstance.delete(`/api/v1/options/app-banner/${id}`);
  return response;
};

export const getPopup = async (body) => {
  const response = axiosInstance.get(`/api/v1/options/popup`);
  return response;
};

export const createPopup = async (body) => {
  const response = axiosInstance.post(`/api/v1/options/popup`, body);
  return response;
};

export const editPopup = async (id, data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(`/api/v1/options/popup/${id}`, data);
  return response;
};

export const deletePopup = async (id) => {
  const response = axiosInstance.delete(`/api/v1/options/popup/${id}`);
  return response;
};

export const getECourses = async (params) => {
  const response = axiosInstance.get(`/api/v2/ecourses`, {
    params: params,
  });
  return response;
};

export const addECourse = async (data) => {
  const response = axiosInstance.post(`/api/v2/ecourses`, data);
  return response;
};

export const editECourse = async (data, slug) => {
  const response = axiosInstance.post(`/api/v2/ecourses/${slug}`, data);
  return response;
};

export const deleteECourse = async (slug) => {
  const response = axiosInstance.delete(`/api/v2/ecourses/${slug}`);
  return response;
};

export const getECourseCategories = async () => {
  const response = axiosInstance.get(`/api/v2/ecourse-categories`);
  return response;
};

export const getDailyCoins = async () => {
  const response = axiosInstance.get(`/api/v2/coin-daily-logins`);
  return response;
};

export const createDailyCoin = async (body) => {
  const response = axiosInstance.post(`/api/v2/coin-daily-logins`, body);
  return response;
};

export const editDailyCoin = async (id, data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(`/api/v2/coin-daily-logins/${id}`, data);
  return response;
};

export const deleteDailyCoin = async (id) => {
  const response = axiosInstance.delete(`/api/v2/coin-daily-logins/${id}`);
  return response;
};

export const getRewardOmzets = async (resellerId, level) => {
  const response = axiosInstance.get(`/api/v2/reward-omzets`, {
    params: {
      "filter[reseller_id]": resellerId,
      "filter[level]": level,
    },
  });
  return response;
};

export const createRewardOmzet = async (body) => {
  const response = axiosInstance.post(`/api/v2/reward-omzets`, body);
  return response;
};

export const editRewardOmzet = async (id, data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(`/api/v2/reward-omzets/${id}`, data);
  return response;
};

export const deleteRewardOmzet = async (id) => {
  const response = axiosInstance.delete(`/api/v2/reward-omzets/${id}`);
  return response;
};

export const getResellerLevels = async () => {
  const response = axiosInstance.get(`/api/v2/reseller-levels`);
  return response;
};

export const getResellerRewardOmzets = async (params) => {
  const response = axiosInstance.get(`/api/v2/resellers-reward-omzets`, {
    params: params,
  });

  return response;
};

export const exportResellerXLS = (params) => {
  const response = axiosInstance.get(`/api/v1/resellers/export-excel`, {
    params: params,
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("TOKEN"),
    },
  });

  return response;
};

export const getTracking = async (body) => {
  const response = axiosInstance.post(
    `/api/v1/support/shipping/tracking`,
    body
  );
  return response;
};

export const claimReward = async (id) => {
  const response = axiosInstance.post(`/api/v2/reward-omzets/${id}/claim`, {});
  return response;
};

export const updatePrice = async (data) => {
  const response = axiosInstance.put(`/api/v1/options/upgrade-account`, data);
  return response;
};

export const getPopupSupplier = async (body) => {
  const response = axiosInstance.get(`/api/v1/options/popup-supplier`);
  return response;
};

export const createPopupSupplier = async (body) => {
  const response = axiosInstance.post(`/api/v1/options/popup-supplier`, body);
  return response;
};

export const editPopupSupplier = async (id, data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(
    `/api/v1/options/popup-supplier/${id}`,
    data
  );
  return response;
};

export const deletePopupSupplier = async (id) => {
  const response = axiosInstance.delete(`/api/v1/options/popup-supplier/${id}`);
  return response;
};


// voucher
export const getVoucher = (page, paginate, filterValue, status) => {
  const url = `/api/v2/my-vouchers/orders?paginate=${paginate}&page=${page}&filter[search]=${filterValue}&filter[voucher.type]=${status}`;

  const response = axiosInstance.get(url);

  return response;
};

// top promotions
export const getTopPromotions = () => {
  const url = `/api/v1/options/top-promotions`;

  const response = axiosInstance.get(url);

  return response;
};

export const editTopPromotions = async (data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(`/api/v1/options/top-promotions`, data);
  return response;
};

// important information
export const getImportantInformations = () => {
  const url = `/api/v2/important-information`;

  const response = axiosInstance.get(url);

  return response;
};

export const createImportantInformation = async (body) => {
  const response = axiosInstance.post(`/api/v2/important-information`, body);
  return response;
};

export const deleteImportantInformation = async (id) => {
  const response = axiosInstance.delete(`/api/v2/important-information/${id}`);
  return response;
};

export const editImportantInformation = async (id, data) => {
  data.append("_method", "PUT");
  const response = axiosInstance.post(`/api/v2/important-information/${id}`, data);
  return response;
};