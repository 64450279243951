import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Badge,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { getResellerRewardOmzets, claimReward } from "../../services/axios";
import Pagination from "@material-ui/lab/Pagination";
import Loading from "../../components/loading";
import { formatRupiah } from "../../utils/currencyFormat";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import swal from "sweetalert";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const ResellerOmzet = withStyles(style)((props) => {
  const { classes } = props;
  const [omzets, setOmzets] = useState(null);
  const [resellerId, setResellerId] = useState("");
  const [level, setLevel] = useState("");
  const [role, setRole] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterRole, setFilterRole] = useState([]);
  const [meta, setmeta] = useState()

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async (page = currentPage) => {
    const response = await getResellerRewardOmzets({
      level: level,
      role: role,
      "filter[reseller_id]": resellerId,
      page: page,
    });
    setmeta({
      total: response.data.total,
      from: response.data.from,
    })
    setOmzets(response.data.data);
    setTotal(response.data.last_page);
    setFilterRole(response.reseller_roles);
    setFilterLevel(response.omzet_levels);
  };

  const handleChangePage = (e, value) => {
    setCurrentPage(value);
    fetch(value);
  };

  const handleFilter = () => {
    setCurrentPage(1);
    fetch(1);
  };

  const handleClaim = async (id) => {
    try {
      await claimReward(id);
      swal("Success!", "Berhasil claim reward", "success").then(() => fetch());
    } catch (error) {
      swal("Failed!", "Claim reward gagal", "warning");
    }
  };

  return (
    <Fragment>
      {!omzets ? (
        <Loading />
      ) : (
        <Fragment>
          <Paper style={{ marginTop: 30 }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ padding: 20 }}
            >
              <Grid item>
                <Select
                  variant="outlined"
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                  label="Filter Level"
                  displayEmpty
                >
                  <MenuItem value={""}>Filter Level</MenuItem>
                  {filterLevel.map((v) => (
                    <MenuItem value={v} key={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Select
                  variant="outlined"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  label="Filter role"
                  displayEmpty
                >
                  <MenuItem value={""}>Filter Role</MenuItem>
                  {filterRole.map((v) => (
                    <MenuItem value={v.id} key={v.id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Reseller ID"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    value={resellerId}
                    onChange={(event) => setResellerId(event.target.value)}
                  />
                </div>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleFilter}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Nama</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Total Omzet</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {omzets &&
                    omzets.map((row, index) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="left">
                          <Badge badgeContent={row.omzet_level} color="primary">
                            <Box pr={1}>
                              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{
                                  marginRight: 5, 
                                  backgroundColor: '#FDCA40', 
                                  borderRadius: '50%', 
                                  padding: 8, 
                                  height: 20,
                                  width: 20,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                >
                                  <Typography variant="h6">
                                    <b>#{meta.total - (meta.from + (index - 1))}</b>
                                  </Typography>
                                </div>
                                <div>{row.name}</div>
                              </div>
                            </Box>
                          </Badge>
                          <Box mt={1}>
                            <Accordion expand>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                Lihat reward
                              </AccordionSummary>
                              <AccordionDetails>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell align="left">
                                        Gambar
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Deskripsi
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Level
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        Aksi
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {(row.reward_claims || []).map(
                                      ({ reward_omzet, id, is_claimed }, i) => (
                                        <>
                                          {reward_omzet && (
                                            <StyledTableRow>
                                              <StyledTableCell align="left">
                                                <img
                                                  width={100}
                                                  height={100}
                                                  src={reward_omzet.images}
                                                />
                                              </StyledTableCell>
                                              <StyledTableCell align="left">
                                                {reward_omzet.reward_desc}
                                              </StyledTableCell>
                                              <StyledTableCell align="left">
                                                {reward_omzet.level}
                                              </StyledTableCell>
                                              <StyledTableCell align="left">
                                                <Button
                                                  onClick={() =>
                                                    handleClaim(id)
                                                  }
                                                  disabled={is_claimed}
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                >
                                                  {is_claimed
                                                    ? "Sudah di claim"
                                                    : "Claim"}
                                                </Button>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          )}
                                        </>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Rp.{formatRupiah(row.total_omzet)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {omzets && omzets.length === 0 && (
              <div className={classes.notFound}>
                <div className={classes.notFoundChild}>
                  <Typography variant="h4" style={{ fontWeight: "bold" }}>
                    Oops!
                  </Typography>
                  <Typography>Data tidak ditemukan.</Typography>
                </div>
              </div>
            )}

            <div className={classes.pagination}>
              <Pagination
                color="secondary"
                onChange={handleChangePage}
                page={currentPage}
                count={total}
              />
            </div>
          </Paper>
        </Fragment>
      )}
    </Fragment>
  );
});

export default ResellerOmzet;
