import { axiosInstance } from "../config/fetchAxios";

export const getAllCampaign = async (params) => {
    const response = axiosInstance.get(`/api/v2/campaigns`, { params });
    return response;
};

export const getDetailCampaign = async (slug) => {
    const response = axiosInstance.get(`/api/v2/campaigns/${slug}`);
    return response;
};

export const createCampaign = async (data) => {
    const response = axiosInstance.post(`/api/v2/campaigns`, data);
    return response;
};

export const updateCampaign = async (slug, data) => {
    data.append("_method", "PUT");
    const response = axiosInstance.post(`/api/v2/campaigns/${slug}`, data);
    return response;
};

export const deleteCampaign = async (slug) => {
    const response = axiosInstance.delete(`/api/v2/campaigns/${slug}`);
    return response;
};