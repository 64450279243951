import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

export default function DocumentsDialog({ data }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyledButton onClick={handleClickOpen}>Lihat toko</StyledButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box mb={1}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <img width={100} height={100} src={data.avatar_url} />
                </Grid>
                <Grid item>{data.name}</Grid>
              </Grid>
            </Box>
            <Box mb={1}>
              <Typography variant="h6">Alamat</Typography>
              <Typography variant="caption">{data.address}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h6">Deskripsi</Typography>
              <Typography variant="caption">{data.description}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h6">Email</Typography>
              <Typography variant="caption">{data.email}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h6">No.HP</Typography>
              <Typography variant="caption">{data.phone}</Typography>
            </Box>
            <Box mb={1}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>COD : {data.is_cod ? "Ya" : "Tidak"}</Grid>
                <Grid item>Dropship : {data.is_dropship ? "Ya" : "Tidak"}</Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
