import React, { useState, useEffect } from 'react'
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Toolbar, Typography } from "@material-ui/core";
import moment from "moment";

const headCells = [
    { id: "voucher_id", label: "ID" },
    { id: 'name', label: 'Nama' },
    { id: "type", label: "Tipe" },
    { id: "reseller", label: "Reseller" },
    { id: "jumlah", label: "Jumlah" },
    { id: "tanggal", label: "Tanggal" },
];

const useToolbarStyles = makeStyles((theme) => ({
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const VoucherTable = ({ dataVoucher, paginate, page, setpage, setpaginate, total }) => {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setpage(newPage+1)
    }

    const handleChangePaginate = (event) => {
        setpaginate(parseInt(event.target.value));
        setpage(1);
    }

    return (
        <div>

            <TableToolbar />

            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                >
                    <TableHeader />
                    <TableBody>
                        {
                            dataVoucher.map((res, index) =>
                                <TableRow key={index + 'tableVouchernew'}>
                                    <TableCell>{res.id}</TableCell>
                                    <TableCell>{res.voucher.name}</TableCell>
                                    <TableCell>{res.voucher.type}</TableCell>
                                    {
                                        
                                        <TableCell key={index + 'cellReseller'}>{res.reseller ? res.reseller?.name + ' ( ' + res.reseller?.email + ' )': '-'}</TableCell>
                                    }
                                    <TableCell>{res.amount}</TableCell>
                                    <TableCell>{moment(res.created_at).format("DD MMM YYYY HH:mm:ss")}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={paginate}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangePaginate}
            />
        </div>
    )
}

const TableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={clsx(classes.root)}>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Voucher
            </Typography>
        </Toolbar>
    );
};

function TableHeader() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default VoucherTable