import { Tabs, Tab } from "@material-ui/core";
import { SearchCustom } from "components";
import React, { useState, useEffect } from "react";
import { DataTableVoucherWithTabsWrap } from "./style";
import moment from 'moment'
import DataTableVoucher from "containers/DataTableVoucher";
import { GET_VOUCHER, GET_VOUCHER_NAME } from "services/voucher.service";

const DataTableVoucherWithTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  const [dataAllPromo, setDataAllPromo] = useState([])
  const [isRefetch, setIsRefetch] = useState(false)
  const [totalData,setTotalData] = React.useState(0)
  const [page,setPage] = React.useState(1)

  useEffect(() => {
    const fetchMyVoucher = async () => {
      let myVoucher = []
      let totalData = 0
      let status = ''

      if(activeTab === 1) status = 'active'
      else if(activeTab === 2) status = 'upcoming'
      else if(activeTab === 3) status = 'expired'

      await GET_VOUCHER(page,status).then( res => {
        res.data.map( data => {
          myVoucher.push({
            id: data.id,
            name: data.name,
            type: data.type,
            used: data.used,
            amount: data.amount,
            startDate: data.start_date,
            endDate: data.end_date,
          })
          return''
        })

        totalData = res.meta.total
      })
      setDataAllPromo(myVoucher)
      setTotalData(totalData)
    }
    
    if(isRefetch) {
      setDataAllPromo([])
      fetchMyVoucher()
      setIsRefetch(false)
    } else {
      fetchMyVoucher()
    }
  }, [isRefetch, page, activeTab])

  const handleFilterName = async (name) => {
    let myVoucher = []
    let totalData = 0
    let status = ''

    if(activeTab === 1) status = 'active'
    else if(activeTab === 2) status = 'upcoming'
    else if(activeTab === 3) status = 'expired'
    
    await GET_VOUCHER_NAME(name, status).then( res => {
      res.data.map( data => {
        myVoucher.push({
          id: data.id,
          name: data.name,
          type: data.type,
          used: data.used,
          amount: data.amount,
          startDate: data.start_date,
          endDate: data.end_date,
        })
        return''
      })

      console.log(res)

      totalData = res.meta.total
    })
    setDataAllPromo([])
    setTotalData(null)
    setDataAllPromo(myVoucher)
    setTotalData(totalData)
  }

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );
  return (
    <DataTableVoucherWithTabsWrap>
      <Tabs value={activeTab} onChange={(e, newValue) => {
        setPage(1)
        setActiveTab(newValue)
      }}>
        <Tab label="Semua" />
        <Tab label="Sedang Berjalan" />
        <Tab label="Akan Datang" />
        <Tab label="Telah Berakhir" />
      </Tabs>
      <div style={{padding: '24px 16px'}}> 
        <SearchCustom onChange={null} onSearch={handleFilterName} withIcon />
      </div>
      <TabPanel activeTab={activeTab} index={0}>
        <DataTableVoucher totalData={totalData} rowsData={dataAllPromo} setIsRefetch={setIsRefetch} setPage={setPage} page={page} {...props}/>
      </TabPanel>
      <TabPanel activeTab={activeTab} index={1}>
        <DataTableVoucher rowsData={dataAllPromo} setIsRefetch={setIsRefetch} {...props} setPage={setPage} page={page} totalData={totalData}  />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={2}>
        <DataTableVoucher rowsData={dataAllPromo} setIsRefetch={setIsRefetch} {...props} setPage={setPage} page={page} totalData={totalData}  />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={3}>
        <DataTableVoucher rowsData={dataAllPromo} setIsRefetch={setIsRefetch} {...props} setPage={setPage} page={page} totalData={totalData}  />
      </TabPanel>
    </DataTableVoucherWithTabsWrap>
  );
};

export default DataTableVoucherWithTabs;
