const style = (theme) => ({
  container: {
    padding: "40px 25px",
  },
  bgFilter: {
    backgroundColor: "rgba(182, 202, 255, 0.38)",
    border: "1px solid #ced4da",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 24px 5px 12px",
    borderRadius: 30,
    marginLeft: 10,
    cursor: "pointer",
  },
  filterButton: {
    backgroundColor: theme.palette.red.main,
    padding: "10px 12px",
    borderRadius: 30,
    color: "#ffffff",
    marginLeft: 10,
    cursor: "pointer",
  },
});

export default style;
