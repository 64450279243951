import React, { useState, useEffect } from 'react'
import { makeStyles, Paper, Typography } from "@material-ui/core";
import PenaltyTable from './component/penaltyTable';
import { GET_ALL_PENALTIES } from 'services/penalty.service';
import SearchTable from './component/searchTable';

const useStyles = makeStyles({
    title: {
        marginBottom: "30px",
    },
});

const Penalty = () => {
    const classes = useStyles();
    const [dataPenalties, setdataPenalties] = useState([])
    const [page, setpage] = useState(1)
    const [paginate, setpaginate] = useState(5)
    const [filterValue, setfilterValue] = useState('')
    const [status, setstatus] = useState('')
    const [total, settotal] = useState()

    useEffect(() => {
        const getDataPenalties = async () => {
            const response = await GET_ALL_PENALTIES(page, paginate, filterValue, status);
            setdataPenalties(response.data)
            settotal(response.meta.total)
        }

        getDataPenalties()
    }, [filterValue, page, paginate, status])

    const handleChangePage = (value) => {
        setpage(value)
    }

    const handleChangePaginate = (value) => {
        setpaginate(value)
    }

    const handleChangeFilterValue = (value) => {
        setfilterValue(value)
    }

    const handleChangeStatus = (value) => {
        setstatus(value)
    }

    return (
        <div>
            <Typography variant="h2" className={classes.title}>
                Penalty
            </Typography>

            <div>
                <Paper elevation={3}>
                    <SearchTable filterValue={filterValue} setfilterValue={handleChangeFilterValue} status={status} setstatus={handleChangeStatus} />

                    <PenaltyTable dataPenalties={dataPenalties} page={page} paginate={paginate} setpage={handleChangePage} setpaginate={handleChangePaginate} total={total} />
                </Paper>
            </div>
        </div>
    )
}

export default Penalty