const styles = (theme) => ({
  bgIcon: {
    backgroundColor: theme.palette.yellow.primary,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12
  },
  wrapper: {
    display: 'flex',
    padding: '30px 10px',
  },
  title: {
    paddingBottom: 20,
    paddingTop: 30
  }
})

export default styles;
