import { axiosInstance } from "../config/fetchAxios";
import { objToParam } from "utils/params";

export const getAllProductDropship = async (filter) => {
    const response = axiosInstance.get(`/api/v2/dropship/products?page=${filter.page}&paginate=${filter.paginate}`);
    return response;
};

export const getSpesificProductDropship = async (id) => {
    const response = axiosInstance.get(`/api/v2/dropship/products/${id}`);
    return response;
};

export const createProductDropship = async (data) => {
    const response = axiosInstance.post(`/api/v2/dropship/products`, data);
    return response;
};

export const editProductDropship = async (id, data) => {
    data.append("_method", "PUT");
    const response = axiosInstance.post(`/api/v2/dropship/products/${id}`, data);
    return response;
};

export const deleteProductDropship = async (id) => {
    const response = axiosInstance.delete(`/api/v2/dropship/products/${id}`);
    return response;
};


// dropshipper
export const getAllDropshipper = async (filter) => {
    const response = axiosInstance.get(`/api/v2/dropship/dropshipers?page=${filter.page}&paginate=${filter.paginate}`);
    return response;
};

export const getSpesificDropshipper = async (id) => {
    const response = axiosInstance.get(`/api/v2/dropship/dropshipers/${id}`);
    return response;
};

export const createDropshipper = async (data) => {
    const response = axiosInstance.post(`/api/v2/dropship/dropshipers`, data);
    return response;
};

export const editDropshipper = async (id, data) => {
    const response = axiosInstance.post(`/api/v2/dropship/dropshipers/${id}`, data);
    return response;
};

export const deleteDropshipper = async (id) => {
    const response = axiosInstance.delete(`/api/v2/dropship/dropshipers/${id}`);
    return response;
};

// investor
export const getAllInvestors = async (filter) => {
    const response = axiosInstance.get(`/api/v2/investors?page=${filter.page}&per_page=${filter.per_page}`);
    return response;
};

export const getInvestors = async (filter) => {
    if (typeof filter == "object") {
        filter = "?" + objToParam(filter);
    }

    const response = axiosInstance.get(`/api/v2/investors${filter || ""}` );
    return response;
};

export const getSpesificInvestor = async (id) => {
    const response = axiosInstance.get(`/api/v2/investors/${id}`);
    return response;
};

export const createInvestor = async (data) => {
    const response = axiosInstance.post(`/api/v2/investors`, data);
    return response;
};

export const editInvestor = async (id, data) => {
    const response = axiosInstance.post(`/api/v2/investors/${id}`, data);
    return response;
};

export const deleteInvestor = async (id) => {
    const response = axiosInstance.delete(`/api/v2/investors/${id}`);
    return response;
};

export const addInvestorProduct = async (data) => {
    const response = axiosInstance.post(`/api/v2/investors/products`, data);
    return response;
};

export const getAllInvestorProduct = async (filter) => {
    const response = axiosInstance.get(`/api/v2/investors/products?page=${filter.page}&per_page=${filter.per_page}&include=investor,product,skus.sku.images`);
    return response;
}

export const deleteInvestorProduct = async (id) => {
    const response = axiosInstance.delete(`/api/v2/investors/products/${id}`);
    return response;
}

export const getSpesificInvestorProduct = async (id) => {
    const response = axiosInstance.get(`/api/v2/investors/products/${id}`);
    return response;
}