import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Grid,
  Box,
  Button,
} from "@material-ui/core";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/garbage.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/icons/copy.svg";
import swal from "sweetalert";

const CardDetailBarang = withStyles(style)((props) => {
  const {
    classes,
    index,
    data,
    handleChangePhoto,
    handleChangeSkus,
    handleRemoveSkus,
    handleChangeSkusProperties,
    isPlusVarian,
    handleChangePhotoPlusVarian,
    handleChangeSkusPlusVarian,
    handleRemoveSkusPlusVarian,
    handleChangeSkusPropertiesPlusVarian,
    handleCopySkus,
  } = props;

  const handleDeleteVarian = (code) => {
    swal({
      title: "Are you sure?",
      text: "Varian akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        isPlusVarian
          ? handleRemoveSkusPlusVarian(code)
          : handleRemoveSkus(code);
        swal("Varian dihapus!", {
          icon: "success",
        });
      } else {
        swal("Varian batal dihapus");
      }
    });
  };

  return (
    <Fragment>
      <div className={classes.detailWrapper}>
        <div className={classes.title}>
          <p>Variasi {index + 1}</p>
          <div>
            <Button
              variant="outlined"
              startIcon={<CopyIcon />}
              style={{ color: "#29c0c7" }}
              onClick={() => handleCopySkus(data.code)}
            >
              Duplikat
            </Button>
            {index !== 0 && (
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                style={{ color: "#ff6564" }}
                onClick={() => handleDeleteVarian(data.code)}
              >
                Hapus
              </Button>
            )}
          </div>
        </div>
        <div className={classes.items}>
          <Grid container>
            <Grid items md={2}>
              Gambar <sup style={{ color: "red" }}>*</sup>
            </Grid>
            <Grid items md={3}>
              <div
                className={classes.tambahGambar}
                style={{ backgroundImage: `url(${data.image_b64})` }}
              >
                {data.image_b64 === null && (
                  <Fragment>
                    <Typography className={classes.tambahGambar1}>+</Typography>
                  </Fragment>
                )}
                <input
                  type="file"
                  className={classes.inputGambar}
                  onChange={(event) =>
                    isPlusVarian
                      ? handleChangePhotoPlusVarian(event, data.code)
                      : handleChangePhoto(event, data.code)
                  }
                />
              </div>
            </Grid>
            <Grid items xs>
              <Typography variant="caption" style={{ color: "#6666" }}>
                <i>
                  Catatan:
                  <br />
                  1. ukuran: Maks. 2MB dan resolusi maks. 1280x1280px
                  <br />
                  2. Format: JPG,JPEG,PNG
                </i>
              </Typography>
            </Grid>
          </Grid>
          <Box mb={2} />
          <Grid container spacing={6} alignItems="center">
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item md={4}>
                  Material
                </Grid>
                <Grid item xs>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    placeholder="Masukan jenis material"
                    fullWidth={true}
                    value={data.properties.material}
                    className={classes.inputStyle}
                    onChange={(event) =>
                      isPlusVarian
                        ? handleChangeSkusPropertiesPlusVarian(
                            data.code,
                            "material",
                            event.target.value
                          )
                        : handleChangeSkusProperties(
                            data.code,
                            "material",
                            event.target.value
                          )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item md={4}>
                  Stok
                </Grid>
                <Grid item xs>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    placeholder="Masukan jumlah stok"
                    fullWidth={true}
                    value={data.stock}
                    className={classes.inputStyle}
                    type="number"
                    onChange={(event) =>
                      isPlusVarian
                        ? handleChangeSkusPlusVarian(
                            data.code,
                            "stock",
                            event.target.value
                          )
                        : handleChangeSkus(
                            data.code,
                            "stock",
                            event.target.value
                          )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={6} alignItems="center">
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item md={4}>
                  Warna
                </Grid>
                <Grid item xs>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    placeholder="Masukan warna"
                    fullWidth={true}
                    value={data.properties.color}
                    className={classes.inputStyle}
                    onChange={(event) =>
                      isPlusVarian
                        ? handleChangeSkusPropertiesPlusVarian(
                            data.code,
                            "color",
                            event.target.value
                          )
                        : handleChangeSkusProperties(
                            data.code,
                            "color",
                            event.target.value
                          )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item md={4}>
                  Berat
                </Grid>
                <Grid item xs>
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <div className={classes.inputAdornment}>gram</div>
                      ),
                    }}
                    placeholder="Masukan berat"
                    fullWidth={true}
                    type="number"
                    value={data.weight_gram}
                    className={classes.inputStyle}
                    onChange={(event) =>
                      isPlusVarian
                        ? handleChangeSkusPlusVarian(
                            data.code,
                            "weight_gram",
                            event.target.value
                          )
                        : handleChangeSkus(
                            data.code,
                            "weight_gram",
                            event.target.value
                          )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={6} alignItems="center">
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item md={4}>
                  Size
                </Grid>
                <Grid item xs>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    placeholder="Masukan size"
                    fullWidth={true}
                    value={data.properties.size}
                    className={classes.inputStyle}
                    onChange={(event) =>
                      isPlusVarian
                        ? handleChangeSkusPropertiesPlusVarian(
                            data.code,
                            "size",
                            event.target.value
                          )
                        : handleChangeSkusProperties(
                            data.code,
                            "size",
                            event.target.value
                          )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container>
                <Grid item md={4}>
                  Harga
                </Grid>
                <Grid item xs>
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <div className={classes.inputAdornmentStart}>Rp</div>
                      ),
                    }}
                    placeholder="Masukan nominal harga"
                    fullWidth={true}
                    value={data.price.consumer}
                    className={classes.inputStyle}
                    type="number"
                    onChange={(event) =>
                      isPlusVarian
                        ? handleChangeSkusPlusVarian(data.code, "price", {
                            ...data.price,
                            consumer: event.target.value,
                          })
                        : handleChangeSkus(data.code, "price", {
                            ...data.price,
                            consumer: event.target.value,
                          })
                    }
                  />
                  <Typography variant="caption">
                    <i>Harga customer</i>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
});

export default CardDetailBarang;
