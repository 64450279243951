import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
  Grid,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import swal from "sweetalert";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import React, { useEffect, useState } from "react";
import { getPrice, updatePrice } from "services/axios";
import Modal from "./components/Modal";
import { formatRupiah } from "../../../../utils/currencyFormat";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
});

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

export default function UpgradeAccount() {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(0);

  const handleEdit = async (payload) => {
    setIsLoading(true);
    const response = await updatePrice(payload);
    if (response.success) {
      fetchData();
      swal("Harga berhasil diupdate!", { icon: "success" });
    } else {
      swal("Harga gagal diupdate!", { icon: "error" });
    }
    setIsLoading(false);
  };

  async function fetchData() {
    const response = await getPrice();
    console.log(response);
    if (response && response.price) {
      setPrice(response.price);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Harga</TableCell>
              <TableCell>Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Rp.{formatRupiah(price)}</TableCell>
              <TableCell>
                <Grid container spacing={1}>
                  <Grid item>
                    <Tooltip title="Edit" placement="bottom">
                      <StyledButton onClick={() => setModalOpen(true)}>
                        <EditIcon />
                      </StyledButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={modalOpen}
        price={price}
        onClose={() => setModalOpen(false)}
        onSubmit={handleEdit}
      />

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
