import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import idejualan_logo from "../../assets/images/Logo-Horizontal.png";
import { ReactComponent as Home } from "../../assets/icons/home1.svg";
import { ReactComponent as Graphic } from "../../assets/icons/graphic.svg";
import { ReactComponent as ShoppingCart } from "../../assets/icons/shopping-cart.svg";
import { ReactComponent as GraphicEditor } from "../../assets/icons/graphics-editor.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/man-avatar.svg";
import { ReactComponent as Discount } from "../../assets/icons/discount.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/Vector.svg";
import Collapse from "@material-ui/core/Collapse";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import SettingsIcon from "@material-ui/icons/Settings";
import CreditCardIcon from "@material-ui/icons/CreditCard";

const Sidebar = withStyles(style)((props) => {
  const { classes, history, location } = props;
  const [openNestedAnalytics, setOpenNestedAnalytics] = useState(false);
  const [openNestedProduct, setOpenNestedProduct] = useState(false);
  const [openNestedSale, setOpenNestedSale] = useState(false);
  const [openNestedPayment, setOpenNestedPayment] = useState(false);
  const [openNestedReseller, setOpenNestedReseller] = useState(false);
  const [openNestedResellerHistory, setOpenNestedResellerHistory] = useState(false);
  const [openNestedSupplier, setOpenNestedSupplier] = useState(false);
  const [openNestedDropship, setOpenNestedDropship] = useState(false);
  const [openNestedOther, setOpenNestedOther] = useState(false);

  const handleClickNestedAnalytics = () => {
    setOpenNestedAnalytics(!openNestedAnalytics);
  };

  const handleClickNestedProduct = () => {
    setOpenNestedProduct(!openNestedProduct);
  };

  const handleClickNestedSale = () => {
    setOpenNestedSale(!openNestedSale);
  };

  const handleClickNestedPayment = () => {
    setOpenNestedPayment(!openNestedPayment);
  };

  const handleClickNestedReseller = () => {
    setOpenNestedReseller(!openNestedReseller);
  };

  const handleClickNestedResellerHistory = () => {
    setOpenNestedResellerHistory(!openNestedResellerHistory);
  };

  const handleClickNestedSupplier = () => {
    setOpenNestedSupplier(!openNestedSupplier);
  };

  const handleClickNestedDropship = () => {
    setOpenNestedDropship(!openNestedDropship);
  };

  const handleClickNestedOther = () => {
    setOpenNestedOther(!openNestedOther);
  };

  const handleLogout = () => {
    swal({
      title: "Are you sure?",
      text: "Kamu akan keluar dari dashboard",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        localStorage.clear();
        window.location.reload();
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoWrapper}>
        <img
          src={idejualan_logo}
          alt="idejualan"
          className={classes.idejualanLogo}
        />
      </div>

      <div className={classes.dashboardWrapper}>
        <List>

          {/* dashboard */}
          <ListItem
            button
            onClick={() => {
              history.push("/");
            }}
            className={
              location.pathname === "/" ? classes.buttonClicked : classes.button
            }
          >
            <ListItemIcon>
              <Home
                className={
                  location.pathname === "/"
                    ? classes.iconClickedDs
                    : classes.iconDs
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                dashboard
              </Typography>
            </ListItemText>
          </ListItem>

          {/* analitycs */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedAnalytics();
            }}
            className={
              location.pathname === "/analytic" ||
              location.pathname === "/analyticv2" ||
              location.pathname === "/analytic-dropship" ||
              location.pathname === "/report" ||
              location.pathname === "/metabase" ||
              location.pathname === "/counter"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <Graphic
                className={
                  location.pathname === "/analytic" ||
                  location.pathname === "/analyticv2" ||
                  location.pathname === "/analytic-dropship" ||
                  location.pathname === "/report" ||
                  location.pathname === "/metabase" ||
                  location.pathname === "/counter"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/analytic" ||
                  location.pathname === "/analyticv2" ||
                  location.pathname === "/analytic-dropship" ||
                  location.pathname === "/report" ||
                  location.pathname === "/metabase" ||
                  location.pathname === "/counter"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Analytics
              </Typography>
            </ListItemText>
            {openNestedAnalytics ? (
              <ArrowUp
                className={
                  location.pathname === "/analytic" ||
                  location.pathname === "/analyticv2" ||
                  location.pathname === "/analytic-dropship" ||
                  location.pathname === "/report" ||
                  location.pathname === "/metabase" ||
                  location.pathname === "/counter"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/analytic" ||
                  location.pathname === "/analyticv2" ||
                  location.pathname === "/analytic-dropship" ||
                  location.pathname === "/report" ||
                  location.pathname === "/metabase" ||
                  location.pathname === "/counter"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedAnalytics} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Analytic */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/analytic");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/analytic"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Analytic
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Analytic V2 */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/analyticv2");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/analyticv2"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Analytic V2
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Analytic Dropship */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/analytic-dropship");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/analytic-dropship"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Analytic Dropship
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Report */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/report");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/report"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Report
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Metabase */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/metabase");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/metabase"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Metabase
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Penjualan Hari Ini (Dummy) */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/counter");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/counter"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Penjualan Hari Ini (Dummy)
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* products */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedProduct();
            }}
            className={
              location.pathname === "/campaign" ||
              location.pathname === "/product/products" ||
              location.pathname === "/product/ecourse" ||
              location.pathname === "/product/category-product" ||
              location.pathname === "/product/category-e-product" ||
              location.pathname === "/product/category-e-course"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <GraphicEditor
                className={
                  location.pathname === "/campaign" ||
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/ecourse" ||
                  location.pathname === "/product/category-product" ||
                  location.pathname === "/product/category-e-product" ||
                  location.pathname === "/product/category-e-course"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/campaign" ||
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/ecourse" ||
                  location.pathname === "/product/category-product" ||
                  location.pathname === "/product/category-e-product" ||
                  location.pathname === "/product/category-e-course"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Product
              </Typography>
            </ListItemText>
            {openNestedProduct ? (
              <ArrowUp
                className={
                  location.pathname === "/campaign" ||
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/ecourse" ||
                  location.pathname === "/product/category-product" ||
                  location.pathname === "/product/category-e-product" ||
                  location.pathname === "/product/category-e-course"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/campaign" ||
                  location.pathname === "/product/products" ||
                  location.pathname === "/product/ecourse" ||
                  location.pathname === "/product/category-product" ||
                  location.pathname === "/product/category-e-product" ||
                  location.pathname === "/product/category-e-course"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedProduct} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Campaign */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/campaign");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/campaign"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Campaign
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Products */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product/products");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product/products"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Products
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* E-Course */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product/ecourse");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product/ecourse"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    E-Course
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Category Product */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product/category-product");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product/category-product"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Category Product
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Category E-Product */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product/category-e-product");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product/category-e-product"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Category E-Product
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Category E-Course */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product/category-e-course");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product/category-e-course"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Category E-Course
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* sale */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedSale();
            }}
            className={
              location.pathname === "/order" ||
              location.pathname === "/order-upgrade" ||
              location.pathname === "/supplier-transaction" ||
              location.pathname === "/reseller-transaction"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <ShoppingCart
                className={
                  location.pathname === "/order" ||
                  location.pathname === "/order-upgrade" ||
                  location.pathname === "/supplier-transaction" ||
                  location.pathname === "/reseller-transaction"
                    ? classes.iconClickedDs
                    : classes.iconDs
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/order" ||
                  location.pathname === "/order-upgrade" ||
                  location.pathname === "/supplier-transaction" ||
                  location.pathname === "/reseller-transaction"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Penjualan
              </Typography>
            </ListItemText>
            {openNestedSale ? (
              <ArrowUp
                className={
                  location.pathname === "/order" ||
                  location.pathname === "/order-upgrade" ||
                  location.pathname === "/supplier-transaction" ||
                  location.pathname === "/reseller-transaction"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/order" ||
                  location.pathname === "/order-upgrade" ||
                  location.pathname === "/supplier-transaction" ||
                  location.pathname === "/reseller-transaction"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedSale} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Order */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/order");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/order"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Order
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Order Upgrade */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/order-upgrade");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/order-upgrade"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Order Upgrade
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Transaksi Supplier */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/supplier-transaction");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/supplier-transaction"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Transaksi Supplier
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Transaksi Reseller */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/reseller-transaction");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/reseller-transaction"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Transaksi Reseller
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* promo */}
          <ListItem
            button
            onClick={() => {
              history.push("/promo");
            }}
            className={
              location.pathname === "/promo"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <Discount
                className={
                  location.pathname === "/promo"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/promo"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Promo
              </Typography>
            </ListItemText>
          </ListItem>

          {/* payment */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedPayment();
            }}
            className={
              location.pathname === "/payment" ||
              location.pathname === "/penalty"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <CreditCardIcon
                className={
                  location.pathname === "/payment" ||
                  location.pathname === "/penalty" 
                    ? classes.iconClickedDs
                    : classes.iconDs
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/payment" ||
                  location.pathname === "/penalty" 
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Payment
              </Typography>
            </ListItemText>
            {openNestedPayment ? (
              <ArrowUp
                className={
                  location.pathname === "/payment" ||
                  location.pathname === "/penalty" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/payment" ||
                  location.pathname === "/penalty" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedPayment} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Pembayaran */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/payment");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/payment"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pembayaran
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Penalty */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/penalty");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/penalty"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Penalty
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* Reseller */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedReseller();
            }}
            className={
              location.pathname === "/data-reseller" ||
              location.pathname === "/data-reseller-omzet" ||
              location.pathname === "/data-customer" ||
              location.pathname === "/data-pic" ||
              location.pathname === "/user-admin" ||
              location.pathname === "/history-topup-dompet-jualan" ||
              location.pathname === "/voucher" ||
              location.pathname === "/bank"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <GroupIcon
                className={
                  location.pathname === "/data-reseller" ||
                  location.pathname === "/data-reseller-omzet" ||
                  location.pathname === "/data-customer" ||
                  location.pathname === "/data-pic" ||
                  location.pathname === "/user-admin" ||
                  location.pathname === "/history-topup-dompet-jualan" ||
                  location.pathname === "/voucher" ||
                  location.pathname === "/bank" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/data-reseller" ||
                  location.pathname === "/data-reseller-omzet" ||
                  location.pathname === "/data-customer" ||
                  location.pathname === "/data-pic" ||
                  location.pathname === "/user-admin" ||
                  location.pathname === "/history-topup-dompet-jualan" ||
                  location.pathname === "/voucher" ||
                  location.pathname === "/bank" 
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Reseller
              </Typography>
            </ListItemText>
            {openNestedReseller ? (
              <ArrowUp
                className={
                  location.pathname === "/data-reseller" ||
                  location.pathname === "/data-reseller-omzet" ||
                  location.pathname === "/data-customer" ||
                  location.pathname === "/data-pic" ||
                  location.pathname === "/user-admin" ||
                  location.pathname === "/history-topup-dompet-jualan" ||
                  location.pathname === "/voucher" ||
                  location.pathname === "/bank" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/data-reseller" ||
                  location.pathname === "/data-reseller-omzet" ||
                  location.pathname === "/data-customer" ||
                  location.pathname === "/data-pic" ||
                  location.pathname === "/user-admin" ||
                  location.pathname === "/history-topup-dompet-jualan" ||
                  location.pathname === "/voucher" ||
                  location.pathname === "/bank" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedReseller} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Reseller */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/data-reseller");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/data-reseller"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Reseller
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Reward Omzet */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/data-reseller-omzet");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/data-reseller-omzet"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Reward Omzet
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Customer */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/data-customer");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/data-customer"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Customer
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* PIC */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/data-pic");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/data-pic"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    PIC
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* User Admin */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/user-admin");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/user-admin"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    User Admin
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* History */}
              <ListItem
                button
                onClick={() => {
                  handleClickNestedResellerHistory();
                }}
                className={
                  location.pathname === "/history-topup-dompet-jualan" ||
                  location.pathname === "/voucher"
                    ? classes.buttonClicked
                    : classes.button
                }
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/history-topup-dompet-jualan" ||
                      location.pathname === "/voucher" 
                        ? classes.textListClicked
                        : classes.textList
                    }
                    style={
                      {marginLeft: 15}
                    }
                  >
                    History
                  </Typography>
                </ListItemText>
                {openNestedResellerHistory ? (
                  <ArrowUp
                    className={
                      location.pathname === "/history-topup-dompet-jualan" ||
                      location.pathname === "/voucher" 
                        ? classes.iconClicked
                        : classes.icon
                    }
                  />
                ) : (
                  <ArrowDown
                    className={
                      location.pathname === "/history-topup-dompet-jualan" ||
                      location.pathname === "/voucher"
                        ? classes.iconClicked
                        : classes.icon
                    }
                  />
                )}
              </ListItem>
              <Collapse in={openNestedResellerHistory} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* Top Up DompetJualan */}
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() => {
                      history.push("/history-topup-dompet-jualan");
                    }}
                  >
                    <ListItemText>
                      <Typography
                        className={
                          location.pathname === "/history-topup-dompet-jualan"
                            ? classes.textListClicked
                            : classes.textList
                        }
                        style={{marginLeft: 5}}
                      >
                        Top Up DompetJualan
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  {/* Pemakaian Voucher */}
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() => {
                      history.push("/voucher");
                    }}
                  >
                    <ListItemText>
                      <Typography
                        className={
                          location.pathname === "/voucher"
                            ? classes.textListClicked
                            : classes.textList
                        }
                        style={{marginLeft: 5}}
                      >
                        Pemakaian Voucher
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Collapse>

              {/* Akun Bank */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/bank");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/bank"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Akun Bank
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* Supplier */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedSupplier();
            }}
            className={
              location.pathname === "/supplier" ||
              location.pathname === "/pre-supplier" ||
              location.pathname === "/marketplace" ||
              location.pathname === "/shop-channel"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <ProfileIcon
                className={
                  location.pathname === "/supplier" ||
                  location.pathname === "/pre-supplier" ||
                  location.pathname === "/marketplace" ||
                  location.pathname === "/shop-channel" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/supplier" ||
                  location.pathname === "/pre-supplier" ||
                  location.pathname === "/marketplace" ||
                  location.pathname === "/shop-channel"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Supplier
              </Typography>
            </ListItemText>
            {openNestedSupplier ? (
              <ArrowUp
                className={
                  location.pathname === "/supplier" ||
                  location.pathname === "/pre-supplier" ||
                  location.pathname === "/marketplace" ||
                  location.pathname === "/shop-channel"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/supplier" ||
                  location.pathname === "/pre-supplier" ||
                  location.pathname === "/marketplace" ||
                  location.pathname === "/shop-channel"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedSupplier} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Supplier */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/supplier");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/supplier"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Supplier
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Pendaftaran Supplier */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/pre-supplier");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/pre-supplier"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pendaftaran Supplier
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Marketplace Form */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/marketplace");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/marketplace"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Marketplace Form
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Shop Channel */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/shop-channel");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/shop-channel"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Shop Channel
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* Admin Dropship */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedDropship();
            }}
            className={
              location.pathname === "/product-dropship" ||
              location.pathname === "/dropshipper" ||
              location.pathname === "/investors" ||
              location.pathname === "/investor-products"
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <GroupIcon
                className={
                  location.pathname === "/product-dropship" ||
                  location.pathname === "/dropshipper" ||
                  location.pathname === "/investors" ||
                  location.pathname === "/investor-products"
                    ? classes.iconClickedDs
                    : classes.iconDs
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/product-dropship" ||
                  location.pathname === "/dropshipper" ||
                  location.pathname === "/investors" ||
                  location.pathname === "/investor-products"
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Admin Dropship
              </Typography>
            </ListItemText>
            {openNestedDropship ? (
              <ArrowUp
                className={
                  location.pathname === "/product-dropship" ||
                  location.pathname === "/dropshipper" ||
                  location.pathname === "/investors"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/product-dropship" ||
                  location.pathname === "/dropshipper" ||
                  location.pathname === "/investors"
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedDropship} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Dropshipper */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/dropshipper");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/dropshipper"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Dropshipper
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Product */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/product-dropship");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/product-dropship"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Product
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Investor */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/investors");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/investors"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Investor
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Investor Produk*/}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/investor-products");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/investor-products"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Investor Produk
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          {/* lainnya */}
          <ListItem
            button
            onClick={() => {
              handleClickNestedOther();
            }}
            className={
              location.pathname === "/annoucement" ||
              location.pathname === "/settings" 
                ? classes.buttonClicked
                : classes.button
            }
          >
            <ListItemIcon>
              <SettingsIcon
                className={
                  location.pathname === "/annoucement" ||
                  location.pathname === "/settings" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                className={
                  location.pathname === "/annoucement" ||
                  location.pathname === "/settings" 
                    ? classes.textListClicked
                    : classes.textList
                }
              >
                Lainnya
              </Typography>
            </ListItemText>
            {openNestedOther ? (
              <ArrowUp
                className={
                  location.pathname === "/annoucement" ||
                  location.pathname === "/settings" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            ) : (
              <ArrowDown
                className={
                  location.pathname === "/annoucement" ||
                  location.pathname === "/settings" 
                    ? classes.iconClicked
                    : classes.icon
                }
              />
            )}
          </ListItem>
          <Collapse in={openNestedOther} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* Pengumuman */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/annoucement");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/annoucement"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pengumuman
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Pengaturan */}
              <ListItem
                button
                className={classes.nested}
                onClick={() => {
                  history.push("/settings");
                }}
              >
                <ListItemText>
                  <Typography
                    className={
                      location.pathname === "/settings"
                        ? classes.textListClicked
                        : classes.textList
                    }
                  >
                    Pengaturan
                  </Typography>
                </ListItemText>
              </ListItem>

              {/* Logout */}
              <ListItem
                button 
                className={classes.nested}
                onClick={handleLogout}
              >
                <ListItemText>
                  <Typography className={classes.textList}>Logout</Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    </div>
  );
});

export default withRouter(Sidebar);
