import axios from "axios";
import swal from "sweetalert";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "https://api.idejualan.com",
});

axiosInstance.interceptors.request.use((config) => {
  let getToken = localStorage.getItem("TOKEN");

  config.headers = Object.assign(
    {
      Authorization: getToken,
    },
    config.headers
  );
  return config;
});

axiosInstance.interceptors.request.use((config) => {
  let getToken = localStorage.getItem("TOKEN");

  config.headers = Object.assign(
    {
      Authorization: "Bearer " + getToken,
    },
    config.headers
  );
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    console.log(error);
    if (!error.response) return;
    if (error.response.status === 401) {
      if (window.location.pathname === "/login") {
        swal("Oops!", "Email dan Password tidak cocok", "warning").then(() => {
          window.location.reload();
        });
      } else {
        swal({
          title: "Waktu sesi telah habis, silahkan Login kembali",
          icon: "info",
        }).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      }
    } else if (
      error.response.status === 400 &&
      window.location.pathname === "/product/category-product"
    )  {
      swal("Oops!", error.response.data.message, "warning");
    } else if (error.response.status === 422 &&
      window.location.pathname === "/supplier"){
        if (error.response.data.errors.email){
          swal("Oops!", error.response.data.message + " " + error.response.data.errors.email, "warning");
        }
      }
    // if (window.confirm('Terjadi kesalahan, silahkan muat ulang')) {
    //   window.location.reload();
    // }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
