import { Button, Grid, Modal, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { TOPUP_COINS } from "services/reseller.service";
import swal from "sweetalert";

const ModalAddCoins = ({ selectedId, isOpen, onClose }) => {

  const [data, setData] = useState({
    amount: 0,
    note: ""
  })

  const handleChangeData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("amount", data.amount);
    formData.append("note", data.note);

    if(data.amount > 0) {
      await TOPUP_COINS(selectedId, formData).then(res => {
        res && swal("Success!", "Berhasil topup coins.", "success")
        onClose()
      }).catch(() => swal("Error!", "Silahkan isi semua data", "error"))
    } else {
      swal("Error!", "Silahkan isi jumlah coins", "error")
    }
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "absolute",
            width: 400,
            backgroundColor: "#FFF",
            border: "none",
            top: 0,
            left: 0,
            right: 0,
            margin: "0 auto",
            outline: "none",
            padding: 24,
          }}
        >
          <h2 id="simple-modal-title">Topup Coins</h2>
          <p id="simple-modal-description">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div>
                  <Typography variant="body2" style={{ width: "50%" }}>
                    Total
                  </Typography>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    fullWidth={true}
                    style={{ width: "100%" }}
                    required
                    onChange={(e) =>
                      handleChangeData("amount", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Typography variant="body2" style={{ width: "50%" }}>
                    Note
                  </Typography>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    fullWidth={true}
                    style={{ width: "100%" }}
                    required
                    onChange={(e) =>
                      handleChangeData("note", e.target.value)
                    }
                    variant="outlined"
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    marginTop: 20,
                    backgroundColor: "#3DDC97",
                    textTransform: "capitalize",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#44bf8a",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddCoins;
