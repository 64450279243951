import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { GET_WALLET_TOPUP_HISTORY } from "services/wallet.service";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { formatRupiah } from "../../utils/currencyFormat";

const headCells = [
  { id: "name", label: "Nama Reseller" },
  { id: "note", label: "Catatan" },
  { id: "amount", label: "Jumlah" },
  { id: "created_at", label: "Dibuat pada" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { filterSearch } = props;
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const cancelTokenRef = React.useRef();

  const getData = useCallback(
    _.debounce(({ rowsPerPage, page, filterSearch }) => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

      const cancelToken = axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      GET_WALLET_TOPUP_HISTORY(
        {
          paginate: rowsPerPage,
          page: page + 1,
          "filter[search]": filterSearch,
          include: "reseller",
        },
        {
          cancelToken: cancelToken.token,
        }
      )
        .then(({ data, meta }) => {
          setRows(data);
          setMeta(meta);
          cancelTokenRef.current = null;
        })
        .catch((err) => {});
    }, 500),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [filterSearch]);

  React.useEffect(() => {
    getData({
      rowsPerPage,
      page,
      filterSearch,
      getData,
    });
  }, [rowsPerPage, page, filterSearch, getData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {rows.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell component="th" scope="row">
                  {row.reseller
                    ? `${row.reseller.name} (${row.reseller.email})`
                    : "-"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.note ? row.note : "-"}
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatRupiah(row.amount || 0)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {moment(row.created_at).format("DD MMM YYYY HH:mm:ss")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
