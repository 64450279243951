import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';
import { UPDATE_BANK_ACCOUNT_STATUS } from 'services/bank.service';

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

export default function DocumentsDialog({ data, onUpdate }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (status) => {
    UPDATE_BANK_ACCOUNT_STATUS(data.id, status).then((response) => {
      data = response.data
      setOpen(false);
      onUpdate(data);
    });
  }

  return (
    <div>
      <StyledButton onClick={handleClickOpen}>
        Lihat Berkas
      </StyledButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Berkas Reseller - {data.reseller.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>1. Photo KTP</p>
            <a href={data.document.ktp} target="_blank" rel="noopener noreferrer" >
              <img width="100%" src={data.document.ktp} alt="ktp" />
            </a>
            <hr />
            <p>2. Photo KTP + Selfie</p>
            <a href={data.document.ktp_selfie} target="_blank" rel="noopener noreferrer" >
              <img width="100%" src={data.document.ktp_selfie} alt="ktp" />
            </a>
            <hr />
            <p>3. Photo Buku Tabungan</p>
            <a href={data.document.book} target="_blank" rel="noopener noreferrer" >
              <img width="100%" src={data.document.book} alt="ktp" />
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Batal
          </Button>
          {data.verification_status === 'under_review' && (
            <>
              <Button onClick={() => handleUpdate('rejected')} color="secondary">
                Tolak
              </Button>
              <Button onClick={() => handleUpdate('verified')} color="primary" autoFocus>
                Verifikasi
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}