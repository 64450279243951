import React, { useState, useEffect } from 'react'
import { makeStyles, Paper, Typography } from "@material-ui/core";
import VoucherTable from './component/voucherTable';
import { getVoucher } from 'services/axios';
import SearchTable from './component/searchTable';
const useStyles = makeStyles({
    title: {
        marginBottom: "30px",
    },
});

const Voucher = () => {
    const classes = useStyles();
    const [dataVoucher, setdataVoucher] = useState([])
    const [page, setpage] = useState(1)
    const [paginate, setpaginate] = useState(5)
    const [filterValue, setfilterValue] = useState('')
    const [type, settype] = useState('')
    const [total, settotal] = useState()

    useEffect(() => {
        const getDataVoucher = async () => {
            const response = await getVoucher(page, paginate, filterValue, type);
            setdataVoucher(response.data)
            settotal(response.meta.total)
            console.log(response)
        }

        getDataVoucher()
    }, [filterValue, page, paginate, type])
    

    const handleChangePage = (value) => {
        setpage(value)
    }

    const handleChangePaginate = (value) => {
        setpaginate(value)
    }

    const handleChangeFilterValue = (value) => {
        setfilterValue(value)
    }

    const handleChangetype = (value) => {
        settype(value)
    }

    return (
        <div>
            <Typography variant="h2" className={classes.title}>
                Voucher
            </Typography>

            <div>
                <Paper elevation={3}>
                    <SearchTable filterValue={filterValue} setfilterValue={handleChangeFilterValue} type={type} settype={handleChangetype} />

                    <VoucherTable dataVoucher={dataVoucher} page={page} paginate={paginate} setpage={handleChangePage} setpaginate={handleChangePaginate} total={total} />
                </Paper>
            </div>
        </div>
    )
}

export default Voucher