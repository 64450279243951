import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
  Backdrop,
  Switch,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import React, { useEffect, useState } from "react";
import {
  createDailyCoin,
  deleteDailyCoin,
  editDailyCoin,
  getDailyCoins,
} from "services/axios";
import Modal from "./components/Modal";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
});

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

export default function DailyCoin() {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const handleInput = async (item) => {
    setIsLoading(true);
    const form = Object.entries(item).reduce(
      (a, [k, v]) => (v !== null ? ((a[k] = v), a) : a),
      {}
    );
    const formData = new FormData();
    for (var key in form) {
      formData.append(key, form[key]);
    }
    if (item.method === "add") {
      const response = await createDailyCoin(formData);
      fetchData();
      swal("Daily coin berhasil ditambah!", { icon: "success" });
    } else if (item.method === "edit") {
      const response = await editDailyCoin(item.id, formData);
      if (response.updated) {
        fetchData();
        swal("Daily Coin berhasil diupdate!", { icon: "success" });
      } else {
        swal("Daily oin gagal diupdate!", { icon: "error" });
      }
    }
    setIsLoading(false);
  };

  const handleDelete = (id) => {
    swal({
      title: "Apakah kamu yakin?",
      text: "Daily Coin yang dihapus tidak akan bisa dikembalikan!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await deleteDailyCoin(id);
        if (response.deleted) {
          swal("Daily Coin telah dihapus!", { icon: "success" });
          fetchData();
        } else {
          swal("Daily Coin gagal dihapus!", { icon: "error" });
        }
      }
    });
  };

  async function fetchData() {
    const response = await getDailyCoins();
    if (response.data && response.data.superseller) {
      setDataList(response.data.superseller);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const createRow = (item, index) => {
    return (
      <TableRow key={item.id}>
        <TableCell>{item}</TableCell>
        <TableCell>{dataList[item]}</TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title="Edit" placement="bottom">
                <StyledButton
                  onClick={() => {
                    setSelectedItem({
                      id: index,
                      value: dataList[item],
                    });
                    setModalOpen(true);
                  }}
                >
                  <EditIcon />
                </StyledButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Hapus" placement="bottom">
                <StyledButton
                  onClick={() => handleDelete(index)}
                >
                  <BinIcon />
                </StyledButton>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <Button
                  onClick={() => {
                    setSelectedItem(false);
                    setModalOpen(true);
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                >
                  Tambah Daily Coin
                </Button><br></br><br></br>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Daily Coin</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(dataList).map((item, i) => {
              return createRow(item, i);
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={modalOpen}
        selectedItem={selectedItem}
        onClose={() => setModalOpen(false)}
        onSubmit={handleInput}
      />

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
