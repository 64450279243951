const style = (theme) => ({
  container: {
    paddingTop: 12,
    paddingBottom: 22,
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    height: 45,
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "fixed",
    zIndex: 100,
  },
  icon: {
    height: "100%",
    width: 25,
    marginLeft: 20,
    "& path": {
      fill: theme.palette.grey.primary,
    },
    [theme.breakpoints.down("md")]: {
      cursor: "pointer",
    },
  },
  text: {
    paddingLeft: 20,
    color: theme.palette.grey.primary,
    textTransform: "capitalize",
  },
});

export default style;
