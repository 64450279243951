import React from "react";
import { WrapDataTableVoucher } from "./style";
import { DataGrid } from "@material-ui/data-grid";
import { LabelPeriode } from "components/global.style";
import { ActionButton } from "components";
import moment from "moment";
import swal from "sweetalert";
import { DELETE_VOUCHER } from "services/voucher.service";

const DataTableVoucher = (props) => {
  const { rowsData = [], setIsRefetch, totalData, page, setPage } = props;

  const handleDeleteRow = (id) => {
    swal({
      title: "Are you sure?",
      text: "Promo akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        DELETE_VOUCHER(id).then(() => {
          swal("Promo dihapus!", {
            icon: "success",
          }).then(() => {
            setIsRefetch(true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
        });
      } else {
        swal("Promo batal dihapus");
      }
    });
  };

  const renderPeriode = (params) => {
    let label = <LabelPeriode type="progress">Berjalan</LabelPeriode>;
    if (params.row.startDate !== null) {
      const startDate = moment(params.row.startDate).format("YYYY-MM-DD HH:mm");
      const endDate = moment(params.row.endDate).format("YYYY-MM-DD HH:mm");
      moment().isBetween(startDate, endDate)
        ? (label = <LabelPeriode type="progress">Berjalan</LabelPeriode>)
        : (label = <LabelPeriode type="soon">Akan Datang</LabelPeriode>);
      moment().isAfter(endDate) &&
        (label = <LabelPeriode type="completed">Selesai</LabelPeriode>);
    }

    return (
      <div style={{ marginTop: 20 }}>
        {params.row.startDate !== null && (
          <div>
            {label}
            <p>
              {moment(params.row.startDate).format("DD-MM-YYYY HH:mm")} s/d{" "}
              {moment(params.row.endDate).format("DD-MM-YYYY HH:mm")}
            </p>
          </div>
        )}
      </div>
    );
  };
  const columns = [
    { field: "name", headerName: "Nama Promo", flex: 1, sortable: false },
    { field: "type", headerName: "Jenis Diskon", width: 150, sortable: false },
    {
      field: "amount",
      headerName: "Jumlah",
      width: 100,
      sortable: false,
    },
    {
      field: "periode",
      headerName: "Periode",
      width: 300,
      sortable: false,
      renderCell: renderPeriode,
    },
    {
      field: "used",
      headerName: "Terpakai",
      width: 100,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 160,
      align: "right",
      renderCell: (params) => (
        <div style={{ marginTop: 16 }}>
          <ActionButton
            type="view"
            onClick={() =>
              props.history.push(`/promo/voucher/${params.row.id}`)
            }
          />
          <ActionButton
            type="edit"
            onClick={() =>
              props.history.push(`/promo/voucher/edit/${params.row.id}`)
            }
            style={{ marginLeft: 8 }}
          />
          <ActionButton
            type="delete"
            style={{ marginLeft: 8 }}
            onClick={() => handleDeleteRow(params.row.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <WrapDataTableVoucher>
      <DataGrid
        rows={rowsData}
        columns={columns}
        checkboxSelection
        disableColumnSelector
        disableColumnMenu
        disableSelectionOnClick
        pagination
        rowCount={totalData}
        pageSize={10}
        paginationMode="server"
        page={page}
        onPageChange={(params) => setPage(params.page)}
      />
    </WrapDataTableVoucher>
  );
};

export default DataTableVoucher;
