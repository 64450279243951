import { Button, makeStyles, Typography } from "@material-ui/core";
import { AddIcon } from "@material-ui/data-grid";
import React from "react";
import { Link } from "react-router-dom";
import Table from "./Table/Table";

const useStyles = makeStyles({
  title: {
    marginBottom: "30px",
  },
  button: {
    marginBottom: "30px",
  },
});

const PIC = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" className={classes.title}>
        Pendaftar Supplier
      </Typography>

      <Table />
    </div>
  );
};

export default PIC;
