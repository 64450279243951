import React from 'react'
import { withStyles  } from "@material-ui/core/styles";
import style from "./style";
import { Box, Button, Typography } from '@material-ui/core';
import { AddIcon } from "@material-ui/data-grid";
import { Link } from 'react-router-dom';
import TableInvestiorsProducts from './Table/Table';

const InvestorsPage = withStyles(style)((props) => {
  const { classes } = props;

  return (
    <Box>
      {/* title */}
      <Typography variant="h2" className={classes.title}>
        Data Investor Produk
      </Typography>

      <Link to="/investor-products/create">
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddIcon />}
        >
          Tambah Investor Produk
        </Button>
      </Link>

      {/* table */}
      <TableInvestiorsProducts/>
    </Box>
  )
})

export default InvestorsPage