import React, { useCallback } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import _ from "lodash";
import StatusMenu from "./StatusMenu";
import { GET_MARKETPLACE_FORMS } from "services/marketplace.service";
import Loading from "components/loading";
import { Box } from "@material-ui/core";

const headCells = [
  { id: "id", label: "No" },
  { id: "owner.name", label: "Nama Supplier" },
  { id: "channel", label: "Marketplace" },
  { id: "shop_domain", label: "Shop Domain" },
  { id: "action", label: "Aksi" },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Marketplace Form
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { search, status, filter } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({
    from: 1,
    total: 1,
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const cancelTokenRef = React.useRef();

  const getData = useCallback(
    _.debounce(({ rowsPerPage, page, search, status, filter }) => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

      const cancelToken = axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      const filterParams = {};

      if (filter.key === "shop_domain") {
        filterParams["filter[shop_domain]"] = search;
      } else {
        filterParams["filter[owner]"] = `${filter.key},${search}`;
      }

      if (status) filterParams["filter[processed]"] = status === "processed";

      GET_MARKETPLACE_FORMS(
        {
          paginate: rowsPerPage,
          page: page + 1,
          ...filterParams,
        },
        {
          cancelToken: cancelToken.token,
        }
      )
        .then(({ data, ...meta }) => {
          setRows(data);
          setMeta(meta);
          setIsLoading(false);
          cancelTokenRef.current = null;
        })
        .catch((err) => {});
    }, 500),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [search, status]);

  React.useEffect(() => {
    setIsLoading(true);
    getData({ rowsPerPage, page, search, status, getData, filter });
  }, [rowsPerPage, page, search, status, getData, filter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdate = (id, newData) => {
    setRows((data) =>
      data.map((item) => {
        return item.id !== id ? item : newData;
      })
    );
  };

  return (
    <div className={classes.root}>
      <TableToolbar />

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={headCells.length}>
                  <Loading style={{ height: 400 }} />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell>{index + meta.from}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.owner.name}
                  </TableCell>
                  <TableCell>{row.channel.toUpperCase()}</TableCell>
                  <TableCell>{row.form.shop_domain}</TableCell>
                  <TableCell>
                    <StatusMenu
                      data={row}
                      onUpdate={(newData) => handleUpdate(row.id, newData)}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={headCells.length}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    height={200}
                    alignItems="center"
                  >
                    Tidak ada data
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
