const styles = (theme) => ({
  title: {
    paddingBottom: 20,
    paddingTop: 30
  },
  bag1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  wrapper: {
    padding: 20
  },
  filter: {
    borderRadius: 50,
    padding: '0 5px',
    border: 'none',
    background: '#5555',
  },
  icon: {
    height: 30,
    width: 30,
    padding: 10,
  },
  green: {
    color: theme.palette.green.main,
  },
  bgGreen: {
    background: theme.palette.green.main,
    width: 'fit-content',
    borderRadius: 10
  },
  red: {
    color: theme.palette.red.main,
  },
  bgRed: {
    background: theme.palette.red.main,
    width: 'fit-content',
    borderRadius: 10
  },
  yellow: {
    color: theme.palette.yellow.primary,
  },
  bgYellow: {
    background: theme.palette.yellow.primary,
    width: 'fit-content',
    borderRadius: 10
  },
})

export default styles;