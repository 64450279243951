import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import styles from "./styles";
import moment from "moment";
import React, { useState, Fragment, useEffect } from "react";

const Modal = withStyles(styles)((props) => {
  const { open, onClose, classes, onSubmit, selectedItem } = props;

  const [state, setState] = useState({
    payload: {
      id: null,
      title: "",
      link: "",
      expired_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      image: null,
    },
    image_b64: null,
    titleError: false,
    imageError: false,
  });

  const handleChange = (event) => {
    const target = event.target;
    setState({
      ...state,
      payload: {
        ...state.payload,
        [target.name]: target.value,
      },
    });
  };
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
    date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    setState({
      ...state,
      payload: {
        ...state.payload,
        ["expired_at"]: date,
      },
    });
  };

  const submit = (event) => {
    if (!state.image_b64 || !state.payload.title.trim()) {
      setState({
        ...state,
        imageError: !state.image_b64,
        titleError: !state.payload.title.trim(),
      });
      return false;
    }
    onClose();
    onSubmit(state.payload);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleChangePhoto(event) {
    setState({
      ...state,
      payload: { ...state.payload, image: event.target.files[0] },
      image_b64: await toBase64(event.target.files[0]),
    });
  }

  useEffect(() => {
    if (!open) return;
    setState({
      payload: {
        method: selectedItem ? "edit" : "add",
        id: selectedItem?.id || null,
        title: selectedItem?.title || "",
        link: selectedItem?.link || "",
        expired_at: selectedItem?.expired_at || null,
        image: null,
      },
      image_b64: selectedItem?.image || null,
      titleError: false,
      imageError: false,
    });
  }, [open, selectedItem]);

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{!selectedItem ? "Tambah" : "Edit"} Popup</DialogTitle>
        <DialogContent>
          <div style={{ maxWidth: 500 }}>
            <div>
              <div
                className={
                  classes.tambahGambar +
                  " " +
                  (state.imageError && classes.error)
                }
                style={{ backgroundImage: `url(${state.image_b64})` }}
              >
                {state.image_b64 === null && (
                  <Fragment>
                    <Typography className={classes.tambahGambar1}>+</Typography>
                    <Typography
                      variant="caption"
                      className={classes.tambahGambar2}
                    >
                      Tambah Gambar
                    </Typography>
                  </Fragment>
                )}
                <input
                  type="file"
                  className={classes.inputGambar}
                  onChange={(event) => handleChangePhoto(event)}
                />
              </div>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Judul"
                value={state.payload.title}
                variant="outlined"
                name="title"
                onChange={handleChange}
                error={state.titleError}
              />
              <TextField
                type="url"
                id="outlined-required"
                fullWidth
                label="Link"
                value={state.payload.link}
                variant="outlined"
                name="link"
                onChange={handleChange}
                margin="normal"
              />
              <Typography>Batas Maksimal Popup Berlaku (opsional)</Typography>
              <KeyboardDatePicker
                margin="normal"
                label="Pilih Tanggal"
                format="MM/dd/yyyy"
                value={state.payload.expired_at}
                name="expired_at"
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                variant="inline"
                emptyLabel="-"
              />
              <KeyboardTimePicker
                margin="normal"
                ampm={false}
                label="Pilih Waktu"
                value={state.payload.expired_at}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                variant="inline"
                emptyLabel="-"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button onClick={onClose} color="secondary">
            Batal
          </Button>
          <Button variant="contained" color="secondary" onClick={submit}>
            {!selectedItem ? "Tambah" : "Edit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default Modal;
