import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import numberSeparator from "../../../utils/numberSeparator";
import swal from "sweetalert";
import { updateOrder } from "../../../services/axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const StatusOrder = withStyles(style)((props) => {
  const { classes, data, onClose } = props;
  const slug = JSON.parse(localStorage.getItem("DATA")).slug;
  const last_history = data.histories[data.histories.length - 1];
  const [state, setState] = useState({
    payload: {
      resi: data.resi || "",
      is_canceled: 0,
      is_processed: 0,
      is_ready_to_delivery: 0,
      cancel_note: "",
    },
    text: "",
  });

  const handleUpdate = async () => {
    swal({
      title: "Apakah kamu yakin?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        const formData = new FormData();
        formData.append("_method", "PATCH");
        if (
          state.payload.is_canceled === 0 &&
          state.payload.is_processed === 0 &&
          state.payload.is_ready_to_delivery === 0
        ) {
          if (state.payload.resi.length !== 0) {
            formData.append("resi", state.payload.resi);
          } else {
            return swal("Oops!", "Masukan Resi!", "warning");
          }
        } else if (
          state.payload.is_canceled === 1 &&
          state.payload.is_processed === 0 &&
          state.payload.is_ready_to_delivery === 0
        ) {
          formData.append("is_canceled", state.payload.is_canceled);
          formData.append("cancel_note", state.payload.cancel_note);

          if (!state.payload.cancel_note.length) {
            return swal("Oops!", "Masukan Catatan!", "warning");
          }
        } else if (
          state.payload.is_canceled === 0 &&
          state.payload.is_processed === 1 &&
          state.payload.is_ready_to_delivery === 0
        ) {
          formData.append("is_processed", state.payload.is_processed);
        } else if (
          state.payload.is_canceled === 0 &&
          state.payload.is_processed === 0 &&
          state.payload.is_ready_to_delivery === 1
        ) {
          formData.append("is_ready_to_delivery", state.payload.is_ready_to_delivery);
        }

        try {
          const response = await updateOrder(slug, data.id, formData);
          if (response.updated) {
            swal("Success!", "Pesanan berhasil diupdate!", "success").then(() =>
              onClose(response)
            );
          }
        } catch (error) {
          if (error.response && error.response.data) {
            swal("Oops!", error.response.data.message, "warning");
          }
        }
      }
    });
  };

  const getSubtotal = (data) => {
    let returnData = 0;
    for (let i = 0; i < data.length; i++) {
      returnData = returnData + data[i].reseller_price_idr * data[i].qty;
    }
    return returnData;
  };

  const handleCheckboxProsesPesanan = () => {
    setState({
      ...state,
      payload: {
        ...state.payload,
        is_processed: state.payload.is_processed === 1 ? 0 : 1,
      },
    });
  };

  const handleCheckboxBatalPesanan = () => {
    setState({
      ...state,
      payload: {
        ...state.payload,
        is_canceled: state.payload.is_canceled === 1 ? 0 : 1,
      },
    });
  };

  const handleCheckboxSiapKirim = () => {
    setState({
      ...state,
      payload: {
        ...state.payload,
        is_ready_to_delivery: state.payload.is_ready_to_delivery === 1 ? 0 : 1,
      },
    });
  }

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.flexCenter}>
          <ArrowBackIcon
            style={{ paddingRight: 10, cursor: "pointer" }}
            onClick={() => onClose()}
          />
          <b>Status Order</b>
        </Typography>
      </div>

      <Paper style={{ marginTop: 30, padding: 30 }}>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="body2">Orderan</Typography>
          </Grid>
          <Grid item xs={10}>
            <div className={classes.orderan}>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="body2"
                  className={classes.flexCenter}
                  style={{ width: 300 }}
                >
                  <b>Produk</b>
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.flexCenter}
                  style={{ width: 100 }}
                >
                  <b>Harga</b>
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.flexCenter}
                  style={{ width: 100 }}
                >
                  <b>QTY</b>
                </Typography>
                <Typography variant="body2">
                  <b>Subtotal</b>
                </Typography>
              </div>

              {data.items.map((res, index) => {
                let productSKU = null;
                let productBundling = null;

                if (res.sku && res.sku !== null) {
                  productSKU = (
                    <div
                      key={res.id}
                      style={{
                        paddingTop: 20,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div style={{ width: 300, display: "flex" }}>
                        <img
                          src={res.sku.image_url}
                          alt={res.sku.product.name}
                          height={60}
                          width={60}
                          style={{ borderRadius: 5 }}
                        />
                        <div style={{ paddingLeft: 10 }}>
                          <Typography
                            variant="caption"
                            style={{ display: "block" }}
                          >
                            {res.sku.product.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ color: "#a2a2a2", paddingTop: 20 }}
                          >
                            SKU#{res.sku.code}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ width: 100 }}
                        className={classes.flexCenter}
                      >
                        <Typography variant="body2">
                          Rp. {numberSeparator(res.consumer_price_idr)}
                        </Typography>
                      </div>
                      <div
                        style={{ width: 100 }}
                        className={classes.flexCenter}
                      >
                        <Typography variant="body2">
                          {numberSeparator(res.qty)}
                        </Typography>
                      </div>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2">
                          Rp.{" "}
                          {numberSeparator(res.consumer_price_idr * res.qty)}
                        </Typography>
                      </div>
                    </div>
                  );
                }

                if (res.bundling && res.bundling !== null) {
                  productBundling = res.bundling.items.map((bundling) => (
                    <div
                      key={res.id + bundling.id}
                      style={{
                        paddingTop: 20,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div style={{ width: 300, display: "flex" }}>
                        <img
                          src={bundling.sku.image}
                          alt={bundling.name}
                          height={60}
                          width={60}
                          style={{ borderRadius: 5 }}
                        />
                        <div style={{ paddingLeft: 10 }}>
                          <Typography
                            variant="caption"
                            style={{ display: "block" }}
                          >
                            {bundling.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ color: "#a2a2a2", paddingTop: 20 }}
                          >
                            SKU#{bundling.sku.code}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{ width: 100 }}
                        className={classes.flexCenter}
                      >
                        <Typography variant="body2">
                          Rp. {numberSeparator(bundling.reseller_price_idr)}
                        </Typography>
                      </div>
                      <div
                        style={{ width: 100 }}
                        className={classes.flexCenter}
                      >
                        <Typography variant="body2">
                          {numberSeparator(res.qty)}
                        </Typography>
                      </div>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2">
                          Rp.{" "}
                          {numberSeparator(
                            bundling.reseller_price_idr * res.qty
                          )}
                        </Typography>
                      </div>
                    </div>
                  ));
                }
                return (
                  <Fragment key={res.id}>
                    {productSKU}
                    {productBundling}
                  </Fragment>
                );
              })}
            </div>

            <div className={classes.flexBetween} style={{ paddingTop: 20 }}>
              <Typography variant="body2">Subtotal</Typography>
              <Typography variant="body2">
                <b>Rp. {numberSeparator(getSubtotal(data.items))}</b>
              </Typography>
            </div>
            <div className={classes.flexBetween} style={{ paddingTop: 10 }}>
              <Typography variant="body2">Ongkos Kirim</Typography>
              <Typography variant="body2">
                <b>Rp. {numberSeparator(data.shipping_cost)}</b>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 30 }}>
          {(last_history.status !== 91 ||
            last_history.status !== 92 ||
            last_history.status !== 50 ||
            last_history.status !== 40 ||
            last_history.status !== 0) && (
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              {last_history.status !== 30 && (
                <Typography variant="body2">Status Order</Typography>
              )}
            </Grid>
          )}
          <Grid item>
            {(last_history.status === 10 ||
              last_history.status === 20 ||
              last_history.status === 30 ||
              last_history.status === 35 ||
              last_history.status === 40 ||
              last_history.status === 50) && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.payload.is_canceled === 1}
                      onClick={handleCheckboxBatalPesanan}
                      color="primary"
                    />
                  }
                  label="Batalkan Pesanan"
                  labelPlacement="start"
                />
                <div>
                  <TextField
                    variant="outlined"
                    placeholder="Masukan catatan"
                    value={state.payload.cancel_note}
                    onChange={(e) =>
                      setState({
                        ...state,
                        payload: {
                          ...state.payload,
                          cancel_note: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </>
            )}
          </Grid>
          <Grid item>
            {last_history.status === 20 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.payload.is_processed === 1}
                    onClick={handleCheckboxProsesPesanan}
                    color="primary"
                  />
                }
                label="Proses Pesanan"
                labelPlacement="start"
              />
            )}
            {last_history.status === 30 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.payload.is_ready_to_delivery === 1}
                    onClick={handleCheckboxSiapKirim}
                    color="primary"
                  />
                }
                label="Siap Kirim"
                labelPlacement="start"
                />
              )}
          </Grid>
        </Grid>

        {last_history.status === 35 && (
          <Grid container style={{ marginTop: 30 }}>
            <Grid item xs={2}>
              <Typography variant="body2">Input Resi</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                className={classes.inputStyle}
                InputProps={{ disableUnderline: true }}
                disabled={data.is_cod}
                onChange={(e) =>
                  setState({
                    ...state,
                    payload: { ...state.payload, resi: e.target.value },
                  })
                }
              />
            </Grid>
          </Grid>
        )}

        <div className={classes.updateButtonDiv}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.updateButton}
            onClick={handleUpdate}
          >
            Update Status Order
          </Button>
        </div>
      </Paper>
    </Fragment>
  );
});

export default StatusOrder;
