import styled from "styled-components";

export const ActionButtonWrap = styled.button`
  position: relative;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  background: none;
  outline: none;
  padding: 4px 8px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  ${({circleBorder}) => circleBorder && `
    border-radius: 100px;
    width: 45px;
    height: 45px;
  `}
`;
