import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Dialog,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  getAllECourseCategories,
  deleteECourseCategory,
} from "../../../services/axios";
import TambahKategori from "./tambah-kategori";
import EditKategori from "./edit-kategori";
import Loading from "../../../components/loading";
import swal from "sweetalert";

import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const CategoryProduct = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    category: null,
    filteredCategory: null,
    openTambahKategori: false,
    openEditKategori: false,
    dataEdit: null,
    parrent: null,
  });
  const [open, setOpen] = React.useState(false);

  const getTotalItem = (data) => {
    let returnData = 0;
    data.forEach((res) => {
      returnData = returnData + res.products_count;
    });
    return returnData;
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllECourseCategories();
      setState((state) => ({
        ...state,
        category: response.data,
        filteredCategory: response.data,
      }));
    };
    fetch();
  }, []);

  const handleCloseTambahKategori = async () => {
    const response = await getAllECourseCategories();
    setState({
      ...state,
      openTambahKategori: false,
      category: response.data,
      filteredCategory: response.data,
    });
  };

  const handleCloseEditKategori = async () => {
    const response = await getAllECourseCategories();
    setState({
      ...state,
      openEditKategori: false,
      category: response.data,
      filteredCategory: response.data,
    });
  };

  const handleRefresh = async () => {
    const response = await getAllECourseCategories();
    setState({
      ...state,
      category: response.data,
      filteredCategory: response.data,
    });
  };

  const handleDeleteKetegori = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Kategori akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const fetch = async () => {
          const response = await deleteECourseCategory(id);
          if (response.deleted === true) {
            swal("Kategori dihapus!", {
              icon: "success",
            }).then(() => {
              handleRefresh();
              window.scrollTo({ top: 0, behavior: "smooth" });
            });
          }
        };
        fetch();
      } else {
        swal("Kategori batal dihapus");
      }
    });
  };

  const handleSearch = (keyword) => {
    setState({
      ...state,
      filteredCategory: state.category.filter((value) =>
        value.name.toLowerCase().match(new RegExp(keyword.toLowerCase()))
      ),
    });
  };

  const Kelola = ({ data, parrent }) => {
    return (
      <div style={{ width: 100 }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton
            onClick={() =>
              setState({
                ...state,
                openEditKategori: true,
                dataEdit: data,
                parrent: parrent || "",
              })
            }
          >
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            onClick={() => handleDeleteKetegori(data.id)}
            style={{ marginLeft: 10 }}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <Fragment>
      {!state.category ? (
        <Fragment>
          <Loading />
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.header}>
            <Typography variant="h6">
              <b>Kategori E-Course</b>
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.tambahKategori}
              style={{ marginRight: 20 }}
              onClick={() => setState({ ...state, openTambahKategori: true })}
            >
              + Tambah Kategori
            </Button>
          </div>

          <Paper style={{ marginTop: 30 }}>
            <div style={{ display: "flex", padding: 20 }}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(event) => handleSearch(event.target.value)}
                />
              </div>
            </div>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" width={50}>
                      No
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Nama Kategori
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: 100 }}>
                      Kelola
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.category &&
                    state.filteredCategory.map((row, indexrow) => (
                      <Fragment>
                        <StyledTableRow key={row.name}>
                          <StyledTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {indexrow + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <b>{row.name}</b>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Kelola data={row} />
                          </StyledTableCell>
                        </StyledTableRow>
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Dialog
            onClose={() =>
              setState({
                ...state,
                openTambahKategori: false,
              })
            }
            aria-labelledby="customized-dialog-title"
            open={state.openTambahKategori}
            fullWidth
            maxWidth="sm"
          >
            <TambahKategori
              category={state.category}
              onClose={handleCloseTambahKategori}
            />
          </Dialog>

          <Dialog
            onClose={() =>
              setState({
                ...state,
                openEditKategori: false,
              })
            }
            aria-labelledby="customized-dialog-title"
            open={state.openEditKategori}
            fullWidth
            maxWidth="sm"
          >
            <EditKategori
              category={state.category}
              onClose={handleCloseEditKategori}
              data={state.dataEdit}
              parrent={state.parrent}
            />
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
});

export default CategoryProduct;
