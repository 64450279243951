import React, { Fragment } from "react";
import { createMuiTheme, useTheme } from "@material-ui/core/styles";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Sidebar from "./components/sidebar";
import Navbar from "./components/navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login";

import Home from "./pages/home";
import listRoute from "./config/listRoute";

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      500: "#FDCA40",
    },
    yellow: {
      primary: "#FDCA40",
      secondary: "#FDCD0F",
      tertiary: "#FFF4D6",
    },
    white: {
      primary: "#FFFFFF",
      secondary: "#F5F5F5",
      tertiary: "#E5ECFF",
    },
    grey: {
      primary: "#333333",
      secondary: "#55555",
      tertiary: "#A2A2A2",
    },
    green: {
      light: "#3DDC97",
      main: "#3DDC97",
      dark: "#3DDC97",
    },
    red: {
      main: "#FF6564",
    },
    blue: {
      main: "#29C0C7",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
  },
});

if (!localStorage.getItem("TOKEN") && window.location.pathname !== "/login")
  window.location.href = "/login";

function App() {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const contentWidth = isMdDown
    ? window.screen.width
    : window.screen.width - 244;

  return (
    <Fragment>
      <ThemeProvider theme={defaultTheme}>
        {window.location.pathname === "/login" ? (
          <Router>
            <Switch>
              <Route exact path="/login" component={Login} />
            </Switch>
          </Router>
        ) : (
          <Router>
            <div style={{ display: "flex" }}>
              <Hidden mdDown>
                <Sidebar />
              </Hidden>
              <div
                style={{
                  width: contentWidth,
                  paddingLeft: !isMdDown ? 244 : 0,
                }}
              >
                <Navbar />
                <div style={{ padding: 20, marginTop: 79 }}>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    {listRoute.map((res, index) => (
                      <Route
                        key={index}
                        path={res.path}
                        component={res.component}
                      />
                    ))}
                  </Switch>
                </div>
              </div>
            </div>
          </Router>
        )}
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
