import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Button, Grid } from "@material-ui/core";
import { getAnnoucements } from "../../services/axios";
import Loading from "../../components/loading";
import TambahData from "./tambah-data";
import EditData from "./edit-data";
import TablePengumuman from "./table";

const Pengumuman = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    annoucements: null,
    openTambahData: false,
    openEditData: false,
    dataEdit: null,
  });

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getAnnoucements();
      setState((state) => ({ ...state, annoucements: response }));
    };
    fetch();
  }, []);

  const handleEditData = (data) => {
    setState({ ...state, openEditData: true, dataEdit: data });
  };

  const handleCloseTambahData = async () => {
    const response = await getAnnoucements();
    setState({ ...state, openTambahData: false, annoucements: response });
  };

  const handleCloseEditData = async () => {
    const response = await getAnnoucements();
    setState({ ...state, openEditData: false, annoucements: response });
  };

  return (
    <Fragment>
      {!state.annoucements ? (
        <Loading />
      ) : (
        <Fragment>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h6">
                <b>Pengumuman</b>
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.tambahOrder}
                style={{ marginRight: 20 }}
                onClick={() => handleChangeState("openTambahData", true)}
              >
                + Tambah Data
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.other}
              >
                ...
              </Button>
            </Grid>
          </Grid>

          <div style={{ paddingTop: 30 }}>
            <TablePengumuman
              data={state.annoucements}
              handleEditData={handleEditData}
            />
          </div>

          <TambahData
            open={state.openTambahData}
            onClose={handleCloseTambahData}
          />
          {state.dataEdit && (
            <EditData
              open={state.openEditData}
              onClose={handleCloseEditData}
              data={state.dataEdit}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

export default Pengumuman;
