import { Button, Typography } from "@material-ui/core";
import { withStyles  } from "@material-ui/core/styles";
import { AddIcon } from "@material-ui/data-grid";
import React from 'react'
import { Link } from "react-router-dom";
import style from "./style";
import TableDropshipper from "./Table/Table";

const DropshipperPage = withStyles(style)((props) => {
    const { classes } = props;

    return (
        <div>
            <Typography variant="h2" className={classes.title}>
                Data Dropshipper
            </Typography>

            <Link to="/dropshipper/create">
                <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<AddIcon />}
                >
                Tambah Dropshipper
                </Button>
            </Link>

            <TableDropshipper/>
        </div>
    )
})

export default DropshipperPage