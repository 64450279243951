import { Button, Grid, TextField, Typography, FormControl} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect } from "react";
import { addInvestorProduct, getInvestors } from "services/dropship.service";
import { getAllProducts } from "services/axios";
import swal from "sweetalert";
import styles from "./style.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalSelectProduct from "./ModalSelectProduct";
import debounce from "lodash/debounce";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  variant: {
    lineHeight: 3.6,
    fontSize: 14,
  },
  select:{
    marginBottom: 20,
  }
}));

const EditInvestor = ({match}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setstate] = useState({
    product_id: '',
    investor_id: '',
    investor_fee: '',
    skus_sku_id: '',
    quantity: '',
    skus: {}
  })
  const [investors, setInvestors] = useState([])
  const [page, setPage] = React.useState(1);
  const [isOpenModalProduct, setIsOpenModalProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [searchInvestor, setSearchInvestor] = React.useState("");

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, []);

  useEffect(() => {
    const handleFetchInvestor = async (name) => {
      let investors = await getInvestors(`?filter[name]=${name}`);
      setInvestors(investors.data);
    };
    if (searchInvestor) {
      handleFetchInvestor(searchInvestor);
    }
  }, [searchInvestor]);

  /* handle submit */
  const handleSubmit = async() => {
    const formData = new FormData();
    formData.append("investor_id", state.investor_id)
    formData.append("product_id", selectedProduct.id)
    formData.append("investor_fee", state.investor_fee)
  
    selectedProduct.skus.forEach((element, index )=> {
      formData.append(`skus[${index}][sku_id]`, selectedProduct.skus[`${index}`].id)
      formData.append(`skus[${index}][qty]`, state.skus[`skus[${index}]`] ?? 0)
    });

    setIsLoading(true);
    await addInvestorProduct(formData)
    .then((result) => {
      swal("Success", `${result.data.investor.name} adalah investor ${result.data.product.name}`, "success").then(() => {
        window.history.back();
        localStorage.removeItem('this_product')
      });
      setIsLoading(false);
    })
    .catch((error) => {
      swal("Error", error.message, "error");
      setIsLoading(false);
    });
  }
  /* end handle submit */

  /* handle change state */
  const handleQuantityChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value
    setstate({ ...state,
      skus: {
        ...state.skus,
        [name]: value
      }
    })
  }
  const handleInputChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setstate({ ...state, [name]: value });
  };
  /* end handle change state*/

  /* handle search investor */
  const handleChangeInvestor = (value) => {
    setPage(1);
    setstate({ ...state, investor_id: value });
  };

  const handleChangeInvestorSearch = (value) => {
    setSearchInvestor(value);
  };

  const debouncedResults = React.useMemo(() => {
    return debounce(handleChangeInvestorSearch, 1000);
  }, []);
  /* end handle search investor */

  /* handle Product*/
  const handleSelectProduct = async(slug) => {
    const params = {
      "filter[slug]": slug
    };
    const res = await getAllProducts(params)
    if(res.data){
      setSelectedProduct(res.data[0])
    }
    setIsOpenModalProduct(false)
  }
  /* end handle Product */


  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Set Inverstor Produk</b>
      </Typography>
      <br/>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit()
      }}>


      <Grid container spacing={3} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap' }}>      
          <div className={styles.innerContainer}>
            <Grid item xs={12}>
            <Autocomplete
              options={investors}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(_, value) => value && handleChangeInvestor(value.id)}
              fullWidth
              onInputChange={(_, value) => debouncedResults(value)}
              renderInput={(params) => (
                <TextField {...params} label="Cari investor" variant="outlined" />
              )}
            />
            </Grid>
            <br/>
            <Grid item xs={12}>
              <TextField
                name="product_name"
                label={selectedProduct.name ? "Pilih Produk" : "" }
                variant="outlined"
                style={{ width: "100%" }}
                type="text"
                readonly
                required
                value={selectedProduct.name  ?? "Pilih Produk"}
                onClick={() => setIsOpenModalProduct(true)}
                onChange={handleInputChange}
              />
            </Grid>
            <br/>
            <ModalSelectProduct
              open={isOpenModalProduct}
              handleClose={() => setIsOpenModalProduct(false)}
              handleSubmit={handleSelectProduct}
            ></ModalSelectProduct>
            {selectedProduct && selectedProduct.skus && selectedProduct.skus.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Varian</Typography>
                  <br/>
                  {selectedProduct.skus && selectedProduct.skus.map((sku, index) => {
                    return (
                      <Grid container spacing={2} key={`sku-${index}`}>
                      <Grid item xs={6}>
                      <Typography className={classes.variant}>
                          {sku?.properties?.material},
                          {sku?.properties?.color},
                          {sku?.properties?.size}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <FormControl fullWidth>
                          <TextField
                            name={`skus[${index}]`}
                            label="Quantity"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="number"
                            onChange={handleQuantityChange}
                        />
                      </FormControl>
                      </Grid>
                      </Grid>
                    )})}
                  <br/>
                </Grid>
              )}
            <Grid item xs={12}>
              <TextField
                name="investor_fee"
                label="Investor Fee"
                variant="outlined"
                required
                style={{ width: "100%" }}
                type="number"
                value={state.investor_fee}
                onChange={handleInputChange}
              />
            </Grid>
            <br/>
            <br/>
            <div style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default EditInvestor;
