import React from "react";
import swal from "sweetalert";

import { updatePreSupplier } from "services/suppliers.service";
import { Select, MenuItem } from "@material-ui/core";

const Table = ({ defaultStatus, id }) => {
  const [status, setStatus] = React.useState(defaultStatus);

  const handleUpdateStatus = async (value) => {
    try {
      await updatePreSupplier(id, { status: value });
      swal("Success", "Status berhasil di update", "success");
    } catch (error) {
      if (error.response && error.response.data) {
        return swal("Oops!", error.response.data.message, "warning");
      }
    }
  };

  return (
    <Select
      name="status"
      value={status}
      onChange={(e) => {
        setStatus(e.target.value);
        handleUpdateStatus(e.target.value);
      }}
      variant="outlined"
    >
      <MenuItem value="pending">Pending</MenuItem>
      <MenuItem value="rejected">Rejected</MenuItem>
      <MenuItem value="approved">Approved</MenuItem>
    </Select>
  );
};

export default Table;
