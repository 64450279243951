import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  Grid,
} from "@material-ui/core";
import React, { Fragment } from "react";
import AppBanner from "./pages/AppBanner";
import Popups from "./pages/Popups";
import DailyCoin from "./pages/DailyCoin";
import RewardOmzet from "./pages/RewardOmzet";
import UpgradeAccount from "./pages/UpgradeAccount";
import PopupsSupplier from "./pages/PopupsSupplier";
import HeroPage from "./pages/HeroPage";
import ImportantInfoPage from "./pages/ImportantInfoPage";
import EkspedisiSettings from "./pages/Ekspedisi";

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
  },
}));

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const Settings = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      {/* Header */}
      <Typography variant="h6">
        <b>Pengaturan</b>
      </Typography>
      {/* Content */}
      <div className={classes.tabs}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs 
            value={value} 
            onChange={handleChange} 
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            >
            <Tab label="App Banner" {...a11yProps(0)} />
            <Tab label="Popups" {...a11yProps(0)} />
            <Tab label="Popups supplier" {...a11yProps(0)} />
            <Tab label="Daily Coin & Reward Omzet" {...a11yProps(0)} />
            <Tab label="Upgrade account" {...a11yProps(0)} />
            <Tab label="Top Promotion" {...a11yProps(0)} />
            <Tab label="Important Information" {...a11yProps(0)} />
            <Tab label="Ekspedisi" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AppBanner />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Popups />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PopupsSupplier />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <DailyCoin />
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <RewardOmzet />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <UpgradeAccount />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <HeroPage/>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <ImportantInfoPage/>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <EkspedisiSettings/>
        </TabPanel>
      </div>
    </Fragment>
  );
};

export default Settings;
