import { Grid, Tab, Tabs, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { WrapHistoryPage } from "./style";
import { DataGrid } from "@material-ui/data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GET_SUPPLIER_WALLET_TRANSACTIONS } from "services/wallet.service";
import { ButtonCustom } from "components";
import { getSupplier } from "services/axios";
import DialogExportTransaction from "./Dialog/DialogExportTransaction"

import { formatRupiah } from "utils/currencyFormat";
import moment from "moment";
import debounce from "lodash/debounce";
import numberSeparator from "utils/numberSeparator";

const HistoryTransactionPage = (props) => {
  const [allData, setAllData] = React.useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [totalData, setTotalData] = React.useState(0);
  const [isRefetch, setIsRefetch] = useState(false);
  const [page, setPage] = React.useState(1);
  const [suppliers, setSuppliers] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState("");
  const [searchSupplier, setSearchSupplier] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [amount, setamount] = useState({
    available: '',
    ongoing: ''
  })

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, []);

  useEffect(() => {
    const handleFetchSupplier = async (search) => {
      let suppliers = await getSupplier(`filter[search]=${search}`);
      setSuppliers(suppliers.data);
    };
    if (searchSupplier) {
      handleFetchSupplier(searchSupplier);
    }
  }, [searchSupplier]);

  useEffect(() => {
    const fetchWallets = async () => {
      let wallets = [];
      let status = "";

      if (activeTab === 1) status = "0";
      else if (activeTab === 2) status = "1";
      else if (activeTab === 3) status = "2";

      await GET_SUPPLIER_WALLET_TRANSACTIONS(
        status,
        page,
        selectedSupplier
      ).then((res) => {
        res.data.map((data, i) => {
          wallets.push({ id: i, ...data });
        });

        setamount({ ...amount, available: res.wallet.amount_available, ongoing: res.wallet.amount_ongoing })
        setAllData(wallets);
        setTotalData(res.meta.total);
      });
    };

    if (selectedSupplier) {
      if (isRefetch) {
        setAllData([]);
        fetchWallets();
        setIsRefetch(false);
      } else {
        fetchWallets();
      }
    }
  }, [isRefetch, page, activeTab, selectedSupplier]);

  const handleChangeSupplier = (value) => {
    setPage(1);
    setSelectedSupplier(value);
  };

  const handleChangeSupplierSearch = (value) => {
    setSearchSupplier(value);
  };

  const debouncedResults = React.useMemo(() => {
    return debounce(handleChangeSupplierSearch, 1000);
  }, []);

  const handleChangeTab = (value) => {
    setPage(1);
    setActiveTab(value);
  };

  const columns = [
    {
      field: "note",
      headerName: "Catatan",
      sortable: false,
      width: 200,
      renderCell: (params) => <>{params.row.note}</>,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        if (params.row.status === "IN") {
          return <span style={{ color: "green" }}>Masuk</span>;
        } else if (params.row.status === "OUT") {
          return <span style={{ color: "red" }}>Keluar</span>;
        } else return <span>Ongoing</span>;
      },
    },
    {
      field: "amount",
      headerName: "Jumlah",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        let color = "";
        if (params.row.status === "IN") {
          color = "green";
        } else if (params.row.status === "OUT") {
          color = "red";
        }
        return (
          <span style={{ color: color }}>
            Rp.{formatRupiah(params.row.amount)}
          </span>
        );
      },
    },
    {
      field: "supplier_gross_idr",
      headerName: "Pemasukan kotor",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.order) {
          return <>Rp.{formatRupiah(params.row.order.supplier_gross_idr)}</>;
        } else return "-";
      },
    },
    {
      field: "platform_fee",
      headerName: "Fee Idejualan",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.order) {
          return <>Rp.{formatRupiah(params.row.order.platform_fee)}</>;
        } else return "-";
      },
    },
    {
      field: "shipping_cost",
      headerName: "Ongkir",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.order) {
          if (
            params.row.order.is_cod ||
            params.row.order.shipment_type === "PICKUP"
          ) {
            return <>Rp.{formatRupiah(0)}</>;
          } else return <>Rp.{formatRupiah(params.row.order.shipping_cost)}</>;
        } else return "-";
      },
    },
    {
      field: "voucher_is_admin",
      headerName: "Voucher",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        if (params.row.order) {
          if (!params.row.order.voucher_is_admin) {
            return (
              <>
                Rp.
                {formatRupiah(params.row.order.voucher_amount) || 0} -{" "}
                {params.row.order.voucher_type}
              </>
            );
          } else return "-";
        } else return "-";
      },
    },
    {
      field: "supplier_net_idr",
      headerName: "Saldo",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.saldo) {
          return <>Rp.{formatRupiah(params.row.saldo)}</>;
        } else return "-";
      },
    },
    {
      field: "created_at",
      headerName: "Tanggal transaksi",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>{moment(params.row.created_at).format("DD MMM YYYY HH:MM")}</>
      ),
    },
  ];

  const handleOpenExport = () => {
    setOpen(true);
  };

  const handleCloseExport = () => {
    setOpen(false);
  }

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );

  console.log(amount)

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
        <Grid container>
          <Grid item xs={8}>
            <h4>Riwayat Transaksi</h4>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={suppliers}
              getOptionLabel={(option) => `${option.name} (${option.email})`}
              onChange={(_, value) => value && handleChangeSupplier(value.slug)}
              fullWidth
              onInputChange={(_, value) => debouncedResults(value)}
              renderInput={(params) => (
                <TextField {...params} label="Cari supplier" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', textAlign: 'center', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 6, paddingBottom: 20}}>
          {/* amount available */}
          <Grid container>
            <Grid item xs={12}>
              <h4>Saldo Available</h4>
            </Grid>
            <Grid item xs={12}>
              <div style={{color: 'rgb(228 166 0)'}}>
                {
                  amount.available === '' ? 
                  '-'
                  :
                  `Rp. ${numberSeparator(amount.available)}`
                }
              </div>
            </Grid>
          </Grid>

          {/* amount available */}
          <Grid container>
            <Grid item xs={12}>
              <h4>Saldo On Going</h4>
            </Grid>
            <Grid item xs={12}>
              <div style={{color: 'rgb(228 166 0)'}}>
                {
                  amount.ongoing === '' ? 
                  '-'
                  :
                  `Rp. ${numberSeparator(amount.ongoing)}`
                }
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item xs={12}>
          <WrapHistoryPage>
            <div className="header">
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => handleChangeTab(newValue)}
              >
                <Tab label="Semua" />
                <Tab label="Keluar" />
                <Tab label="Masuk" />
                <Tab label="On going" />
              </Tabs>
              {selectedSupplier && (
              <ButtonCustom 
                color="red"
                withBorder={true}
                onClick={handleOpenExport}
              >export</ButtonCustom>
              )}
            </div>
            <TabPanel activeTab={activeTab} index={0}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={1}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={2}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={3}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
          </WrapHistoryPage>
        </Grid>
      </Grid>
      <DialogExportTransaction
        {...props}
        open={open}
        supplier={selectedSupplier}
        onClose={handleCloseExport}
      />
    </div>
  );
};

export default HistoryTransactionPage;
