import { GET_ALL_PICS } from "services/pic.service";

let fetch = null;

export const fetchPic = async () => {
  const fetchData =
    fetch ||
    GET_ALL_PICS({
      paginate: 100,
    });

  if (!fetch) {
    fetch = fetchData;
  }

  return fetch.then(({ data }) => {
    return data;
  });
};
