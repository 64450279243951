import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import SimpleImageSlider from "react-simple-image-slider";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const DetailProduct = withStyles(style)((props) => {
  const { classes, data, handleBack } = props;
  const [state, setState] = useState({
    selectedSkus: data.skus[0],
  });

  const handleSetState = (state_name, value) => {
    setState({ ...state, [state_name]: value });
  };

  const getImage = () => {
    let dataResult = [];
    data.skus.forEach((res) => {
      dataResult = [...dataResult, { url: res.image_url }];
    });
    return dataResult;
  };

  const getTotalStock = () => {
    let resultData = 0;
    data.skus.forEach((res) => {
      resultData = resultData + res.stock;
    });
    return resultData;
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.flexCenter}>
          <ArrowBackIcon
            style={{ paddingRight: 10, cursor: "pointer" }}
            onClick={handleBack}
          />
          <b>Detail Produk</b>
        </Typography>
      </div>

      <Grid container spacing={3} style={{ marginTop: 30 }}>
        <Grid item md={9} sm={12} xs={12}>
          <Paper style={{ padding: 30 }}>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <SimpleImageSlider
                  width={250}
                  height={250}
                  images={getImage()}
                />
              </Grid>
              <Grid item xs>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography>
                    <b>{data.name}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#c4c4c4", paddingLeft: 10 }}
                  >
                    {state.selectedSkus.code}
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingTop: 20 }}>
                  <div>
                    <Typography variant="body2" style={{ color: "#c4c4c4" }}>
                      Harga Reseller
                    </Typography>
                    <Typography>
                      <b>Rp. {state.selectedSkus.price.reseller}</b>
                    </Typography>
                  </div>
                  <div style={{ paddingLeft: 20 }}>
                    <Typography variant="body2" style={{ color: "#c4c4c4" }}>
                      Komisi
                    </Typography>
                    <Typography>
                      <b>Rp. {state.selectedSkus.price.commission}</b>
                    </Typography>
                  </div>
                </div>

                <div style={{ display: "flex", paddingTop: 20 }}>
                  <Typography
                    variant="body2"
                    style={{ color: "#c4c4c4", width: 50 }}
                  >
                    Tersisa
                  </Typography>
                  <Typography variant="body2" style={{ paddingLeft: 20 }}>
                    {state.selectedSkus.stock}
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingTop: 5 }}>
                  <Typography
                    variant="body2"
                    style={{ color: "#c4c4c4", width: 50 }}
                  >
                    Warna
                  </Typography>
                  <Typography variant="body2" style={{ paddingLeft: 20 }}>
                    {state.selectedSkus.properties.color}
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingTop: 5 }}>
                  <Typography
                    variant="body2"
                    style={{ color: "#c4c4c4", width: 50 }}
                  >
                    Berat
                  </Typography>
                  <Typography variant="body2" style={{ paddingLeft: 20 }}>
                    {state.selectedSkus.weight_gram}
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingTop: 5 }}>
                  <Typography
                    variant="body2"
                    style={{ color: "#c4c4c4", width: 50 }}
                  >
                    Varian
                  </Typography>
                  <div style={{ paddingLeft: 20 }}>
                    <Grid container spacing={1}>
                      {data.skus.map((res, index) => (
                        <Grid item key={index}>
                          <img
                            src={res.image_url}
                            style={{
                              borderRadius: 5,
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                            onClick={() => handleSetState("selectedSkus", res)}
                            height={50}
                            width={50}
                            alt=""
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>

            <div style={{ paddingTop: 30 }}>
              <Typography style={{ color: "#c4c4c4" }}>Deskripsi :</Typography>
              <Typography>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <Paper style={{ padding: 30 }}>
            <div>
              <Typography style={{ color: "#c4c4c4" }}>Total Stock</Typography>
              <Typography>
                <b>{getTotalStock()}</b>
              </Typography>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Typography style={{ color: "#c4c4c4" }}>Kategori</Typography>
              <Typography>
                <b>{data.category.name}</b>
              </Typography>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Typography style={{ color: "#c4c4c4" }}>Jenis Produk</Typography>
              <Typography>
                <b>Produk Sendiri</b>
              </Typography>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                style={{ textTransform: "capitalize", color: "#ffffff" }}
              >
                Edit Barang
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default DetailProduct;
