import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import styles from "./styles";
import moment from "moment";
import React, { useState, Fragment, useEffect } from "react";

const Modal = withStyles(styles)((props) => {
  const { open, onClose, classes, onSubmit, selectedItem } = props;

  const [state, setState] = useState({
    payload: {
      id: null,
      level:"",
      minimum_omzet: "",
      reward_desc: "",
      images: null,
    },
    image_b64: null,
    titleError: false,
    imageError: false,
  });

  const handleChange = (event) => {
    const target = event.target;
    setState({
      ...state,
      payload: {
        ...state.payload,
        [target.name]: target.value,
      },
    });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleChangePhoto(event) {
    setState({
      ...state,
      payload: { ...state.payload, images: event.target.files[0] },
      image_b64: await toBase64(event.target.files[0]),
    });
  }

  const submit = (event) => {
    onClose();
    onSubmit(state.payload);
  };

  useEffect(() => {
    if (!open) return;
    setState({
      payload: {
        method: selectedItem ? "edit" : "add",
        id: selectedItem ? selectedItem.id : null,
        level: selectedItem ? selectedItem.level : "",
        minimum_omzet: selectedItem ? selectedItem.minimum_omzet : "",
        reward_desc: selectedItem ? selectedItem.reward_desc : "",
        level: 1,
      },
      image_b64: selectedItem?.images || null,
      titleError: false,
      imageError: false,
    });
  }, [open, selectedItem]);

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{!selectedItem ? "Tambah" : "Edit"} Reward Omzet</DialogTitle>
        <DialogContent>
          <div style={{ maxWidth: 500 }}>
            <div
              className={
                classes.tambahGambar + " " + (state.imageError && classes.error)
              }
              style={{ backgroundImage: `url(${state.image_b64})` }}
            >
              {state.image_b64 === null && (
                <Fragment>
                  <Typography className={classes.tambahGambar1}>+</Typography>
                  <Typography
                    variant="caption"
                    className={classes.tambahGambar2}
                  >
                    Tambah Gambar
                  </Typography>
                </Fragment>
              )}
              <input
                type="file"
                className={classes.inputGambar}
                onChange={(event) => handleChangePhoto(event)}
              />
            </div>
            <div>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Level"
                value={state.payload.level}
                variant="outlined"
                name="level"
                onChange={handleChange}
                error={state.titleError}
                type="number"
                required
              />
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Omzet Minimum"
                value={state.payload.minimum_omzet}
                variant="outlined"
                name="minimum_omzet"
                onChange={handleChange}
                error={state.titleError}
                type="number"
                margin="normal"
                required
              />
              <TextField
                type="url"
                id="outlined-required"
                fullWidth
                label="Deskripsi"
                value={state.payload.reward_desc}
                variant="outlined"
                name="reward_desc"
                onChange={handleChange}
                margin="normal"
                required
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button onClick={onClose} color="secondary">
            Batal
          </Button>
          <Button variant="contained" color="secondary" onClick={submit}>
            {!selectedItem ? "Tambah" : "Edit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default Modal;
