import { axiosInstance } from "../config/fetchAxios";

export const TOPUP_COINS = (id, body) => {
  const response = axiosInstance.post(`/api/v1/resellers/${id}/topup-coins`, body);
  return response;
}
export const MINUS_COINS = (id, body) => {
  const response = axiosInstance.post(`/api/v1/resellers/${id}/minus-coins`, body);
  return response;
}

export const TOPUP_WALLET = (id, body) => {
  const response = axiosInstance.post(`/api/v1/resellers/${id}/topup-dompetjualan`, body);
  return response;
}