import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  withStyles
} from "@material-ui/core";
import styles from "./styles";
import React, { useState } from "react";

const ModalAddEkspedisi = withStyles(styles)((props) => {
  const [selectedCourier, setselectedCourier] = useState()
  const { open, onClose, dataCourier, list, setlist } = props;

  const submit = () => {
    const newList = JSON.parse(JSON.stringify(list))
    
    setlist([...newList, {'item': selectedCourier, id: newList.length}])
    onClose()
  }

  const handleChange = (e) => {
    setselectedCourier(e.target.value)
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Tambah Ekspedisi</DialogTitle>
        <DialogContent>
          <div style={{ maxWidth: 500, minWidth: 200 }}>
            <>
              <FormControl style={{width: '100%'}}>
                <InputLabel htmlFor="age-native-simple">Tambah Kurir</InputLabel>
                <Select
                  native
                  onChange={
                    (e) => {handleChange(e)}
                  }
                >
                  <option aria-label="None" value="" />
                  {
                    dataCourier.map((res, index) =>
                      <option key={index+'opsi'} value={res.code}>{res.name}</option>
                    )
                  }
                </Select>
              </FormControl>
            </>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button onClick={onClose} color="secondary">
            Batal
          </Button>
          <Button variant="contained" color="secondary" onClick={submit}>
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default ModalAddEkspedisi;