const style = (theme) => ({
  downloadButton: {
    border: "2px solid #404040",
    color: "#050505",
    fontWeight: 500,
    textTransform: "capitalize",
    marginLeft: theme.spacing(2),
    fontSize: 12,
  },
  addProductButton: {
    backgroundColor: "#ff8787",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#fc6d6d",
    },
  },
});

export default style;
