const style = (theme) => ({
  tambahGambar: {
    border: `2px dashed #29C0C7`,
    borderRadius: 5,
    height: 125,
    width: 125,
    cursor: "pointer",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  inputGambar: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  tambahGambar1: {
    height: "84%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 100,
    color: "#29C0C7",
  },
  inputStyle: {
    border: `1px solid #e5e5e5`,
    borderRadius: 5,
    paddingLeft: 10,
    font: 13,
  },
});

export default style;
