import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";

const useTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    marginLeft: "10px",
    minWidth: "200px",
  },
});

const Table = () => {
  const classes = useTableStyles();
  const [shopName, setShopName] = React.useState("");
  const [shopUrl, setShopUrl] = React.useState("");
  const [channel, setChannel] = React.useState("");
  const [ownerEmail, setOwnerEmail] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");

  return (
    <Paper elevation={3}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari nama toko"
              value={shopName}
              onChange={(event) => setShopName(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari url toko"
              value={shopUrl}
              onChange={(event) => setShopUrl(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari channel"
              value={channel}
              onChange={(event) => setChannel(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari email owner"
              value={ownerEmail}
              onChange={(event) => setOwnerEmail(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari nama owner"
              value={ownerName}
              onChange={(event) => setOwnerName(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Table */}
      <TableContent
        shopName={shopName}
        shopUrl={shopUrl}
        channel={channel}
        ownerEmail={ownerEmail}
        ownerName={ownerName}
      />
    </Paper>
  );
};

export default Table;
