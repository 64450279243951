import constants from "config/constants";
import styled from "styled-components";

const _getColor = (string) => {
  if (string === "red") {
    return constants.COLOR.RED;
  } else if (string === "yellow") {
    return constants.COLOR.YELLOW;
  } else if (string === "green") {
    return constants.COLOR.GREEN;
  }
  return string
};

export const ButtonCustomWrap = styled.button`
  position: relative;
  border-radius: 5px;
  outline: none;
  padding: 12px 18px;
  display: flex;
  border: none;
  background: #f7f7f7;
  cursor: pointer;
  p {
    padding: 0;
    margin: 0;
    font-family: "DMSans-Bold";
    font-size: 13px;
    letter-spacing: 0.02em;
    margin-top: 3px;
  }
  img {
    width: 24px;
    margin-right: 13px;
  }
  :focus {
    outline: none;
  }
  ${({ withBorder, color }) =>
    withBorder &&
    `
    border: 1px solid ${_getColor(color)};
    color: ${_getColor(color)};
    background: none;
  `}

  ${({ bgColor }) =>
    bgColor &&
    `
    background: ${_getColor(bgColor)};
    color: #fff;
  `}

  :disabled {
    background: #ffc7c7;
    cursor: not-allowed;
  }
`;
