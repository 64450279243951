import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../style";
import {
  Typography,
  Button,
  Paper,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Grid,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import { getCustomer, deleteCustomer } from "../../../services/axios";
import swal from "sweetalert";

import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const Customer = withStyles(style)((props) => {
  const { classes, customers, provinces, filteredCustomers, handleEditData } =
    props;
  const [state, setState] = useState({
    filteredCustomers: filteredCustomers,
    customers: customers,
    provinces: provinces,
    cities: null,
    openEditData: false,
    dataEdit: null,
    search: "",
  });

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  const handleSearch = (keyword) => {
    handleChangeState("search", keyword);
  };

  const handlePressSearch = async (e) => {
    if (e.key === "Enter") {
      const fetch = async () => {
        const response = await getCustomer(`?filter[name]=${state.search}`);
        setState({
          ...state,
          filteredCustomers: response.data,
          customers: response,
        });
      };
      fetch();
    }
  };

  const handlePressSearchBtn = async () => {
    const fetch = async () => {
      const response = await getCustomer(`?filter[name]=${state.search}`);
      setState({
        ...state,
        filteredCustomers: response.data,
        customers: response,
      });
    };
    fetch();
  };

  const handleChangePage = (e, value) => {
    window.scrollTo({ top: 1800, behavior: "smooth" });
    const fetch = async () => {
      const response = await getCustomer(
        `?page=${value}&filter[name]=${state.search}`
      );
      setState({
        ...state,
        filteredCustomers: response.data,
        customers: response,
      });
    };
    fetch();
  };

  const handleResetFilter = () => {
    const fetch = async () => {
      const response = await getCustomer();
      setState({
        ...state,
        filteredCustomers: response.data,
        customers: response,
      });
    };
    fetch();
  };

  const handleDeleteProduct = (id) => {
    swal({
      title: "Are you sure?",
      text: "Customer akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCustomer(id);
        swal("Customer dihapus!", {
          icon: "success",
        }).then(() => {
          handleResetFilter();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      } else {
        swal("Customer batal dihapus");
      }
    });
  };

  function createData(nama, kategori, telepon, alamat, kelola, res) {
    return {
      nama,
      kategori,
      telepon,
      alamat,
      kelola,
      ...res,
    };
  }

  const Kelola = ({ data }) => {
    return (
      <div style={{ width: 100 }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton onClick={() => handleEditData(data, "customer")}>
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteProduct(data.id)}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
      </div>
    );
  };

  const rows = () => {
    let returnData = [];
    state.filteredCustomers.forEach((res) => {
      returnData = [
        ...returnData,
        createData(
          res.name,
          <Typography variant="body2" className={classes.kategoriTable}>
            Customer
          </Typography>,
          res.phone,
          (res.address ? res.address + ", " : "") +
            (res.subdistrict
              ? res.subdistrict.name
              : "Belum Mengisi kecamatan"),
          <Kelola data={res} />,
          res
        ),
      ];
    });
    return returnData;
  };

  return (
    <Fragment>
      <Paper style={{ marginTop: 20 }}>
        <Grid container spacing={2} style={{ padding: 20 }}>
          <Grid item alignItems="center">
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => handleSearch(e.target.value)}
                onKeyPress={handlePressSearch}
              />
            </div>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonCari}
              onClick={handlePressSearchBtn}
            >
              Cari
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell align="center">Nama</StyledTableCell>
                <StyledTableCell align="center">Kategori</StyledTableCell>
                <StyledTableCell align="center">No. Telepon</StyledTableCell>
                <StyledTableCell align="center">Alamat</StyledTableCell>
                <StyledTableCell align="center">Dibuat pada</StyledTableCell>
                <StyledTableCell align="center">Kelola</StyledTableCell>
              </TableRow>
            </TableHead>

            {state.customers.data.length !== 0 && (
              <Fragment>
                <TableBody>
                  {rows().map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {row.nama}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.kategori}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.telepon || "Tidak tersedia"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.alamat}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(row.created_at).fromNow()}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.kelola}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Fragment>
            )}
          </Table>
        </TableContainer>

        {state.customers.data.length === 0 && (
          <div className={classes.notFound}>
            <div className={classes.notFoundChild}>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                Oops!
              </Typography>
              <Typography>Data tidak ditemukan.</Typography>
            </div>
          </div>
        )}

        {state.customers.data.length !== 0 && (
          <div className={classes.Pagination}>
            <Pagination
              count={state.customers.meta.last_page}
              variant="outlined"
              onChange={handleChangePage}
              color="secondary"
            />
          </div>
        )}
      </Paper>
    </Fragment>
  );
});

export default Customer;
