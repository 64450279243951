import React from 'react';
import { Paper } from '@material-ui/core';
import TableContent from './TableContent';

const TableDropshipper = () => {
  const [search, setSearch] = React.useState('');

  return (
    <Paper elevation={3}>
      {/* Table */}
      <TableContent search={search} />
    </Paper>
  )
}

export default TableDropshipper
