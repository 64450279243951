import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Switch,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import swal from "sweetalert";
import {
  getAllCity,
  editReseller,
  getSubdistrict,
  editCustomer,
} from "../../../services/axios";
import Loading from "../../../components/loading";

const EditData = withStyles(style)((props) => {
  const { classes, handleBack, resApi, role } = props;
  const [data, setData] = useState({
    name: resApi.name,
    email: resApi.email,
    password: "",
    store_name: resApi.storefront ? resApi.storefront.name : "",
    activated: resApi.activated ? 1 : 0,
    address: role === "reseller" ? resApi.location.address : resApi.address,
    postal_code:
      role === "reseller" ? resApi.location.postal_code : resApi.postal_code,
    phone: resApi.phone,
    city_id:
      role === "reseller"
        ? resApi.location.city.id
        : resApi.subdistrict.city_id,
    avatar: null,
    subdistrict_id: resApi.subdistrict_id,
    coins: 0,
    note: "",
    is_custom_label: resApi.is_custom_label ? 1 : 0,
  });
  const [cities, setCities] = useState(null);
  const [selectedCity, setSelectedCity] = useState(
    role === "reseller"
      ? resApi.location.city.name
      : resApi.subdistrict.city.name
  );
  const [subdistrict, setSubdistrict] = useState(null);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(
    resApi.subdistrict && resApi.subdistrict.name
  );
  const [selectedGambar, setSelectedGambar] = useState(resApi.avatar);

  const handleChangeData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  useEffect(() => {
    const fetch = async () => {
      const resCity = await getAllCity();
      setCities(resCity.data);
      const fetchCust = async () => {
        const resSubdistrict = await getSubdistrict(resApi.subdistrict.city_id);
        setSubdistrict(resSubdistrict.data);
      };
      role === "customer" && fetchCust();
    };
    fetch();
  }, [resApi, role]);

  useEffect(() => {
    console.log("data => ", data);
  }, [data]);

  const handleChangePhoto = async (event, id) => {
    console.log(event.target.files);
    if (event.target.files[0].type === "image/jpeg") {
      setData({ ...data, avatar: event.target.files[0] });
      setSelectedGambar(URL.createObjectURL(event.target.files[0]));
    } else swal("Oops!", "Gunakan File dengan exstensi JPG/JPEG", "warning");
  };

  const handleChangeCity = async (res) => {
    setSelectedCity(res.name);
    const resSubdistrict = await getSubdistrict(res.id);
    setSubdistrict(resSubdistrict.data);
    setSelectedSubdistrict(resSubdistrict.data[0].name);
    setData({
      ...data,
      city_id: res.id,
      subdistrict_id: resSubdistrict.data[0].id,
    });
  };

  const handleSimpanData = () => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("store_name", data.store_name);
    formData.append("activated", data.activated);
    formData.append("is_custom_label", data.is_custom_label);
    data.address && formData.append("address", data.address);
    data.postal_code && formData.append("postal_code", data.postal_code);
    data.phone && formData.append("phone", data.phone);
    data.city_id && formData.append("city_id", data.city_id);
    data.avatar && formData.append("avatar", data.avatar);
    formData.append("_method", "PATCH");

    const formDataCust = new FormData();
    formDataCust.append("name", data.name);
    data.phone && formDataCust.append("phone", data.phone);
    formDataCust.append("subdistrict_id", data.subdistrict_id);
    data.address && formDataCust.append("address", data.address);
    data.postal_code && formDataCust.append("postal_code", data.postal_code);
    formDataCust.append("_method", "PUT");

    if (data.name.length === 0) {
      swal("Oops!", "Nama Lengkap harus dilengkapi.", "warning");
    } else if (role === "reseller" && data.email.length === 0) {
      swal("Oops!", "Email harus dilengkapi.", "warning");
    } else if (role === "reseller" && !new RegExp("@").test(data.email)) {
      swal("Oops!", "Alamat email harus menyertakan karakter @ .", "warning");
    } else if (role === "reseller" && selectedGambar === null) {
      swal("Oops!", "Unggah foto profile", "warning");
    } else {
      // check pakai password atau tidak
      if (role === "reseller" && data.password.length !== 0) {
        if (role === "reseller" && data.password.length < 5) {
          swal("Oops!", "Maaf password harus lebih dari 5 character", "warning");
        }else{
          formData.append("password", data.password);
              role === "reseller"
            ? editReseller(resApi.id, formData)
            : editCustomer(resApi.id, formDataCust);
          handleBack();
          swal("Succes!", "date berhasil diupdate.", "success");
        }
      } else{
          role === "reseller"
          ? editReseller(resApi.id, formData)
          : editCustomer(resApi.id, formDataCust);
        handleBack();
        swal("Succes!", "date berhasil diupdate.", "success");
      }
    }
  };

  return (
    <Fragment>
      {cities === null ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={classes.header}>
            <Typography variant="h6" className={classes.flexCenter}>
              <ArrowBackIcon
                style={{ paddingRight: 10, cursor: "pointer" }}
                onClick={handleBack}
              />
              <b>Edit Data</b>
            </Typography>
          </div>

          <Grid container spacing={3} style={{ paddingTop: 30 }}>
            <Grid item md={9} sm={12} xs={12}>
              <Paper style={{ padding: 20 }}>
                <Grid container spacing={3}>
                  {role === "reseller" && (
                    <Grid item xs={12}>
                      <div className={classes.flexFullCenter}>
                        <div
                          className={classes.tambahGambar}
                          style={{
                            backgroundImage: `url(${selectedGambar})`,
                            border: selectedGambar && "none",
                          }}
                        >
                          {!selectedGambar && (
                            <Fragment>
                              <Typography className={classes.tambahGambar1}>
                                +
                              </Typography>
                              <Typography
                                variant="caption"
                                className={classes.tambahGambar2}
                              >
                                Tambah Gambar
                              </Typography>
                            </Fragment>
                          )}
                          <input
                            type="file"
                            className={classes.inputGambar}
                            onChange={(event) =>
                              handleChangePhoto(event, data.id)
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                  )}
                  <Grid item md={role === "reseller" ? 6 : 12} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography
                        variant="body2"
                        style={{ width: role === "reseller" ? "50%" : "23%" }}
                      >
                        Nama Lengkap
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.name}
                        fullWidth={true}
                        style={{ width: role === "reseller" ? "50%" : "77%" }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("name", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  {role === "reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Email
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          value={data.email}
                          style={{ width: "50%" }}
                          required
                          className={classes.inputStyle}
                          onChange={(e) =>
                            handleChangeData("email", e.target.value)
                          }
                        />
                      </div>
                    </Grid>
                  )}

                  {role === "reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Password
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          value={data.password}
                          style={{ width: "50%" }}
                          className={classes.inputStyle}
                          onChange={(e) =>
                            handleChangeData("password", e.target.value)
                          }
                        />
                      </div>
                    </Grid>
                  )}
                  {role === "reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Store
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          value={data.store_name}
                          style={{ width: "50%" }}
                          className={classes.inputStyle}
                          onChange={(e) =>
                            handleChangeData("store_name", e.target.value)
                          }
                        />
                      </div>
                    </Grid>
                  )}

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        Kode Pos
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        value={data.postal_code}
                        style={{ width: "50%" }}
                        className={classes.inputStyle}
                        type="number"
                        onChange={(e) =>
                          handleChangeData("postal_code", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        No. Handphone
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        value={data.phone}
                        style={{ width: "50%" }}
                        className={classes.inputStyle}
                        type="number"
                        onChange={(e) =>
                          handleChangeData("phone", e.target.value)
                        }
                      />
                    </div>
                  </Grid>

                  {role === "reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Aktifkan Akun
                        </Typography>
                        <Switch
                          checked={data.activated === 1}
                          onChange={() =>
                            setData({
                              ...data,
                              activated: data.activated === 0 ? 1 : 0,
                            })
                          }
                          color="secondary"
                          name="activated"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        Kabupaten/Kota
                      </Typography>
                      <FormControl
                        className={classes.inputStyle}
                        style={{ width: "50%" }}
                      >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={selectedCity}
                          disableUnderline
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {cities &&
                            cities.map((res, index) => (
                              <MenuItem
                                key={index}
                                value={res.name}
                                onClick={() => handleChangeCity(res)}
                              >
                                {(res.type === "Kabupaten"
                                  ? res.type.slice(0, 3) + ". "
                                  : res.type + " ") + res.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  {role === "customer" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Kecamatan
                        </Typography>
                        <FormControl
                          className={classes.inputStyle}
                          style={{ width: "50%" }}
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedSubdistrict}
                            disableUnderline
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {subdistrict &&
                              subdistrict.map((res, index) => (
                                <MenuItem
                                  key={index}
                                  value={res.name}
                                  onClick={() => {
                                    handleChangeData("subdistrict_id", res.id);
                                    setSelectedSubdistrict(res.name);
                                  }}
                                >
                                  {res.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  )}

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        Hilangkan logo idejualan
                      </Typography>
                      <Switch
                        checked={data.is_custom_label === 1}
                        onChange={() => {
                          if (resApi.omzet_level < 5) {
                            return swal(
                              "Oops!",
                              "Level omzet harus 5 untuk mengaktifkan fitur ini",
                              "warning"
                            );
                          } else {
                            setData({
                              ...data,
                              is_custom_label:
                                data.is_custom_label === 0 ? 1 : 0,
                            });
                          }
                        }}
                        color="secondary"
                        name="is_custom_label"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2" style={{ width: "24%" }}>
                        Alamat Lengkap
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        multiline
                        rows={10}
                        value={data.address}
                        style={{ width: "76%", borderRadius: 15 }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("address", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Paper style={{ padding: 20 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Kategori Customer
                </Typography>
                <div style={{ marginTop: 20 }}>
                  <Typography>Customer</Typography>
                  <Typography variant="caption" style={{ color: "#a2a2a2" }}>
                    Customer toko yang mendapatkan harga normal.
                  </Typography>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography>Reseller</Typography>
                  <Typography variant="caption" style={{ color: "#a2a2a2" }}>
                    Customer yang mendapatkan potongan harga.
                  </Typography>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography>Dropshipper</Typography>
                  <Typography variant="caption" style={{ color: "#a2a2a2" }}>
                    Customer mendapatkan harga normal, yang disertai alamat
                    pengiriman pada resi melekat pada customer dropship
                    tersebut.
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.buttonGreen}
                  style={{ marginTop: 20 }}
                  onClick={handleSimpanData}
                >
                  Simpan Data
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
});

export default EditData;
