import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { FormFlashsale } from "containers";
import { ArrowBack } from "@material-ui/icons";

const CreateFlashsale = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingRight: 20 }}>
          <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}>
            <ArrowBack
              style={{ paddingRight: 10, cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            <b>Tambah Flashsale</b>
          </Typography>
          <div style={{ paddingTop: 16 }}>
            <FormFlashsale {...props} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateFlashsale;
