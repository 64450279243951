import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Table from "./Table/Table";

const useStyles = makeStyles({
  title: {
    marginBottom: "30px",
  },
});

const Marketplace = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        Integrasi Marketplace
      </Typography>

      <Table />
    </div>
  );
};

export default Marketplace;
