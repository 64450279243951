const style = (theme) => ({
  logo: {
    border: `1px solid ${theme.palette.green.main}`,
    height: 175,
    width: 280,
    borderRadius: 5,
  },
  buttonLogo: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "flex-end",
  },
  buttonLogoText: {
    paddingBottom: 5,
    width: "100%",
    textAlign: "center",
    backgroundColor: "#afafaf5c",
    color: theme.palette.yellow.primary,
    cursor: 'pointer'
  },
  inputStyle: {
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterHorizontal: {
    display: "flex",
    justifyContent: "center",
  },
  buttonGreen: {
    backgroundColor: theme.palette.green.main,
    color: '#ffffff',
    textTransform: 'capitalize',
    padding: '6px 40px',
    '&:hover': {
      backgroundColor: '#42b382',
    }
  },
});

export default style;
