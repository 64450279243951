import React,{useState, useEffect} from 'react'
import {
    Button,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    withStyles,
    Backdrop,
    CircularProgress,
    Grid,
    Switch,
} from "@material-ui/core";
import swal from "sweetalert";
import { getTopPromotions, editTopPromotions } from 'services/axios';
import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import Modal from "./components/Modal";

const StyledButton = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.green.main,
        "&:hover": {
            backgroundColor: "#33b17a",
        },
        minWidth: 0,
        padding: 10,
    },
}))(Button);

const useStyles = makeStyles({
    backdrop: {
      zIndex: 100,
      color: "#fff",
    },
  });

const HeroPage = () => {
    const classes = useStyles();
    const [dataTopPromotions, setdataTopPromotions] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [position, setposition] = useState()

    const handleInput = async (item) => {
        setIsLoading(true);
        let convertDataToPost = {}

        if (position === 'left') {
            convertDataToPost = {
                'left[image]': item.image,
                'left[link]': item.url
            }
        } else if (position === 'center'){
            convertDataToPost = {
                'center[image]': item.image,
                'center[link]': item.url
            }
        } else if (position === 'right'){
            convertDataToPost = {
                'right[image]': item.image,
                'right[link]': item.url
            }
        }

        const form = Object.entries(convertDataToPost).reduce(
          (a, [k, v]) => (v !== null ? ((a[k] = v), a) : a),
          {}
        );
        const formData = new FormData();
        for (var key in form) {
          formData.append(key, form[key]);
        }

        const response = await editTopPromotions(formData)
        if (response.message) {
            setdataTopPromotions(response.data)
            swal("Hero berhasil diupdate!", { icon: "success" });
            } else {
            swal("Hero gagal diupdate!", { icon: "error" });
        }
        setIsLoading(false);
      };

    useEffect(() => {
      const getDataTopPromotions = async() => {
        const responseDataPromotions = await getTopPromotions()
        setdataTopPromotions(responseDataPromotions.data)
      }

      getDataTopPromotions()
    }, [])

    const handleChangeStatus = async(item) => {
        setIsLoading(true);

        const newStatus = !item.is_enabled;
        const formData = new FormData();
        formData.append("is_enabled", newStatus ? 1 : 0);

        const response = await editTopPromotions(formData)
        if (response.message) {
            setdataTopPromotions(response.data)
            swal("Top promotions diupdate!", { icon: "success" });
            } else {
            swal("Top promotions gagal diupdate!", { icon: "error" });
        }
        setIsLoading(false);
    }

    const createStatus = (items) => {
        return(
            <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', margin: 18}}>
                <div>Status</div>
                {
                    items &&
                    <Switch
                        checked={items.is_enabled}
                        onChange={() => handleChangeStatus(items)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                }
            </div>
        )
    }

    const createTable = (items) => {
        return(
            <TableBody>
                        <TableRow>
                            <TableCell>1.</TableCell>
                            {
                                items &&
                                <>
                                    <TableCell><img src={items.left.image} alt='ini gambar kiri' style={{ width: 100 }} /></TableCell>
                                    <TableCell>{items.left.link}</TableCell>
                                </>
                            }
                            <TableCell align="center">
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Tooltip title="Edit" placement="bottom">
                                            <StyledButton
                                                onClick={() => {
                                                    setposition('left')
                                                    setSelectedItem(items.left);
                                                    setModalOpen(true);
                                                }}
                                            >
                                                <EditIcon />
                                            </StyledButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>2.</TableCell>
                            {
                                items &&
                                <>
                                    <TableCell><img src={items.center.image} alt='ini gambar tengah' style={{ width: 100 }} /></TableCell>
                                    <TableCell>{items.center.link}</TableCell>
                                </>
                            }
                            <TableCell align="center">
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Tooltip title="Edit" placement="bottom">
                                            <StyledButton
                                                onClick={() => {
                                                    setposition('center')
                                                    setSelectedItem(items.center);
                                                    setModalOpen(true);
                                                }}
                                            >
                                                <EditIcon />
                                            </StyledButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>3.</TableCell>
                            {
                                items &&
                                <>
                                    <TableCell><img src={items.right.image} alt='ini gambar kanan' style={{ width: 100 }} /></TableCell>
                                    <TableCell>{items.right.link}</TableCell>
                                </>
                            }
                            <TableCell align="center">
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Tooltip title="Edit" placement="bottom">
                                            <StyledButton
                                                onClick={() => {
                                                    setposition('right')
                                                    setSelectedItem(items.right);
                                                    setModalOpen(true);
                                                }}
                                            >
                                                <EditIcon />
                                            </StyledButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
        )
    }

    return (
        <div>
            <TableContainer component={Paper}>
                {
                    createStatus(dataTopPromotions)
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        createTable(dataTopPromotions)
                    }
                    
                </Table>
            </TableContainer>

            <Modal
                open={modalOpen}
                selectedItem={selectedItem}
                onClose={() => setModalOpen(false)}
                onSubmit={handleInput}
            />

            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default HeroPage