import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputAdornment, OutlinedInput, Paper } from '@material-ui/core';
import { SearchIcon } from '@material-ui/data-grid';
import TableContent from './TableContent';

const useTableStyles = makeStyles({
  container: {
    padding: '20px',
  },
  filterStatus: {
    marginLeft: '10px',
    minWidth: '200px',
  }
});

const Table = () => {
  const classes = useTableStyles();
  const [search, setSearch] = React.useState('');

  return (
    <Paper elevation={3}>
      <div className={classes.container}>
        <FormControl variant="standard">
          <OutlinedInput
            placeholder="Cari PIC"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
          />
        </FormControl>
      </div>

      {/* Table */}
      <TableContent search={search} />
    </Paper>
  )
}

export default Table
