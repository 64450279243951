const style = (theme) => ({
  detailWrapper: {
    border: `2px solid #f4f4f4`,
  },
  title: {
    borderBottom: `2px solid #f4f4f4`,
    padding: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > p": {
      color: "#555555",
      fontWeight: "bold",
    },

    "& .MuiButton-outlined": {
      backgroundColor: "#f4f4f4",
      border: "none",
      textTransform: "none",
      marginRight: 8,
      fontSize: 13,
      fontWeight: 500,
    },
  },
  items: {
    padding: 20,
  },
  tambahGambar: {
    border: `2px dashed #29C0C7`,
    borderRadius: 5,
    height: 125,
    width: 125,
    cursor: "pointer",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  inputGambar: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  tambahGambar1: {
    height: "84%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 100,
    color: "#29C0C7",
  },
  tambahGambar2: {
    display: "flex",
    justifyContent: "center",
    color: "#29C0C7",
  },
  inputStyle: {
    border: `1px solid #e5e5e5`,
    borderRadius: 5,
    paddingLeft: 10,
    font: 13,
  },
  inputAdornment: {
    color: "#7777",
    borderLeft: `1px solid #e5e5e5`,
    padding: "0 8px",
    marginLeft: 8,
  },
  inputAdornmentStart: {
    color: "#7777",
    borderRight: `1px solid #e5e5e5`,
    padding: "0 8px",
    marginRight: 8,
  },
  flex: {
    display: "flex",
  },
  textVar: {
    display: "flex",
    alignItems: "center",
  },
  gram: {
    display: "flex",
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
    width: "100%",
    marginRight: 10,
  },
  textGram: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 15,
    alignItems: "center",
    backgroundColor: theme.palette.green.main,
    borderRadius: "0 30px 30px 0",
    color: "#ffffff",
  },
});

export default style;
