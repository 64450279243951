const style = (theme) => ({
  privilege: {
    backgroundColor: "#D6FFED",
    color: "#61E4AB",
    padding: "10px 35px",
    borderRadius: 5,
    width: 50,
    margin: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tambahAdmin: {
    borderRadius: 30,
    textTransform: "capitalize",
  },
  search: {
    position: "relative",
    borderRadius: 30,
    backgroundColor: "#f3f6fe",
    marginLeft: 0,
    width: 200,
    display: "flex",
    alignitems: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  notFound: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
  },
  notFoundChild: {
    textAlign: "center",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 10,
  },
});

export default style;
