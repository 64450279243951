import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Button, MenuItem, Popover, Grid } from "@material-ui/core";
import { getReseller, getProvince, getCustomer } from "../../services/axios";
import Loading from "../../components/loading";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import TambahData from "./tambah-data";
import EditData from "./edit-data";
import CustomerTable from "./table/customer";

const CustomerResellers = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    filteredResellers: null,
    filteredCustomers: null,
    resellers: null,
    customers: null,
    provinces: null,
    openTambahData: false,
    openEditData: false,
    dataEdit: null,
    role: null,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const fetch = async () => {
      const resReseller = await getReseller();
      const resCustomer = await getCustomer();
      const resProvinces = await getProvince();
      setState((state) => ({
        ...state,
        filteredCustomers: resCustomer.data,
        filteredResellers: resReseller.data,
        resellers: resReseller,
        customers: resCustomer,
        provinces: resProvinces.data,
      }));
    };
    fetch();
  }, []);

  const handleRefreshData = () => {
    const fetch = async () => {
      const resReseller = await getReseller();
      const resCustomer = await getCustomer();
      const resProvinces = await getProvince();
      setState({
        ...state,
        openTambahData: false,
        openEditData: false,
        filteredCustomers: resCustomer.data,
        filteredResellers: resReseller.data,
        resellers: resReseller,
        customers: resCustomer,
        provinces: resProvinces.data,
      });
    };
    fetch();
  };

  useEffect(() => {
    //console.log("state => ", state);
  }, [state]);

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  function createDataCsv(nama, kategori, telepon, alamat) {
    return {
      nama,
      kategori,
      telepon,
      alamat,
    };
  }

  const csvDataReseller = () => {
    let row = [];
    state.resellers.data.forEach((res, index) => {
      row = [
        ...row,
        createDataCsv(
          res.name,
          "Reseller",
          res.phone,
          res.location.address +
            ", " +
            res.location.city.name +
            " " +
            res.location.city.postal_code
        ),
      ];
    });
    return row;
  };

  const csvDataCustomer = () => {
    let row = [];
    state.customers.data.forEach((res, index) => {
      row = [
        ...row,
        createDataCsv(
          res.name,
          "Customer",
          res.phone,
          res.address + ", " + res.subdistrict.city.name + " " + res.postal_code
        ),
      ];
    });
    return row;
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleEditData = (data, role) => {
    setState({ ...state, openEditData: true, dataEdit: data, role: role });
  };

  return (
    <Fragment>
      {state.openTambahData ? (
        <Fragment>
          <TambahData
            handleBack={() => {
              handleRefreshData();
            }}
          />
        </Fragment>
      ) : state.openEditData ? (
        <Fragment>
          <EditData
            handleBack={() => {
              handleRefreshData();
            }}
            resApi={state.dataEdit}
            role={state.role}
          />
        </Fragment>
      ) : (
        <Fragment>
          {!state.resellers ? (
            <Loading />
          ) : (
            <Fragment>
              <Grid container spacing={2} justify="space-between">
                <Grid item>
                  <Typography variant="h6">
                    <b>Data Customer</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.tambahOrder}
                      style={{ marginRight: 20 }}
                      onClick={() => handleChangeState("openTambahData", true)}
                    >
                      + Tambah Data
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.other}
                      onClick={handleClick}
                    >
                      ...
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          exportToCSV(
                            csvDataReseller(),
                            `Data Reseller | Idejualan`
                          );
                          exportToCSV(
                            csvDataCustomer(),
                            `Data Customer | Idejualan`
                          );
                        }}
                      >
                        <SystemUpdateAltIcon />{" "}
                        <Typography style={{ paddingLeft: 10 }}>
                          Download Excel
                        </Typography>
                      </MenuItem>
                    </Popover>
                  </div>
                </Grid>
              </Grid>

              <div style={{ paddingTop: 30 }}>
                <CustomerTable
                  customers={state.customers}
                  filteredCustomers={state.filteredCustomers}
                  provinces={state.provinces}
                  handleEditData={handleEditData}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

export default CustomerResellers;
