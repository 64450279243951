import React from "react";
import swal from "sweetalert";

import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

import { editProduct } from "../../../../services/axios";

const DialogNotes = ({
  selectedSingleProduct,
  isOpen,
  handleClose,
  handleRefetch,
}) => {
  const [value, setValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (selectedSingleProduct) {
      setValue(selectedSingleProduct.declined_note);
    }
  }, [selectedSingleProduct]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await editProduct(selectedSingleProduct.slug, {
        declined_note: value,
      });
      setIsLoading(false);
      swal("Produk diupdate", {
        icon: "success",
      }).then(() => {
        handleRefetch();
        handleClose();
      });
    } catch (error) {
      setIsLoading(false);
      swal("Produk gagal diupdate");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{"Catatan"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            id="outlined-multiline-flexible"
            placeholder="Masukan catatan"
            multiline
            maxRows={4}
            variant="outlined"
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          variant="contained"
          autoFocus
          disabled={isLoading}
        >
          {isLoading ? "Loading...." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNotes;
