const styles = (theme) => ({
  title: {
    paddingBottom: 20,
    paddingTop: 30
  },
  bag1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  wrapper: {
    padding: 20
  },
  filter: {
    borderRadius: 50,
    padding: '0 5px',
    border: 'none',
    background: '#5555',
  },
})

export default styles;