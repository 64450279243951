import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  TextField,
  Input,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { editECategory } from "../../../../services/axios";
import swal from "sweetalert";

const EditKategori = withStyles(style)((props) => {
  const { classes, category, onClose, data, parrent } = props;
  const [state, setState] = useState({
    name: data.name,
    icon_b64: "",
    is_root_node: data.children ? 1 : 0,
    parent_id: parrent.id || "",
    current_parent_name: parrent.name || ""
  });

  console.log("dataaa => ", data);
  console.log("parrent ==> ", parrent)

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChangePhoto = async (event) => {
    console.log(event.target.files[0].type);
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/svg+xml" ||
      event.target.files[0].type === "image/png"
    ) {
      const getData = await toBase64(event.target.files[0]);
      setState({ ...state, icon_b64: getData.split(",")[1] });
    } else alert("Gunakan File dengan exstensi JPG/SVG/PNG");
  };

  const handleSimpan = async () => {
    const formData = new FormData();
    formData.append("name", state.name);
    state.icon_b64.length !== 0 && formData.append("icon_b64", state.icon_b64);
    state.is_root_node === 1 &&
      formData.append("is_root_node", state.is_root_node);
    state.parent_id.length !== 0 &&
      formData.append("parent_id", state.parent_id);
    formData.append("_method", "PATCH");
    if (state.name.length === 0) {
      swal("Oops!", "Input Nama Kategori", "warning");
    } else if (state.parent_id.length === 0 && !data.children) {
      swal("Oops!", "Input Kategori Utama dari Subkategori", "warning");
    } else {
      const response = await editECategory(data.id, formData);
      if (response.updated){
        swal("Success!", "Kategori berhasil diupdate", "success").then(() =>
        onClose()
      );
      }
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Typography>
          <b>Edit Kategori E-Produk</b>
        </Typography>
        <div style={{ marginTop: 20 }}>
          {data.children && (
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              color="primary"
              onClick={() => setState({ ...state, is_root_node: 1 })}
            >
              Kategori Utama
            </Button>
          )}
          {!data.children && (
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              color="primary"
              onClick={() => setState({ ...state, is_root_node: 0 })}
            >
              Subcategory
            </Button>
          )}
        </div>
        <div style={{ paddingTop: 20 }}>
          <Typography variant="body2" style={{ paddingLeft: 10 }}>
            Nama Kategori
          </Typography>
          <div className={classes.inputStyle}>
            <TextField
              InputProps={{ disableUnderline: true }}
              fullWidth={true}
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </div>
        </div>

        <div style={{ paddingTop: 20, display: "flex" }}>
          {!data.children && (
            <div style={{ width: "50%" }}>
              <Typography variant="body2" style={{ paddingLeft: 10 }}>
                Pilih Kategori Utama
              </Typography>
              <FormControl
                className={classes.inputStyle}
                style={{ width: "100%" }}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.current_parent_name}
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {category.map((res, index) => (
                    <MenuItem
                      key={index}
                      value={res.name}
                      onClick={() => setState({ ...state, parent_id: res.id, current_parent_name: res.name })}
                    >
                      {res.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div style={{ width: "50%", marginLeft: !data.children && 50 }}>
            <Typography variant="body2" style={{ paddingLeft: 10 }}>
              Upload Icon <em>( Optional )</em>
            </Typography>
            <div>
              <Input
                type="file"
                disableUnderline
                onChange={(event) => handleChangePhoto(event)}
              />
            </div>
          </div>
        </div>

        <div className={classes.divButton}>
          <Button className={classes.buttonGreen} onClick={handleSimpan}>
            Simpan
          </Button>
        </div>
      </div>
    </Fragment>
  );
});

export default EditKategori;
