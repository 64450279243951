import { Title } from "components/global.style";
import React from "react";
import { WrapVariantPromo } from "./style";

const index = ({ onClick, img, title, desc }) => {
  return (
    <WrapVariantPromo onClick={onClick ?? null}>
      {img && (
        <div className="icon">
          <img src={img} alt={title} />
        </div>
      )}
      <div className="text">
        <Title>{title ?? ""}</Title>
        <p>{desc ?? ""}</p>
      </div>
    </WrapVariantPromo>
  );
};

export default index;
