import { axiosInstance } from "../config/fetchAxios";

export const GET_REPORTS_SUMMARY_FILTER = (startDate, endDate) => {
  const url = `api/v1/reports/summary?between=${startDate},${endDate}&limit=10`
  const response = axiosInstance.get(url);

  return response 
}

export const GET_CHART_DAILY_FILTER = (startDate, endDate) => {
  const url = `api/v1/chart/daily?date_between=${startDate},${endDate}&limit=10`
  const response = axiosInstance.get(url);

  return response 
}

export const GET_CHART_RESELLER_FILTER = (startDate, endDate) => {
  const url = `api/v1/chart/reseller-statistic/daily?date_between=${startDate},${endDate}&limit=10`
  const response = axiosInstance.get(url);

  return response 
}
