import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import style from "./style";
import photoprofile from "../../../../assets/images/placeholder-profile.jpg";
import verticaldot from "../../../../assets/icons/vertical-dot.svg";
import house from "../../../../assets/icons/house-vector.svg";
import Typography from "@material-ui/core/Typography";

const DetailUser = withStyles(style)((props) => {
  const { classes } = props;
  const data = JSON.parse(localStorage.getItem("DATA"));

  // console.log(data);
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.left}>
          <img
            src={photoprofile}
            className={classes.photoprofile}
            alt={data.name}
          />
          <div style={{ paddingLeft: 20 }}>
            <Typography className={classes.username}>{data.name}</Typography>
            <Typography variant="caption" className={classes.titlename}>
              SuperAdmin
            </Typography>
          </div>
          <img
            src={verticaldot}
            className={classes.verticaldot}
            alt="vertical dot"
          />
        </div>
        <div className={classes.right}>
          <Hidden smDown>
            <img src={house} className={classes.house} alt="house" />
          </Hidden>
        </div>
      </div>
    </Fragment>
  );
});

export default DetailUser;
