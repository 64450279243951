import { Grid } from "@material-ui/core";
import { VariantPromo } from "components";
import { Title } from "components/global.style";
import { DataTableFlashsaleWithTabs } from "containers";
import React from "react";
import { WrapPromo } from "./style";
import iconShopSale from "../../assets/icons/shop-sale.svg";
import iconVoucher from "../../assets/icons/voucher.svg";

const Promo = (props) => {
  const listVariantPromo = [
    {
      title: "Flashsale (Promo Singkat)",
      desc: `Berikan diskon dalam waktu terbatas di beberapa produk di tokomu`,
      img: iconShopSale,
      onClick: () => props.history.push("/promo/flashsale"),
    },
    {
      title: "Kupon",
      desc: `Berikan kupon ke pengikut setia tokomu & tingkatkan penjualanmu`,
      img: iconVoucher,
      onClick: () => props.history.push("/promo/voucher"),
    },
  ];

  const _mappingVariantPromo = () => {
    const render = [];
    listVariantPromo.map((variant, index) => {
      render.push(
        <Grid item md={6} sm={12} xs={12} key={index}>
          <VariantPromo
            img={variant.img}
            title={variant.title}
            desc={variant.desc}
            onClick={variant.onClick}
          />
        </Grid>
      );
      return "";
    });
    return render;
  };
  return (
    <WrapPromo>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingRight: 20 }}>
          <Title>Promo Toko Saya</Title>
          <p>Gunakan Fitur Promosi untuk meningkatkan penjualan di Tokomu</p>
          <div style={{ paddingTop: 16 }}>
            <Grid container spacing={2}>
              {_mappingVariantPromo()}
            </Grid>
          </div>
          <div style={{ paddingTop: 16 }}>
            <DataTableFlashsaleWithTabs {...props} />
          </div>
        </Grid>
      </Grid>
    </WrapPromo>
  );
};

export default Promo;
