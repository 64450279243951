import React, { useCallback } from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { GET_ALL_BANK_ACCOUNTS } from 'services/bank.service';
import { UPDATE_BANK_ACCOUNT_NOTES } from 'services/bank.service';
import axios from 'axios';
import _ from 'lodash';
import StatusMenu from './StatusMenu';
import DocumentsDialog from './DocumentsDialog';

const headCells = [
  { id: 'id', label: 'No' },
  { id: 'name', label: 'Nama Reseller' },
  { id: 'bank', label: 'Bank' },
  { id: 'account_name', label: 'Nama Rekening' },
  { id: 'account_number', label: 'Nomor Rekening' },
  { id: 'notes', label: 'Catatan' },
  { id: 'notes_field', label: 'Beri Catatan' },
  { id: 'document', label: 'Berkas' },
  { id: 'status', label: 'Status' },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Akun Bank
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function TableContent(props) {
  const classes = useStyles();
  const { search, status, level } = props;
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([])
  const [meta, setMeta] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const cancelTokenRef = React.useRef();

  const getData = useCallback(_.debounce(({ rowsPerPage, page, search, status, level }) => {
    if (cancelTokenRef.current) cancelTokenRef.current.cancel('canceled');

    const cancelToken = axios.CancelToken.source();
    cancelTokenRef.current = cancelToken;

    GET_ALL_BANK_ACCOUNTS({
      'filter[reseller.name]': search,
      'filter[status]': status,
      'filter[reseller.level]': level,
      paginate: rowsPerPage,
      page: page + 1,
    }, {
      cancelToken: cancelToken.token
    }).then(({ data, meta }) => {
      setRows(data);
      setMeta(meta);
      cancelTokenRef.current = null;
    }).catch(err => { });
  }, 500), []);

  React.useEffect(() => {
    setPage(0);
  }, [search, status, level])

  React.useEffect(() => {
    getData({ rowsPerPage, page, search, status, getData, level });
  }, [rowsPerPage, page, search, status, getData, level])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdate = (id, newData) => {
    setRows((data) => data.map((item) => {
      return  item.id !== id ? item : newData;
    }));
  }

  const handleNotesChange = (e,id) => {
    UPDATE_BANK_ACCOUNT_NOTES(id, e.target.value).then((response) => {
      console.log(response.data);
    });
  }

  return (
    <div className={classes.root}>
      <TableToolbar />

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size='medium'
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {rows.map((row) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
              >
                <TableCell>
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.reseller.name}
                </TableCell>
                <TableCell>{row.bank}</TableCell>
                <TableCell>{row.account_name}</TableCell>
                <TableCell>{row.account_number}</TableCell>
                <TableCell>{row.notes ?? 'tidak ada catatan'}</TableCell>
                <TableCell><TextField 
                onBlur={(e,id) => {
                  handleNotesChange(e,row.id);
                }}
                label="Catatan"/></TableCell>
                <TableCell>
                  {row.document ? (
                    <DocumentsDialog data={row} onUpdate={(newData) => handleUpdate(row.id, newData)} />
                  ) : 'Tidak ada berkas'}
                </TableCell>
                <TableCell>
                  <StatusMenu data={row} onUpdate={(newData) => handleUpdate(row.id, newData)}  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}