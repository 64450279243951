import { Tabs, Tab } from "@material-ui/core";
import { SearchCustom } from "components";
import { DataTableFlashsale } from "containers";
import React, { useState, useEffect } from "react";
import { DataTableFlashsaleWithTabsWrap } from "./style";
import { GET_ACCOUNT_FLASHSALE } from "services/flashsale.service";
import moment from 'moment'

const DataTableFlashsaleWithTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  const [dataAllPromo, setDataAllPromo] = useState([])
  const [isRefetch, setIsRefetch] = useState(false)

  const filteredSedangBerjalan = dataAllPromo.filter(data => moment().isBetween(moment(data.startDate).format("YYYY-MM-DD HH:mm"), moment(data.endDate).format("YYYY-MM-DD HH:mm")))

  const filteredAkanDatang = dataAllPromo.filter(data => !moment().isBetween(moment(data.startDate).format("YYYY-MM-DD HH:mm"), moment(data.endDate).format("YYYY-MM-DD HH:mm")))

  const filteredSelesai = dataAllPromo.filter(data => moment().isAfter(moment(data.endDate).format("YYYY-MM-DD HH:mm")))

  useEffect(() => {
    const fetchMyFlashSale = async () => {
      let myFlashsale = []
      await GET_ACCOUNT_FLASHSALE().then( res => {
        res.data.map( data => {
          myFlashsale.push({
            id: data.id,
            name: data.name,
            type: "FLASHSALE",
            startDate: data.start_date,
            endDate: data.end_date,
            product: data.items.length
          })
          return''
        })
      })
      setDataAllPromo(myFlashsale)
    }
    
    if(isRefetch) {
      setDataAllPromo([])
      fetchMyFlashSale()
      setIsRefetch(false)
    } else {
      fetchMyFlashSale()
    }
  }, [isRefetch])

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );
  return (
    <DataTableFlashsaleWithTabsWrap>
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
        <Tab label="Semua" />
        <Tab label="Sedang Berjalan" />
        <Tab label="Akan Datang" />
        <Tab label="Telah Berakhir" />
      </Tabs>
      <div style={{padding: '24px 16px'}}>
        <SearchCustom onChange={null} onSearch={null} withIcon />
      </div>
      <TabPanel activeTab={activeTab} index={0}>
        <DataTableFlashsale rowsData={dataAllPromo} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
      <TabPanel activeTab={activeTab} index={1}>
        <DataTableFlashsale rowsData={filteredSedangBerjalan} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
      <TabPanel activeTab={activeTab} index={2}>
        <DataTableFlashsale rowsData={filteredAkanDatang} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
      <TabPanel activeTab={activeTab} index={3}>
        <DataTableFlashsale rowsData={filteredSelesai} setIsRefetch={setIsRefetch} {...props}/>
      </TabPanel>
    </DataTableFlashsaleWithTabsWrap>
  );
};

export default DataTableFlashsaleWithTabs;
