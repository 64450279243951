import styled from 'styled-components'

export const FormTablePromoWrap = styled.div`
  position: relative;
  width: 100%;
  min-height: 400px;
  .MuiDataGrid-root {
    position: relative;
    font-family: 'DMSans-Regular';
    .MuiDataGrid-colCell {
      padding: 16px;
    }
    .MuiDataGrid-cell {
      font-size: 13px;
    }
    .MuiDataGrid-cell, .MuiDataGrid-row {
      min-height: 60px!important;
      max-height: 60px!important;
      line-height: 59px!important;
    }
    .MuiDataGrid-columnSeparator {
      display: none;
    }
    .MuiDataGrid-colCellTitle {
      font-family: 'DMSans-Bold';
      font-size: 14px;
      color: #787878;
      letter-spacing: 0.02em;
    }
  }
  .switchPercent p {
    position: relative;
    background: #3DDC97;
    padding: 6px 15px;
    margin-right: 0px;
    left: -13px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    color: #FFF;
    :hover {
      background: #3db581;
    }
  }
  .MuiOutlinedInput-adornedStart {
    height: 38px;
  }
`