const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tambahOrder: {
    borderRadius: 30,
    textTransform: "capitalize",
  },
  other: {
    borderRadius: 30,
  },
  search: {
    position: "relative",
    borderRadius: 30,
    backgroundColor: "#f3f6fe",
    marginLeft: 0,
    width: 200,
    display: "flex",
    alignitems: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  formControl: {
    backgroundColor: "#f3f6fe",
    borderRadius: 30,
    marginLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  select: {
    padding: "5px 24px 5px 12px",
  },
  bgFilter: {
    backgroundColor: "rgba(182, 202, 255, 0.38)",
    // border: "1px solid #ced4da",
    display: "flex",
    alignItems: "center",
    width: 150,
    justifyContent: "space-between",
    padding: "5px 24px 5px 12px",
    borderRadius: 30,
    cursor: "pointer",
    marginLeft: 10,
  },
  Pagination: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  kategoriTable: {
    backgroundColor: "#D6FFED",
    borderRadius: 5,
    padding: "7px 22px",
    fontWeight: 'bold'
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300
  },
  notFoundChild: {
    textAlign: 'center'
  },
  notFoundButton: {
    borderRadius: 30,
    textTransform: 'capitalize',
    marginTop: 20,
  },
  buttonCari: {
    borderRadius: 30,
    textTransform: 'capitalize'
  }
});

export default style;
