import styled from 'styled-components'

export const FormAddPromoWrap = styled.div`
  position: relative;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  span {
    font-size: 10px;
    color: #777777;
  }
  .label {
    font-size: 16px;
    color: #555555;
    padding-left: 24px;
  }
  .desc {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 17px;
    color: #777777;
    padding-left: 24px;
  }
`;

export const InputSaldo = styled.div`
  .MuiInput-underline::before {
    border-bottom: none !important;
  }
  .MuiInput-underline::after {
    border-bottom: none !important;
  }

  .MuiFormLabel-root{
    font-size: 16px;
    color: #000;
    margin-bottom: 8px;
  }

  label {
    font-size: 13px;
    color: #000;
  }

  .before {
    background: #FFF;
    border: 1px solid #DDD;
    border-radius: 5px 0px 0px 5px;
    padding: 12px 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  input{
    padding: 12px 16px;
    background: #FFF;
    border: 1px solid #DDD;
    border-radius: 0px 5px 5px 0px;
    color: #888;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: none;
  }

  .MuiFormControl-root {
    width: 100%;
  }
`;

export const RadioInputStyle = styled.div`
  span {
    font-size: 14px;
  }
  .PrivateRadioButtonIcon-checked-28 {
    color: #FDCA40;
  }
`

export const SwitchStyle = styled.div`
  .Mui-checked .MuiSwitch-thumb {
    color: #FDCA40;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #FDCA40;
  }
`

export const SelectStyle = styled.div`
  .MuiSelect-selectMenu {
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    width: 250px;
    padding: 10px 20px;
  }
`