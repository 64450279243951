import { Button, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { ToggleButton } from "@material-ui/lab";
import React, { useState } from "react";
import { CREATE_PIC } from "services/pic.service";
import swal from "sweetalert";
import styles from "./style.module.css";
import CheckIcon from '@material-ui/icons/Check';
import { createCampaign, getDetailCampaign, updateCampaign } from "services/campaign.service";
import { GetAllSupportedBanks } from "services/bank.service";

const EditCampaign = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [allDataDetailCampaign, setallDataDetailCampaign] = useState()
  const [state, setstate] = useState({
      "name": "",
      "slug": "",
      "description": "",
      "image": "",
      "urlImage": '',
      "fields": [
        {
          "name": "",
          "type": "",
          "is_required": 1
        }
      ],
      "products": [
        {
          "description": "",
          "image": "",
          "urlImage": '',
          "price": '',
          "is_bump": 0
        }
      ],
      "testimonials": [
        {
          "name": "",
          "avatar": '',
          "urlAvatar": '',
          "testimonial": ""
        }
      ],
      "tax": 0,
      "custom_css": '',
      "custom_js": '',
      "is_midtrans_enabled": 1,
      "is_custom_bank": 0,
      "banks": ''
  })
  const [supportedBanks, setsupportedBanks] = useState()

  React.useEffect(() => {
    const getAllSupportedBanks = async() => {
      const response = await GetAllSupportedBanks()
      setsupportedBanks(response.data)
    }

    getAllSupportedBanks()
  }, [])

  const updateAllDetailDataToState = () => {
    let dataProduct = allDataDetailCampaign.products
    let dataTesti = allDataDetailCampaign.testimonials
    let dataField = allDataDetailCampaign.fields
    let dataBanks

    // insert data url image to product
    for (let i = 0; i < dataProduct.length; i++) {
      let newDataProduct = Object.assign(dataProduct[i], {
        urlImage: dataProduct[i].image,
        image: '',
        is_bump: dataProduct[i].is_bump === true ? 1 : 0
      })
      dataProduct[i] = newDataProduct
    }

    // insert data url image to testi
    for (let i = 0; i < dataTesti.length; i++) {
      let newDataField = Object.assign(dataTesti[i], {
        urlAvatar: dataTesti[i].avatar,
        avatar: '',
      })
      dataTesti[i] = newDataField
    }

    // insert data url image to product
    for (let i = 0; i < dataField.length; i++) {
      let newDataProduct = Object.assign(dataField[i], {
        is_required: dataField[i].is_required === true ? 1 : 0
      })
      dataField[i] = newDataProduct
    }

    if (allDataDetailCampaign.is_custom_bank === true) {
      dataBanks = allDataDetailCampaign.banks
    }else{
      dataBanks = []
    }

    setstate(
      {
        "name": allDataDetailCampaign.name,
        "slug": allDataDetailCampaign.slug,
        "description": allDataDetailCampaign.description,
        "image": '',
        "urlImage": allDataDetailCampaign.image,
        "fields": dataField,
        "products": dataProduct,
        "testimonials": dataTesti,
        "tax": allDataDetailCampaign.tax,
        "custom_css": allDataDetailCampaign.custom_css ? allDataDetailCampaign.custom_css : '',
        "custom_js": allDataDetailCampaign.custom_js ? allDataDetailCampaign.custom_js : '',
        "is_midtrans_enabled": allDataDetailCampaign.is_midtrans_enabled === true ? 1 : 0,
        "is_custom_bank": allDataDetailCampaign.is_custom_bank === true ? 1 : 0,
        "banks": dataBanks
    }
    )
}

  React.useEffect(() => {
    const getDataSpesific = async() => {
      const response = await getDetailCampaign(match.params.slug)
      setallDataDetailCampaign(response.data)
    }

    getDataSpesific()
  }, [])
  
  React.useEffect(() => {
    if (allDataDetailCampaign) {
        updateAllDetailDataToState()
    }
}, [allDataDetailCampaign])

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'slug') {
      if (value.includes(' ')) {
        swal("Error", 'Maaf input Slug tidak boleh ada spasi', "error");
        event.target.value = ''
      }else if(value.includes(value.toUpperCase())){
        swal("Error", 'Maaf input Slug tidak boleh ada huruf besar', "error");
        event.target.value = ''
      }else{
        setstate({ ...state, [name]: value });
      }
    }else{
      setstate({ ...state, [name]: value });
    }
  };

  const handleSubmit = async() => {
    const dataToUpload = state
    // delete data url image
    delete dataToUpload.urlImage
    if (dataToUpload.image === '') {
      delete dataToUpload.image
    }

    // delete data url image from product
    for (let i = 0; i < dataToUpload.products.length; i++) {
      delete dataToUpload.products[i].urlImage
      if (dataToUpload.products[i].image === '') {
        delete dataToUpload.products[i].image
      }
    }

    // delete data url image from testi
    for (let i = 0; i < dataToUpload.testimonials.length; i++) {
      delete dataToUpload.testimonials[i].urlAvatar
      if (dataToUpload.testimonials[i].avatar === '') {
        delete dataToUpload.testimonials[i].avatar
      }
    }

    const form_data = new FormData();
    for (let key in dataToUpload) {
      if (typeof(dataToUpload[key]) === 'object') {
        if (key === 'image') {
          form_data.append(key, dataToUpload[key]);
        }else{
          for (let index = 0; index < dataToUpload[key].length; index++) {
            for (let innerKey in dataToUpload[key][index]) {
              form_data.append(`${key}[${index}][${innerKey}]`, dataToUpload[key][index][innerKey]);
            }
          }
        }
      }else{
        if (key === 'image') {
          if (dataToUpload[key] === '-') {
            form_data.append(key, '');
          }else{
            form_data.append(key, dataToUpload[key]);
          }
        }else{
          form_data.append(key, dataToUpload[key]);
        }
      }
    }

    setIsLoading(true);
    
    await updateCampaign(match.params.slug, form_data)
    .then((result) => {
      swal("Success", "Berhasil menambahkan Campaign", "success").then(() => {
        window.history.back();
      });
      setIsLoading(false);
    })
    .catch((error) => {
      swal("Error", error.response.data.message, "error");
      setIsLoading(false);
    });
  }

  console.log(state)

  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Edit Campaign</b>
      </Typography>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit()
      }}>
        <Grid container spacing={3} style={{ marginTop: 20 }}>

          {/* banner campaign */}
          <Grid item xs={12}>
            <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                <div className={styles.photo}>
                  {state.urlImage !== null ? (
                    <img src={state.urlImage} alt="Preview" />
                  ) : (
                    <>
                      <div>Banner Photo</div>
                      <Add />
                    </>
                  )}
                  <input
                    name="images"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const url = URL.createObjectURL(file);
                      setstate({...state, image: file, urlImage: url})
                    }}
                  />
                </div>
                <div style={{marginLeft: 10}}>
                  {
                    state.urlImage !== null &&
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setstate({...state, image: '-', urlImage: null })
                      }}
                    >
                      Hapus
                    </Button>
                  }
                </div>
            </div>
          </Grid>

          {/* nama campaign */}
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Nama Campaign"
              variant="outlined"
              style={{ width: "100%" }}
              required
              value={state.name}
              onChange={handleInputChange}
            />
          </Grid>

          {/* desc utama */}
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Deskripsi Utama"
              variant="outlined"
              style={{ width: "100%" }}
              required
              value={state.description}
              onChange={handleInputChange}
            />
          </Grid>

          {/* slug utama */}
          <Grid item xs={12}>
            <TextField
              name="slug"
              label="Slug"
              variant="outlined"
              style={{ width: "100%" }}
              required
              value={state.slug}
              onChange={handleInputChange}
            />
          </Grid>

          {/* is midtrans */}
          <Grid item xs={12}>
            <div>
              <FormLabel>Pembayaran Otomatis</FormLabel>
              <RadioGroup 
                name='is_midtrans_enabled'
                aria-label="midtrans" 
                style={{display: 'flex', flexDirection: 'row'}} 
                required
                value={state.is_midtrans_enabled}
                onChange={(e) => {
                  setstate({...state, is_midtrans_enabled: Number(e.target.value)})
                }}>
                <FormControlLabel value={1} control={<Radio />} label="ya" />
                <FormControlLabel value={0} control={<Radio />} label="tidak" />
              </RadioGroup>
            </div>
          </Grid>

          {/* is custom bank */}
          <Grid item xs={12}>
            <div>
              <FormLabel>Custom Bank</FormLabel>
              <RadioGroup 
                name='is_custom_bank'
                aria-label="midtrans" 
                style={{display: 'flex', flexDirection: 'row'}} 
                required
                value={state.is_custom_bank}
                onChange={(e) => {
                  if (Number(e.target.value) === 0) {
                    setstate({...state, is_custom_bank: Number(e.target.value), banks: []})
                  }else{
                    setstate({...state, is_custom_bank: Number(e.target.value), banks: [
                      {
                        "bank_code": "",
                        "account_name": "",
                        "account_number": ''
                      }
                    ]})
                  }
                }}>
                <FormControlLabel value={1} control={<Radio />} label="ya" />
                <FormControlLabel value={0} control={<Radio />} label="tidak" />
              </RadioGroup>
            </div>
          </Grid>

          {/* field add bank */}
          {
            state.is_custom_bank === 1 &&
            state.banks&&
            <Grid item xs={12}>
              {
                state.banks.map((resBank, indexBank) => 
                  <div key={indexBank+'bank'} style={{display: 'flex', justifyContent: 'start', alignItems: 'start', marginBottom: 15}}>
                    {/* code bank */}
                    <div style={{width: '30%'}}>
                      <InputLabel id="demo-simple-select-label">Pilih Bank {indexBank+1}</InputLabel>
                      <select
                        required
                        style={{border: 'none', fontSize: '1.1rem', width: '90%'}}
                        onChange={(e) => {
                          const allDataBanks = state.banks
                          allDataBanks[indexBank].bank_code = e.target.value
                          setstate({...state, banks: allDataBanks})
                        }}
                      >
                        <option value={''} >None</option>
                        {
                          supportedBanks?.sort((a, b) => a.name.localeCompare(b.name)).map((res) =>
                            <option key={'key'+res} selected={res.code === state.banks[indexBank].bank_code} value={res.code} >{res.name}</option>
                          )
                        }
                      </select>
                    </div>

                    {/* nama account bank */}
                    <TextField
                      name="account_name"
                      label={`Nama Akun Bank ${indexBank+1}`}
                      variant="outlined"
                      style={{ width: "30%", marginRight: 5 }}
                      required
                      value={state.banks[indexBank].account_name}
                      onChange={(e) => {
                        const allBanks = state.banks
                        allBanks[indexBank].account_name = e.target.value
                        setstate({...state, banks: allBanks})
                      }}
                    />

                    {/* no account bank */}
                    <TextField
                      name="account_number"
                      label={`Nomor Akun Bank ${indexBank+1}`}
                      variant="outlined"
                      style={{ width: "30%" }}
                      type={'number'}
                      required
                      value={state.banks[indexBank].account_number}
                      onChange={(e) => {
                        const allBanks = state.banks
                        allBanks[indexBank].account_number = e.target.value
                        setstate({...state, banks: allBanks})
                      }}
                    />

                    {
                      indexBank !== 0 &&
                        indexBank === state.banks.length-1 &&
                        <div style={{marginLeft: 5}}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              const dataBanks = state.banks
                              dataBanks.pop()
                              setstate({...state, 'banks': dataBanks})
                            }}
                          >
                            Hapus
                          </Button>
                        </div>
                    }
                  </div>
                )
              }
              <Button
                variant="contained"
                color="primary"
                onClick={() => {setstate({...state, 'banks': [...state.banks, {
                  "bank_code": "",
                  "account_name": "",
                  "account_number": ''
                }]})}}
              >
                Tambah Bank
              </Button>
            </Grid>
          }

          {/* product */}
          <Grid item xs={12}>
            <FormLabel>Products</FormLabel>
            <br/><br/>
            {
              state.products.map((res, index) => 
                <div key={index + 'produk'} style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: 10}}>
                  <div className={styles.photo} style={{marginRight: 10}}>
                    {state.products[index].urlImage !== '' ? (
                      <img src={state.products[index].urlImage} alt="Preview" />
                    ) : (
                      <>
                        <div>Product Photo {index+1}</div>
                        <Add />
                      </>
                    )}
                    <input
                      name="images"
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const url = URL.createObjectURL(file);
                        const allProduct = state.products
                        allProduct[index].image = file
                        allProduct[index].urlImage = url
                        setstate({...state, 
                          'products': allProduct})
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      type="text"
                      label="Deskripsi Product"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={state.products[index].description}
                      onChange={(e) => {
                        const allProduct = state.products
                        allProduct[index].description = e.target.value
                        setstate({...state, 
                          'products': allProduct})
                      }}
                    />
                    <br/><br/>
                    <TextField
                      type="number"
                      label="Price"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={state.products[index].price}
                      onChange={(e) => {
                        const allProduct = state.products
                        allProduct[index].price = e.target.value
                        setstate({...state, 
                          'products': allProduct})
                      }}
                    />
                    <br/><br/>
                    {/* is bump */}
                    <div>
                      <FormLabel>Bump</FormLabel>
                      <RadioGroup 
                        aria-label="bump" 
                        style={{display: 'flex', flexDirection: 'row'}} 
                        value={state.products[index].is_bump}
                        required
                        onChange={(e) => {
                          const allDataProduct = state.products
                          allDataProduct[index].is_bump = Number(e.target.value)
                          setstate({...state, products: allDataProduct})
                        }}>
                        <FormControlLabel value={1} control={<Radio />} label="ya" 
                          disabled={index===0}
                        />
                        <FormControlLabel value={0} control={<Radio />} label="tidak" 
                          disabled={index===0}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  {
                    index !== 0 &&
                      index === state.products.length-1 &&
                      <div style={{marginLeft: 5}}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            const dataProducts = state.products
                            dataProducts.pop()
                            setstate({...state, 'products': dataProducts})
                          }}
                        >
                          Hapus
                        </Button>
                      </div>
                  }
                </div>
              )
            }
            <br/><br/>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {setstate({...state, 'products': [...state.products, {
                "description": "",
                "image": "",
                "urlImage": '',
                "price": '',
                "is_bump": 1
              }]})}}
            >
              Tambah Product
            </Button>
          </Grid>

          {/* testimonial */}
          <Grid item xs={12}>
            <FormLabel>Testimonial</FormLabel>
            <br/><br/>
            {
              state.testimonials.map((res, index) => 
                <div key={index + 'testi'} style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: 10}}>
                  <div className={styles.photo} style={{marginRight: 10}}>
                    {state.testimonials[index].urlAvatar !== '' ? (
                      <img src={state.testimonials[index].urlAvatar} alt="Preview" />
                    ) : (
                      <>
                        <div>Testimoni Photo {index+1}</div>
                        <Add />
                      </>
                    )}
                    <input
                      name="images"
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const url = URL.createObjectURL(file);
                        const allTesti = state.testimonials
                        allTesti[index].avatar = file
                        allTesti[index].urlAvatar = url
                        setstate({...state, 
                          'testimonials': allTesti})
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      type="text"
                      label="Nama Testimoni"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={state.testimonials[index].name}
                      onChange={(e) => {
                        const allTesti = state.testimonials
                        allTesti[index].name = e.target.value
                        setstate({...state, 
                          'testimonials': allTesti})
                      }}
                    />
                    <br/><br/>
                    <TextField
                      type="text"
                      label="Deskripsi Testimoni"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                      value={state.testimonials[index].testimonial}
                      onChange={(e) => {
                        const allTesti = state.testimonials
                        allTesti[index].testimonial = e.target.value
                        setstate({...state, 
                          'testimonials': allTesti})
                      }}
                    />
                  </div>
                  {
                    index !== 0 &&
                      index === state.testimonials.length-1 &&
                      <div style={{marginLeft: 5}}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            const dataTesti = state.testimonials
                            dataTesti.pop()
                            setstate({...state, 'testimonials': dataTesti})
                          }}
                        >
                          Hapus
                        </Button>
                      </div>
                  }
                </div>
              )
            }
            <br/><br/>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {setstate({...state, 'testimonials': [...state.testimonials, {
                "name": "",
                "avatar": '',
                "urlAvatar": '',
                "testimonial": ""
              }]})}}
            >
              Tambah Testimoni
            </Button>
          </Grid>

          {/* field */}
          <Grid item xs={12}>
            <FormLabel>Field</FormLabel>
            <br/><br/>
            {
              state.fields.map((res, index) => 
                <div key={'field' + index} style={{display: 'flex', justifyContent: 'start', alignItems: 'start', marginBottom: 10}}>
                  <TextField
                    label={`Nama Field ${index+1}`}
                    variant="outlined"
                    style={{ width: "50%", marginRight: 10 }}
                    required
                    value={state.fields[index].name}
                    disabled={
                      state.fields[index].name === 'Nama Panjang' || 
                      state.fields[index].name === 'Email' ||
                      state.fields[index].name === 'Password' ||
                      state.fields[index].name === 'No. Hp'}
                    onChange={(e) => {
                      const allFields = state.fields
                      allFields[index].name = e.target.value
                      setstate({...state, fields: allFields})
                    }}
                  />

                  <div style={{marginRight: 10}}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <select
                      required
                      style={{border: 'none', fontSize: '1.1rem'}}
                      disabled={
                        state.fields[index].name === 'Nama Panjang' || 
                        state.fields[index].name === 'Email' ||
                        state.fields[index].name === 'Password' ||
                        state.fields[index].name === 'No. Hp'}
                      onChange={(e) => {
                        const allDataField = state.fields
                        allDataField[index].type = e.target.value
                        setstate({...state, fields: allDataField})
                      }}
                    >
                      <option value={''} >None</option>
                      {
                        ['text', 'number', 'email', 'password', 'name', 'phone'].map((res) =>
                          res === state.fields[index].type ? 
                          <option key={'key'+res} value={res} selected='selected' >{res}</option>
                          :
                          <option key={'key'+res} value={res} >{res}</option>
                        )
                      }
                    </select>
                  </div>

                  {/* is required */}
                  <div>
                      <FormLabel>Required</FormLabel>
                      <RadioGroup 
                        aria-label="bump" 
                        style={{display: 'flex', flexDirection: 'row'}} 
                        required
                        onChange={(e) => {
                          const allDataProduct = state.fields
                          allDataProduct[index].is_required = Number(e.target.value)
                          setstate({...state, fields: allDataProduct})
                        }}>
                        <FormControlLabel value={1} checked={state.fields[index].is_required === 1} control={<Radio />} label="ya" 
                          disabled={
                            state.fields[index].name === 'Nama Panjang' || 
                            state.fields[index].name === 'Email' ||
                            state.fields[index].name === 'Password' ||
                            state.fields[index].name === 'No. Hp'}
                        />
                        <FormControlLabel value={0} checked={state.fields[index].is_required === 0} control={<Radio />} label="tidak" 
                          disabled={
                            state.fields[index].name === 'Nama Panjang' || 
                            state.fields[index].name === 'Email' ||
                            state.fields[index].name === 'Password' ||
                            state.fields[index].name === 'No. Hp'}
                        />
                      </RadioGroup>
                    </div>

                    {
                      index !== 0 &&
                        index === state.fields.length-1 &&
                        index !== 3 &&
                        <div style={{marginLeft: 5}}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              const dataFields = state.fields
                              dataFields.pop()
                              setstate({...state, 'fields': dataFields})
                            }}
                          >
                            Hapus
                          </Button>
                        </div>
                    }
                </div>
              )
            }
            <br/><br/>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {setstate({...state, 'fields': [...state.fields, {
                "name": "",
                "type": "",
                "is_required": 1
              }]})}}
            >
              Tambah Field
            </Button>
          </Grid>

          {/* pajak */}
          <Grid item xs={12}>
            <FormLabel>Pajak</FormLabel>
            <br/><br/>

            <TextField
              name="tax"
              label="PPN"
              variant="outlined"
              style={{ width: "100%" }}
              value={state.tax}
              type={'number'}
              min={0}
              required
              onChange={handleInputChange}
            />

            <br/><br/>

            <TextField
              name="custom_js"
              label="Custom JS"
              variant="outlined"
              style={{ width: "100%" }}
              value={state.custom_js}
              onChange={handleInputChange}
            />

            <br/><br/>

            <TextField
              name="custom_css"
              label="Custom CSS"
              variant="outlined"
              style={{ width: "100%" }}
              value={state.custom_css}
              onChange={handleInputChange}
            />

            <br/><br/>
          </Grid>

          <div style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default EditCampaign;
