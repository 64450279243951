import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import numberSeparator from "utils/numberSeparator";
import { deleteProductDropship, getAllProductDropship } from "services/dropship.service";

const headCells = [
  { id: "no", label: "No." },
  { id: "img", label: "Gambar" },
  { id: "name", label: "Nama Product" },
  { id: "harga_jual", label: "Harga Jual" },
  { id: 'action', label: 'Action'}
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  photo: {
    marginRight: 10,
    borderRadius: "50%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { search } = props;
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getData = useCallback(
    _.debounce(async({ rowsPerPage, page, search, getData }) => {
      const response = await getAllProductDropship(
        {
          paginate: rowsPerPage,
          page: page + 1,
        }
      )

      setRows(response.data);
      setMeta(response.meta);
    }, 500),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [search]);

  React.useEffect(() => {
    getData({ rowsPerPage, page, search, getData });
  }, [rowsPerPage, page, search, getData]);

  const reFetch = () => {
    getData({ rowsPerPage, page, search, getData });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (slug) => {
    swal({
      title: "Apakah kamu yakin?",
      text: "Product akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // fetch api delete
        deleteProductDropship(slug)
        swal("Product telah dihapus!", {
          icon: "success",
        }).then(() => {
          reFetch();
        });
      } else {
        swal("Product batal dihapus");
      }
    });
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {rows.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell component="th" scope="row">
                  <div className={classes.flex}>
                    {
                      row.image === null ?
                      <>Image Tidak Di Set</>
                      :
                      <img
                        className={classes.photo}
                        src={row.image}
                        alt={row.name}
                        width={50}
                        height={50}
                      />
                    }
                  </div>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>Rp. {numberSeparator(row.price)}</TableCell>

                <TableCell>
                  <Link to={"/product-dropship/Edit/" + row.id}>
                    <IconButton aria-label="edit" size="small">
                      <Edit />
                    </IconButton>
                  </Link>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(row.id)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
