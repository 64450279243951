import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";

const useTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    marginLeft: "10px",
    minWidth: "200px",
  },
});

const Table = () => {
  const classes = useTableStyles();
  const [filter, setFilter] = React.useState({
    id: "",
    brand_name: "",
    fullname: "",
    whatsapp: "",
    status: "all",
    include: "productExamples",
  });

  const handleChangeFilter = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  return (
    <Paper elevation={3}>
      <div className={classes.container}>
        <FormControl variant="standard">
          <Grid container spacing={2}>
            <Grid item>
              <OutlinedInput
                placeholder="Cari ID"
                name="id"
                value={filter.id}
                onChange={handleChangeFilter}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item>
              <OutlinedInput
                placeholder="Cari nama brand"
                name="brand_name"
                value={filter.brand_name}
                onChange={handleChangeFilter}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item>
              <OutlinedInput
                placeholder="Cari nama lengkap"
                name="fullname"
                value={filter.fullname}
                onChange={handleChangeFilter}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item>
              <OutlinedInput
                placeholder="Cari no whatsapp"
                name="whatsapp"
                value={filter.whatsapp}
                onChange={handleChangeFilter}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item>
              <Select
                name="status"
                value={filter.status}
                onChange={handleChangeFilter}
                variant="outlined"
              >
                <MenuItem value="all">Semua status</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </FormControl>
      </div>

      {/* Table */}
      <TableContent filter={filter} />
    </Paper>
  );
};

export default Table;
