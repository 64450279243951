import { axiosInstance } from "../config/fetchAxios";

export const GET_ALL_PAYMENTS = (params, options) => {
  const url = "/api/v2/payments";

  const response = axiosInstance.get(url, { params, ...options });

  return response;
};

export const UPDATE_PAYMENT_STATUS = (id, status) => {
  const url = `/api/v2/payments/${id}`;

  const response = axiosInstance.put(url, {
    status,
  });

  return response;
};
