import React from "react";

import { Typography, Box, Button, Grid } from "@material-ui/core";
import {
  Add as AddIcon,
  CloudDownload as DownloadIcon,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import Notification from "../home/components/notifikasi";
import ListProduct from "./components/ListProduct";
import Form from "./components/Form";

import style from "./style";

const ECourse = withStyles(style)((props) => {
  const { classes } = props;

  const [activeState, setActiveState] = React.useState("list");
  const [selectedProduct, setSelectedProduct] = React.useState();

  let renderContent = <ListProduct />;

  if (activeState === "list") {
    renderContent = (
      <ListProduct
        setSelectedProduct={(product) => setSelectedProduct(product)}
        setActiveState={(value) => setActiveState(value)}
      />
    );
  } else if (activeState === "add" || "edit") {
    renderContent = (
      <Form
        setActiveState={(value) => setActiveState(value)}
        activeState={activeState}
        selectedProduct={selectedProduct}
      />
    );
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs style={{ paddingRight: 20 }}>
          {activeState === "list" && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Daftar E-Course</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box my={1}>
                  <Button
                    className={classes.addProductButton}
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={() => setActiveState("add")}
                  >
                    Tambah E-Course
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}

          {renderContent}
        </Grid>
      </Grid>
    </div>
  );
});

export default ECourse;
