const style = (theme) => ({
    filter: {
        borderRadius: 30,
        backgroundColor: theme.palette.white.tertiary,
        padding: `10px 30px`,
        margin: '0 10px',
        textTransform: "capitalize",
    },
    title: {
        marginBottom: '30px',
    },
    button: {
        marginBottom: '30px',
    }
});

    export default style;
