import React from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import style from "./style";
import { ReactComponent as HamburgerMenu } from "../../assets/icons/burger-menu.svg";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import { withRouter } from "react-router-dom";
import Sidebar from "../sidebar";

const Navbar = withStyles(style)((props) => {
  const { classes, location } = props;
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [showSidebar, setShowSidebar] = React.useState(false);

  React.useEffect(() => {
    document.getElementById("root").style.overflow = showSidebar
      ? "hidden"
      : "unset";
  }, [showSidebar]);

  return (
    <>
      <div className={classes.container}>
        <HamburgerMenu
          className={classes.icon}
          onClick={() => isMdDown && setShowSidebar(true)}
        />
        <Typography className={classes.text}>
          Dashboard{" "}
          {location.pathname === "/"
            ? ""
            : "/ " +
              location.pathname.slice(1)[0].toUpperCase() +
              location.pathname.slice(2).replace("-", " ").replace("/", " / ")}
        </Typography>
      </div>
      <Drawer
        anchor="left"
        open={showSidebar}
        onClose={() => setShowSidebar(false)}
        style={{ height: "100vh"}}
        PaperProps={{ style: { display: "unset" } }}
      >
        <Sidebar />
      </Drawer>
    </>
  );
});

export default withRouter(Navbar);
