/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import ReactDOM from 'react-dom'

const Metabase = withStyles(style)((props) => {
  const { classes } = props;
  const jwt = require("jsonwebtoken");

  const METABASE_SITE_URL = "https://metabase.idejualan.com";
  const METABASE_SECRET_KEY = "e7bdca3d0944133dbc3784b9c219db735b1a14423c78633ebeba054ea8868632";
  
  const payload = {
    resource: { dashboard: 2 },
    params: {},
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };
  const token = jwt.sign(payload, METABASE_SECRET_KEY);
  
  const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

  return (
      <React.Fragment>
        <div className={classes.containerFrame}>
          <iframe
            src={iframeUrl}
            width='100%'
            height='100%'
            frameborder="0"
            allowtransparency
            allowfullscreen
        ></iframe>
        </div>
      </React.Fragment>
  )
})

export default Metabase