import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Paper, Grid, TextField, Button } from "@material-ui/core";
import swal from "sweetalert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { editAdmin } from "../../../services/axios";

const EditAdmin = withStyles(style)((props) => {
  const { classes, onClose, dataAdmin, handleBack } = props;
  const [data, setData] = useState({
    name: dataAdmin.name,
    email: dataAdmin.email,
    password: "",
    rePassword: "",
    activated: 1,
    phone: "",
  });

  console.log("yeee butul => ", dataAdmin);

  const handleChangeData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleSimpanData = () => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("activated", data.activated);
    console.log("simpan => ", data);
    if (data.name.length === 0) {
      swal("Oops!", "Masukan Nama Lengkap", "warning");
    } else if (data.email.length === 0) {
      swal("Oops!", "Masukan Email", "warning");
    } else if (!new RegExp("@").test(data.email)) {
      swal("Oops!", "Alamat email harus menyertakan karakter @ .", "warning");
    } else if (data.password.length === 0) {
      swal("Oops!", "Masukan Password", "warning");
    } else if (data.password !== data.rePassword) {
      swal("Oops!", "Password salah!", "warning");
    } else {
      editAdmin(formData);
      onClose();
    }
  };
  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.flexCenter}>
          <ArrowBackIcon
            style={{ paddingRight: 10, cursor: "pointer" }}
            onClick={handleBack}
          />
          <b>Edit Admin</b>
        </Typography>
      </div>

      <Paper style={{ marginTop: 30, padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={classes.flexCenter}>
              <Typography variant="body2" style={{ width: "50%" }}>
                Nama Lengkap
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={data.name}
                style={{ width: "50%" }}
                className={classes.inputStyle}
                onChange={(e) => handleChangeData("name", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={6}>
            <div className={classes.flexCenter}>
              <Typography variant="body2" style={{ width: "50%" }}>
                Email
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={data.email}
                style={{ width: "50%" }}
                type="email"
                className={classes.inputStyle}
                onChange={(e) => handleChangeData("email", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.flexCenter}>
              <Typography variant="body2" style={{ width: "20%" }}>
                No. Tlp
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                value={data.phone}
                type="number"
                style={{ width: "50%" }}
                className={classes.inputStyle}
                onChange={(e) => handleChangeData("phone", e.target.value)}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.flexCenter}>
              <Typography variant="body2" style={{ width: "50%" }}>
                Password
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                type="password"
                fullWidth={true}
                style={{ width: "50%" }}
                className={classes.inputStyle}
                onChange={(e) => handleChangeData("password", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <div className={classes.flexCenter}>
              <Typography variant="body2" style={{ width: "50%" }}>
                Re-enter Password
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                type="password"
                style={{ width: "50%" }}
                className={classes.inputStyle}
                onChange={(e) => handleChangeData("rePassword", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <div className={classes.simpanButton}>
          <Button
            variant="contained"
            className={classes.buttonGreen}
            style={{ marginTop: 20 }}
            onClick={handleSimpanData}
          >
            Simpan Data
          </Button>
        </div>
      </Paper>
    </Fragment>
  );
});

export default EditAdmin;
