const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  }
})

export default style;