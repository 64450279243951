const style = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 119px)",
  },
  header: {
    height: "25%",
    backgroundColor: "#FDCA40",
    color: "#fff",
    padding: "24px 60px",
    position: "relative",
    "& .MuiTypography-root.MuiTypography-caption": {
      fontSize: 10,
    },
  },
  headerLogo: {
    height: 60,
    width: 180,
    filter: "brightness(0) invert(1)",
    margin: "0 12px",
  },
  headerOverlay: {
    position: "absolute",
    borderRadius: 8,
    left: 0,
    right: 0,
    bottom: -20,
    backgroundColor: "#fff",
    width: "fit-content",
    margin: "0 auto",
  },
  body: {
    height: "75%",
    backgroundColor: "#f6f7f9",
    padding: "32px 48px 0px",
  },
  card: {
    backgroundColor: "#fff",
    height: "100%",
    "& .MuiTypography-root.MuiTypography-subtitle1": {
      fontWeight: 600,
    },
  },
  cardCriteriaItemWrapper: {
    height: "100%",
  },
  cardCriteriaItem: {
    padding: 8,
    textAlign: "center",
    "& .MuiTypography-root.MuiTypography-body2": {
      color: "red",
    },
    borderBottom: "1px solid #e1e1e1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  rightBorder: {
    borderRight: "1px solid #e1e1e1",
  },
  height100: {
    height: "100%",
  },
  height100Min57: {
    height: "calc(100% - 61px)",
  },
  mostBuyProductItem: {
    padding: "8px",
    "& .MuiTypography-root.MuiTypography-caption": {
      color: "red",
    },
    "&:nth-child(odd)": {
      backgroundColor: "#fafafa",
    },
    display: "flex",
    alignItems: "center",
  },
});

export default style;
