import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  Switch,
  Collapse,
  Popper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CardDetailBarang from "./CardDetailBarang";
import createRandomId from "../../../../utils/createRandomId";
import swal from "sweetalert";
import {
  editProduct,
  addSkus,
  editSkus,
  deleteSkus,
  editProductCustom,
} from "../../../../services/axios";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CardCustomProduct from "./CardCustomProduct";

const addImageB64 = (data) => {
  let dataResult = [];
  data.forEach((res, index) => {
    dataResult = [
      ...dataResult,
      {
        ...data[index],
        image_b64: res.image_url,
      },
    ];
  });
  return dataResult;
};

const EditProduk = withStyles(style)((props) => {
  const { classes, category, onClose, dataApi, reFetch } = props;
  const dataSupplier = JSON.parse(localStorage.getItem("DATA"));

  const [state, setState] = useState({
    nama_produk: dataApi.name,
    tags: dataApi.tags.join(),
    kategori: dataApi.category,
    supplier: dataApi.supplier,
    diskon: "",
    comission_value: dataApi.commission.value,
    comission_type: "PERCENT",
    deskripsi: dataApi.description,
    platform_fee_value: dataSupplier.platform_fee_value,
    platform_fee_type: dataSupplier.platform_fee_type,
    detail_barang: dataApi.is_custom ? [] : addImageB64(dataApi.skus),
    plus_varian: [],
    remove_varian: [],
    images: dataApi.images,
  });

  const [settings, setSettings] = useState({
    varian: true,
    diskon: false,
    publish: true,
    show_stock: dataApi.show_stock,
  });
  const [openChilderenCat, setOpenChilderenCat] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingTambah, setLoadingTambah] = useState(false);
  const [isCustom, setIsCustom] = React.useState(dataApi.is_custom);
  const [customVariant, setCustomVariant] = React.useState(
    dataApi.properties_custom || [""]
  );
  const [customProductImage, setCustomProductImage] = React.useState(
    dataApi.image_url
  );
  const [deletedImages, setDeletedImages] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSetState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSettings = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChangePhoto = async (event, code) => {
    const validImage = ["image/jpeg", "image/png"];
    if (validImage.includes(event.target.files[0].type)) {
      if (code) {
        setState({
          ...state,
          detail_barang: [
            ...state.detail_barang.filter((value) => value.code !== code),
            {
              ...state.detail_barang.filter((value) => value.code === code)[0],
              image_b64: await toBase64(event.target.files[0]),
            },
          ],
        });
      } else {
        setCustomProductImage(await toBase64(event.target.files[0]));
      }
    } else alert("Gambar yang di upload tidak valid");
  };

  const handleChangePhotoPlusVarian = async (event, code) => {
    const validImage = ["image/jpeg", "image/png"];
    if (validImage.includes(event.target.files[0].type)) {
      const image = await toBase64(event.target.files[0]);
      setState({
        ...state,
        plus_varian: state.plus_varian.map((item) => {
          if (item.code !== code) return item;
          return {
            ...item,
            image_b64: image,
          };
        }),
      });
    } else swal("Gambar yang di upload tidak valid");
  };

  const handleChangeImage = async (event, index, image) => {
    const validImage = ["image/jpeg", "image/png"];
    if (validImage.includes(event.target.files[0].type)) {
      const _images = [...state.images];
      _images[index] = event.target.files[0];
      setState({ ...state, images: _images });

      if (image && image.id) {
        setDeletedImages([...deletedImages, image.id]);
      }
    } else alert("Gambar yang di upload tidak valid");
  };

  const handleChangeSkus = (code, key, value) => {
    setState((state) => {
      const barang = state.detail_barang.find((item) => item.code === code);
      barang[key] = value;
      return { ...state };
    });
  };

  const handleChangeSkusPlusVarian = (code, key, value) => {
    setState((state) => {
      const barang = state.plus_varian.find((item) => item.code === code);
      barang[key] = value;
      return { ...state };
    });
  };

  const handleRemoveSkus = (code) => {
    setState({
      ...state,
      detail_barang: state.detail_barang.filter((value) => value.code !== code),
      remove_varian: [...state.remove_varian, code],
    });
  };

  const toDataURL = (url) => {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  const handleCopySkus = async (code) => {
    let fromDetailBarang = true;
    let barang = state.detail_barang.find((value) => value.code === code);
    if (!barang) {
      barang = state.plus_varian.find((value) => value.code === code);
      fromDetailBarang = false;
    }

    const clonedBarang = JSON.parse(JSON.stringify(barang));
    clonedBarang.code = createRandomId(10);

    if (fromDetailBarang) {
      clonedBarang.image_b64 = await toDataURL(clonedBarang.image_b64);
    }

    setState({
      ...state,
      plus_varian: [...state.plus_varian, clonedBarang],
    });
  };

  const handleRemoveSkusPlusVarian = (code) => {
    setState({
      ...state,
      plus_varian: state.plus_varian.filter((value) => value.code !== code),
    });
  };

  const handleChangeSkusProperties = (code, key, value) => {
    setState((state) => {
      const barang = state.detail_barang.find((item) => item.code === code);
      barang.properties[key] = value;
      return { ...state };
    });
  };

  const handleChangeSkusPropertiesPlusVarian = (code, key, value) => {
    setState((state) => {
      const barang = state.plus_varian.find((item) => item.code === code);
      barang.properties[key] = value;
      return { ...state };
    });
  };

  const handleTambahVarian = () => {
    setState({
      ...state,
      plus_varian: [
        ...state.plus_varian,
        {
          code: createRandomId(10),
          price: { consumer: "" },
          properties: {
            color: "",
            size: "",
            material: "",
          },
          stock: "",
          weight_gram: "",
          image_b64: null,
        },
      ],
    });
  };

  const handleChangeCustomVariant = (e, i) => {
    const newVariant = [...customVariant];
    newVariant[i] = e.target.value;

    setCustomVariant(newVariant);
  };

  const handleAddCustomVariant = () => {
    setCustomVariant((prev) => [...prev, ""]);
  };

  const handleRemoveCustomVariant = (i) => {
    const newVariant = [...customVariant];
    const filtered = newVariant.filter((val, index) => index !== i);
    setCustomVariant(filtered);
  };

  const handleDeleteImages = (i) => {
    const _images = [...state.images];
    const filtered = _images.filter((val, index) => index !== i);
    setState({ ...state, images: filtered });
  };

  const renderSkus = (data) => {
    let dataSkus = [];
    data.forEach((res) => {
      if (
        new RegExp("http").test(res.image_b64) ||
        res.image_b64 === "" ||
        res.image_b64 === null
      ) {
        dataSkus = [
          ...dataSkus,
          {
            code: res.code,
            properties: {
              color: res.properties.color,
              size: res.properties.size,
              material: res.properties.material,
            },
            stock: res.stock,
            weight_gram: res.weight_gram,
            consumer_price_idr: res.price.consumer,
          },
        ];
      } else {
        dataSkus = [
          ...dataSkus,
          {
            code: res.code,
            properties: {
              color: res.properties.color,
              size: res.properties.size,
              material: res.properties.material,
            },
            stock: res.stock,
            weight_gram: res.weight_gram,
            consumer_price_idr: res.price.consumer,
            image_b64: res.image_b64.split(",")[1],
          },
        ];
      }
    });
    return dataSkus;
  };

  const handleSimpanProduk = async () => {
    const payload = {
      name: state.nama_produk,
      description: state.deskripsi,
      tags: state.tags.split(","),
      category_id: state.kategori.id,
      supplier_id: state.supplier.id,
      published: settings.publish,
      show_stock: settings.show_stock,
      commission_value: state.comission_value,
      commission_type: state.comission_type,
      images: state.images,
      // platform_fee_type: state.platform_fee_type,
      // platform_fee_value: state.platform_fee_value,
    };

    if (state.nama_produk.length === 0) {
      swal("Oops!", "Silahkan input Nama Produk.", "warning");
    } else if (state.deskripsi.length === 0) {
      swal("Oops!", "Silahkan input Deskripsi Produk.", "warning");
    } else if (state.kategori.length === 0) {
      swal("Oops!", "Silahkan input Kategori.", "warning");
    } else if (state.supplier.length === 0) {
      swal("Oops!", "Silahkan input Supplier.", "warning");
    } else if (state.comission_value.length === 0) {
      swal("Oops!", "Silahkan input Komisi Reseller.", "warning");
    } else if (
      state.comission_type === "PERCENT" &&
      state.comission_value > 100
    ) {
      swal("Oops!", "Komisi dengan type persen hanya sampai 100%", "warning");
    } else if (
      state.comission_type === "PERCENT" &&
      state.comission_value < 10
    ) {
      swal("Oops!", "Komisi minimal 10%", "warning");
    } else {
      setLoadingTambah(true);
      let helper = 0;

      if (isCustom) {
        if (!/(http|https)/.test(customProductImage)) {
          payload.image = customProductImage.split(",")[1];
        }
        payload.is_custom = isCustom;
        payload.properties_custom = customVariant;

        const res = await editProductCustom(dataApi.slug, payload);

        if (res.updated) {
          swal("Success", "Produk berhasil ditambahkan", "success")
            .then(() => setLoadingTambah(false))
            .then(() => onClose())
            .then(() => reFetch());
        }
      } else {
        const formData = new FormData();

        formData.append("_method", "PATCH");
        formData.append("name", payload.name);
        formData.append("description", payload.description);
        payload.tags.forEach((tag, i) => formData.append(`tags[${i}]`, tag));
        formData.append("category_id", payload.category_id);
        formData.append("supplier_id", payload.supplier_id);
        formData.append("published", payload.published ? 1 : 0);
        formData.append("show_stock", payload.show_stock ? 1 : 0);
        formData.append("commision_value", payload.commission_value);
        formData.append("commision_type", payload.commission_type);
        payload.images.forEach((image, i) => {
          if (image && !image.id) {
            formData.append(`new_images[${i}]`, image);
          }
        });
        deletedImages.forEach((image, i) => {
          formData.append(`delete_images[${i}]`, image);
        });

        const resEditProduct = await editProduct(dataApi.slug, formData, true);
        if (resEditProduct.updated) helper = helper + 1;
        for (let i = 0; i < state.detail_barang.length; i++) {
          const resEditSkus = await editSkus(
            dataApi.slug,
            state.detail_barang[i].code,
            renderSkus(state.detail_barang)[i]
          );
          if (resEditSkus.updated) helper = helper + 1;
        }
        state.remove_varian.forEach(async (code) => {
          await deleteSkus(dataApi.slug, code);
        });
        if (state.plus_varian.length !== 0) {
          for (let i = 0; i < state.plus_varian.length; i++) {
            const resTambahSkus = await addSkus(
              dataApi.slug,
              renderSkus(state.plus_varian)[i]
            );
            if (resTambahSkus.updated) helper = helper + 1;
          }
        }
        if (state.detail_barang.length + state.plus_varian.length + 1)
          swal("Success", "Produk berhasil ditambahkan", "success")
            .then(() => setLoadingTambah(false))
            .then(() => onClose());
      }
    }
  };

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.flexCenter}>
          <ArrowBackIcon
            style={{ paddingRight: 10, cursor: "pointer" }}
            onClick={() => onClose()}
          />
          <b>Edit Produk</b>
        </Typography>
      </div>

      <Grid container spacing={3} style={{ paddingTop: 30 }}>
        <Grid item xs={9}>
          <Paper elevation={2} style={{ padding: 30 }}>
            <h3>1. Informasi Dasar</h3>
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Nama Produk
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Masukan nama produk"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <div className={classes.inputAdornment}>
                        {state.nama_produk.length}/100
                      </div>
                    ),
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  value={state.nama_produk}
                  fullWidth={true}
                  className={classes.inputStyle}
                  onChange={(event) =>
                    handleSetState("nama_produk", event.target.value)
                  }
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Komisi reseller
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.inputStyle}
                  fullWidth={true}
                  value={state.comission_value}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: { min: 10 },
                    endAdornment: (
                      <div className={classes.inputAdornment}>%</div>
                    ),
                  }}
                  type="number"
                  onChange={(event) =>
                    handleSetState("comission_value", event.target.value)
                  }
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Kategori
              </Grid>
              <Grid item xs={4}>
                <TextField
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  className={`${classes.inputStyle} ${classes.inputSelectStyle}`}
                  fullWidth={true}
                  value={state.kategori.name}
                  disabled
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: <ArrowDropDownIcon />,
                  }}
                />
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  disablePortal={false}
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: "scrollParent",
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper>
                      <Typography className={classes.typography}>
                        <List>
                          {category.map((res, index) => (
                            <Fragment>
                              <ListItem
                                button
                                onClick={() =>
                                  openChilderenCat === res.name
                                    ? setOpenChilderenCat("")
                                    : setOpenChilderenCat(res.name)
                                }
                              >
                                <ListItemText primary={res.name} />
                                {openChilderenCat === res.name ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                              <Collapse
                                in={openChilderenCat === res.name}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {res.children.map((child, index) => (
                                    <ListItem
                                      button
                                      className={classes.nested}
                                      onClick={() => {
                                        handleSetState("kategori", child);
                                        setOpenChilderenCat(false);
                                        setAnchorEl(null);
                                      }}
                                    >
                                      <ListItemText primary={child.name} />
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            </Fragment>
                          ))}
                        </List>
                      </Typography>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Tags
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Masukan tag produk"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <div className={classes.inputAdornment}>
                        {state.tags.length}/40
                      </div>
                    ),
                  }}
                  inputProps={{
                    maxLength: 40,
                  }}
                  value={state.tags}
                  fullWidth={true}
                  className={classes.inputStyle}
                  onChange={(event) =>
                    handleSetState("tags", event.target.value)
                  }
                />
                <Typography variant="caption" component="div">
                  <i>Pisahkan antar tag dengan koma ","</i>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Deskripsi
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Masukan deskripsi"
                  value={state.deskripsi}
                  className={classes.inputStyle}
                  InputProps={{ disableUnderline: true }}
                  multiline
                  rows={10}
                  fullWidth
                  onChange={(event) =>
                    handleSetState("deskripsi", event.target.value)
                  }
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} className="label">
                Gambar
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  {state.images.map((image, i) => (
                    <Grid item key={i}>
                      <div
                        className={classes.tambahGambar}
                        style={{
                          backgroundImage: `url(${
                            image && (image.large || URL.createObjectURL(image))
                          })`,
                        }}
                      >
                        {image === null && (
                          <Fragment>
                            <Typography className={classes.tambahGambar1}>
                              +
                            </Typography>
                          </Fragment>
                        )}
                        <input
                          type="file"
                          className={classes.inputGambar}
                          onChange={(event) =>
                            handleChangeImage(event, i, image)
                          }
                        />
                      </div>
                      <Button
                        onClick={() => {
                          handleDeleteImages(i);
                          if (image && image.id) {
                            setDeletedImages([...deletedImages, image.id]);
                          }
                        }}
                        fullWidth
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{ marginTop: 8 }}
                      >
                        Hapus
                      </Button>
                    </Grid>
                  ))}
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        setState({
                          ...state,
                          images: [...state.images, null],
                        })
                      }
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {settings.diskon && (
              <div className={classes.flex} style={{ paddingTop: 20 }}>
                <div className={classes.flex} style={{ width: "50%" }}>
                  <div className={classes.textVar}>
                    <Typography style={{ width: 150 }}>
                      <b>Diskon</b>
                    </Typography>
                  </div>
                  <div className={classes.discount}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      fullWidth={true}
                      onChange={(event) =>
                        handleSetState("diskon", event.target.value)
                      }
                    />
                    <div className={classes.discountPercent}>%</div>
                  </div>
                </div>
              </div>
            )}
            <div style={{ paddingTop: 30 }}>
              <h3>2. Detail Varian</h3>
              {isCustom ? (
                <CardCustomProduct
                  data={customVariant}
                  image={customProductImage}
                  handleChangePhoto={handleChangePhoto}
                  handleChangeCustomVariant={handleChangeCustomVariant}
                  handleAddCustomVariant={handleAddCustomVariant}
                  handleRemoveCustomVariant={handleRemoveCustomVariant}
                />
              ) : (
                <>
                  {state.detail_barang
                    .concat(state.plus_varian)
                    .map((res, index) => (
                      <div style={{ paddingTop: 20 }} key={res.code}>
                        <CardDetailBarang
                          index={index}
                          data={res}
                          handleChangePhoto={handleChangePhoto}
                          handleChangeSkus={handleChangeSkus}
                          handleRemoveSkus={handleRemoveSkus}
                          isPlusVarian={index + 1 > state.detail_barang.length}
                          handleChangeSkusProperties={
                            handleChangeSkusProperties
                          }
                          handleChangePhotoPlusVarian={
                            handleChangePhotoPlusVarian
                          }
                          handleChangeSkusPlusVarian={
                            handleChangeSkusPlusVarian
                          }
                          handleRemoveSkusPlusVarian={
                            handleRemoveSkusPlusVarian
                          }
                          handleChangeSkusPropertiesPlusVarian={
                            handleChangeSkusPropertiesPlusVarian
                          }
                          handleCopySkus={handleCopySkus}
                        />
                      </div>
                    ))}
                </>
              )}
            </div>

            {!isCustom && (
              <>
                <div
                  className={classes.flexWidthCenter}
                  style={{ paddingTop: 30, marginBottom: 24 }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    style={{
                      borderRadius: 30,
                      padding: "10px 20px 10px 20px",
                      backgroundColor: "#f4f4f4",
                      color: "#29C0C7",
                      border: "none",
                      fontWeight: 600,
                      textTransform: "none",
                    }}
                    onClick={handleTambahVarian}
                    startIcon={<AddIcon />}
                  >
                    Tambah Varian
                  </Button>
                </div>
                <Grid container spacing={4} alignItems="center">
                  <Grid item>Custom variasi</Grid>
                  <Grid item xs>
                    <Button
                      variant="outlined"
                      size="large"
                      fullWidth
                      style={{
                        border: "2px dashed #29C0C7",
                        borderRadius: 5,
                        color: "#29C0C7",
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                      startIcon={<AddCircleIcon />}
                      onClick={() => setIsCustom(true)}
                    >
                      Aktifkan Custom Variasi
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper style={{ padding: 30 }}>
            <Typography style={{ color: "#c4c4c4" }}>
              Product Setting
            </Typography>

            <div className={classes.flexBetween}>
              <Typography>Varian</Typography>
              <Switch
                checked={settings.varian}
                onChange={() => handleSettings("varian", !settings.varian)}
                color="primary"
                name="varian"
                disabled
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <div className={classes.flexBetween}>
              <Typography>Diskon</Typography>
              <Switch
                checked={settings.diskon}
                onChange={() => handleSettings("diskon", !settings.diskon)}
                color="primary"
                name="diskon"
                disabled
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>

            <Typography style={{ color: "#c4c4c4" }}>
              Storefront Setting
            </Typography>
            <div className={classes.flexBetween}>
              <Typography>Publish</Typography>
              <Switch
                checked={settings.publish}
                onChange={() => handleSettings("publish", !settings.publish)}
                color="primary"
                name="publish"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <div className={classes.flexBetween}>
              <Typography>Show Stock</Typography>
              <Switch
                checked={settings.show_stock}
                onChange={() =>
                  handleSettings("show_stock", !settings.show_stock)
                }
                color="primary"
                name="show_stock"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>

            <div style={{ paddingTop: 20, textAlign: "center" }}>
              <Button
                variant="contained"
                style={{ textTransform: "capitalize" }}
                color="primary"
                onClick={handleSimpanProduk}
                fullWidth
              >
                {loadingTambah ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Simpan produk"
                )}
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default EditProduk;
