import { Button, Grid, TextField, Typography, FormControl} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import { addInvestorProduct, getSpesificInvestorProduct } from "services/dropship.service";
import swal from "sweetalert";
import styles from "./style.module.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  variant: {
    lineHeight: 3.6,
    fontSize: 14,
  }

}));

const EditInvestor = ({match}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setstate] = useState({
    product_id: '',
    investor_id: '',
    investor_fee: '',
    skus_sku_id: '',
    quantity: '',
    skus: {}
  })
  const [investorProduct, setInvestorProduct] = useState([])

  React.useEffect(() => {
    const getInvestorProductData = async() => {
      const response = await getSpesificInvestorProduct(match.params.slug)
      setInvestorProduct(response.data)
      setstate({
        ...state,
        investor_fee: response.data.investor_fee,
      })
    }
    getInvestorProductData()
  }, [])
  
  /* handle change */
  const handleQuantityChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value
    setstate({ ...state,
      skus: {
        ...state.skus,
        [name]: value
      }
    })
  }

  const handleInputChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setstate({ ...state, [name]: value });
  };
  /* end handle change */

  /* handle submit */
  const handleSubmit = async() => {
    const formData = new FormData();
    formData.append("investor_id", investorProduct.investor_id)
    formData.append("product_id", investorProduct.product_id)
    formData.append("investor_fee", state.investor_fee)
  
    investorProduct.skus.forEach((element, index )=> {
      formData.append(`skus[${index}][sku_id]`, investorProduct.skus[`${index}`].sku_id)
      formData.append(`skus[${index}][qty]`, state.skus[`skus[${index}]`] ?? investorProduct.skus[`${index}`].qty )
    });

    setIsLoading(true);
    await addInvestorProduct(formData)
    .then((result) => {
      swal("Success", `Edit Berhasil untuk investor ${result.data.investor.name} pada ${result.data.product.name}`, "success").then(() => {
        window.history.back();
        localStorage.removeItem('this_product')
      });
      setIsLoading(false);
    })
    .catch((error) => {
      swal("Error", error.message, "error");
      setIsLoading(false);
    });
  }
  /* end handle submit */

  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Edit Inverstor Product</b>
      </Typography>
      <br/>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit()
      }}>

      <Grid container spacing={3} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap' }}>
      <Grid item xs={12}>
      </Grid>
      
          <div className={styles.innerContainer}>
          <Grid item xs={12}>
              <TextField
                name="investor_name"
                label="Nama Investor"
                variant="outlined"
                readonly
                style={{ width: "100%" }}
                type="text"
                value={investorProduct?.investor?.name  ?? ""}
              />
            </Grid>
            <br/>

            <Grid item xs={12}>
              <TextField
                name="product_name"
                label="Nama Produk"
                variant="outlined"
                readonly
                style={{ width: "100%" }}
                type="text"
                value={investorProduct?.product?.name  ?? "Pilih Produk"}
              />
            </Grid>
            <br/>

            {investorProduct && investorProduct.skus && investorProduct.skus.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Varian</Typography>
                  <br/>
                  {investorProduct.skus && investorProduct.skus.map((sku, index) => {
                    return (
                      <Grid container spacing={2} key={`sku-${index}`}>
                      <Grid item xs={6}>
                      <Typography className={classes.variant}>
                          {sku.sku?.properties?.material},
                          {sku.sku?.properties?.color},
                          {sku.sku?.properties?.size}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <FormControl fullWidth>
                          <TextField
                            name={`skus[${index}]`}
                            value={state.skus[`skus[${index}]`] ?? sku.qty }
                            label="Quantity"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="number"
                            onChange={handleQuantityChange}
                        />
                      </FormControl>
                      </Grid>
                      </Grid>
                    )})}

                </Grid>
              )}
              <br/>
              {investorProduct && investorProduct.investor_fee && (
                  <Grid item xs={12}>
                    <TextField
                      name="investor_fee"
                      label="Investor Fee"
                      variant="outlined"
                      required
                      style={{ width: "100%" }}
                      type="number"
                      value={state.investor_fee}
                      onChange={handleInputChange}
                    />
                  </Grid>
              )}
            <br/>

            <br/><br/>
          <div style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default EditInvestor;
