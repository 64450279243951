const style = (theme) => ({
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  bgPercent: {
    height: 10,
    width: 125,
    backgroundColor: "#c4c4c4",
    borderRadius: 30,
  },
  percent: {
    height: 10,
    backgroundColor: theme.palette.green.main,
    borderRadius: 30,
  },
  filter: {
    borderRadius: 30,
    backgroundColor: theme.palette.white.tertiary,
    padding: `10px 30px`,
    margin: '0 10px',
    textTransform: "capitalize",
  },
  calendar: {
    borderRadius: 8,
    backgroundColor: theme.palette.white.tertiary,
    padding: `24px`,
  },
  filterApply: {
    marginTop: 24,
    borderRadius: 30,
    backgroundColor:theme.palette.red.main,
    color: '#FFF',
    padding: `10px 30px`,
    margin: '0 10px',
    textTransform: "capitalize",
    '&:hover': {
      backgroundColor:theme.palette.red.main,
      color: '#FFF',
    }
  },
});

export default style;
