import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Collapse } from "@material-ui/core";
import { ReactComponent as Notification } from "../../../../assets/icons/notification.svg";
import enterRounded from "../../../../assets/icons/enter-rounded.svg";
import failRounded from "../../../../assets/icons/fail-rounded.svg";
import successRounded from "../../../../assets/icons/success-rounded.svg";
import { getNotification } from "../../../../services/axios";

const ItemNotification = (data) => {
  console.log(data);
  if (data) {
    return (
      <div
        style={{
          display: "flex",
          padding: "10px 0",
        }}
      >
        <div style={{ lineHeight: 0 }}>
          <img
            src={
              new RegExp("dibatalkan").test(data.data.message.toLowerCase())
                ? failRounded
                : new RegExp("diteruskan").test(data.data.message.toLowerCase())
                ? successRounded
                : enterRounded
            }
            alt="icon notifikasi"
          />
        </div>
        <div style={{ paddingLeft: 20 }}>
          <Typography
            style={{ fontWeight: "bold", textTransform: "capitalize" }}
          >
            {data.data.type.replace("-", " ")}
          </Typography>
          <Typography variant="caption" style={{ color: "#8C8C8C" }}>
            {data.data.message}
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};

const Notifikasi = withStyles(style)((props) => {
  const { classes } = props;
  const [notifikasi, setNotifikasi] = useState([]);
  const [page, setPage] = useState(10);

  useEffect(() => {
    fetchNotification();
  }, [page]);

  const fetchNotification = async () => {
    const notification = await getNotification(page);
    setNotifikasi((prev) => prev.concat(notification.data));
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.header}>
          <Notification className={classes.iconNotification} />
          <Typography className={classes.headerText}>Notifikasi</Typography>
        </div>
        <div className={classes.content}>
          {(notifikasi || []).map(
            (res, index) =>
              res && <ItemNotification key={index} data={res.data} />
          )}

          <div style={{ textAlign: "center" }}>
            <Typography
              variant="caption"
              style={{ color: "#FDCA40", cursor: "pointer" }}
              onClick={() => setPage(page + 10)}
            >
              Tampilkan lebih banyak
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default Notifikasi;
