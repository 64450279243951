import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../style";
import {
  Typography,
  Button,
  Paper,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import { getAnnoucements, deleteAnnoucements } from "../../../services/axios";
import swal from "sweetalert";
import moment from "moment";

import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const Customer = withStyles(style)((props) => {
  const { classes, data, handleEditData } = props;
  const [state, setState] = useState({
    filteredData: data.data,
    openEditData: false,
    dataEdit: null,
    search: "",
  });

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  useEffect(() => {
    setState((state) => ({ ...state, filteredData: data.data }));
  }, [data]);

  const handleSearch = (keyword) => {
    handleChangeState("search", keyword);
  };

  const handlePressSearch = async (e) => {
    if (e.key === "Enter") {
      const fetch = async () => {
        const response = await getAnnoucements(
          `?filter[title]=${state.search}`
        );
        setState({
          ...state,
          filteredData: response.data,
        });
      };
      fetch();
    }
  };

  const handlePressSearchBtn = async () => {
    const fetch = async () => {
      const response = await getAnnoucements(`?filter[title]=${state.search}`);
      setState({
        ...state,
        filteredData: response.data,
      });
    };
    fetch();
  };

  const handleChangePage = (e, value) => {
    window.scrollTo({ top: 1800, behavior: "smooth" });
    const fetch = async () => {
      const response = await getAnnoucements(
        `?page=${value}&filter[title]=${state.search}`
      );
      setState({
        ...state,
        filteredData: response.data,
      });
    };
    fetch();
  };

  const handleResetFilter = () => {
    const fetch = async () => {
      const response = await getAnnoucements();
      setState({
        ...state,
        filteredData: response.data,
      });
    };
    fetch();
  };

  const handleDeleteProduct = (id) => {
    swal({
      title: "Are you sure?",
      text: "Data akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAnnoucements(id);
        swal("Data dihapus!", {
          icon: "success",
        }).then(() => {
          handleResetFilter();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      } else {
        swal("Data batal dihapus");
      }
    });
  };

  function createData(
    title,
    scope,
    message,
    tags,
    link,
    scheduled_time,
    kelola
  ) {
    return {
      title,
      scope,
      message,
      tags,
      link,
      scheduled_time,
      kelola,
    };
  }

  const Kelola = ({ data }) => {
    return (
      <div style={{ width: 100 }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton onClick={() => handleEditData(data)}>
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteProduct(data.id)}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
      </div>
    );
  };

  const rows = () => {
    let returnData = [];
    state.filteredData.forEach((res) => {
      returnData = [
        ...returnData,
        createData(
          res.title,
          res.scope,
          res.message,
          res.tags.join(", "),
          res.link,
          res.scheduled_time,
          <Kelola data={res} />
        ),
      ];
    });
    return returnData;
  };

  return (
    <Fragment>
      <Paper style={{ marginTop: 20 }}>
        <div style={{ display: "flex", padding: 20 }}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => handleSearch(e.target.value)}
              onKeyPress={handlePressSearch}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonCari}
            onClick={handlePressSearchBtn}
          >
            Cari
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No.</StyledTableCell>
                <StyledTableCell align="center">Title</StyledTableCell>
                <StyledTableCell align="center">Scope</StyledTableCell>
                <StyledTableCell align="center">Message</StyledTableCell>
                <StyledTableCell align="center">Tags</StyledTableCell>
                <StyledTableCell align="center">link</StyledTableCell>
                <StyledTableCell align="center">Jadwal</StyledTableCell>
                <StyledTableCell align="center">Kelola</StyledTableCell>
              </TableRow>
            </TableHead>

            {state.filteredData.length !== 0 && (
              <Fragment>
                <TableBody>
                  {rows()
                    .sort(function (a, b) {
                      return a.created_at > b.created_at
                        ? 1
                        : b.created_at > a.created_at
                        ? -1
                        : 0;
                    })
                    .map((row, index) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {index + 1 + "."}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.title}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.scope}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.message}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.tags}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.link}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.scheduled_time
                            ? moment(row.scheduled_time).format(
                                "DD MMM YYYY HH:mm:ss"
                              )
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.kelola}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Fragment>
            )}
          </Table>
        </TableContainer>

        {state.filteredData.length === 0 && (
          <div className={classes.notFound}>
            <div className={classes.notFoundChild}>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                Oops!
              </Typography>
              <Typography>Data tidak ditemukan.</Typography>
              <Button
                variant="contained"
                color="secondary"
                className={classes.notFoundButton}
                onClick={handleResetFilter}
              >
                Reset Filter
              </Button>
            </div>
          </div>
        )}

        {state.filteredData.length !== 0 && (
          <div className={classes.Pagination}>
            <Pagination
              count={data.meta.last_page}
              variant="outlined"
              onChange={handleChangePage}
              color="secondary"
            />
          </div>
        )}
      </Paper>
    </Fragment>
  );
});

export default Customer;
