import IconChecklist from '../../../../assets/icons/Checklist.png'

const warna = {
  grayDark: '#E6E6E6',
  orange: '#FECF1A'
}

const style = (theme) => ({
  // old card
  // flexAlignCenter: {
  //   display: "flex",
  //   alignItems: "center",
  // },
  // wrapperCard: {
  //   border: `2px solid ${theme.palette.green.main}`,
  //   borderRadius: 15,
  //   padding: "20px 15px",
  // },
  // flexEnd: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  // },
  // ubahPesanan: {
  //   backgroundColor: theme.palette.green.main,
  //   textTransform: "capitalize",
  //   // cursor: "no-drop",
  //   color: "#fff",
  //   "&:hover": {
  //     backgroundColor: "#35bd82",
  //   },
  // },
  // EditIcon: {
  //   height: 15,
  //   width: "auto",
  // },
  // flexBetween: {
  //   display: "flex",
  //   // alignItems: "center",
  //   justifyContent: "space-between",
  // },
  // Divider: {
  //   backgroundColor: theme.palette.green.main,
  // },
  // roundStepper: {
  //   backgroundColor: theme.palette.green.main,
  //   width: 25,
  //   height: 25,
  //   borderRadius: "50%",
  //   marginLeft: -5,
  //   zIndex: 2,
  //   [theme.breakpoints.down("md")]: {
  //     width: 8,
  //     height: 8,
  //   },
  // },
  // stepperWrapper: {
  //   display: "flex",
  //   alignItems: "center",
  // },
  // stepper: {
  //   height: 10,
  //   width: 92,
  //   backgroundColor: theme.palette.green.main,
  //   marginLeft: -5,
  //   zIndex: 1,
  //   [theme.breakpoints.down("md")]: {
  //     width: 72,
  //     height: 2,
  //   },
  // },
  // stepperText: {
  //   width: 105,
  //   [theme.breakpoints.down("md")]: {
  //     width: 72,
  //   },
  // },
  // statusPembayaran: {
  //   border: `1px solid ${theme.palette.green.main}`,
  //   color: theme.palette.green.main,
  //   borderRadius: 30,
  //   width: 125,
  //   marginBottom: 10,
  // },
  // downloadLabel: {
  //   border: `1px solid ${theme.palette.green.main}`,
  //   color: theme.palette.green.main,
  // },

  // new card
  // container: {
  //   border: `2px solid ${warna.grayDark}`,
  //   padding: 10,
  //   borderRadius: 6,
  // },
  // flexBetween: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'center'
  // },
  // flexCenter: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center'
  // },
  roundStepper: {
    backgroundImage: `url(${IconChecklist})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 15,
    height: 15,
    borderRadius: "50%",
    marginLeft: -4,
    zIndex: 2
  },
  stepperWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
    marginTop: 4,
  },
  stepper: {
    height: 120,
    border: '.1px dashed #FECF1A',
    marginLeft: -4,
    zIndex: 1
  },

  // newer card
  box: {
    position: 'relative',
    width: '100%',
    border: 'dashed 1px black',
    borderRadius: '5px -5px 5px 5px',
		display: 'flex',
		justifyContent: 'center',
		alignitems: 'center',
    marginTop: 50,
    background: 'white',
  },
  ne: {
    right: '-2px',
    top: '-2px',
    borderRadius: '0px 0px 0px 20px',
    borderBottom: '1px dashed #000',
    borderLeft: '1px dashed #000',
    background: '#fff',
    height:20,
    width:20,    
    position:'absolute',
  },
  nw: {
    left: '-2px',
    top: '-2px',
    borderRadius: '0px 0px 20px 0px',
    borderBottom: '1px dashed #000',
    borderRight: '1px dashed #000',
    background: '#fff',
    height:20,
    width:20,    
    position:'absolute',
  },
  se: {
    right: '-2px',
    bottom: '-2px',
    borderRadius: '20px 0px 0px 0px',
    background: '#fff',
    height:20,
    width:20,    
    position:'absolute',
  },
  sw: {
    left: '-2px',
    bottom: '-2px',
    borderRadius: '0px 20px 0px 0px',
    background: '#fff',
    height:20,
    width:20,    
    position:'absolute',
  },
  headerBox: {
    width: '100%',
    minHeight: 50,
    position: 'absolute',
    top: -50,
    height: 50,
    display: 'flex'
  },
  headerBoxMobile: {
    background: '#FDCA40',
    width: '100%',
    minHeight: 50,
    position: 'absolute',
    top: -124,
    height: 124,
    display: 'flex'
  },
  headerBoxMobileMini: {
    background: '#FDCA40',
    width: '100%',
    minHeight: 50,
    position: 'absolute',
    top: -164,
    height: 164,
    display: 'flex'
  }

});

export default style;
