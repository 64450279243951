import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Switch,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import swal from "sweetalert";
import {
  addSupplier,
  getProvinces,
  getCitiesByProvinceId,
  getDistrictsByCityId,
  getSubDistrictsByDisctrictId,
  syncCourierSupplier,
} from "../../../services/axios";
import Loading from "../../../components/loading";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const TambahSupplier = withStyles(style)((props) => {
  const { classes, handleBack, couriers } = props;
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    activated: 0,
    is_dropship: 0,
    is_custom_label: 0,
    hide_label: 0,
    maklon_moq: 0,
    address: "",
    postal_code: "",
    phone: "",
    avatar: null,
    description: "",
    platform_fee_value: "",
    platform_fee_type: "PERCENT",
    couriers: [],
    province_id: null,
    city_id: null,
    district_id: null,
    village_id: null,
    is_cod: false,
    shipment_type: "DROP",
    affiliate_code: "",
    marketer_commision: 0,
  });
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDisctricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);

  const [selectedGambar, setSelectedGambar] = useState(null);

  const handleChangeData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  useEffect(() => {
    handleGetProvinces();
  }, []);

  const handleGetProvinces = async () => {
    const response = await getProvinces();

    if (response) {
      setProvinces(response.data);
    }
  };

  const handleGetCities = async (id) => {
    const response = await getCitiesByProvinceId(id);

    if (response) {
      setCities(response.data);
    }
  };

  const handleGetDistricts = async (id) => {
    const response = await getDistrictsByCityId(id);

    if (response) {
      setDisctricts(response.data);
    }
  };

  const handleGetSubDistricts = async (id) => {
    const response = await getSubDistrictsByDisctrictId(id);

    if (response) {
      setSubDistricts(response.data);
    }
  };

  const handleChangeProvince = (id) => {
    setData({
      ...data,
      province_id: id,
      city_id: null,
      district_id: null,
      village_id: null,
      postal_code: null,
    });
    setDisctricts([]);
    setSubDistricts([]);
    handleGetCities(id);
  };

  const handleChangeCity = (id) => {
    setData({
      ...data,
      city_id: id,
      district_id: null,
      village_id: null,
      postal_code: null,
    });
    setSubDistricts([]);
    handleGetDistricts(id);
  };

  const handleChangeDistrict = (id) => {
    setData({
      ...data,
      district_id: id,
      village_id: null,
      postal_code: null,
    });
    handleGetSubDistricts(id);
  };

  const handleChangeSubDistrict = (id) => {
    const value = id;

    const matchedSubDistrict = subDistricts.find((sub) => sub.id === value);

    setData({
      ...data,
      village_id: value,
      postal_code: matchedSubDistrict ? matchedSubDistrict.kodepos : null,
    });
  };

  const handleChangePhoto = async (event) => {
    console.log(event.target.files);
    if (event.target.files[0].type === "image/jpeg") {
      setData({ ...data, avatar: event.target.files[0] });
      setSelectedGambar(URL.createObjectURL(event.target.files[0]));
    } else swal("Oops!", "Gunakan File dengan exstensi JPG/JPEG", "warning");
  };

  const handleChangeCourier = (code) => {
    if (data.couriers.filter((value) => value === code)[0] === code) {
      setData({
        ...data,
        couriers: data.couriers.filter((value) => value !== code),
      });
    } else {
      setData({ ...data, couriers: [...data.couriers, code] });
    }
  };

  const handleSimpanData = async () => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("activated", data.activated);
    formData.append("is_dropship", data.is_dropship);
    formData.append("is_custom_label", data.is_custom_label);
    formData.append("hide_label", data.hide_label);
    formData.append("maklon_moq", data.maklon_moq);
    formData.append("address", data.address);
    formData.append("postal_code", data.postal_code);
    formData.append("phone", data.phone);
    formData.append("avatar", data.avatar);
    data.platform_fee_value.length !== 0 &&
      formData.append("platform_fee_value", data.platform_fee_value);
    data.platform_fee_value.length !== 0 &&
      formData.append("platform_fee_type", data.platform_fee_type);
    formData.append("village_id", data.village_id);
    formData.append("is_cod", data.is_cod ? 1 : 0);
    formData.append("shipment_type", data.shipment_type);
    data.affiliate_code &&
      formData.append("affiliate_code", data.affiliate_code);
    data.marketer_commision &&
      formData.append("marketer_commision", data.marketer_commision);

    if (data.name.length === 0) {
      swal("Oops!", "Nama Lengkap harus dilengkapi.", "warning");
    } else if (data.email.length === 0) {
      swal("Oops!", "Email harus dilengkapi.", "warning");
    } else if (!new RegExp("@").test(data.email)) {
      swal("Oops!", "Alamat email harus menyertakan karakter @ .", "warning");
    } else if (data.password.length === 0) {
      swal("Oops!", "Password harus dilengkapi.", "warning");
    } else if (data.password.length < 6) {
      swal("Oops!", "Panjang password harus minimal 6 karakter", "warning");
    } else if (data.avatar === null) {
      swal("Oops!", "Unggah foto profile", "warning");
    } else if (!data.village_id) {
      swal("Oops!", "Harap Input kelurahan", "warning");
    } else if (data.affiliate_code && !data.marketer_commision) {
      swal("Oops!", "Harap Input Komisi Marketer", "warning");
    // } else if (data.is_custom_label === 1 && !data.maklon_moq) {
    //   swal("Oops!", "Harap Input Maklon MOQ", "warning");
    } else {
      const response = await addSupplier(formData);
      if (response.data) {
        if (data.couriers.length !== 0) {
          syncCourierSupplier(response.data.slug, { couriers: data.couriers });
        }
        swal("Success", "Supplier berhasil ditambahkan.", "success").then(() =>
          handleBack()
        );
      } else {
        swal("Oops!", "Supplier gagal ditambahkan.", "success");
      }
    }
  };

  return (
    <Fragment>
      {cities === null ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={classes.header}>
            <Typography variant="h6" className={classes.flexCenter}>
              <ArrowBackIcon
                style={{ paddingRight: 10, cursor: "pointer" }}
                onClick={handleBack}
              />
              <b>Tambah Supplier</b>
            </Typography>
          </div>

          <Grid container spacing={3} style={{ paddingTop: 30 }}>
            <Grid item xs={12}>
              <Paper style={{ padding: 20 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className={classes.flexFullCenter}>
                      <div
                        className={classes.tambahGambar}
                        style={{
                          backgroundImage: `url(${selectedGambar})`,
                          border: data.avatar && "none",
                        }}
                      >
                        {data.avatar === null && (
                          <Fragment>
                            <Typography className={classes.tambahGambar1}>
                              +
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.tambahGambar2}
                            >
                              Tambah Gambar
                            </Typography>
                          </Fragment>
                        )}
                        <input
                          type="file"
                          className={classes.inputGambar}
                          onChange={(event) =>
                            handleChangePhoto(event, data.id)
                          }
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Nama Lengkap
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "70%" }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("name", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Email
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "70%" }}
                        required
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("email", e.target.value)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Password
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "70%" }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("password", e.target.value)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Kode Pos
                      </Typography>
                      <TextField
                        value={data.postal_code}
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "70%" }}
                        className={classes.inputStyle}
                        type="number"
                        onChange={(e) =>
                          handleChangeData("postal_code", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        No. Handphone
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "70%" }}
                        className={classes.inputStyle}
                        type="number"
                        onChange={(e) =>
                          handleChangeData("phone", e.target.value)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Provinsi
                      </Typography>
                      <FormControl
                        className={classes.inputStyle}
                        style={{ width: "70%" }}
                      >
                        <Select
                          fullWidth
                          value={data.province_id}
                          onChange={(e) => handleChangeProvince(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem disabled value={null}>
                            --Pilih provinsi--
                          </MenuItem>
                          {provinces.map((province) => (
                            <MenuItem key={province.id} value={province.id}>
                              {province.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Aktifkan Akun
                      </Typography>
                      <Switch
                        checked={data.activated === 1}
                        onChange={() =>
                          setData({
                            ...data,
                            activated: data.activated === 0 ? 1 : 0,
                          })
                        }
                        color="secondary"
                        name="activated"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Menerima Dropship?
                      </Typography>
                      <Switch
                        checked={data.is_dropship === 1}
                        onChange={() =>
                          setData({
                            ...data,
                            is_dropship: data.is_dropship === 0 ? 1 : 0,
                          })
                        }
                        color="secondary"
                        name="is_dropship"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Grid>
    
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Kota / Kabupaten
                      </Typography>
                      <FormControl
                        className={classes.inputStyle}
                        style={{ width: "70%" }}
                      >
                        <Select
                          fullWidth
                          value={data.city_id}
                          onChange={(e) => handleChangeCity(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem disabled value={null}>
                            --Pilih kota / kabupaten--
                          </MenuItem>
                          {cities.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Alamat Lengkap
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        multiline
                        style={{ width: "70%" }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("address", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Kecamatan
                      </Typography>
                      <FormControl
                        className={classes.inputStyle}
                        style={{ width: "70%" }}
                      >
                        <Select
                          fullWidth
                          value={data.district_id}
                          onChange={(e) => handleChangeDistrict(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem disabled value={null}>
                            --Pilih kecamatan--
                          </MenuItem>
                          {districts.map((district) => (
                            <MenuItem key={district.id} value={district.id}>
                              {district.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "45%" }}>
                        Komisi Idejualan
                      </Typography>
                      <div className={classes.komisi}>
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                          }}
                          inputProps={{ min: "0", max: "10", step: "1" }}
                          fullWidth={true}
                          value={data.platform_fee_value}
                          type="number"
                          onChange={(event) =>
                            handleChangeData(
                              "platform_fee_value",
                              Number(event.target.value)
                            )
                          }
                        />
                        <div
                          className={classes.textKomisi}
                          onClick={() =>
                            handleChangeData(
                              "platform_fee_type",
                              data.platform_fee_type === "PERCENT"
                                ? "FIXED"
                                : "PERCENT"
                            )
                          }
                        >
                          {data.platform_fee_type === "PERCENT" ? "%" : "Rp"}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Kelurahan
                      </Typography>
                      <FormControl
                        className={classes.inputStyle}
                        style={{ width: "70%" }}
                      >
                        <Select
                          fullWidth
                          value={data.village_id}
                          onChange={(e) =>
                            handleChangeSubDistrict(e.target.value)
                          }
                          displayEmpty
                        >
                          <MenuItem disabled value={null}>
                            --Pilih kelurahan--
                          </MenuItem>
                          {subDistricts.map((subDistrict) => (
                            <MenuItem
                              key={subDistrict.id}
                              value={subDistrict.id}
                            >
                              {subDistrict.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Kode referral
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "70%" }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("affiliate_code", e.target.value)
                        }
                      />
                    </div>
                  </Grid>

                  {data.affiliate_code && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "30%" }}>
                          Komisi Marketer
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          style={{ width: "70%" }}
                          className={classes.inputStyle}
                          type="number"
                          onChange={(e) =>
                            handleChangeData(
                              "marketer_commision",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </Grid>
                  )}

                  <Grid item md={3} sm={6} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Hide Label
                      </Typography>
                      <Switch
                        checked={data.hide_label === 1}
                        onChange={() =>
                          setData({
                            ...data,
                            hide_label: data.hide_label === 0 ? 1 : 0,
                          })
                        }
                        color="secondary"
                        name="hide_label"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Pakai Custom Label
                      </Typography>
                      <Switch
                        checked={data.is_custom_label === 1}
                        onChange={() =>
                          setData({
                            ...data,
                            is_custom_label: data.is_custom_label === 0 ? 1 : 0,
                          })
                        }
                        color="secondary"
                        name="is_custom_label"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Grid>
                  {data.is_custom_label === 1 && ( 
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "30%" }}>
                        Maklon MOQ
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "70%" }}
                        className={classes.inputStyle}
                        type="number"
                        onChange={(e) =>
                          handleChangeData("maklon_moq", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  )}

                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2" style={{ width: "15%" }}>
                        Kurir
                      </Typography>
                      <div style={{ width: "85%" }}>
                        {couriers.map((res) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  data.couriers.filter(
                                    (value) => value === res.code
                                  )[0] === res.code
                                }
                                onChange={() => handleChangeCourier(res.code)}
                                name={res.name}
                                color="primary"
                              />
                            }
                            label={res.name}
                          />
                        ))}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2" style={{ width: "15%" }}>
                        Tipe Pengiriman
                      </Typography>
                      <div style={{ width: "85%" }}>
                        <RadioGroup
                          row
                          value={data.shipment_type}
                          onChange={(e) =>
                            setData({
                              ...data,
                              shipment_type: e.target.value,
                            })
                          }
                        >
                          <FormControlLabel
                            value="DROP"
                            control={<Radio />}
                            label="Drop"
                          />
                          <FormControlLabel
                            value="PICKUP"
                            control={<Radio />}
                            label="Pickup"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2" style={{ width: "15%" }}>
                        Menerima COD?
                      </Typography>
                      <div style={{ width: "85%" }}>
                        <Switch
                          checked={data.is_cod === true}
                          onChange={() =>
                            setData({
                              ...data,
                              is_cod: data.is_cod === false ? true : false,
                            })
                          }
                          color="secondary"
                          name="is_cod"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2" style={{ width: "14%" }}>
                        Deskripsi
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        multiline
                        rows={10}
                        style={{ width: "84%", borderRadius: 15 }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("description", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                </Grid>

                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.buttonGreen}
                    style={{ marginTop: 30, width: 200 }}
                    onClick={handleSimpanData}
                  >
                    Simpan
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
});

export default TambahSupplier;
