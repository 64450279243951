import styled from 'styled-components'

export const SearchCustomWrap = styled.div`
  position: relative;
  .MuiOutlinedInput-root {
    border-radius: 5px;
  }
  .search{
    width: 24px;
    cursor: pointer;
  }
  input {
    padding: 12px 16px;
    font-size: 14px;
    font-family: 'DMSans-Regular'
  }
`