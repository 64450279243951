import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Grid from "@material-ui/core/Grid";
import DetailUser from "./components/detail-user";
import Notification from "./components/notifikasi";
import SummaryPenjualan from "./components/summary-penjualan";
import Statistik from "./components/statistik";
import Grafik from "./components/grafik";

const Home = withStyles(style)((props) => {
  const { classes } = props;

  const jwt = require("jsonwebtoken");

  const METABASE_SITE_URL = "https://metabase.idejualan.com";
  const METABASE_SECRET_KEY = "e7bdca3d0944133dbc3784b9c219db735b1a14423c78633ebeba054ea8868632";

  const payload = {
    resource: { dashboard: 4 },
    params: {},
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };
  const token = jwt.sign(payload, METABASE_SECRET_KEY);

  const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

  return (
    <Fragment>
      <Grid container className={classes.container}>
        <Grid item md={8} sm={12} style={{ paddingRight: 20 }}>
          <div>
            <DetailUser />
            {/* <SummaryPenjualan /> */}

            <br/>

            <div 
              style={{
                width: '100%',
                height: '2000px'
              }}
            >
              <iframe
                title='DashboardAdminSummary'
                src={iframeUrl}
                width='100%'
                height='100%'
                frameborder="0"
                allowtransparency
                allowfullscreen
              />
            </div>
            {/* <Grid container spacing={2}>
              <Grid item md={7} sm={12} xs={12}>
                <Statistik />
              </Grid>
              <Grid item md={5} sm={12} xs={12}>
                <Grafik />
              </Grid>
            </Grid> */}
          </div>
        </Grid>
        <Grid item md={4} sm={12}>
          <Notification />
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default Home;
