import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Switch,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import swal from "sweetalert";
import {
  getAllCity,
  addReseller,
  getSubdistrict,
  addCustomer,
} from "../../../services/axios";
import Loading from "../../../components/loading";

const TambahData = withStyles(style)((props) => {
  const { classes, handleBack } = props;
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    store_name: "",
    activated: 0,
    address: "",
    postal_code: "",
    phone: "",
    city_id: "",
    avatar: null,
    subdistrict_id: "",
    role: "Reseller",
  });
  const [cities, setCities] = useState(null);
  const [subdistrict, setSubdistrict] = useState(null);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedGambar, setSelectedGambar] = useState(null);

  const handleChangeData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  useEffect(() => {
    const fetch = async () => {
      const resCity = await getAllCity();
      setCities(resCity.data);
      setSelectedCity(resCity.data[0].name);
      const resSubdistrict = await getSubdistrict(resCity.data[0].id);
      setSubdistrict(resSubdistrict.data);
      setSelectedSubdistrict(resSubdistrict.data[0].name);
    };
    fetch();
  }, []);

  const handleChangePhoto = async (event, id) => {
    console.log(event.target.files);
    if (event.target.files[0].type === "image/jpeg") {
      setData({ ...data, avatar: event.target.files[0] });
      setSelectedGambar(URL.createObjectURL(event.target.files[0]));
    } else swal("Oops!", "Gunakan File dengan exstensi JPG/JPEG", "warning");
  };

  const handleChangeCity = async (res) => {
    handleChangeData("city_id", res.id);
    setSelectedCity(res.name);
    const resSubdistrict = await getSubdistrict(res.id);
    setSubdistrict(resSubdistrict.data);
    setSelectedSubdistrict(resSubdistrict.data[0].name);
  };

  const handleSimpanData = () => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("store_name", data.store_name);
    formData.append("activated", data.activated);
    formData.append("address", data.address);
    formData.append("postal_code", data.postal_code);
    formData.append("phone", data.phone);
    formData.append("city_id", data.city_id);
    formData.append("avatar", data.avatar);
    const formDataCust = new FormData();
    formDataCust.append("name", data.name);
    formDataCust.append("phone", data.phone);
    formDataCust.append("subdistrict_id", data.subdistrict_id);
    formDataCust.append("address", data.address);
    formDataCust.append("postal_code", data.postal_code);
    if (data.name.length === 0) {
      swal("Oops!", "Nama Lengkap harus dilengkapi.", "warning");
    } else if (data.email.length === 0 && data.role === "Reseller") {
      swal("Oops!", "Email harus dilengkapi.", "warning");
    } else if (!new RegExp("@").test(data.email) && data.role === "Reseller") {
      swal("Oops!", "Alamat email harus menyertakan karakter @ .", "warning");
    } else if (data.password.length === 0 && data.role === "Reseller") {
      swal("Oops!", "Password harus dilengkapi.", "warning");
    } else if (data.password.length < 5 && data.role === "Reseller") {
      swal("Oops!", "Panjang password harus lebih dari 5 karakter", "warning");
    } else if (data.avatar === null && data.role === "Reseller") {
      swal("Oops!", "Unggah foto profile", "warning");
    } else {
      data.role === "Reseller"
        ? addReseller(formData)
        : addCustomer(formDataCust);
      handleBack();
    }
  };

  return (
    <Fragment>
      {cities === null ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={classes.header}>
            <Typography variant="h6" className={classes.flexCenter}>
              <ArrowBackIcon
                style={{ paddingRight: 10, cursor: "pointer" }}
                onClick={handleBack}
              />
              <b>Tambah Data</b>
            </Typography>
          </div>

          <Grid container spacing={3} style={{ paddingTop: 30 }}>
            <Grid item md={9} sm={12} xs={12}>
              <Paper style={{ padding: 20 }}>
                <Grid container spacing={3}>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        Kategori Customer
                      </Typography>
                      <FormControl
                        className={classes.inputStyle}
                        style={{ width: "50%" }}
                      >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={data.role}
                          onChange={(e) =>
                            handleChangeData("role", e.target.value)
                          }
                          disableUnderline
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="Reseller">Reseller</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}></Grid>

                  {data.role === "Reseller" && (
                    <Grid item xs={12}>
                      <div className={classes.flexFullCenter}>
                        <div
                          className={classes.tambahGambar}
                          style={{
                            backgroundImage: `url(${selectedGambar})`,
                            border: data.avatar && "none",
                          }}
                        >
                          {data.avatar === null && (
                            <Fragment>
                              <Typography className={classes.tambahGambar1}>
                                +
                              </Typography>
                              <Typography
                                variant="caption"
                                className={classes.tambahGambar2}
                              >
                                Tambah Gambar
                              </Typography>
                            </Fragment>
                          )}
                          <input
                            type="file"
                            className={classes.inputGambar}
                            onChange={(event) =>
                              handleChangePhoto(event, data.id)
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    md={data.role === "Reseller" ? 6 : 12}
                    sm={12}
                    xs={12}
                  >
                    <div className={classes.flexCenter}>
                      <Typography
                        variant="body2"
                        style={{
                          width: data.role === "Reseller" ? "50%" : "23%",
                        }}
                      >
                        Nama Lengkap
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{
                          width: data.role === "Reseller" ? "50%" : "77%",
                        }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("name", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  {data.role === "Reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Email
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          style={{ width: "50%" }}
                          required
                          className={classes.inputStyle}
                          onChange={(e) =>
                            handleChangeData("email", e.target.value)
                          }
                        />
                      </div>
                    </Grid>
                  )}

                  {data.role === "Reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Password
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          style={{ width: "50%" }}
                          className={classes.inputStyle}
                          onChange={(e) =>
                            handleChangeData("password", e.target.value)
                          }
                        />
                      </div>
                    </Grid>
                  )}
                  {data.role === "Reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Store
                        </Typography>
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          style={{ width: "50%" }}
                          className={classes.inputStyle}
                          onChange={(e) =>
                            handleChangeData("store_name", e.target.value)
                          }
                        />
                      </div>
                    </Grid>
                  )}

                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        Kode Pos
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "50%" }}
                        className={classes.inputStyle}
                        type="number"
                        onChange={(e) =>
                          handleChangeData("postal_code", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        No. Handphone
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ width: "50%" }}
                        className={classes.inputStyle}
                        type="number"
                        onChange={(e) =>
                          handleChangeData("phone", e.target.value)
                        }
                      />
                    </div>
                  </Grid>

                  {data.role === "Reseller" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Aktifkan Akun
                        </Typography>
                        <Switch
                          checked={data.activated === 1}
                          onChange={() =>
                            setData({
                              ...data,
                              activated: data.activated === 0 ? 1 : 0,
                            })
                          }
                          color="secondary"
                          name="activated"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item md={6} sm={12} xs={12}>
                    <div className={classes.flexCenter}>
                      <Typography variant="body2" style={{ width: "50%" }}>
                        Kabupaten/Kota
                      </Typography>
                      <FormControl className={classes.inputStyle}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={selectedCity}
                          disableUnderline
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {cities &&
                            cities.map((res, index) => (
                              <MenuItem
                                key={index}
                                value={res.name}
                                onClick={() => handleChangeCity(res)}
                              >
                                {(res.type === "Kabupaten"
                                  ? res.type.slice(0, 3) + ". "
                                  : res.type + " ") + res.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  {data.role === "Customer" && (
                    <Grid item md={6} sm={12} xs={12}>
                      <div className={classes.flexCenter}>
                        <Typography variant="body2" style={{ width: "50%" }}>
                          Kecamatan
                        </Typography>
                        <FormControl
                          className={classes.inputStyle}
                          style={{ width: "50%" }}
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedSubdistrict}
                            disableUnderline
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {subdistrict &&
                              subdistrict.map((res, index) => (
                                <MenuItem
                                  key={index}
                                  value={res.name}
                                  onClick={() => {
                                    handleChangeData("subdistrict_id", res.id);
                                    setSelectedSubdistrict(res.name);
                                  }}
                                >
                                  {res.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  )}

                  <Grid item md={6} sm={12} xs={12}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2" style={{ width: "24%" }}>
                        Alamat Lengkap
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        multiline
                        rows={10}
                        style={{ width: "76%", borderRadius: 15 }}
                        className={classes.inputStyle}
                        onChange={(e) =>
                          handleChangeData("address", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3} sm={12} xs={12}>
              <Paper style={{ padding: 20 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Kategori Customer
                </Typography>
                <div style={{ marginTop: 20 }}>
                  <Typography>Customer</Typography>
                  <Typography variant="caption" style={{ color: "#a2a2a2" }}>
                    Customer toko yang mendapatkan harga normal.
                  </Typography>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography>Reseller</Typography>
                  <Typography variant="caption" style={{ color: "#a2a2a2" }}>
                    Customer yang mendapatkan potongan harga.
                  </Typography>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography>Dropshipper</Typography>
                  <Typography variant="caption" style={{ color: "#a2a2a2" }}>
                    Customer mendapatkan harga normal, yang disertai alamat
                    pengiriman pada resi melekat pada customer dropship
                    tersebut.
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.buttonGreen}
                  style={{ marginTop: 20 }}
                  onClick={handleSimpanData}
                >
                  Simpan Data
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
});

export default TambahData;
