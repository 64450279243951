import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { deleteInvestorProduct, getAllInvestorProduct } from "services/dropship.service";

const headCells = [
  { id: "no", label: "No." },
  { id: "investor", label: "Investor" },
  { id: "product", label: "Nama Produk" },
  { id: 'fee', label: 'Investor Fee'},
  { id: 'qty', label: 'Quantity'},
  { id: 'action', label: 'Action'}
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cellAction: {
    width: "10%",
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { search } = props;
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const getData = useCallback(
    _.debounce(async({ rowsPerPage, page, search, getData }) => {
      const response = await getAllInvestorProduct(
        {
          per_page: rowsPerPage,
          page: page + 1,
        }
      )
      setRows(response.data);
      setMeta(response.meta);
    }, 500),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [search]);

  React.useEffect(() => {
    getData({ rowsPerPage, page, search, getData });
  }, [rowsPerPage, page, search, getData]);

  const reFetch = () => {
    getData({ rowsPerPage, page, search, getData });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (slug) => {
    swal({
      title: "Apakah kamu yakin?",
      text: "Data Investor Produk akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // fetch api delete
        deleteInvestorProduct(slug)
        swal("Data Investor Produk telah dihapus!", {
          icon: "success",
        }).then(() => {
          reFetch();
        });
      } else {
        swal("Data Investor Produk batal dihapus");
      }
    });
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {rows.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.investor.name}</TableCell>
                <TableCell>{row.product.name}</TableCell>
                <TableCell>{row.investor_fee}</TableCell>
                <TableCell>{row.qty}</TableCell>
                <TableCell className={classes.cellAction}>
                  <Link to={"/investor-products/edit/" + row.id}>
                    <IconButton aria-label="edit" size="small">
                      <Edit />
                    </IconButton>
                  </Link>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(row.id)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
