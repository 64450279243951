import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Paper, Grid, TextField, Button } from "@material-ui/core";

const Info = withStyles(style)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Typography variant="h6">
        <b>Edit Info</b>
      </Typography>
      <Paper style={{ padding: 20, marginTop: 30 }}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography>
                <b>Logo</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <div className={classes.logo}>
                <div className={classes.buttonLogo}>
                  <Typography className={classes.buttonLogoText}>
                    Ganti Logo
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Nama Toko</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                style={{ marginRight: 20 }}
                className={classes.inputStyle}
              />
            </Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Email</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                className={classes.inputStyle}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ paddingLeft: 30 }}
              className={classes.flexCenter}
            >
              <Typography>
                <b>No. Telp</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                className={classes.inputStyle}
              />
            </Grid>

            <Grid item xs={3} className={classes.flexCenter}>
              <Typography>
                <b>Kabupaten/Kota</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                className={classes.inputStyle}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ paddingLeft: 30 }}
              className={classes.flexCenter}
            >
              <Typography>
                <b>Provinsi</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                className={classes.inputStyle}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography>
                <b>Deskripsi</b>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                multiline
                rows={10}
                style={{ paddingTop: 10 }}
                InputProps={{ disableUnderline: true }}
                fullWidth={true}
                className={classes.inputStyle}
              />
            </Grid>
          </Grid>

          <div className={classes.flexCenterHorizontal} style={{ paddingTop: 20 }}>
            <Button variant="contained" className={classes.buttonGreen}>Simpan</Button>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
});

export default Info;
