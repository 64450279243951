import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { getAdmin, deleteAdmin } from "../../services/axios";
import Pagination from "@material-ui/lab/Pagination";
import TambahAdmin from "./tambah-admin";
import EditAdmin from "./edit-admin";
import swal from "sweetalert";
import Loading from "../../components/loading";

import { ReactComponent as BinIcon } from "../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const UsersAdmin = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    admins: null,
    filteredAdmins: [],
    selectedAdmin: null,
    openTambahAdmin: false,
    openEditAdmin: false,
  });

  const handleChangeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getAdmin();
      setState(state => ({
        ...state,
        admins: response,
        filteredAdmins: response.data,
      }));
    };
    fetch();
  }, []);

  const resetData = async () => {
    const response = await getAdmin();
    setState({
      ...state,
      admins: response,
      filteredAdmins: response.data,
    });
  };

  const handleChangePage = (e, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetch = async () => {
      const response = await getAdmin(`?page=${value}`);
      setState({
        ...state,
        admins: response,
        filteredAdmins: response.data,
      });
    };
    fetch();
  };
  const handleSearch = (keyword) => {
    setState({
      ...state,
      filteredAdmins: state.admins.filter((value) =>
        value.name.toLowerCase().match(new RegExp(keyword.toLowerCase()))
      ),
    });
  };

  const handleDeleteAdmin = (id) => {
    swal({
      title: "Are you sure?",
      text: "Admin akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAdmin(id);
        swal("Admin dihapus!", {
          icon: "success",
        }).then(() => {
          resetData();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      } else {
        swal("Admin batal dihapus");
      }
    });
  };

  const Kelola = ({ data }) => {
    return (
      <div style={{ width: 100 }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton
            onClick={() =>
              setState({ ...state, selectedAdmin: data, openEditAdmin: true })
            }
          >
            <EditIcon />
          </StyledButton>
        </Tooltip>
        {data.id !== 1 && (
          <Tooltip title="Hapus" placement="bottom">
            <StyledButton
              style={{ marginLeft: 10 }}
              onClick={() => handleDeleteAdmin(data.id)}
            >
              <BinIcon />
            </StyledButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleCloseTambahAdmin = () => {
    const fetch = async () => {
      const response = await getAdmin();
      setState({
        ...state,
        admins: response,
        filteredAdmins: response.data,
        openTambahAdmin: false,
      });
    };
    fetch();
  };

  return (
    <Fragment>
      {state.openTambahAdmin ? (
        <Fragment>
          <TambahAdmin
            onClose={handleCloseTambahAdmin}
            handleBack={() => handleChangeState("openTambahAdmin", false)}
          />
        </Fragment>
      ) : state.openEditAdmin ? (
        <EditAdmin
          dataAdmin={state.selectedAdmin}
          handleBack={() => handleChangeState("openEditAdmin", false)}
        />
      ) : (
        <Fragment>
          {!state.admins ? (
            <Loading />
          ) : (
            <Fragment>
              <div className={classes.header}>
                <Typography variant="h6">
                  <b>List User Admin</b>
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.tambahAdmin}
                  style={{ marginRight: 20 }}
                  onClick={() => handleChangeState("openTambahAdmin", true)}
                >
                  + Tambah Admin
                </Button>
              </div>

              <Paper style={{ marginTop: 30 }}>
                <div style={{ display: "flex", padding: 20 }}>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      onChange={(event) => handleSearch(event.target.value)}
                    />
                  </div>
                </div>

                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center" width={50}>
                          No
                        </StyledTableCell>
                        <StyledTableCell align="left">Nama</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                        <StyledTableCell align="left">Role</StyledTableCell>
                        <StyledTableCell align="center">
                          Privilege
                        </StyledTableCell>
                        <StyledTableCell align="center" style={{ width: 100 }}>
                          Kelola
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.admins &&
                        state.filteredAdmins.map((row, index) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.email}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Owner
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Typography className={classes.privilege}>
                                Owner
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Kelola data={row} />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {state.filteredAdmins.length === 0 && state.admins && (
                  <div className={classes.notFound}>
                    <div className={classes.notFoundChild}>
                      <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        Oops!
                      </Typography>
                      <Typography>Data tidak ditemukan.</Typography>
                    </div>
                  </div>
                )}

                {(
                  <div className={classes.pagination}>
                    <Pagination
                      count={state.admins.meta.last_page}
                      color="secondary"
                      onChange={handleChangePage}
                    />
                  </div>
                )}
              </Paper>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

export default UsersAdmin;
