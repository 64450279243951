import { Grid, Tab, Tabs, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { WrapHistoryPage } from "./style";
import { DataGrid } from "@material-ui/data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GET_RESELLER_WALLET_TRANSACTIONS } from "services/wallet.service";
import { getReseller } from "services/axios";

import { formatRupiah } from "utils/currencyFormat";
import moment from "moment";
import debounce from "lodash/debounce";

const HistoryTransactionPage = (props) => {
  const [allData, setAllData] = React.useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [totalData, setTotalData] = React.useState(0);
  const [isRefetch, setIsRefetch] = useState(false);
  const [page, setPage] = React.useState(1);
  const [resellers, setResellers] = React.useState([]);
  const [selectedReseller, setSelectedReseller] = React.useState("");
  const [searchReseller, setSearchReseller] = React.useState("");

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, []);

  useEffect(() => {
    const handleFetchReseller = async (name) => {
      let resellers = await getReseller(`?filter[search]=${name}`);
      setResellers(resellers.data);
    };
    if (searchReseller) {
      handleFetchReseller(searchReseller);
    }
  }, [searchReseller]);

  useEffect(() => {
    const fetchWallets = async () => {
      let wallets = [];
      let status = "";

      if (activeTab === 1) status = "0";
      else if (activeTab === 2) status = "1";

      await GET_RESELLER_WALLET_TRANSACTIONS(
        status,
        page,
        selectedReseller
      ).then((res) => {
        res.data.map((data, i) => {
          wallets.push({ id: i, ...data });
        });

        setAllData(wallets);
        setTotalData(res.meta.total);
      });
    };

    if (selectedReseller) {
      if (isRefetch) {
        setAllData([]);
        fetchWallets();
        setIsRefetch(false);
      } else {
        fetchWallets();
      }
    }
  }, [isRefetch, page, activeTab, selectedReseller]);

  const handleChangeReseller = (value) => {
    setPage(1);
    setSelectedReseller(value);
  };

  const handleChangeResellerSearch = (value) => {
    setSearchReseller(value);
  };

  const debouncedResults = React.useMemo(() => {
    return debounce(handleChangeResellerSearch, 1000);
  }, []);

  const handleChangeTab = (value) => {
    setPage(1);
    setActiveTab(value);
  };

  const columns = [
    {
      field: "note",
      headerName: "Catatan",
      sortable: false,
      width: 250,
      renderCell: (params) => <>{params.row.note}</>,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        if (params.row.status === "IN") {
          return <span style={{ color: "green" }}>Masuk</span>;
        } else if (params.row.status === "OUT") {
          return <span style={{ color: "red" }}>Keluar</span>;
        } else return <span>Ongoing</span>;
      },
    },
    {
      field: "amount",
      headerName: "Jumlah",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        let color = "";
        if (params.row.status === "IN") {
          color = "green";
        } else if (params.row.status === "OUT") {
          color = "red";
        }
        return (
          <span style={{ color: color }}>
            Rp.{formatRupiah(params.row.amount)}
          </span>
        );
      },
    },
    {
      field: "supplier_net_idr",
      headerName: "Saldo",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.saldo) {
          return <>Rp.{formatRupiah(params.row.saldo)}</>;
        } else return "-";
      },
    },
    {
      field: "created_at",
      headerName: "Tanggal transaksi",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <>{moment(params.row.created_at).format("DD MMM YYYY HH:MM")}</>
      ),
    },
  ];

  const TabPanel = ({ children, activeTab, index }) => (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {activeTab === index && <div className="content">{children}</div>}
    </div>
  );

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <h4>Riwayat Transaksi</h4>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={resellers}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            onChange={(_, value) => value && handleChangeReseller(value.id)}
            fullWidth
            onInputChange={(_, value) => debouncedResults(value)}
            renderInput={(params) => (
              <TextField {...params} label="Cari reseller" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item xs={12}>
          <WrapHistoryPage>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => handleChangeTab(newValue)}
            >
              <Tab label="Semua" />
              <Tab label="Keluar" />
              <Tab label="Masuk" />
            </Tabs>
            <TabPanel activeTab={activeTab} index={0}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={1}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
            <TabPanel activeTab={activeTab} index={2}>
              {/* <WrapTable> */}
              <DataGrid
                rows={allData}
                rowCount={totalData}
                columns={columns}
                pageSize={20}
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                pagination
                paginationMode="server"
                autoHeight
                page={page}
                onPageChange={(params) => setPage(params.page)}
              />
              {/* </WrapTable> */}
            </TabPanel>
          </WrapHistoryPage>
        </Grid>
      </Grid>
    </div>
  );
};

export default HistoryTransactionPage;
