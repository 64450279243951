import { axiosInstance } from "../config/fetchAxios";

export const DELETE_SUPPLIER = (id) => {
  const response = axiosInstance.delete(`/api/v1/suppliers/${id}`);
  return response;
};

export const getPreSupplier = async (params) => {
  const response = axiosInstance.get(`/api/v1/pre-suppliers`, {
    params,
  });
  return response;
};

export const updatePreSupplier = async (id, body) => {
  const response = axiosInstance.post(`/api/v1/pre-suppliers/${id}`, body);
  return response;
};
