import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
  Backdrop,
  TextField,
  CircularProgress,
  Grid,
  Box,
} from "@material-ui/core";
import swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import React, { useEffect, useState } from "react";
import {
  deleteRewardOmzet,
  editRewardOmzet,
  getRewardOmzets,
  createRewardOmzet,
} from "services/axios";
import Modal from "./components/Modal";
import { formatRupiah } from "../../../../utils/currencyFormat";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
});

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

export default function RewardOmzet() {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [filterState, setFilterState] = useState({
    resellerId: "",
    level: "",
  });

  const handleInput = async (item) => {
    setIsLoading(true);
    const form = Object.entries(item).reduce(
      (a, [k, v]) => (v !== null ? ((a[k] = v), a) : a),
      {}
    );
    const formData = new FormData();
    for (var key in form) {
      formData.append(key, form[key]);
    }
    if (item.method === "add") {
      const response = await createRewardOmzet(formData);
      setDataList([...dataList, response.data]);
      swal("Reward Omzet berhasil ditambah!", { icon: "success" });
    } else if (item.method === "edit") {
      const response = await editRewardOmzet(item.id, formData);
      if (response.updated) {
        const data = response.data;
        setDataList((prevState) =>
          prevState.map((item) => (item.id !== data.id ? item : data))
        );
        swal("Reward Omzet berhasil diupdate!", { icon: "success" });
      } else {
        swal("Reward Omzet gagal diupdate!", { icon: "error" });
      }
    }
    setIsLoading(false);
  };

  const handleDelete = (id) => {
    swal({
      title: "Apakah kamu yakin?",
      text: "Reward Omzet yang dihapus tidak akan bisa dikembalikan!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await deleteRewardOmzet(id);
        if (response.deleted) {
          swal("Reward Omzet telah dihapus!", { icon: "success" });
          setDataList((prevState) =>
            prevState.filter((item) => item.id !== id)
          );
        } else {
          swal("Reward Omzet gagal dihapus!", { icon: "error" });
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await getRewardOmzets(
      filterState.resellerId,
      filterState.level
    );
    setDataList(data.data);
  };

  const handleChangeFilter = (e) => {
    setFilterState({ ...filterState, [e.target.name]: e.target.value });
  };

  const createRow = (item) => {
    return (
      <TableRow key={item.id}>
        <TableCell>{item.level}</TableCell>
        <TableCell>
          <img src={item.images} alt={item.title} width="100%" />
        </TableCell>
        <TableCell>{`Rp ${formatRupiah(item.minimum_omzet)}`}</TableCell>
        <TableCell>{item.reward_desc}</TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title="Edit" placement="bottom">
                <StyledButton
                  onClick={() => {
                    setSelectedItem(item);
                    setModalOpen(true);
                  }}
                >
                  <EditIcon />
                </StyledButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Hapus" placement="bottom">
                <StyledButton onClick={() => handleDelete(item.id)}>
                  <BinIcon />
                </StyledButton>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <Button
                  onClick={() => {
                    setSelectedItem(false);
                    setModalOpen(true);
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                >
                  Tambah Reward Omzet
                </Button><br></br><br></br>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Level</TableCell>
              <TableCell width={300}>Gambar</TableCell>
              <TableCell>Omzet Minimun</TableCell>
              <TableCell>Deskripsi</TableCell>
              <TableCell>Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((item) => {
              return createRow(item);
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={modalOpen}
        selectedItem={selectedItem}
        onClose={() => setModalOpen(false)}
        onSubmit={handleInput}
      />

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
