import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, withStyles } from '@material-ui/core'
import { editReseller, editSubscription } from 'services/axios';
import { resellerLevels } from 'config/constants';

const StyledButton = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.green.main,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: "#33b17a",
        },
        padding: 10,
    },
}))(Button);

const ResellerLevelMenu = ({ reseller, onSave }) => {
    const [resellerAnchorEl, setResellerAnchoreEl] = useState(null)
    const [level, setLevel] = useState(reseller.level)

    const updateResellerLevel = (level) => {
        if (typeof level === 'number') {
            const formData = new FormData();
            formData.append("level", level);
            formData.append("_method", "PATCH");
            editReseller(reseller.id, formData).then(({ data }) => {
                reseller.level = data.level
                reseller.subscription_month = null

                setLevel(data.level)
                setResellerAnchoreEl(null)
                onSave();
            })
        }

        if (typeof level === 'string' && level.includes('subscription-')) {
            const month = level.split('-')[1];
            const formData = new FormData();
            formData.append("month", month);
            formData.append("_method", "PUT");
            editSubscription(reseller.id, formData).then(({ data }) => {
                reseller.subscription_month = data.subscription_month
                setLevel({
                    id: level,
                    name: `Superseller ${data.subscription_month} Bulan`
                })
                setResellerAnchoreEl(null)
                onSave();
            })
        }
    }

    return (
        <div>
            <StyledButton
                size='small'
                onClick={(event) => setResellerAnchoreEl(event.currentTarget)}
                style={{ width: "fit-content" }}
            >
                {
                    reseller.subscription_month !== null ?
                    reseller.subscription_month === 3 ? 'Superseller 3 Bulan'  : 'Superseller 6 Bulan'
                    : level.name
                }
            </StyledButton>


            <Menu
                id="reseller-level-menu"
                anchorEl={resellerAnchorEl}
                keepMounted
                open={Boolean(resellerAnchorEl)}
                onClose={() => setResellerAnchoreEl(null)}
            >
                {resellerLevels.map(({ name, id }) => (
                    <MenuItem key={ id } onClick={() => updateResellerLevel(id)}>{ name }</MenuItem>
                ))}
            </Menu>
        </div>
    )
}

ResellerLevelMenu.propTypes = {
    reseller: PropTypes.object,
}

export default ResellerLevelMenu
