import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import swal from "sweetalert";

import { createAnnoucements } from "../../../services/axios";
import moment from "moment";
const TambahData = withStyles(style)((props) => {
  const { classes, onClose, open } = props;
  const [payload, setPayload] = useState({
    title: "",
    message: "",
    scope: [],
    tags: [],
    link: "",
    scheduled_time: null,
  });
  const [showSchedule, setShowSchedule] = useState(false);

  const handleChangePayload = (valueState, value) => {
    setPayload({ ...payload, [valueState]: value });
  };

  const handleTambah = async () => {
    try {
      const data = {
        title: payload.title,
        message: payload.message,
        scope: payload.scope,
      };

      if (payload.scheduled_time && showSchedule) {
        data.scheduled_time = payload.scheduled_time;
      }
      if (payload.link) {
        data.link = payload.link;
      }
      if (payload.tags) {
        data.tags = payload.tags;
      }

      const response = await createAnnoucements(data);
      response.data &&
        swal("Success", "Data ditambahkan", "success").then(() => onClose());
        setPayload({
          title: "",
          message: "",
          scope: [],
          tags: [],
          link: "",
          scheduled_time: null,
        })
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        swal("Error", error.response.data.message, "error");
      } else {
        swal("Error", "Terjadi kesalahan silakan coba lagi", "error");
      }
      setPayload({
        title: "",
        message: "",
        scope: [],
        tags: [],
        link: "",
        scheduled_time: null,
      })
    }
  };

  const onChangeScope = (e) => {
    const options = payload.scope;
    let index;
    if (e.target.checked) {
      options.push(e.target.value);
    } else {
      index = options.indexOf(e.target.value);
      options.splice(index, 1);
    }
    handleChangePayload("scope", options);
  };

  const onChangeSchedule = (e) => {
    handleChangePayload("scheduled_time", e.target.value);
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Tambah Pengumuman</DialogTitle>
        <DialogContent>
          <div style={{ width: 500 }}>
            <div>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Title"
                onChange={(e) => handleChangePayload("title", e.target.value)}
                value={payload.title}
                variant="outlined"
              />
            </div>

            <div className={classes.range}>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Message"
                onChange={(e) => handleChangePayload("message", e.target.value)}
                value={payload.message}
                variant="outlined"
              />
            </div>
            <p>Scope</p>
            <FormControlLabel
              control={
                <Checkbox
                  value="general"
                  onClick={(e) => onChangeScope(e)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Umum"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="supplier"
                  onClick={(e) => onChangeScope(e)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Supplier"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="reseller"
                  onClick={(e) => onChangeScope(e)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Semua Reseller"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="level_1"
                  onClick={(e) => onChangeScope(e)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Seller"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="level_2"
                  onClick={(e) => onChangeScope(e)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="SuperSeller"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="level_3"
                  onClick={(e) => onChangeScope(e)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="SuperSeller Gold"
            />
            <div className={classes.range}>
              <TextField
                id="outlined-required"
                fullWidth
                label="Tags"
                onChange={(e) =>
                  handleChangePayload(
                    "tags",
                    e.target.value.replace(" ", "").split(",")
                  )
                }
                value={payload.tags}
                placeholder="Pisah antar tag dengan koma"
                variant="outlined"
              />
            </div>
            <div className={classes.range}>
              <TextField
                id="outlined-required"
                fullWidth
                label="link"
                onChange={(e) => handleChangePayload("link", e.target.value)}
                value={payload.link}
                placeholder="Link Untuk produk/admin (Opsional)"
                variant="outlined"
              />
            </div>
            <div className={classes.range}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showSchedule}
                    onClick={(e) => setShowSchedule(e.target.checked)}
                  />
                }
                label="Jadwal"
              />
              {showSchedule && (
                <TextField
                  id="datetime-local"
                  label="Jadwal"
                  type="datetime-local"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: new Date().toISOString().slice(0, 16),
                  }}
                  value={payload.scheduled_time}
                  onChange={(e) => onChangeSchedule(e)}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button variant="contained" color="secondary" onClick={handleTambah}>
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default TambahData;
