import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import "./style.css";
import { Typography, Grid } from "@material-ui/core";
import CardReport from "./components/CardReport";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getReportSummary, getReportSummaryFilter } from "../../services/axios";
import moment from "moment";

const Report = withStyles(style)((props) => {
  const { classes } = props;
  const [reportSummary, setReportSummary] = useState({});
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDateEndDate, handleDateChangeDateEnd] = useState(new Date());
  const [filterTrue, setFilterTrue] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const report = await getReportSummary();
      setReportSummary(report);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (filterTrue) {
      const fetch = async () => {
        const report = await getReportSummaryFilter(
          moment(selectedDate).format("YYYY-MM-DD"),
          moment(selectedDateEndDate).format("YYYY-MM-DD")
        );
        // console.log(report);
        setReportSummary(report);
      };
      fetch();
      setFilterTrue(false);
    }
  }, [filterTrue, selectedDate, selectedDateEndDate]);

  // console.log(selectedDate, reportSummary);

  function handleChange(event) {
    // setFilterTime(event.target.value);
    setFilterTrue(true);
  }

  return (
    <Fragment>
      <div className={classes.container}>
        <Grid container justify="flex-end" alignItems="center" spacing={1}>
          {/* <FormControl className={classes.margin}>
            <NativeSelect
              id="demo-customized-select-native"
              value={filterTime}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <option value="Berdasarkan Tanggal">Berdasarkan Tanggal</option>
              <option value="Berdasarkan Bulan">Berdasarkan Bulan</option>
              <option value="Berdasarkan Tahun">Berdasarkan Tahun</option>
            </NativeSelect>
          </FormControl> */}
          <Grid item>
            {/* <Typography variant="caption">1 Juni 2020</Typography>
            <CalendarIcon /> */}
            <div className={classes.bgFilter}>
              <KeyboardDatePicker
                autoOk
                format="yyyy-MM-dd"
                value={selectedDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => handleDateChange(date)}
              />
            </div>
          </Grid>
          <Grid item>
            {/* <Typography variant="caption">5 Juni 2020</Typography>
            <CalendarIcon /> */}
            <div className={classes.bgFilter}>
              {" "}
              <KeyboardDatePicker
                autoOk
                format="yyyy-MM-dd"
                value={selectedDateEndDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => handleDateChangeDateEnd(date)}
              />
            </div>
          </Grid>
          <Grid item>
            <div
              className={classes.filterButton}
              onClick={() => handleChange()}
            >
              <Typography variant="caption">Terapkan Filter</Typography>
            </div>
          </Grid>
        </Grid>

        <div style={{ paddingTop: 30 }}>
          {reportSummary.order_count && reportSummary.order_count ? (
            <CardReport reportSummary={reportSummary} />
          ) : (
            <h4>Tidak ada data</h4>
          )}
        </div>
      </div>
    </Fragment>
  );
});

export default Report;
