const style = (theme) => ({
  container: {
    paddingLeft: 22,
    paddingRight: 22,
    paddingTop: 12,
    paddingBottom: 20,
    width: 200,
    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    height: "100vh",
    position: "fixed",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 12,
  },
  idejualanLogo: {
    height: 45,
  },
  dashboardWrapper: {
    paddingTop: 10,
  },
  titleDashboard: {
    textTransform: "uppercase",
    color: theme.palette.grey.tertiary,
  },
  icon: {
    height: 25,
    width: 25,
    "& path": {
      // stroke: theme.palette.grey.tertiary,
      fill: theme.palette.grey.tertiary,
    },
  },
  iconClicked: {
    height: 25,
    width: 25,
    "& path": {
      // stroke: theme.palette.yellow.primary,
      fill: theme.palette.yellow.primary,
    },
  },
  iconDs: {
    height: 25,
    width: 25,
    "& path": {
      stroke: theme.palette.grey.tertiary,
    },
  },
  iconClickedDs: {
    height: 25,
    width: 25,
    "& path": {
      stroke: theme.palette.yellow.primary,
    },
  },
  textList: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.palette.grey.tertiary,
  },
  textListClicked: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.palette.yellow.primary,
  },
  button: {
    borderRadius: 10,
  },
  buttonClicked: {
    borderRadius: 10,
    backgroundColor: theme.palette.yellow.tertiary,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

export default style;
