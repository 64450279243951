import React, { Fragment } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { ReactComponent as GroupIcon } from "assets/icons/Vector.svg";
import { ReactComponent as PremiumIcon } from "assets/icons/icon_premium.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/man-avatar.svg";
import numberSeparator from "utils/numberSeparator";

const ResellerSummary = withStyles(style)((props) => {
  const reportSummary = props.report;
  const { classes } = props;

  const data = [
    {
      icon: <GroupIcon fill="white" stroke="white" />,
      text: "Total Reseller",
      count: reportSummary?.reseller?.all || 0,
    },
    {
      icon: <GroupIcon fill="white" stroke="white" />,
      text: "Total Reseller Bulan Ini",
      count: reportSummary?.reseller?.month || 0,
    },
    {
      icon: <ProfileIcon fill="white" />,
      text: "Total Seller",
      count: reportSummary?.seller?.all || 0,
    },
    {
      icon: <ProfileIcon fill="white" />,
      text: "Total Seller Bulan Ini",
      count: reportSummary?.seller?.month || 0,
    },
    {
      icon: <PremiumIcon fill="white" stroke="white" width="25" />,
      text: "Total Superseller",
      count: reportSummary?.superseller?.all || 0,
    },
    {
      icon: <PremiumIcon fill="white" stroke="white" width="25" />,
      text: "Total Superseller Bulan Ini",
      count: reportSummary?.superseller?.month || 0,
    },
  ];
  return (
    <Fragment>
      <Grid container spacing={3}>
        {data.map((res, i) => (
          <Grid item sm={12} lg={6} xl={i > 1 ? 3 : 6} key={i}>
            <Paper className={classes.wrapper}>
              <div className={classes.bgIcon}>{res.icon}</div>
              <div style={{ paddingLeft: 20 }}>
                <Typography variant="caption">{res.text}</Typography>
                <Typography variant="h5">{numberSeparator(parseInt(res.count))}</Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
});

export default ResellerSummary;
