const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexFullCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    border: `1px solid ${theme.palette.green.main}`,
    borderRadius: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  buttonGreen: {
    backgroundColor: theme.palette.green.main,
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#44bf8a',
    }
  },
  simpanButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }
});

export default style;
