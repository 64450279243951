import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Input,
  Typography,
  Button,
  Switch,
  Grid,
  Fade,
  Avatar,
  InputBase,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Fab,
  Menu,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Select,
} from "@material-ui/core";
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import CardOrder from "./components/CardOrder";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getAllOrders, getSupplier, downloadLabel, getCouriers } from "../../services/axios";
import moment from "moment";
import { EXPORT_EXCEL } from "services/orders.service";
import { GET_ALL_PICS } from "services/pic.service";
import { handleFinishOrder } from "./handler";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { updateOrder } from "services/axios";
import swal from "sweetalert"

const Content = withStyles(style)((props) => {
  const {
    classes,
    handleUbahPesanan,
    order,
    filteredOrder = [],
    handleOrderData,
  } = props;
  const DateNow = `${new Date().getFullYear()}-${
    "0" + (new Date().getMonth() + 1)
  }-${"0" + new Date().getDate()}`;
  const [filter, setFilter] = useState({
    open: false,
    cod: 0,
    not_cod: 0,
    pending: 0,
    waiting: 0,
    forwarded: 0,
    processes: 0,
    delivery: 0,
    done: 0,
    canceled: 0,
    canceled_supplier: 0,
    is_withdrawed: 0,
    not_withdrawed: 0,
    date_start: moment().format("YYYY-MM-DD hh:mm:ss"),
    date_end: "",
    date_done_start: moment().format("YYYY-MM-DD hh:mm:ss"),
    date_done_end: "",
    date_paid_start: moment().format("YYYY-MM-DD hh:mm:ss"),
    date_paid_end: "",
    courier_id: "",
    page: 1,
    search: "",
    loadingSearch: false,
    listSupplier: null,
    selectedSupplier: null,
    pic_id: "",
    delivered: 0,
    search_supplier: "",
  });

  const [dataPic, setDataPic] = useState([]);
  const [anchorElSupplier, setAnchorElSupplier] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
  const filterList = [
    { key: "order_id", name: "Order ID" },
    { key: "supplier_name", name: "Supplier" },
    { key: "reseller_name", name: "Pemesan" },
    { key: "customer_name", name: "Customer" },
    { key: "resi", name: "Resi" },
  ];
  const [downloadQueue, setDownloadQueue] = useState([]);
  const [selectAllLabel, setSelectAllLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [dataCouries, setdataCouries] = useState()
  const isMobile = useMediaQuery("(max-width:500px)");

  const handleToggleAllLabel = () => {
    setSelectAllLabel((value) => {
      if (value === false) {
        filteredOrder.forEach((item) => {
          if (item.is_label_downloaded === 0) {
            toggleDownloadQueue(item.id);
          }
        });
      } else {
        setDownloadQueue([]);
      }
      return !value;
    });
  };

  const handleDownloadAllLabel = () => {
      let list = []
      filteredOrder.forEach((item) => {
          list = [...list];
          if (list.indexOf(item.id) === -1) {
            list.push(item.id);
          } else {
            list.splice(list.indexOf(item.id), 1);
          }
      });
      setTimeout(() => {
        downloadMultipleLabel(list);
      }, 500);
  };

  const toggleDownloadQueue = (id) => {
    setDownloadQueue((value) => {
      const list = [...value];
      if (list.indexOf(id) === -1) {
        list.push(id);
      } else {
        list.splice(list.indexOf(id), 1);
      }
      return list;
    });
  };

  const handleChangeFilter = (event, index) => {
    setAnchorElFilter(null);
    setCurrentFilterIndex(index);
  };

  const handleClickSupplier = (event) => {
    setAnchorElSupplier(event.currentTarget);
  };

  const handleCloseSupplier = () => {
    setAnchorElSupplier(null);
  };

  const openFilterSupplier = Boolean(anchorElSupplier);
  const idSupplier = openFilterSupplier ? "simple-popover" : undefined;

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllOrders();
      handleOrderData(response);
    };
    isRefetch && fetch().then(setIsRefetch(false));
  }, [isRefetch]);

  useEffect(() => {
    GET_ALL_PICS().then((res) => setDataPic(res.data));
    getCouriers().then((res) => setdataCouries(res.data))
  }, []);

  const getFilter = () => {
    const currentFilter = filterList[currentFilterIndex].key ?? "";
    let filters = `${
      (filter.waiting !== 0
        ? "&filter[waiting_payment]=" + filter.waiting
        : "") +
      (filter.is_withdrawed !== 0
        ? "&filter[is_withdrawed]=" + filter.is_withdrawed
        : "") +
      (filter.not_withdrawed !== 0
        ? "&filter[not_withdrawed]=" + filter.not_withdrawed
        : "") +
      (filter.processes !== 0 ? "&filter[processed]=" + filter.processes : "") +
      (filter.ready_to_delivery !== 0 ? "&filter[ready_to_delivery]=" + filter.ready_to_delivery : "") +
      (filter.forwarded !== 0 ? "&filter[forwarded]=" + filter.forwarded : "") +
      (filter.delivery !== 0 ? "&filter[delivery]=" + filter.delivery : "") +
      (filter.done !== 0 ? "&filter[done]=" + filter.done : "") +
      (filter.canceled !== 0 ? "&filter[canceled]=" + filter.canceled : "") +
      (filter.canceled_supplier !== 0
        ? "&filter[canceled_supplier]=" + filter.canceled_supplier
        : "") +
      (filter.pending !== 0 ? "&filter[pending]=" + filter.pending : "") +
      (filter.search != ""
        ? "&filter[" + currentFilter + "]=" + filter.search
        : "") +
      (filter.date_end.length !== 0
        ? "&filter[date_between]=" + filter.date_start + "," + filter.date_end
        : "") +
      (filter.date_done_end.length !== 0
        ? "&filter[done_between]=" +
          filter.date_done_start +
          "," +
          filter.date_done_end
        : "") +
      (filter.date_paid_end.length !== 0
          ? "&filter[paid_between]=" +
            filter.date_paid_start +
            "," +
            filter.date_paid_end
          : "") +
      (filter.selectedSupplier
        ? "&filter[supplier_id]=" + filter.selectedSupplier.id
        : "") +
      (filter.cod
        ? "&filter[is_cod]=" + filter.cod
        : "") +
      (filter.not_cod
        ? "&filter[not_cod]=" + filter.not_cod
        : "") +
      (filter.courier_id
        ? "&filter[courier_id]=" + filter.courier_id
        : "") +
      (filter.delivered
        ? "&filter[delivered]=" + filter.delivered
        : "")
    }`;
    return filters;
  };

  const handleResetFilter = async () => {
    setFilter({
      open: false,
      pending: 0,
      waiting: 0,
      processes: 0,
      ready_to_delivery: 0,
      delivery: 0,
      done: 0,
      canceled: 0,
      canceled_supplier: 0,
      forwarded: 0,
      is_withdrawed: 0,
      not_withdrawed: 0,
      date_start: moment().format("YYYY-MM-DD hh:mm:ss"),
      date_end: "",
      date_done_start: moment().format("YYYY-MM-DD hh:mm:ss"),
      date_done_end: "",
      date_paid_start: moment().format("YYYY-MM-DD hh:mm:ss"),
      date_paid_end: "",
      courier_id: "",
      page: 1,
      search: "",
      loadingSearch: false,
      listSupplier: null,
      selectedSupplier: null,
      pic_id: "",
      delivered: '',
      search_supplier: "",
    });
    const response = await getAllOrders();
    handleOrderData(response);
  };

  const handleFilter = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  const handleChangePage = async (e, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFilter({ ...filter, page: value });
    const response = await getAllOrders(`?page=${value}` + getFilter());
    handleOrderData(response);
  };

  const handleGunakanFilter = () => {
    const fetch = async () => {
      const response = await getAllOrders(
        `?page=${
          filter.page +
          (filter.waiting !== 0
            ? "&filter[waiting_payment]=" + filter.waiting
            : "") +
          (filter.processes !== 0
            ? "&filter[processed]=" + filter.processes
            : "") +
          (filter.ready_to_delivery !== 0
            ? "&filter[ready_to_delivery]=" + filter.ready_to_delivery
            : "") +
          (filter.is_withdrawed !== 0
            ? "&filter[is_withdrawed]=" + filter.is_withdrawed
            : "") +
          (filter.not_withdrawed !== 0
            ? "&filter[not_withdrawed]=" + filter.not_withdrawed
            : "") +
          (filter.forwarded !== 0
            ? "&filter[forwarded]=" + filter.forwarded
            : "") +
          (filter.delivery !== 0
            ? "&filter[delivery]=" + filter.delivery
            : "") +
          (filter.done !== 0 ? "&filter[done]=" + filter.done : "") +
          (filter.canceled !== 0
            ? "&filter[canceled]=" + filter.canceled
            : "") +
          (filter.canceled_supplier !== 0
            ? "&filter[canceled_supplier]=" + filter.canceled_supplier
            : "") +
          (filter.pending !== 0 ? "&filter[pending]=" + filter.pending : "") +
          (filter.date_end.length !== 0
            ? "&filter[date_between]=" +
              filter.date_start +
              "," +
              filter.date_end
            : "") +
          (filter.date_done_end.length !== 0
            ? "&filter[done_between]=" +
              filter.date_done_start +
              "," +
              filter.date_done_end
            : "") +
          (filter.date_paid_end.length !== 0 && filter.date_paid_start.length !== 0
            ? "&filter[paid_between]=" +
              filter.date_paid_start +
              "," +
              filter.date_paid_end
            : "") +
          (filter.selectedSupplier
            ? "&filter[supplier_id]=" + filter.selectedSupplier.id
            : "") +
          (filter.pic_id ? "&filter[pic_id]=" + filter.pic_id : "") +
          (filter.cod
            ? "&filter[is_cod]=" + filter.cod
            : "") +
          (filter.not_cod
            ? "&filter[not_cod]=" + filter.not_cod
            : "") +
          (filter.courier_id
            ? "&filter[courier_id]=" + filter.courier_id
            : "") +
          (filter.delivered
            ? "&filter[delivered]=" + filter.delivered
            : "")
        }`
      );
      handleOrderData(response);
    };
    fetch();
  };

  const handleSearch = (keyword) => {
    setFilter({ ...filter, search: keyword });
  };

  const handleCariSupplier = async () => {
    setFilter({ ...filter, loadingSearch: true });
    const response = await getSupplier(`filter[name]=${filter.search_supplier}`);
    if (response.data) {
      setFilter({
        ...filter,
        loadingSearch: false,
        listSupplier: response.data,
      });
    }
  };

  const handleCari = async () => {
    const currentFilter = filterList[currentFilterIndex].key;
    setFilter({ ...filter, page: 1, loadingSearch: true });
    const response = await getAllOrders(
      `?page=${
        filter.page +
        (filter.waiting !== 0
          ? "&filter[waiting_payment]=" + filter.waiting
          : "") +
        (filter.processes !== 0
          ? "&filter[processed]=" + filter.processes
          : "") +
        (filter.ready_to_delivery !== 0
          ? "&filter[ready_to_delivery]=" + filter.ready_to_delivery
            : "") +
        (filter.forwarded !== 0
          ? "&filter[forwarded]=" + filter.forwarded
          : "") +
        (filter.pending !== 0 ? "&filter[pending]=" + filter.pending : "") +
        (filter.delivery !== 0 ? "&filter[delivery]=" + filter.delivery : "") +
        (filter.done !== 0 ? "&filter[done]=" + filter.done : "") +
        (filter.canceled !== 0 ? "&filter[canceled]=" + filter.canceled : "") +
        (filter.canceled_supplier !== 0
          ? "&filter[canceled_supplier]=" + filter.canceled_supplier
          : "") +
        (filter.date_end.length !== 0
          ? "&filter[date_between]=" + filter.date_start + "," + filter.date_end
          : "") +
        (filter.date_done_end.length !== 0
          ? "&filter[done_between]=" +
            filter.date_done_start +
            "," +
            filter.date_done_end
          : "") +
        (filter.date_paid_end.length !== 0
          ? "&filter[paid_between]=" +
            filter.date_paid_start +
            "," +
            filter.date_paid_end
          : "") +
        (filter.selectedSupplier
          ? "&filter[supplier_id]=" + filter.selectedSupplier.id
          : "") +
        (filter.cod
          ? "&filter[is_cod]=" + filter.cod
          : "") +
        (filter.not_cod
          ? "&filter[not_cod]=" + filter.not_cod
          : "") +
        (filter.courier_id
          ? "&filter[courier_id]=" + filter.courier_id
          : "") +
        (filter.delivered
          ? "&filter[delivered]=" + filter.delivered
          : "")
      }&filter[${currentFilter}]=${filter.search}`
    );
    handleOrderData(response);
  };

  const downloadMultipleLabel = async (listDownload = null) => {
    setIsLoading(true);
    let url = "https://api.idejualan.com/printlabel/preview/"
    if(listDownload == null) {
      url = url + downloadQueue.join(",");
    } else {
      url = url + listDownload.join(",");
    }
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", `_blank`);
    document.body.appendChild(link);
    link.click();

    // Update order
    const filteredOrderTemp = filteredOrder.map((item) => {
      if (downloadQueue.indexOf(item.id) !== -1) {
        item = { ...item, is_label_downloaded: 1 };
      }
      return item;
    });

    const response = { ...order, data: filteredOrderTemp };

    handleOrderData(response);
    setIsLoading(false);
  };

  const prosesAllPesanan = async (filteredOrder) => {
    const slug = JSON.parse(localStorage.getItem("DATA")).slug;
    swal({
      title: "Apakah kamu yakin memproses semua pesanan di halaman ini?",
      text: "Merubah status pesanan dari 'Forwarded' menjadi 'Processed'",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        try {
        filteredOrder
          .filter((item) => item.histories[item.histories.length - 1].status === 20)
          .map((item) => {
            const formData = new FormData();
            formData.append("_method", "PATCH");
            formData.append("is_processed", 1);
            updateOrder(slug, item.id, formData);
          });
            swal("Success!", "Pesanan berhasil diupdate!", "success").then(handleCari)
          } catch (error) {
            if (error.response && error.response.data) {
              swal("Oops!", error.response.data.message, "warning")
            }
          }
      }
    });
  };

  const [selectedDateStart, setSelectedDateStart] = React.useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(null);
  const [selectedDateDoneStart, setSelectedDateDoneStart] = React.useState(null);
  const [selectedDateDoneEnd, setSelectedDateDoneEnd] = React.useState(null);
  const [selectedDatePaidStart, setSelectedDatePaidStart] = React.useState(null);
  const [selectedDatePaidEnd, setSelectedDatePaidEnd] = React.useState(null);

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
    let formatted_date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    handleFilter("date_start", formatted_date);
  };

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
    let formatted_date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    handleFilter("date_end", formatted_date);
  };

  const handleDateChangeDoneStart = (date) => {
    setSelectedDateDoneStart(date);
    let formatted_date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    handleFilter("date_done_start", formatted_date);
  };

  const handleDateChangeDoneEnd = (date) => {
    setSelectedDateDoneEnd(date);
    let formatted_date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    handleFilter("date_done_end", formatted_date);
  };

  // date paid off
  const handleDateChangePaidStart = (date) => {
    setSelectedDatePaidStart(date);
    let formatted_date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    handleFilter("date_paid_start", formatted_date);
  };

  const handleDateChangePaidEnd = (date) => {
    setSelectedDatePaidEnd(date);
    let formatted_date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    handleFilter("date_paid_end", formatted_date);
  };

  const downloadExcel = async () => {
    const currentFilter = filterList[currentFilterIndex].key;
    setIsLoading(true);
    const response = await EXPORT_EXCEL(
      `?${
        (filter.pending !== 0 ? "&filter[pending]=" + filter.pending : "") +
        (filter.waiting !== 0
          ? "&filter[waiting_payment]=" + filter.waiting
          : "") +
        (filter.processes !== 0
          ? "&filter[processed]=" + filter.processes
          : "") +
        (filter.forwarded !== 0
          ? "&filter[forwarded]=" + filter.forwarded
          : "") +
        (filter.ready_to_delivery !== 0
          ? "&filter[ready_to_delivery]=" + filter.ready_to_delivery
            : "") +
        (filter.delivery !== 0 ? "&filter[delivery]=" + filter.delivery : "") +
        (filter.done !== 0 ? "&filter[done]=" + filter.done : "") +
        (filter.canceled !== 0 ? "&filter[canceled]=" + filter.canceled : "") +
        (filter.canceled_supplier !== 0
          ? "&filter[canceled_supplier]=" + filter.canceled_supplier
          : "") +
        (filter.date_end.length !== 0
          ? "&filter[date_between]=" + filter.date_start + "," + filter.date_end
          : "") +
        (filter.date_done_end.length !== 0
          ? "&filter[done_between]=" +
            filter.date_done_start +
            "," +
            filter.date_done_end
          : "") +
        (filter.date_paid_end.length !== 0
          ? "&filter[paid_between]=" +
            filter.date_paid_start +
            "," +
            filter.date_paid_end
          : "") +
        (filter.selectedSupplier
          ? "&filter[supplier_id]=" + filter.selectedSupplier.id
          : "") +
        (filter.pic_id ? "&filter[pic_id]=" + filter.pic_id : "") +
        (filter.cod
          ? "&filter[is_cod]=" + filter.cod
          : "") +
        (filter.not_cod
          ? "&filter[not_cod]=" + filter.not_cod
          : "") +
        (filter.courier_id
          ? "&filter[courier_id]=" + filter.courier_id
          : "")+
        (filter.search 
          ? `&filter[${currentFilter}]=${filter.search}`
          : '')+
        (filter.delivered
          ? "&filter[delivered]=" + filter.delivered
          : "")
      }`
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Excel Order ${downloadQueue.map((id) => `#${id}`).join(", ")}`
    );
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    setIsLoading(false);
  };

  const enter = (event) => {
    if (event.keyCode === 13) {
      handleCari();
    }
  };
  const enterSupplier = (event) => {
    if (event.keyCode === 13) {
      handleCariSupplier();
    }
  };

  return (
    <Fragment>
      <Paper style={{ padding: 10 }}>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(event) => setAnchorElFilter(event.currentTarget)}
            >
              {filterList[currentFilterIndex].name}
              <ArrowDropDownIcon />
            </Button>
          </Grid>
          <Grid item>
            <Menu
              id="anchorFilter"
              anchorEl={anchorElFilter}
              keepMounted
              open={Boolean(anchorElFilter)}
              onClose={() => setAnchorElFilter(null)}
            >
              {filterList.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={(event) => handleChangeFilter(event, index)}
                >
                  {filterList[index].name}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          <Grid item>
            <div className={classes.searchWrapper}>
              <SearchIcon className={classes.SearchIcon} />
              <Input
                disableUnderline={true}
                placeholder="Pencarian..."
                onKeyDown={(e) => enter(e)}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleCari()}
              style={{ borderRadius: 30, marginLeft: 10 }}
            >
              Cari
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.filter}
              onClick={() => handleFilter("open", !filter.open)}
            >
              <Typography>Filter</Typography>
              <ArrowDropDownIcon />
            </Button>
          </Grid>
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllLabel}
                  onChange={handleToggleAllLabel}
                  name="selectAllLabel"
                />
              }
              label="Pilih semua label"
            />
          </Grid>
          <Grid item>
          <Button
              className={classes.downloadAllBtn}
              onClick={handleDownloadAllLabel}
            >
              <GetAppIcon />
              <Typography>Download All Label ({filteredOrder.length})</Typography>
            </Button>
          </Grid>
        </Grid>

        {filter.open && (
          <Fade in={filter.open}>
            <div className={classes.filterOrderWrapper}>
              <Typography variant="h6">
                <b>Filter Order</b>
              </Typography>
              <Grid
                container
                justify="space-between"
                spacing={2}
                style={{ paddingTop: 10 }}
              >
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>Waiting</Typography>
                    <Switch
                      checked={filter.waiting === 1}
                      onChange={() =>
                        handleFilter("waiting", filter.waiting === 1 ? 0 : 1)
                      }
                      name="waiting"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>Forwarded</Typography>
                    <Switch
                      checked={filter.forwarded === 1}
                      onChange={() =>
                        handleFilter(
                          "forwarded",
                          filter.forwarded === 1 ? 0 : 1
                        )
                      }
                      name="waiting"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>Processes</Typography>
                    <Switch
                      checked={filter.processes === 1}
                      onChange={() =>
                        handleFilter(
                          "processes",
                          filter.processes === 1 ? 0 : 1
                        )
                      }
                      name="processes"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>Ready to delivery</Typography>
                    <Switch
                      checked={filter.ready_to_delivery === 1}
                      onChange={() =>
                        handleFilter(
                          "ready_to_delivery",
                          filter.ready_to_delivery === 1 ? 0 : 1
                        )
                      }
                      name="ready_to_delivery"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>Delivery</Typography>
                    <Switch
                      checked={filter.delivery === 1}
                      onChange={() =>
                        handleFilter("delivery", filter.delivery === 1 ? 0 : 1)
                      }
                      name="delivery"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>Done</Typography>
                    <Switch
                      checked={filter.done === 1}
                      onChange={() =>
                        handleFilter("done", filter.done === 1 ? 0 : 1)
                      }
                      name="done"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>Canceled</Typography>
                    <Switch
                      checked={filter.canceled === 1}
                      onChange={() =>
                        handleFilter("canceled", filter.canceled === 1 ? 0 : 1)
                      }
                      name="canceled"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>
                      Canceled Supplier
                    </Typography>
                    <Switch
                      checked={filter.canceled_supplier === 1}
                      onChange={() =>
                        handleFilter(
                          "canceled_supplier",
                          filter.canceled_supplier === 1 ? 0 : 1
                        )
                      }
                      name="canceled_supplier"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>

                {/* cod filter */}
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>
                      COD
                    </Typography>
                    <Switch
                      checked={filter.cod === 1}
                      onChange={() =>
                        handleFilter(
                          "cod",
                          filter.cod === 1 ? 0 : 1
                        )
                      }
                      name="cod"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>

                {/* non cod filter */}
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>
                      Non COD
                    </Typography>
                    <Switch
                      checked={filter.not_cod === 1}
                      onChange={() =>
                        handleFilter(
                          "not_cod",
                          filter.not_cod === 1 ? 0 : 1
                        )
                      }
                      name="not_cod"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>

                {/* delivered filter */}
                <Grid item>
                  <div className={classes.filterDiv}>
                    <Typography style={{ color: "#fff" }}>
                      Delivered
                    </Typography>
                    <Switch
                      checked={filter.delivered === 1}
                      onChange={() =>
                        handleFilter(
                          "delivered",
                          filter.delivered === 1 ? 0 : 1
                        )
                      }
                      name="delivered"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </Grid>

              </Grid>
              <div style={{ paddingTop: 20 }}>
                <Typography>Tanggal dibuat</Typography>

                <Grid container spacing={2} style={{ paddingTop: 10 }}>
                  <Grid item>
                    <div
                      className={classes.filterDiv}
                      style={{
                        maxWidth: 300,
                        paddingRight: 10,
                        paddingLeft: 10,
                        margin: 10,
                      }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        value={selectedDateStart}
                        onChange={handleDateChangeStart}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        ampm={false}
                        value={selectedDateStart}
                        onChange={handleDateChangeStart}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div
                      className={classes.filterDiv}
                      style={{
                        maxWidth: 300,
                        paddingRight: 10,
                        paddingLeft: 10,
                        margin: 10,
                      }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        value={selectedDateEnd}
                        onChange={handleDateChangeEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        ampm={false}
                        value={selectedDateEnd}
                        onChange={handleDateChangeEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              {/* tanggal terbayar */}
              <div style={{ paddingTop: 20 }}>
                <Typography>Tanggal Terbayar</Typography>

                <Grid container spacing={2} style={{ paddingTop: 10 }}>
                  <Grid item>
                    <div
                      className={classes.filterDiv}
                      style={{
                        maxWidth: 300,
                        paddingRight: 10,
                        paddingLeft: 10,
                        margin: 10,
                      }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        value={selectedDatePaidStart}
                        onChange={handleDateChangePaidStart}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        ampm={false}
                        value={selectedDatePaidStart}
                        onChange={handleDateChangePaidStart}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div
                      className={classes.filterDiv}
                      style={{
                        maxWidth: 300,
                        paddingRight: 10,
                        paddingLeft: 10,
                        margin: 10,
                      }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        value={selectedDatePaidEnd}
                        onChange={handleDateChangePaidEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        ampm={false}
                        value={selectedDatePaidEnd}
                        onChange={handleDateChangePaidEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div style={{ paddingTop: 20 }}>
                <Typography>Tanggal Done</Typography>

                <Grid container spacing={2} style={{ paddingTop: 10 }}>
                  <Grid item>
                    <div
                      className={classes.filterDiv}
                      style={{
                        maxWidth: 300,
                        paddingRight: 10,
                        paddingLeft: 10,
                        margin: 10,
                      }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        value={selectedDateDoneStart}
                        onChange={handleDateChangeDoneStart}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        ampm={false}
                        value={selectedDateDoneStart}
                        onChange={handleDateChangeDoneStart}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div
                      className={classes.filterDiv}
                      style={{
                        maxWidth: 300,
                        paddingRight: 10,
                        paddingLeft: 10,
                        margin: 10,
                      }}
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        value={selectedDateDoneEnd}
                        onChange={handleDateChangeDoneEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        ampm={false}
                        value={selectedDateDoneEnd}
                        onChange={handleDateChangeDoneEnd}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div style={{ paddingTop: 20 }}>
                <Typography>Filter Supplier</Typography>
                <div
                  aria-describedby={idSupplier}
                  className={classes.filterDiv}
                  onClick={handleClickSupplier}
                  style={{
                    width: "fit-content",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    src={
                      filter.selectedSupplier &&
                      filter.selectedSupplier.avatar_url
                    }
                  />
                  <Typography style={{ paddingLeft: 10 }}>
                    {filter.selectedSupplier
                      ? filter.selectedSupplier.name
                      : "Filter Supplier"}
                  </Typography>
                </div>
                <Popover
                  id={idSupplier}
                  open={openFilterSupplier}
                  anchorEl={anchorElSupplier}
                  onClose={handleCloseSupplier}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div style={{ padding: 20 }}>
                    <div className={classes.searchSupplier}>
                      <div className={classes.search}>
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                        <InputBase
                          placeholder="Cari Supplier…"
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          onKeyDown={(e) => enterSupplier(e)}
                          inputProps={{ "aria-label": "search_supplier" }}
                          onChange={(e) =>
                            setFilter({ ...filter, search_supplier: e.target.value })
                          }
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCariSupplier}
                      >
                        Cari
                      </Button>
                    </div>

                    <div className={classes.searchSupplier}>
                      {filter.loadingSearch && (
                        <div className={classes.centerLoading}>
                          <CircularProgress />
                        </div>
                      )}
                      {filter.listSupplier && !filter.loadingSearch && (
                        <List dense>
                          {filter.listSupplier.map((res) => (
                            <ListItem
                              key={res.id}
                              style={{ width: 300 }}
                              onClick={() => {
                                setFilter({ ...filter, selectedSupplier: res });
                                handleCloseSupplier();
                              }}
                            >
                              <MenuItem style={{ width: "100%" }}>
                                <ListItemAvatar>
                                  <Avatar src={res.avatar_url} />
                                </ListItemAvatar>
                                <ListItemText primary={res.name} />
                              </MenuItem>
                            </ListItem>
                          ))}
                        </List>
                      )}

                      {filter.listSupplier &&
                        filter.listSupplier.length === 0 && (
                          <Typography variant="body2">
                            Data tidak ditemukan.
                          </Typography>
                        )}
                    </div>
                  </div>
                </Popover>
              </div>

              <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap'}}>
                {/* filter PIC */}
                <div style={{ paddingTop: 20, marginRight: 20 }}>
                  <Typography>Filter PIC</Typography>
                  <Select
                    value={filter.pic_id}
                    onChange={(e) =>
                      setFilter({ ...filter, pic_id: e.target.value })
                    }
                    displayEmpty
                    variant="outlined"
                  >
                    <MenuItem value="">--Pilih PIC--</MenuItem>
                    {dataPic.map((pic) => (
                      <MenuItem key={pic.id} value={pic.id}>
                        {pic.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                {/* filter expedisi */}
                <div style={{ paddingTop: 20 }}>
                  <Typography>Filter Expedisi</Typography>
                  <Select
                    value={filter.courier_id}
                    onChange={(e) =>
                      setFilter({ ...filter, courier_id: e.target.value })
                    }
                    displayEmpty
                    variant="outlined"
                  >
                    <MenuItem value="">--Pilih Expedisi--</MenuItem>
                    {dataCouries.map((courier, index) => (
                      <MenuItem key={index + courier.name} value={courier.id}>
                        {courier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 20,
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    borderRadius: 30,
                    marginRight: 10,
                  }}
                  onClick={handleResetFilter}
                >
                  Reset
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ textTransform: "capitalize", borderRadius: 30 }}
                  onClick={handleGunakanFilter}
                >
                  Gunakan Filter
                </Button>
              </div>
            </div>
          </Fade>
        )}

        {filteredOrder &&
          filteredOrder.map((res, index) => (
            <div key={res.id} style={{ paddingTop: 20 }}>
              <CardOrder
                data={res}
                index={order.meta.total - (order.meta.from + (index - 1))}
                handleUbahPesanan={handleUbahPesanan}
                isLabelChecked={downloadQueue.indexOf(res.id) !== -1}
                onLabelDownload={(value) => {
                  setIsLoading(value);
                }}
                onFinishOrder={() => {
                  handleFinishOrder(res.id)
                }}
                onToggleDownloadQueue={(id) => toggleDownloadQueue(id)}
                isMobile={isMobile}
                setIsRefetch={setIsRefetch}
              />
            </div>
          ))}

        {filteredOrder.length === 0 && (
          <div className={classes.notFound}>
            <div className={classes.notFound2}>
              <Typography variant="h5">
                <b>Oops!</b>
              </Typography>
              <Typography>Data tidak ditemukan</Typography>
            </div>
          </div>
        )}

        {order && order.meta.last_page !== 1 && (
          <div className={classes.containerPagination}>
            <div>
              <Typography>
                {order !== 0 ?
                'Showing ' +
                order.meta.from +
                ' to ' +
                order.meta.to +
                ' of ' +
                order.meta.total +
                ' entries' : ''}
              </Typography>
            </div>

            <Pagination
              count={order.meta.last_page}
              variant="outlined"
              onChange={handleChangePage}
              color="secondary"
            />
          </div>
        )}
      </Paper>
      {downloadQueue.length !== 0 && (
        <Fab
          color="primary"
          className={classes.fab}
          variant="extended"
          onClick={() => downloadMultipleLabel(null)}
          style={{ right: 268 }}
        >
          <GetAppIcon />
          Download Label ({downloadQueue.length})
        </Fab>
      )}
      {filter.forwarded !== 0 && (
      <Fab
        color="primary"
        className={classes.fab}
        variant="extended"
        style={{zIndex: 10, bottom: 140, backgroundColor: '#FDCA40'}}
        onClick={() => prosesAllPesanan(filteredOrder)}
      >
        Proses All Pesanan
      </Fab>
      )}
      <Fab
        color="primary"
        className={classes.fab}
        variant="extended"
        style={{zIndex: 10}}
        onClick={downloadExcel}
      >
        <GetAppIcon />
        Download Excel
      </Fab>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
});

export default Content;
