import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Paper,
  Button,
  InputBase,
  FormControl,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Popover,
  MenuItem,
  Tooltip,
  Popper,
  Avatar,
  Switch,
  Checkbox,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Badge,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  getAllCategories,
  getAllProducts,
  deleteProducts,
  getSupplier,
  editProduct,
} from "../../../services/axios";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import Pagination from "@material-ui/lab/Pagination";
import DetailProduct from "./detail-product";
import TambahProduct from "./tambah-produk";
import EditProduct from "./edit-product";
import swal from "sweetalert";
import Loading from "../../../components/loading";
import numberSeparator from "../../../utils/numberSeparator";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImagePlaceholder from "../../../assets/images/placeholder.jpg";

import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as LihatIcon } from "../../../assets/icons/eye.svg";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  handleDeleteSelectedProduct,
  onCheckedAll,
  onCheckedProduct,
} from "./handler";
import _, { isEmpty } from "lodash";
import DialogNotes from "./dialog-notes";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const Products = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    filterWaktu: "Berdasarkan tanggal",
    openTambahPengeluaran: false,
    category: [],
    suppliers: [],
    supplier: [],
    products: [],
    selectedCategory: "",
    selectedFilterHarga: "",
    openDetailProduct: false,
    selectedProduct: null,
    openTambahProduct: false,
    openOther: false,
    openEditproduct: false,
    dataEdit: null,
    search: "",
    searchSupplier: "",
    supplierSearched: [],
    tabLoading: false,
  });
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState({ name: "" });
  const [selectedFilterHarga, setSelectedFilterHarga] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState({ slug: "" });
  const [filteredProduct, setFilteredProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSupplier, setAnchorElSupplier] = React.useState(null);
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [selectedSingleProduct, setSelectedSingleProduct] = useState(null);
  const [isOpenDialogNotes, setIsOpenDialogNotes] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickSupplier = (event) => {
    setAnchorElSupplier(event.currentTarget);
  };

  const handleCloseSupplier = () => {
    setAnchorElSupplier(null);
  };

  const handleChangeFilterSupplier = async (response) => {
    setAnchorElSupplier(null);
    handleChangeState("tabLoading", true);
    setSelectedSupplier(response);
    const produk = await getAllProducts(
      `?filter[from_newest]=1&filter[supplier.slug]=${response.slug}&include=images`
    );
    let dataproduk = [];
    setState({
      ...state,
      products: produk,
      tabLoading: false,
    });
    produk.data.map((product) => {
      dataproduk.push({
        ...product,
        checked: false,
      });
      return "";
    });
    if (!isEmpty(selectedProduct)) {
      selectedProduct.map((selected) => {
        const findIndexProduct = produk.data.findIndex(
          (x) => x.slug === selected.slug
        );
        if (findIndexProduct >= 0) {
          dataproduk[findIndexProduct] = selected;
        }
        return "";
      });
      setFilteredProduct(dataproduk);
    }
    setFilteredProduct(dataproduk);
  };

  const openSupplier = Boolean(anchorElSupplier);
  const idSupplier = openSupplier ? "simple-popover" : undefined;

  const handleChangeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleSearch = (keyword) => {
    handleChangeState("search", keyword);
  };

  const handleSearchSupplier = (keyword) => {
    handleChangeState("searchSupplier", keyword);
  };

  useEffect(() => {
    const fetch = async () => {
      const kategori = await getAllCategories();
      const produk = await getAllProducts(
        `?filter[from_newest]=1&filter[show_custom]=1&include=images`
      );
      let dataproduk = [];
      const supplier = await getSupplier();
      setState((state) => ({
        ...state,
        category: kategori.data,
        products: produk,
        suppliers: supplier.data,
      }));
      produk.data.map((product) => {
        dataproduk.push({
          ...product,
          checked: false,
        });
        return "";
      });
      setFilteredProduct(dataproduk);
      if (kategori && produk) setLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const kategori = await getAllCategories();
      const produk = await getAllProducts(
        `?page=${page}&filter[from_newest]=1&filter[show_custom]=1&include=images`
      );
      let dataproduk = [];
      const supplier = await getSupplier();
      setState((state) => ({
        ...state,
        category: kategori.data,
        products: produk,
        suppliers: supplier.data,
      }));
      produk.data.map((product) => {
        dataproduk.push({
          ...product,
          checked: false,
        });
        return "";
      });
      setFilteredProduct(dataproduk);
      if (kategori && produk) setLoading(false);
      setIsRefetch(false);
      setLoading(false);
    };
    isRefetch && fetch();
  }, [isRefetch]);

  useEffect(() => {
    if (selectedSingleProduct) {
      setIsOpenDialogNotes(true);
    }
  }, [selectedSingleProduct]);

  useEffect(() => {
    if (!isOpenDialogNotes) {
      setSelectedSingleProduct(null);
    }
  }, [isOpenDialogNotes]);

  const handleSwitchFeautured = async (res) => {
    const response = await editProduct(res.slug, {
      is_featured_by_admin: res.is_featured_by_admin === 1 ? 0 : 1,
    });
    let dataproduk = [];
    if (response.updated) {
      const produk = await getAllProducts(
        `?page=${page}&filter[show_custom]=1&filter[name]=${
          state.search
        }&filter[category.id]=${
          selectedCategory.id || ""
        }&sort=${selectedFilterHarga}&filter[from_newest]=1&filter[supplier.slug]=${
          selectedSupplier.slug
        }&include=images`
      );
      setState({ ...state, products: produk });
      produk.data.map((product) => {
        dataproduk.push({
          ...product,
          checked: false,
        });
        return "";
      });
      if (!isEmpty(selectedProduct)) {
        selectedProduct.map((selected) => {
          const findIndexProduct = produk.data.findIndex(
            (x) => x.slug === selected.slug
          );
          if (findIndexProduct >= 0) {
            dataproduk[findIndexProduct] = {
              ...produk.data[findIndexProduct],
              checked: true,
            };
          }
          return "";
        });
        setFilteredProduct(dataproduk);
      }
      setFilteredProduct(dataproduk);
    }
  };

  const handleSwitchApprove = async (res) => {
    const response = await editProduct(res.slug, {
      is_approved: res.is_approved === 1 ? 0 : 1,
    });
    let dataproduk = [];
    if (response.updated) {
      const produk = await getAllProducts(
        `?page=${page}&filter[show_custom]=1&filter[category.id]=${
          selectedCategory.id || ""
        }&sort=${selectedFilterHarga}&filter[name]=${
          state.search
        }&filter[from_newest]=1&filter[supplier.slug]=${
          selectedSupplier.slug
        }&include=images`
      );
      setState({ ...state, products: produk });
      produk.data.map((product) => {
        dataproduk.push({
          ...product,
          checked: false,
        });
        return "";
      });
      if (!isEmpty(selectedProduct)) {
        selectedProduct.map((selected) => {
          const findIndexProduct = produk.data.findIndex(
            (x) => x.slug === selected.slug
          );
          if (findIndexProduct >= 0) {
            dataproduk[findIndexProduct] = {
              ...produk.data[findIndexProduct],
              checked: true,
            };
          }
          return "";
        });
        setFilteredProduct(dataproduk);
      }
      setFilteredProduct(dataproduk);
    }
  };

  const handleBackEdit = async () => {
    const produk = await getAllProducts(
      `?page=${page}&filter[show_custom]=1&filter[from_newest]=1&filter[supplier.slug]=${selectedSupplier.slug}&include=images`
    );
    let dataproduk = [];
    setState({ ...state, openEditproduct: false });
    produk.data.map((product) => {
      dataproduk.push({
        ...product,
        checked: false,
      });
      return "";
    });

    if (!isEmpty(selectedProduct)) {
      selectedProduct.map((selected) => {
        const findIndexProduct = produk.data.findIndex(
          (x) => x.slug === selected.slug
        );
        if (findIndexProduct >= 0) {
          dataproduk[findIndexProduct] = {
            ...produk.data[findIndexProduct],
            checked: true,
          };
        }
        return "";
      });
      setFilteredProduct(dataproduk);
    }
    setFilteredProduct(dataproduk);
  };

  useEffect(() => {
    setState((state) => ({ ...state, tabLoading: true }));
    const fetch = async () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const produk = await getAllProducts(
        `?page=${page}&filter[show_custom]=1&filter[category.id]=${
          selectedCategory.id || ""
        }&sort=${selectedFilterHarga}&filter[from_newest]=1&filter[supplier.slug]=${
          selectedSupplier.slug
        }&include=images`
      );
      let dataproduk = [];
      setState((state) => ({ ...state, products: produk, tabLoading: false }));
      produk.data.map((product) => {
        dataproduk.push({
          ...product,
          checked: false,
        });
        return "";
      });
      if (!isEmpty(selectedProduct)) {
        selectedProduct.map((selected) => {
          const findIndexProduct = produk.data.findIndex(
            (x) => x.slug === selected.slug
          );
          if (findIndexProduct >= 0) {
            dataproduk[findIndexProduct] = {
              ...produk.data[findIndexProduct],
              checked: true,
            };
          }
          return "";
        });
        setFilteredProduct(dataproduk);
      }
      setFilteredProduct(dataproduk);
      setIsCheckedAll(false);
    };
    fetch();
  }, [page, selectedCategory, selectedFilterHarga, selectedSupplier.slug]);

  const handleDetailProduct = (data) => {
    setState({ ...state, openDetailProduct: true, selectedProduct: data });
  };

  const handleEditproduct = (data) => {
    setState({ ...state, openEditproduct: true, dataEdit: data });
  };

  const handleDeleteProduct = (slug) => {
    swal({
      title: "Are you sure?",
      text: "Produk akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProducts(slug);
        swal("Produk dihapus!", {
          icon: "success",
        }).then(() => window.location.reload());
      } else {
        swal("Produk batal dihapus");
      }
    });
  };

  function createData(
    checkbox,
    no,
    produk,
    supplier,
    inventori,
    harga_jual,
    kategori,
    joined_at,
    approve,
    feautured,
    kelola
  ) {
    return {
      checkbox,
      no,
      produk,
      supplier,
      inventori,
      harga_jual,
      kategori,
      joined_at,
      approve,
      feautured,
      kelola,
    };
  }

  const Kelola = ({ product }) => {
    return (
      <div style={{ width: 150 }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton onClick={() => handleEditproduct(product)}>
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteProduct(product.slug)}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Lihat Detail" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10, height: 35 }}
            onClick={() => handleDetailProduct(product)}
          >
            <LihatIcon />
          </StyledButton>
        </Tooltip>
        {/* <StyledButton style={{ marginLeft: 10 }}>
          <TokoIcon />
        </StyledButton>
        <StyledButton style={{ marginLeft: 10 }}>
          <ListIcon />
        </StyledButton> */}
      </div>
    );
  };

  const Produk = ({ image, name, totalVarian }) => {
    return (
      <div style={{ display: "flex" }}>
        <img
          src={image}
          alt=""
          height={60}
          onError={(e) => (e.target.src = ImagePlaceholder)}
          width={60}
          style={{ borderRadius: 5, objectFit: "cover" }}
        />
        <div style={{ paddingLeft: 10 }}>
          <Typography
            variant="body2"
            style={{
              textAlign: "start",
              width: 200,
              overflow: "hidden",
              display: "-webkit-box",
              boxOrient: "vertical",
              lineClamp: 2,
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            style={{ paddingTop: 15, textAlign: "start" }}
          >
            {totalVarian} Varian
          </Typography>
        </div>
      </div>
    );
  };

  const getStock = (data) => {
    if (!data) return;
    let returnData = 0;
    data.forEach((res) => {
      returnData = returnData + res.stock;
    });
    return returnData;
  };

  const dataRows = () => {
    let row = [];
    filteredProduct.forEach((res, index) => {
      row = [
        ...row,
        createData(
          <Checkbox
            onChange={(e) =>
              onCheckedProduct(
                e.target.checked,
                res,
                filteredProduct,
                setFilteredProduct,
                selectedProduct,
                setSelectedProduct
              )
            }
            checked={res.checked}
          />,
          index + 1,
          <Produk
            image={res.image_url}
            name={res.name}
            totalVarian={res?.skus?.length || 1}
          />,
          res.supplier.name,
          getStock(res.skus),
          res?.skus ? res.skus[0] ? res.skus[0].price.reseller : '' : "????",
          res.category.name,
          res.created_at,
          <>
            <Switch
              key={`approve-${index}`}
              checked={res.is_approved === 1}
              onChange={() => handleSwitchApprove(res)}
              name={res.name}
              color="primary"
            />
            {!res.is_approved && (
              <>
                {res.declined_note ? (
                  <Badge
                    color="secondary"
                    variant="dot"
                    classes={{ badge: classes.badge }}
                  >
                    <Button
                      onClick={() => setSelectedSingleProduct(res)}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Catatan
                    </Button>
                  </Badge>
                ) : (
                  <Button
                    onClick={() => setSelectedSingleProduct(res)}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Catatan
                  </Button>
                )}
              </>
            )}
          </>,
          <Switch
            checked={res.is_featured_by_admin === 1}
            onChange={() => handleSwitchFeautured(res)}
            name={res.name}
            color="primary"
          />,
          <Kelola product={res} />
        ),
      ];
    });
    return row;
  };

  const handlePressSearch = async (e) => {
    let dataproduk = [];
    if (e.key === "Enter") {
      handleChangeState("tabLoading", true);
      const fetch = async () => {
        const produk = await getAllProducts(
          `?filter[name]=${state.search}&filter[from_newest]=1&include=images`
        );
        setState({ ...state, products: produk, tabLoading: false });
        produk.data.map((product) => {
          dataproduk.push({
            ...product,
            checked: false,
          });
          return "";
        });
        if (!isEmpty(selectedProduct)) {
          selectedProduct.map((selected) => {
            const findIndexProduct = produk.data.findIndex(
              (x) => x.slug === selected.slug
            );
            if (findIndexProduct >= 0) {
              dataproduk[findIndexProduct] = selected;
            }
            return "";
          });
          setFilteredProduct(dataproduk);
        }
        setFilteredProduct(dataproduk);
      };
      fetch();
    }
  };

  const handlePressSearchBtn = async (e) => {
    handleChangeState("tabLoading", true);
    let dataproduk = [];
    const fetch = async () => {
      const produk = await getAllProducts(
        `?filter[name]=${state.search}&filter[from_newest]=1&include=images`
      );
      setState({ ...state, products: produk, tabLoading: false });
      produk.data.map((product) => {
        dataproduk.push({
          ...product,
          checked: false,
        });
        return "";
      });
      if (!isEmpty(selectedProduct)) {
        selectedProduct.map((selected) => {
          const findIndexProduct = produk.data.findIndex(
            (x) => x.slug === selected.slug
          );
          if (findIndexProduct >= 0) {
            dataproduk[findIndexProduct] = selected;
          }
          return "";
        });
        setFilteredProduct(dataproduk);
      }
      setFilteredProduct(dataproduk);
    };
    fetch();
  };

  const handlePressSearchSupplier = async (e) => {
    if (e.key === "Enter") {
      const fetch = async () => {
        const response = await getSupplier(
          `?filter[name]=${state.searchSupplier}`
        );
        setState({ ...state, supplierSearched: response.data });
      };
      fetch();
    }
  };

  const handleClickSearch = async () => {
    const produk = await getAllProducts(
      `?filter[name]=${state.search}&filter[from_newest]=1&filter[supplier.slug]=${selectedSupplier.slug}&include=images`
    );
    let dataproduk = [];
    setState({ ...state, products: produk });
    produk.data.map((product) => {
      dataproduk.push({
        ...product,
        checked: false,
      });
      return "";
    });
    if (!isEmpty(selectedProduct)) {
      selectedProduct.map((selected) => {
        const findIndexProduct = produk.data.findIndex(
          (x) => x.slug === selected.slug
        );
        if (findIndexProduct >= 0) {
          dataproduk[findIndexProduct] = selected;
        }
        return "";
      });
      setFilteredProduct(dataproduk);
    }
    setFilteredProduct(dataproduk);
  };

  const handleClickSearchSupplier = async () => {
    const response = await getSupplier(`filter[name]=${state.searchSupplier}`);
    setState({ ...state, supplierSearched: response.data });
  };

  const refreshPage = async () => {
    let dataproduk = [];
    handleCloseSupplier();
    setSelectedSupplier({ slug: "" });
    handleChangeState("tabLoading", true);
    const produk = await getAllProducts(
      `?filter[from_newest]=1&filter[show_custom]=1&include=images`
    );
    setState({ ...state, products: produk, tabLoading: false });
    produk.data.map((product) => {
      dataproduk.push({
        ...product,
        checked: false,
      });
      return "";
    });
    if (!isEmpty(selectedProduct)) {
      selectedProduct.map((selected) => {
        const findIndexProduct = produk.data.findIndex(
          (x) => x.slug === selected.slug
        );
        if (findIndexProduct >= 0) {
          dataproduk[findIndexProduct] = selected;
        }
        return "";
      });
      setFilteredProduct(dataproduk);
    }
    setFilteredProduct(dataproduk);
  };

  const handleCloseDialog = () => {
    setIsOpenDialogDelete(!isOpenDialogDelete);
  };

  const DialogDelete = () => (
    <Dialog
      open={isOpenDialogDelete}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Apakah kamu yakin ingin menghapus ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Data yang dihapus tidak dapat dipulihkan!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Tidak
        </Button>
        <Button
          onClick={() =>
            handleDeleteSelectedProduct(
              selectedProduct,
              setSelectedProduct,
              setFilteredProduct,
              isOpenDialogDelete,
              setIsOpenDialogDelete,
              setLoading,
              setIsRefetch
            )
          }
          color="primary"
          autoFocus
        >
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          {state.openDetailProduct ? (
            <DetailProduct
              data={state.selectedProduct}
              handleBack={() => handleChangeState("openDetailProduct", false)}
            />
          ) : state.openTambahProduct ? (
            <TambahProduct
              category={state.category}
              suppliers={state.suppliers}
              handleChangeStateParent={handleChangeState}
              onClose={() => handleChangeState("openTambahProduct", false)}
              reFetch={() => setIsRefetch(true)}
            />
          ) : state.openEditproduct ? (
            <EditProduct
              dataApi={state.dataEdit}
              category={state.category}
              suppliers={state.suppliers}
              handleBack={handleBackEdit}
              onClose={() => handleChangeState("openEditproduct", false)}
              reFetch={() => setIsRefetch(true)}
            />
          ) : (
            <Fragment>
              <div className={classes.header}>
                <Typography variant="h6">
                  <b>Produk</b>
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.tambah}
                    style={{ marginRight: 20 }}
                    onClick={() => handleChangeState("openTambahProduct", true)}
                  >
                    + Tambah Produk
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.other}
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    ...
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem>
                      <SystemUpdateAltIcon />{" "}
                      <Typography style={{ paddingLeft: 10 }}>
                        Download Excel
                      </Typography>
                    </MenuItem>
                  </Popover>
                </div>
              </div>

              <Paper style={{ marginTop: 20 }}>
                <Grid container spacing={2} style={{ padding: 20 }}>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <div className={classes.search}>
                          <div className={classes.searchIcon}>
                            <SearchIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleClickSearch}
                            />
                          </div>
                          <InputBase
                            placeholder="Search…"
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                            onChange={(event) =>
                              handleSearch(event.target.value)
                            }
                            onKeyPress={handlePressSearch}
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.buttonCari}
                          onClick={handlePressSearchBtn}
                        >
                          Cari
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <FormControl
                      className={classes.formControl}
                      style={{ marginLeft: 20 }}
                    >
                      <Select
                        className={classes.select}
                        value={selectedCategory.name}
                        disableUnderline
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <em>Kategori</em>
                        </MenuItem>
                        {state.category.map((res, index) => (
                          <MenuItem
                            key={index}
                            value={res.name}
                            onClick={(event) => setSelectedCategory(res)}
                          >
                            {res.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <Select
                        className={classes.select}
                        value={selectedFilterHarga}
                        disableUnderline
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <em>Urutkan</em>
                        </MenuItem>
                        <MenuItem
                          value="-ref_price"
                          onClick={() => setSelectedFilterHarga("-ref_price")}
                        >
                          Termahal
                        </MenuItem>
                        <MenuItem
                          value="ref_price"
                          onClick={() => setSelectedFilterHarga("ref_price")}
                        >
                          Termurah
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl className={classes.formControlSupplier}>
                      <div
                        className={classes.select}
                        onClick={handleClickSupplier}
                      >
                        <Typography>
                          {selectedSupplier.slug !== ""
                            ? selectedSupplier.name
                            : "Seluruh Produk"}
                        </Typography>
                      </div>
                      <Popper
                        id={idSupplier}
                        open={openSupplier}
                        anchorEl={anchorElSupplier}
                        disablePortal={false}
                        modifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                          },
                        }}
                      >
                        <Paper
                          style={{ padding: 20 }}
                          // onClick={handleCloseSupplier}
                        >
                          <div style={{ display: "flex" }}>
                            <div className={classes.search}>
                              <div className={classes.searchIcon}>
                                <SearchIcon style={{ cursor: "pointer" }} />
                              </div>
                              <InputBase
                                placeholder="Search…"
                                classes={{
                                  root: classes.inputRoot,
                                  input: classes.inputInput,
                                }}
                                inputProps={{ "aria-label": "search" }}
                                onChange={(event) =>
                                  handleSearchSupplier(event.target.value)
                                }
                                onKeyPress={handlePressSearchSupplier}
                              />
                            </div>
                            <Button
                              onClick={handleClickSearchSupplier}
                              variant="contained"
                              color="secondary"
                              className={classes.Cari}
                            >
                              Cari
                            </Button>
                          </div>
                          <div style={{ paddingTop: 10 }}>
                            <MenuItem onClick={refreshPage}>
                              Seluruh Produk
                            </MenuItem>
                            {state.supplierSearched.map((res, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => handleChangeFilterSupplier(res)}
                              >
                                <Avatar src={res.avatar_url} />
                                <Typography style={{ paddingLeft: 10 }}>
                                  {res.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </div>
                        </Paper>
                      </Popper>
                    </FormControl>
                  </Grid>
                </Grid>

                {!state.tabLoading && (
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            <Checkbox
                              onChange={(e) =>
                                onCheckedAll(
                                  e.target.checked,
                                  isCheckedAll,
                                  setIsCheckedAll,
                                  filteredProduct,
                                  setFilteredProduct,
                                  selectedProduct,
                                  setSelectedProduct
                                )
                              }
                              checked={isCheckedAll}
                            />
                          </StyledTableCell>
                          <StyledTableCell>No</StyledTableCell>
                          <StyledTableCell align="center">
                            Produk
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Supplier
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Inventori
                          </StyledTableCell>
                          <StyledTableCell align="center" width={200}>
                            Harga Jual
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Kategori
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Tanggal Upload
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Approve
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Featured
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Kelola
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.products.length !== 0 &&
                          dataRows().map((row) => (
                            <StyledTableRow key={row.name}>
                              <StyledTableCell component="th" scope="row">
                                {row.checkbox}
                              </StyledTableCell>
                              <StyledTableCell>{row.no}</StyledTableCell>
                              <StyledTableCell align="center">
                                {row.produk}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.supplier}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.inventori}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Rp. {numberSeparator(row.harga_jual)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.kategori}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {moment(row.joined_at).format("DD MMMM YYYY")}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.approve}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.feautured}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.kelola}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {filteredProduct.length === 0 && (
                  <div className={classes.notFound}>
                    <div className={classes.notFoundChild}>
                      <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        Oops!
                      </Typography>
                      <Typography>Data tidak ditemukan.</Typography>
                    </div>
                  </div>
                )}

                {state.tabLoading && (
                  <div className={classes.tabLoading}>
                    <CircularProgress />
                  </div>
                )}

                {!state.tabLoading && (
                  <div className={classes.pagination}>
                    <Pagination
                      count={state.products.meta.last_page}
                      color="primary"
                      page={page}
                      onChange={(event, value) => setPage(value)}
                    />
                  </div>
                )}
              </Paper>
            </Fragment>
          )}
        </Fragment>
      )}
      <div className={classes.floatingButton}>
        {!isEmpty(selectedProduct) && (
          <Fab
            color="secondary"
            aria-label="delete"
            variant="extended"
            onClick={() => setIsOpenDialogDelete(!isOpenDialogDelete)}
          >
            <DeleteIcon /> Delete ({selectedProduct.length})
          </Fab>
        )}
      </div>
      {DialogDelete()}
      <DialogNotes
        selectedSingleProduct={selectedSingleProduct}
        isOpen={isOpenDialogNotes}
        handleClose={() => setIsOpenDialogNotes(false)}
        handleRefetch={() => setIsRefetch(true)}
      />
    </Fragment>
  );
});

export default Products;
