import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, Select } from "@material-ui/core";
import { UPDATE_MARKETPLACE_FORM } from "services/marketplace.service";

export default function StatusMenu({ data, onUpdate }) {
  const { id, processed } = data;

  const handleChange = (event) => {
    const formData = {
      processed: event.target.value === "processed",
    };

    UPDATE_MARKETPLACE_FORM(id, formData);

    data.processed = formData.processed;
    onUpdate(data);
  };

  return (
    <FormControl variant="outlined">
      <Select
        style={{ width: 200 }}
        value={processed ? "processed" : "not_processed"}
        onChange={handleChange}
      >
        <MenuItem value="not_processed">Belum diproses</MenuItem>
        <MenuItem value="processed">Sudah diproses</MenuItem>
      </Select>
    </FormControl>
  );
}
