import React from "react";
import style from "./FormStyle";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  Switch,
  Select,
  MenuItem,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import swal from "sweetalert";
import {
  getECourseCategories,
  addECourse,
  editECourse,
} from "../../../services/axios";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Form = withStyles(style)(
  ({ classes, setActiveState, selectedProduct, activeState }) => {
    const [product, setProduct] = React.useState({
      title: "",
      content: EditorState.createEmpty(),
      category_id: "",
      course_type: 1,
      image: "",
      is_draft: true,
    });
    const [categories, setCategories] = React.useState([]);
    const [deletedImages, setDeletedImages] = React.useState([]);

    const getCategories = async () => {
      const res = await getECourseCategories();

      if (res.data) {
        setCategories(res.data);
      }
    };

    React.useEffect(() => {
      getCategories();
    }, []);

    React.useEffect(() => {
      if (activeState === "edit" && selectedProduct) {
        const blocksFromHtml = htmlToDraft(selectedProduct.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        
        setProduct({
          title: selectedProduct.title,
          content: editorState,
          category_id: selectedProduct.category.id,
          course_type: selectedProduct.course_type,
          image: selectedProduct.image.url,
          is_draft: selectedProduct.is_draft,
        });
      }
    }, []);

    const handleProductState = (key, value) => {
      setProduct((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };

    const handleEditorStateChange = (value) => {
      setProduct((prevState) => ({
        ...prevState,
        content: value,
      }));
    };

    const handleSubmit = async () => {
      const { title, content, image, is_draft, course_type, category_id } = product;

      if (title === "") {
        swal("Oops!", "Silahkan input Judul.", "warning");
      } else if (content === "") {
        swal("Oops!", "Silahkan isi konten.", "warning");
      } else if (category_id === "") {
        swal("Oops!", "Silahkan pilih kategori.", "warning");
      } else if (image === "") {
        swal("Oops!", "Silahkan masukan gambar", "warning");
      }
      else if (course_type === "") {
        swal("Oops!", "Silahkan masukan pengguna", "warning");
      }

      const formData = new FormData();

      formData.append("title", product.title);
      formData.append(
        "content",
        draftToHtml(convertToRaw(product.content.getCurrentContent()))
      );
      formData.append("category_id", product.category_id);
      formData.append("course_type", product.course_type);
      formData.append("is_draft", product.is_draft ? "1" : "0");
      if(activeState === "edit"){
        formData.append("_method", "put");
      }
      console.log(typeof image);
      if (typeof image != 'string'){
        formData.append("image", product.image);
      }
      //   if (activeState === "edit") {
      //     formData.append("_method", "put");
      //     product.images.forEach((image) => {
      //       if (image instanceof File) {
      //         formData.append("new_images[]", image);
      //       } else if (image.url instanceof File) {
      //         formData.append(`update_images[${image.id}]`, image.url);
      //       }
      //     });
      //     if (deletedImages.length > 0) {
      //       deletedImages.forEach((image) => {
      //         formData.append("remove_images", image);
      //       });
      //     }
      //   } else {
      //     product.images.forEach((image) => {
      //       formData.append("images[]", image);
      //     });
      //   }

      const response =
        activeState === "add"
          ? await addECourse(formData)
          : await editECourse(formData, selectedProduct.slug);

      if (response.data) {
        swal("Success", "Produk berhasil ditambahkan", "success").then(() => {
          setActiveState("list");
        });
      } else {
        swal("Success", "Produk gagal ditambahkan", "warning");
      }
    };

    const handleChangeImage = async (event, index) => {
      const validImage = ["image/jpeg", "image/png"];
      if (event.target.files && event.target.files.length > 0) {
        const newProduct = { ...product };

        newProduct.image = event.target.files[0];

        setProduct(newProduct);
      } else alert("Gambar yang di upload tidak valid");
    };

    const renderCategories = () => {
      const items = [];

      categories.forEach((category) => {
        items.push(
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        );
      });

      return items;
    };

    return (
      <div>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.flexCenter}>
            <ArrowBackIcon
              style={{ paddingRight: 10, cursor: "pointer" }}
              onClick={() => setActiveState("list")}
            />
            <b>{activeState === "edit" ? "Edit" : "Tambah"} E-Course</b>
          </Typography>
        </div>
        <Paper elevation={2} style={{ padding: 30 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3} className="label">
              Draft
            </Grid>
            <Grid item xs={9}>
              <Switch
                checked={product.is_draft}
                onChange={(e) =>
                  setProduct((prevState) => ({
                    ...prevState,
                    is_draft: e.target.checked,
                  }))
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Judul
            </Grid>
            <Grid item xs={9}>
              <TextField
                placeholder="Masukan Judul"
                InputProps={{
                  disableUnderline: true,
                }}
                value={product.title}
                fullWidth={true}
                className={classes.inputStyle}
                onChange={(event) =>
                  handleProductState("title", event.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Kategori
            </Grid>
            <Grid item xs={9}>
              <Select
                value={product.category_id}
                onChange={(e) =>
                  e.target.value &&
                  handleProductState("category_id", e.target.value)
                }
                variant="outlined"
                fullWidth
              >
                <MenuItem value="" disabled>
                  Pilih kategori
                </MenuItem>
                {renderCategories()}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Bagikan Kepada
            </Grid>
            <Grid item xs={9}>
              <Select
                value={product.course_type}
                onChange={(e) =>
                  e.target.value &&
                  handleProductState("course_type", e.target.value)
                }
                variant="outlined"
                fullWidth
              >
                <MenuItem value="" disabled>
                  Pilih Pengguna
                </MenuItem>
                <MenuItem value="1" >
                  Seller
                </MenuItem>
                <MenuItem value="2" >
                  Superseller
                </MenuItem>
                <MenuItem value="3" >
                  Superseller Gold
                </MenuItem>
                <MenuItem value="4" >
                  Superseller & Superseller Gold
                </MenuItem>
                <MenuItem value="5" >
                  Supplier
                </MenuItem>
                <MenuItem value="6" >
                  Semua Pengguna
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} className="label">
              Konten
            </Grid>
            <Grid item xs={12}>
              <Editor
                editorState={product.content}
                wrapperClassName="demo-wrapper"
                editorClassName={classes.editor}
                onEditorStateChange={handleEditorStateChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="label">
              Gambar Produk
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: 20 }}
            xs={9}
          >
            {activeState === "add" ? (
              <Grid item>
                <div
                  className={classes.tambahGambar}
                  style={{
                    backgroundImage: `url(${
                      product.image !== "" && URL.createObjectURL(product.image)
                    })`,
                  }}
                >
                  {product.image === "" && (
                    <>
                      <Typography className={classes.tambahGambar1}>
                        +
                      </Typography>
                    </>
                  )}
                  <input
                    type="file"
                    className={classes.inputGambar}
                    onChange={(event) => handleChangeImage(event)}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item>
                <div
                  className={classes.tambahGambar}
                  style={{
                    backgroundImage: `url(${
                      product.image
                        ? product.image instanceof File
                          ? URL.createObjectURL(product.image)
                          : product.image
                        : product.image !== "" &&
                          URL.createObjectURL(product.image)
                    })`,
                  }}
                >
                  {product.image === "" && (
                    <>
                      <Typography className={classes.tambahGambar1}>
                        +
                      </Typography>
                    </>
                  )}
                  <input
                    type="file"
                    className={classes.inputGambar}
                    onChange={(event) => handleChangeImage(event)}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            justify="flex-end"
            style={{ marginTop: 20 }}
          >
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Simpan
            </Button>
          </Grid>
        </Paper>
      </div>
    );
  }
);

export default Form;
