import { Button, makeStyles, Typography } from '@material-ui/core'
import { AddIcon } from '@material-ui/data-grid';
import React from 'react'
import { Link } from 'react-router-dom';
import Table from './Table/Table';

const useStyles = makeStyles({
  title: {
    marginBottom: '30px',
  },
  button: {
    marginBottom: '30px',
  }
});

const PIC = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        Data PIC
      </Typography>

      <Link to="/data-pic/create">
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddIcon />}
        >
          Tambah PIC
        </Button>
      </Link>

      <Table />
    </div>
  )
}

export default PIC
