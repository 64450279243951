import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography, Paper, Grid, Button } from "@material-ui/core";
import photoprofile from "../../../../assets/images/cute-cat.jpg";
import verticaldot from "../../../../assets/icons/vertical-dot.svg";
import house from "../../../../assets/icons/house-vector.svg";

import { ReactComponent as WhatsappIcon } from "../../../../assets/icons/whatsapp-round.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/email-round.svg";

const DetailInfo = withStyles(style)((props) => {
  const { classes, handleEditInfo } = props;
  const data = JSON.parse(localStorage.getItem("DATA"));
  console.log(data);
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.left}>
          <img src={photoprofile} className={classes.photoprofile} alt="" />
          <div style={{ paddingLeft: 20 }}>
            <Typography className={classes.username}>Victor Wick</Typography>
            <Typography variant="caption" className={classes.titlename}>
              SuperAdmin
            </Typography>
          </div>
          <img src={verticaldot} className={classes.verticaldot} alt="" />
        </div>
        <div className={classes.right}>
          <img src={house} className={classes.house} alt="" />
        </div>
      </div>

      <Paper style={{ padding: 30, marginTop: 30 }}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <img
              src={data.avatar_url}
              alt=""
              height={150}
              width={150}
              className={classes.storeImg}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              <b>{data.name}</b>
            </Typography>
            {/* <Typography variant="caption">
              Last Login : Sabtu, 22 Juni 2020
            </Typography> */}

            <Typography style={{ paddingTop: 10 }}>
              {data.location.address + ", " + data.location.city.name + ", " + data.location.province.name + " " + data.location.city.postal_code}
            </Typography>
            <div
              className={classes.flexCenterVertical}
              style={{ paddingTop: 10 }}
            >
              <div className={classes.flexCenterVertical}>
                <WhatsappIcon />
                <Typography style={{ paddingLeft: 10 }}>
                  {data.phone}
                </Typography>
              </div>
              <div
                className={classes.flexCenterVertical}
                style={{ paddingLeft: 30 }}
              >
                <EmailIcon />
                <Typography style={{ paddingLeft: 10 }}>
                  {data.email}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={handleEditInfo} className={classes.buttonEdit}>
              Edit Info
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
});

export default DetailInfo;
