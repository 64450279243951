import React, { Fragment, useEffect, useState } from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Avatar,
  useMediaQuery,
  Dialog,
  Badge,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import {
  getChartDaily,
  getReportSummary,
  getResellerStatistic,
} from "../../services/axios";
import ResellerSummary from "components/statistic/reseller-summary";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import numberSeparator from "utils/numberSeparator";
import { DateRangePicker, DateRange } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  GET_REPORTS_SUMMARY_FILTER,
  GET_CHART_DAILY_FILTER,
  GET_CHART_RESELLER_FILTER,
} from "services/reports.service";
import { formatRupiah } from "../../utils/currencyFormat";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Doughnut } from "react-chartjs-2";

const Analytic = withStyles(style)((props) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [reportSummary, setReportSummary] = useState({});
  const [chartDaily, setChartDaily] = useState({});
  const [resellerStatistic, setResellerStatistic] = useState({});
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);
  const [isLoading, setisLoading] = useState(false)

  const onApplyFilter = async () => {
    setisLoading(true)
    setIsOpenDate(!isOpenDate);
    await GET_REPORTS_SUMMARY_FILTER(
      moment(selectionRange[0].startDate).format("YYYY-MM-DD"),
      moment(selectionRange[0].endDate).format("YYYY-MM-DD")
    ).then((data) => {
      setReportSummary(data);
    });

    await GET_CHART_DAILY_FILTER(
      moment(selectionRange[0].startDate).format("YYYY-MM-DD"),
      moment(selectionRange[0].endDate).format("YYYY-MM-DD")
    ).then((data) => {
      setChartDaily(data);
    });

    await GET_CHART_RESELLER_FILTER(
      moment(selectionRange[0].startDate).format("YYYY-MM-DD"),
      moment(selectionRange[0].endDate).format("YYYY-MM-DD")
    ).then((data) => {
      setResellerStatistic(data);
    });

    setisLoading(false)
  };
  const { classes } = props;

  useEffect(() => {
    const fetch = async () => {
      try {
        setisLoading(true)

        const report = await getReportSummary(moment().format("YYYY-MM-DD"));
        setReportSummary(report);
        const reportDaily = await getChartDaily();
        setChartDaily(reportDaily);
        const resellerData = await getResellerStatistic();
        setResellerStatistic(resellerData);

        setisLoading(false)
      } catch (error) {
        setisLoading(false)
      }
    };
    fetch();
  }, []);
  

  const data = {
    labels:
      chartDaily.incomes &&
      Object.keys(chartDaily.incomes && chartDaily.incomes),
    datasets: [
      {
        label: "Pemasukan",
        fill: false,
        // lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        // pointBorderWidth: 1,
        // pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        // pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartDaily.incomes && Object.values(chartDaily.incomes),
      },
    ],
  };

  const resellerChart = {
    labels:
      resellerStatistic.reseller_signup &&
      Object.keys(resellerStatistic.reseller_signup),
    datasets: [
      {
        ...data.datasets[0],
        label: "Reseller baru",
        data:
          resellerStatistic.reseller_signup &&
          Object.values(resellerStatistic.reseller_signup),
      },
      {
        ...data.datasets[0],
        borderColor: "#4caf50",
        pointBorderColor: "#4caf50",
        pointBackgroundColor: "#4caf50",
        label: "Seller Baru",
        data:
          resellerStatistic.seller_signup &&
          Object.values(resellerStatistic.seller_signup),
      },
      {
        ...data.datasets[0],
        borderColor: "#FDCA40",
        pointBorderColor: "#FDCA40",
        pointBackgroundColor: "#FDCA40",
        label: "Superseller Baru",
        data:
          resellerStatistic.reseller_premium_upgrade &&
          Object.values(resellerStatistic.reseller_premium_upgrade),
      },
    ],
  };
  const options = {
    legend: {
      display: true,
    },
  };

  const renderGender = () => {
    const data = {
      labels: ["Laki-Laki", "Perempuan"],
      datasets: [
        {
          data: [
            reportSummary.gender_analytic.female.count,
            reportSummary.gender_analytic.male.count,
          ],
          backgroundColor: ["#FF6564", "#3DDC97"],
          hoverBackgroundColor: ["#FF6564", "#3DDC97"],
        },
      ],
    };

    const options = {
      legend: {
        display: false,
      },
    };

    return (
      <>
        {(reportSummary.gender_analytic.female.count !== 0 ||
          reportSummary.gender_analytic.male.count !== 0) && (
          <Doughnut data={data} options={options} height={80} width="100%" />
        )}
        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              Laki-Laki : {reportSummary.gender_analytic.male.percentage}%
            </Grid>
            <Grid item xs={12}>
              Perempuan : {reportSummary.gender_analytic.female.percentage}%
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  console.log(isLoading)

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={60} />
      </Box>
    )
  } else {
    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item sm={12} style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.title}>
              <b>Analisis</b>
            </div>
            <div>
              <Button
                className={classes.filter}
                onClick={() => setIsOpenDate(!isOpenDate)}
              >
                <Typography>Filter</Typography>
                <ArrowDropDownIcon />
              </Button>
            </div>
          </Grid>
          <Grid item sm={12}>
            {isOpenDate && !mdDown && (
              <div className={classes.calendar}>
                <DateRangePicker
                  onChange={(item) => setSelectionRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectionRange}
                  direction="vertical"
                />
                <Button
                  className={classes.filterApply}
                  onClick={() => onApplyFilter()}
                >
                  <Typography>Apply</Typography>
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
        <ResellerSummary report={reportSummary} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Grafik Penjualan</b>
            </Typography>
  
            <Paper style={{ padding: 30, marginTop: 20 }}>
              <Line data={data} options={options} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Grafik Reseller</b>
            </Typography>
            <Paper style={{ padding: 30, marginTop: 20 }}>
              <Line data={resellerChart} options={options} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <b>Best Reseller by nominal</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nama Reseller</TableCell>
                      <TableCell align="center">Total Omzet</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.best_resellers_by_nominal &&
                      reportSummary.best_resellers_by_nominal.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">
                            {numberSeparator(
                              row.total_omzet ? row.total_omzet : 0
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Typography>
              <b>Best Reseller by Omzet</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nama Reseller</TableCell>
                      {/* <TableCell align="center">Rating</TableCell> */}
                      <TableCell align="center">Total Penjualan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.best_resellers_all_time &&
                      reportSummary.best_resellers_all_time.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{row.name}</TableCell>
                          {/* <TableCell align="center">{row.rating}</TableCell> */}
                          <TableCell align="center">
                          Rp{numberSeparator(
                              row.total_omzet ? row.total_omzet : 0
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Typography>
              <b>Best Reseller by order count</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nama Reseller</TableCell>
                      {/* <TableCell align="center">Rating</TableCell> */}
                      <TableCell align="center">Total Penjualan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.best_resellers &&
                      reportSummary.best_resellers.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{row.name}</TableCell>
                          {/* <TableCell align="center">{row.rating}</TableCell> */}
                          <TableCell align="center">
                            {row.total_transaction}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <b>Best PIC</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nama PIC</TableCell>
                      {/* <TableCell align="center">Rating</TableCell> */}
                      <TableCell align="center">Total Order</TableCell>
                      <TableCell align="center">Total Pendapatan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.best_pic &&
                      reportSummary.best_pic.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell align="left">
                            <b>{row.name}</b> ({row.phone})
                          </TableCell>
                          {/* <TableCell align="center">{row.rating}</TableCell> */}
                          <TableCell align="center">{row.total_order}</TableCell>
                          <TableCell align="center">
                            Rp{" "}
                            {numberSeparator(
                              row.total_income ? row.total_income : 0
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
  
          <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Best Location</b>
            </Typography>
            <div>
              <Paper style={{ marginTop: 20 }}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Nama Kota</TableCell>
                        {/* <TableCell align="center">Rating</TableCell> */}
                        <TableCell align="center">
                          Total persentase Terjual
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportSummary.best_order_locations &&
                        reportSummary.best_order_locations.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell align="center">
                              <div style={{ display: "flex" }}>
                                <Typography variant="body2">
                                  <b>{row.name}</b>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className={classes.flexEnd}
                                  style={{ paddingLeft: 5 }}
                                >
                                  {row.order_count} Penjualan
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              <div className={classes.bgPercent}>
                                <div
                                  style={{
                                    width: `${row.order_count}%`,
                                  }}
                                  className={classes.percent}
                                />
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body2">
                                {row.order_count}%
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
  
                {/* <div style={{ textAlign: "center", padding: "20px 0" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ textTransform: "capitalize", color: "#fff" }}
                  >
                    Detail
                  </Button>
                </div> */}
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Best Product</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Foto Produk</TableCell>
                      <TableCell align="center">Nama Produk</TableCell>
                      {/* <TableCell align="center">Rating</TableCell> */}
                      <TableCell align="center">Total Penjualan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.best_sellers &&
                      reportSummary.best_sellers.map((row) => (
                        <TableRow key={row.name}>
                          <img
                            src={"https://api.idejualan.com/" + row.image.path}
                            width="100px"
                          ></img>
                          <TableCell align="center">{row.name}</TableCell>
                          {/* <TableCell align="center">{row.rating}</TableCell> */}
                          <TableCell align="center">{row.total}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
  
              {/* <div style={{ textAlign: "center", padding: "20px 0" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ textTransform: "capitalize", color: "#fff" }}
                >
                  Detail
                </Button>
              </div> */}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Best Categories</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Foto</TableCell>
                      <TableCell align="center">Nama Kategori</TableCell>
                      {/* <TableCell align="center">Rating</TableCell> */}
                      <TableCell align="center">Total Penjualan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.best_categories &&
                      reportSummary.best_categories.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell>
                            <img src={row.category.icon_url} width="100px"></img>
                          </TableCell>
                          <TableCell align="center">
                            {row.category.name}
                          </TableCell>
                          {/* <TableCell align="center">{row.rating}</TableCell> */}
                          <TableCell align="center">{row.total}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
  
              {/* <div style={{ textAlign: "center", padding: "20px 0" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ textTransform: "capitalize", color: "#fff" }}
                >
                  Detail
                </Button>
              </div> */}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Most viewed products</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Foto</TableCell>
                      <TableCell align="center">Nama Produk</TableCell>
                      <TableCell align="center">Jumlah dilihat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.most_products_views &&
                      reportSummary.most_products_views.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell>
                            <img src={row.image} width="100px"></img>
                          </TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          {/* <TableCell align="center">{row.rating}</TableCell> */}
                          <TableCell align="center">{row.total_view}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          
          <Grid item xs={12} sm={12} md={12}>
            <Typography>
              <b>Reward Omzet</b>
            </Typography>
  
            <Paper style={{ marginTop: 20 }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Foto</TableCell>
                      <TableCell align="left">Nama</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Total Omzet</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportSummary.resellers_reward_omzets &&
                      reportSummary.resellers_reward_omzets.map((reseller) => (
                        <TableRow>
                          <TableCell>
                            <img src={reseller.avatar} height={100} width={100} />
                          </TableCell>
                          <TableCell>
                            <Badge
                              badgeContent={reseller.omzet_level}
                              color="primary"
                            >
                              <Box pr={1}>{reseller.name}</Box>
                            </Badge>
                            <Box mt={1}>
                              <Accordion expand>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  Lihat reward
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="left">Level</TableCell>
                                        <TableCell align="left">
                                          Deskripsi
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(reseller.reward_omzet || []).map(
                                        ({ level, reward_desc }, i) => (
                                          <TableRow>
                                            <TableCell align="left">
                                              {level}
                                            </TableCell>
                                            <TableCell align="left">
                                              {reward_desc}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </TableCell>
                          <TableCell>{reseller.email}</TableCell>
                          <TableCell>
                            Rp.{formatRupiah(reseller.total_omzet)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Gender</b>
            </Typography>
            {reportSummary.gender_analytic && renderGender()}
          </Grid>
        {mdDown && (
          <Dialog
            fullScreen
            open={isOpenDate}
            onClose={() => setIsOpenDate(false)}
          >
            <DateRange
              onChange={(item) => setSelectionRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={selectionRange}
              direction="vertical"
            />
            <Button
              className={classes.filterApply}
              onClick={() => onApplyFilter()}
            >
              <Typography>Apply</Typography>
            </Button>
          </Dialog>
        )}
      </Fragment>
    );
  }
});

export default Analytic;
