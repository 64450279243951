import { Button, Grid, TextField, Typography } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { editInvestor, getSpesificInvestor } from "services/dropship.service";
import swal from "sweetalert";
import styles from "./style.module.css";

const EditInvestor = ({match}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setstate] = useState({
    name: '',
    email: '',
    phone: '',
    invest_amount: '',
    password: '',
    password_confirmation: ''
  })
  const [allDataInvestor, setallDataInvestor] = useState()

  // state management
  const updateAllDetailDataToState = () => {
    setstate(
      {
        name: allDataInvestor.name,
        email: allDataInvestor.email,
        phone: allDataInvestor.phone,
        invest_amount: allDataInvestor.invest_amount,
        password: '',
        password_confirmation: ''
      }
    )
  }

  React.useEffect(() => {
    const getDataSpesific = async() => {
      const response = await getSpesificInvestor(match.params.slug)
      setallDataInvestor(response.data)
    }

    getDataSpesific()
  }, [])
  
  React.useEffect(() => {
    if (allDataInvestor) {
        updateAllDetailDataToState()
    }
  }, [allDataInvestor])

  // management input
  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setstate({ ...state, [name]: value });
  };

  const handleSubmit = async() => {
    const dataToUpload = JSON.parse(JSON.stringify(state))

    // check data upload invest amount
    if (typeof(dataToUpload.invest_amount) === 'string') {
      if (dataToUpload.invest_amount.includes(',')) {
        dataToUpload.invest_amount = dataToUpload.invest_amount.split(',').join('')
      }
    }

    // check is user update password or not
    if (dataToUpload.password === '') {
      delete dataToUpload.password
      delete dataToUpload.password_confirmation
    }

    console.log(dataToUpload)

    // add method put
    dataToUpload._method = 'PUT'

    setIsLoading(true);
      
    // upload to api
    await editInvestor(match.params.slug, dataToUpload)
    .then((result) => {
      swal("Success", "Berhasil edit Investor", "success").then(() => {
        window.history.back();
      });
      setIsLoading(false);
    })
    .catch((error) => {
      swal("Error", error.response.data.message, "error");
      setIsLoading(false);
    });
  }

  console.log(state)

  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Edit Investor</b>
      </Typography>

      <br/>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit()
      }}>
        <Grid container spacing={3} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap' }}>

          <div className={styles.innerContainer}>
            {/* nama */}
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nama"
                variant="outlined"
                style={{ width: "100%" }}
                value={state.name}
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* email */}
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                style={{ width: "100%" }}
                type={'email'}
                value={state.email}
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* Phone */}
            <Grid item xs={12}>
              <TextField
                name="phone"
                label="No. Hp"
                type="number"
                variant="outlined"
                style={{ width: "100%" }}
                value={state.phone}
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* Invest amount */}
            <Grid item xs={12}>
              <NumberFormat 
                customInput={TextField} 
                name='invest_amount'
                label='Invest Amount'
                style={{ width: "100%" }}
                variant="outlined"
                value={state.invest_amount}
                thousandSeparator={true} 
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Grid>

            <br/><br/>
          </div>

          <div className={styles.innerContainer}>
            {/* password */}
            <Grid item xs={12}>
              <TextField
                name="password"
                label="New Password"
                variant="outlined"
                style={{ width: "100%" }}
                onChange={handleInputChange}
              />
            </Grid>

            <br/>

            {/* password konfirmasi */}
            <Grid item xs={12}>
              <TextField
                name="password_confirmation"
                label="New Password Confirmation"
                variant="outlined"
                style={{ width: "100%" }}
                disabled={state.password === ''}
                required={state.password !== ''}
                onChange={handleInputChange}
              />
            </Grid>

            <br/><br/>
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end', width:'100%'}}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default EditInvestor;
