const style = (theme) => ({
	container: {
		background: '#FFFFFF',
		boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.04)',
		borderRadius: 5,
	},
	header: {
		borderRadius: '5px 5px 0px 0px',
		backgroundColor: theme.palette.yellow.primary,
		display: 'flex',
		padding: '25px 22px',
	},
	iconNotification: {
		height: 25,
		width: 25,
		'& path': {
			fill: theme.palette.white.primary
		}
	},
	headerText: {
		color: theme.palette.white.primary,
		fontWeight: 'bold',
		paddingLeft: 10,
	},
	content: {
		padding: "27px 20px",
		backgroundColor: theme.palette.white.primary,
		borderRadius: 5,
	},
})

export default style;