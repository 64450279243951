import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  MenuItem,
  Popover,
  InputBase,
  Paper,
  FormControl,
  Select,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Switch,
  Tooltip,
  Checkbox,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  editSupplier,
  getSupplier,
  getCity,
  deleteSupplier,
  getProvince,
  getCouriers,
  exportSupplier,
} from "../../services/axios";
import swal from "sweetalert";
import Loading from "../../components/loading";
import TambahSupplier from "./tambah-supplier";
import EditSupplier from "./edit-supplier";

import { ReactComponent as BinIcon } from "../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import moment from "moment";
import { DELETE_SUPPLIER } from "services/suppliers.service";
import _, { isEmpty } from "lodash";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const Supplier = withStyles(style)((props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = useState({
    openTambahSupplier: false,
    openEditSupplier: false,
    openDialogDetail: false,
    filteredSupplier: null,
    selectedSupplier: null,
    suppliers: null,
    provinces: null,
    couriers: null,
    cities: null,
    openEditData: false,
    dataEdit: null,
    search: "",
  });
  const [filter, setFilter] = useState({
    page: 1,
    city: "",
    province: "",
  });
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isOpenDialogCommision, setIsOpenDialogCommision] =
    React.useState(false);
  const [commision, setCommision] = React.useState("0");
  const [selectedRow, setSelectedRow] = React.useState();

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  useEffect(() => {
    const fetch = async () => {
      const resSuppliers =
        filter.page > 1
          ? await getSupplier(
              `page=${filter.page}&filter[location.province.id]=${
                filter.province.id || ""
              }&filter[location.city.id]=${filter.city.id || ""}`
            )
          : await getSupplier();
      const resProvinces = await getProvince();
      const resCouriers = await getCouriers();
      let dataSupplier = [];
      resSuppliers.data.map((supplier) => {
        dataSupplier.push({
          ...supplier,
          checked: false,
        });
        return "";
      });
      setState((state) => ({
        ...state,
        suppliers: resSuppliers,
        filteredSupplier: dataSupplier,
        provinces: resProvinces.data,
        couriers: resCouriers.data,
      }));
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setState({
        ...state,
        suppliers: null,
        filteredSupplier: null,
        provinces: null,
        couriers: null,
      });
      const resSuppliers =
        filter.page > 1
          ? await getSupplier(
              `page=${filter.page}&filter[location.province.id]=${
                filter.province.id || ""
              }&filter[location.city.id]=${filter.city.id || ""}`
            )
          : await getSupplier();
      const resProvinces = await getProvince();
      const resCouriers = await getCouriers();
      let dataSupplier = [];
      resSuppliers.data.map((supplier) => {
        dataSupplier.push({
          ...supplier,
          checked: false,
        });
        return "";
      });
      setState((state) => ({
        ...state,
        suppliers: resSuppliers,
        filteredSupplier: dataSupplier,
        provinces: resProvinces.data,
        couriers: resCouriers.data,
      }));
      setIsRefetch(false);
    };

    isRefetch && fetch();
  }, [isRefetch]);

  useEffect(() => {
    if (!isOpenDialogCommision) {
      setCommision(0);
      setSelectedRow(undefined);
    }
  }, [isOpenDialogCommision]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangePage = (e, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setFilter({
      ...filter,
      page: value,
    });
    const fetch = async () => {
      const response = await getSupplier(
        `page=${value}&filter[location.province.id]=${
          filter.province.id || ""
        }&filter[location.city.id]=${
          filter.city.id || ""
        }&include=village.district.city.province`
      );
      let dataSupplier = [];
      response.data.map((supplier) => {
        dataSupplier.push({
          ...supplier,
          checked: false,
        });
        return "";
      });
      if (!isEmpty(selectedSupplier)) {
        selectedSupplier.map((selected) => {
          const findIndexSupplier = response.data.findIndex(
            (x) => x.id === selected.id
          );
          if (findIndexSupplier >= 0) {
            dataSupplier[findIndexSupplier] = selected;
          }
        });
        setState({
          ...state,
          suppliers: response,
          filteredSupplier: dataSupplier,
          search: "",
        });
      }
      setState({
        ...state,
        filteredSupplier: dataSupplier,
        suppliers: response,
      });
      setIsCheckedAll(false);
    };
    fetch();
  };

  const handleChangeProvince = (e) => {
    setFilter({ ...filter, province: e.target.value });
    const fetch = async () => {
      const resSupplier = await getSupplier(
        `page=${filter.page}&filter[location.province.id]=${
          e.target.value.id || ""
        }&filter[location.city.id]=${filter.city.id || ""}`
      );
      const resCity = await getCity(e.target.value.id);
      setState({
        ...state,
        cities: resCity.data,
        suppliers: resSupplier,
        filteredSupplier: resSupplier.data,
      });
    };
    fetch();
  };

  const handleChangeCity = (e) => {
    setFilter({ ...filter, city: e.target.value });
    const fetch = async () => {
      const resSupplier = await getSupplier(
        `page=${filter.page}&filter[location.province.id]=${
          filter.province.id || ""
        }&filter[location.city.id]=${e.target.value.id || ""}`
      );
      setState({
        ...state,
        suppliers: resSupplier,
        filteredSupplier: resSupplier.data,
      });
    };
    fetch();
  };

  const handleResetFilter = () => {
    setFilter({
      page: 1,
      city: "",
      province: "",
    })
    const fetch = async () => {
      const resSupplier =
        filter.page > 1
          ? await getSupplier(
              `page=${filter.page}&filter[location.province.id]=${
                filter.province.id || ""
              }&filter[location.city.id]=${filter.city.id || ""}`
            )
          : await getSupplier();
      let dataSupplier = [];
      resSupplier.data.map((supplier) => {
        dataSupplier.push({
          ...supplier,
          checked: false,
        });
        return "";
      });
      setState({
        ...state,
        suppliers: resSupplier,
        filteredSupplier: dataSupplier,
      });
    };
    fetch();
  };

  const handleBackEdit = async () => {
    const resSupplier = await getSupplier();
    setState({
      ...state,
      openEditSupplier: false,
      suppliers: resSupplier,
      filteredSupplier: resSupplier.data,
    });
  };

  const handleBackTambah = async () => {
    const resSupplier = await getSupplier();
    setState({
      ...state,
      suppliers: resSupplier,
      filteredSupplier: resSupplier.data,
      openTambahSupplier: false,
    });
  };

  const handleDeleteSupplier = (slug) => {
    swal({
      title: "Are you sure?",
      text: "Supplier akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteSupplier(slug);
        swal("Supplier dihapus!", {
          icon: "success",
        }).then(() => {
          handleResetFilter();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      } else {
        swal("Supplier batal dihapus");
      }
    });
  };

  function createData(checkbox, nama, telepon, aktif, alamat, kelola, res) {
    return {
      checkbox,
      nama,
      telepon,
      aktif,
      alamat,
      kelola,
      ...res,
    };
  }

  const Kelola = ({ data }) => {
    return (
      <div style={{ width: 200 }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton
            onClick={() =>
              setState({
                ...state,
                openEditSupplier: true,
                selectedSupplier: data,
              })
            }
          >
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteSupplier(data.slug)}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Detail" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() =>
              setState({
                ...state,
                openDialogDetail: true,
                selectedSupplier: data,
              })
            }
          >
            <VisibilityIcon style={{ color: "#fff", fontSize: 14 }} />
          </StyledButton>
        </Tooltip>
      </div>
    );
  };

  const Nama = ({ img, name, email }) => {
    return (
      <div className={classes.flex}>
        <Avatar src={img} />
        <div style={{ paddingLeft: 10 }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {name}
          </Typography>
          <Typography variant="caption" style={{ color: "#a2a2a2" }}>
            {email}
          </Typography>
        </div>
      </div>
    );
  };

  const handleActivated = (id, activated, slug, isAffiliate) => {
    if (!activated && isAffiliate) {
      return setIsOpenDialogCommision(true);
    }

    const formData = new FormData();

    formData.append("activated", activated ? 0 : 1);
    formData.append("_method", "PATCH");
    editSupplier(slug, formData);
    setState({
      ...state,
      filteredSupplier: [
        ...state.filteredSupplier.filter((value) => value.id !== id),
        {
          ...state.filteredSupplier.filter((value) => value.id === id)[0],
          activated: activated ? false : true,
        },
      ],
    });
  };

  const handleActivatedWithCommision = () => {
    const formData = new FormData();

    formData.append("activated", selectedRow.activated ? 0 : 1);
    formData.append("marketer_commission", commision);
    formData.append("_method", "PATCH");
    editSupplier(selectedRow.slug, formData);
    setState({
      ...state,
      filteredSupplier: [
        ...state.filteredSupplier.filter(
          (value) => value.id !== selectedRow.id
        ),
        {
          ...state.filteredSupplier.filter(
            (value) => value.id === selectedRow.id
          )[0],
          activated: selectedRow.activated ? false : true,
        },
      ],
    });
    setIsOpenDialogCommision(false);
  };

  const handleSearch = () => {
    setIsCheckedAll(false);
    const fetch = async () => {
      const resSupplier = await getSupplier(
        `page=${filter.page}&filter[location.province.id]=${
          filter.province.id || ""
        }&filter[location.city.id]=${filter.city.id || ""}&filter[name]=${
          state.search
        }`
      );
      let dataSupplier = [];
      resSupplier.data.map((supplier) => {
        dataSupplier.push({
          ...supplier,
          checked: false,
        });
      });
      setState({
        ...state,
        suppliers: resSupplier,
        filteredSupplier: dataSupplier,
        search: "",
      });

      if (!isEmpty(selectedSupplier)) {
        selectedSupplier.map((selected) => {
          const findIndexSupplier = resSupplier.data.findIndex(
            (x) => x.id === selected.id
          );
          if (findIndexSupplier >= 0) {
            dataSupplier[findIndexSupplier] = selected;
          }
        });
        setState({
          ...state,
          suppliers: resSupplier,
          filteredSupplier: dataSupplier,
          search: "",
        });
      }
    };
    fetch();
  };

  const handleClickEnterSearch = () => {
    setIsCheckedAll(false);
    const fetch = async () => {
      const resSupplier = await getSupplier(
        `page=${filter.page}&filter[location.province.id]=${
          filter.province.id || ""
        }&filter[location.city.id]=${filter.city.id || ""}&filter[name]=${
          state.search
        }`
      );
      let dataSupplier = [];
      resSupplier.data.map((supplier) => {
        dataSupplier.push({
          ...supplier,
          checked: false,
        });
      });
      setState({
        ...state,
        suppliers: resSupplier,
        filteredSupplier: dataSupplier,
        search: "",
      });

      if (!isEmpty(selectedSupplier)) {
        selectedSupplier.map((selected) => {
          const findIndexSupplier = resSupplier.data.findIndex(
            (x) => x.id === selected.id
          );
          if (findIndexSupplier >= 0) {
            dataSupplier[findIndexSupplier] = selected;
          }
        });
        setState({
          ...state,
          suppliers: resSupplier,
          filteredSupplier: dataSupplier,
          search: "",
        });
      }
    };
    fetch();
  };

  const onCheckedAll = (checked) => {
    setIsCheckedAll(!isCheckedAll);
    let dataSupplier = state.filteredSupplier;
    state.filteredSupplier.map((supplier) => {
      const foundIndex = state.filteredSupplier.findIndex(
        (x) => x.id === supplier.id
      );
      dataSupplier[foundIndex] = {
        ...supplier,
        checked,
      };
      return "";
    });

    setState({
      ...state,
      filteredSupplier: dataSupplier,
    });
    if (!isEmpty(selectedSupplier)) {
      _.pullAllBy(selectedSupplier, dataSupplier, "id");
      setSelectedSupplier([
        ...selectedSupplier,
        ...dataSupplier.filter((x) => x.checked === true),
      ]);
    } else {
      setSelectedSupplier(dataSupplier.filter((x) => x.checked === true));
    }
  };

  const onCheckedSupplier = (checked, supplier) => {
    let dataSupplier = state.filteredSupplier;
    const foundIndex = state.filteredSupplier.findIndex(
      (x) => x.id === supplier.id
    );
    dataSupplier[foundIndex] = {
      ...supplier,
      checked,
    };
    setState({
      ...state,
      filteredSupplier: dataSupplier,
    });
    if (!isEmpty(selectedSupplier)) {
      _.pullAllBy(selectedSupplier, dataSupplier, "id");
      setSelectedSupplier([
        ...selectedSupplier,
        ...dataSupplier.filter((x) => x.checked === true),
      ]);
    } else {
      setSelectedSupplier(dataSupplier.filter((x) => x.checked === true));
    }
  };

  const handleDeleteSelectedSupplier = async () => {
    await selectedSupplier.map((supplier) => {
      DELETE_SUPPLIER(supplier.slug);
    });
    setState({
      ...state,
      suppliers: null,
      filteredSupplier: null,
    });
    setSelectedSupplier([]);
    handleResetFilter();
    setIsRefetch(true);
    setIsOpenDialogDelete(!isOpenDialogDelete);
  };

  const handleCloseDialog = () => {
    setIsOpenDialogDelete(!isOpenDialogDelete);
  };

  const DialogDelete = () => (
    <Dialog
      open={isOpenDialogDelete}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Apakah kamu yakin ingin menghapus ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Data yang dihapus tidak dapat dipulihkan!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Tidak
        </Button>
        <Button
          onClick={handleDeleteSelectedSupplier}
          color="primary"
          autoFocus
        >
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );

  const DialogCommision = () => (
    <Dialog
      open={isOpenDialogCommision}
      onClose={() => setIsOpenDialogCommision(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Masukan komisi</DialogTitle>
      <DialogContent>
        <TextField
          value={commision}
          onChange={(e) => setCommision(e.target.value)}
          type="number"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpenDialogCommision(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleActivatedWithCommision}
          color="primary"
          autoFocus
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  const DialogDetail = () => (
    <Dialog
      open={state.openDialogDetail}
      onClose={() => setState({ ...state, openDialogDetail: false })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Detail Supplier</DialogTitle>
      <DialogContent>
        {state.selectedSupplier && (
          <>
            <Box mb={2}>
              <Typography>Nama Lengkap</Typography>
              <Typography variant={"caption"}>
                {state.selectedSupplier.contact_name || "-"}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography>Nomor whatsapp</Typography>
              <Typography variant={"caption"}>
                {state.selectedSupplier.contact_whatsapp || "-"}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography>Email</Typography>
              <Typography variant={"caption"}>
                {state.selectedSupplier.email || "-"}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography>Deskripsi</Typography>
              <Typography variant={"caption"}>
                {state.selectedSupplier.description || "-"}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography>Alamat</Typography>
              <Typography variant={"caption"}>
                {state.selectedSupplier.location?.address || "-"}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography>Nama Brand</Typography>
              <Typography variant={"caption"}>
                {state.selectedSupplier.name || "-"}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography>Shipment type</Typography>
              <Typography variant={"caption"}>
                {state.selectedSupplier.shipment_type || "-"}
              </Typography>
            </Box>
            {state.selectedSupplier.affiliater && (
              <>
                <Box mb={2}>
                  <Typography>Kode afiliasi</Typography>
                  <Typography variant={"caption"}>
                    {state.selectedSupplier.affiliater.affiliate_code || "-"}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>Link afiliasi</Typography>
                  <Typography variant={"caption"}>
                    {state.selectedSupplier.affiliater.affiliate_link || "-"}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>Affiliater</Typography>
                  <Typography variant={"caption"}>
                    {state.selectedSupplier.affiliater.name || "-"}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>No.HP Affiliater</Typography>
                  <Typography variant={"caption"}>
                    {state.selectedSupplier.affiliater.phone || "-"}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>Email</Typography>
                  <Typography variant={"caption"}>
                    {state.selectedSupplier.affiliater.email || "-"}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>Level</Typography>
                  <Typography variant={"caption"}>
                    {state.selectedSupplier.affiliater?.level?.name || "-"}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>Alamat</Typography>
                  <Typography variant={"caption"}>
                    {state.selectedSupplier.affiliater?.location?.address ||
                      "-"}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setState({ ...state, openDialogDetail: false })}
          color="primary"
        >
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );

  const rows = () => {
    let returnData = [];
    state.filteredSupplier.forEach((res) => {
      returnData = [
        ...returnData,
        createData(
          <Checkbox
            onChange={(e) => onCheckedSupplier(e.target.checked, res)}
            checked={res.checked}
          />,
          <Nama img={res.avatar_url} name={res.name} email={res.email} />,
          res.phone,
          <Switch
            checked={res.activated}
            onChange={() => {
              setSelectedRow(res);
              handleActivated(
                res.id,
                res.activated,
                res.slug,
                res.is_affiliate
              );
            }}
            color="primary"
            name={res.name}
            inputProps={{ "aria-label": "primary checkbox" }}
          />,
          (res.location.address ? res.location.address + ", " : "") +
            (res.location.village
              ? res.location.village?.name +
                " " +
                res.location.village.district?.city?.name +
                " " +
                res.location.village.district?.city?.province?.name +
                " "
              : "Belum Mengisi data"),
          <Kelola data={res} />,
          res
        ),
      ];
    });
    return returnData;
  };

  const handleDownloadSupplier = async () => {
    const response = await exportSupplier(`${(filter.province !== '' ? "?filter[location.province.id]=" + filter.province.id : "") + (filter.city !== '' ? "&filter[location.city.id]=" + filter.city.id : "")}`)
    const url = window.URL.createObjectURL(
      new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Export Supplier`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link);
  }

  return (
    <Fragment>
      {state.openTambahSupplier ? (
        <TambahSupplier
          handleBack={handleBackTambah}
          couriers={state.couriers}
        />
      ) : state.openEditSupplier ? (
        <EditSupplier
          handleBack={handleBackEdit}
          dataApi={state.selectedSupplier}
          couriers={state.couriers}
        />
      ) : (
        <Fragment>
          {!state.filteredSupplier ? (
            <Fragment>
              <Loading />
            </Fragment>
          ) : (
            <Fragment>
              <Grid container spacing={2} justify="space-between">
                <Grid item>
                  <Typography variant="h6">
                    <b>Data Supplier</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.tambahOrder}
                      style={{ marginRight: 20 }}
                      onClick={() =>
                        handleChangeState("openTambahSupplier", true)
                      }
                    >
                      + Tambah Supplier
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.other}
                      onClick={handleClick}
                    >
                      ...
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          // exportToCSV(csvDataSupplier(), `Data Supplier | Idejualan`);
                          // exportToCSV(csvDataCustomer(), `Data Customer | Idejualan`);
                        }}
                      >
                        <SystemUpdateAltIcon />{" "}
                        <Typography 
                          style={{ paddingLeft: 10 }}
                          onClick={handleDownloadSupplier}
                        >
                          Download Excel
                        </Typography>
                      </MenuItem>
                    </Popover>
                  </div>
                </Grid>
              </Grid>

              <Paper style={{ marginTop: 20 }}>
                <Grid
                  container
                  style={{ padding: 20 }}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        value={state.search}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) =>
                          handleChangeState("search", e.target.value)
                        }
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            handleClickEnterSearch()
                          }
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item>
                    <Button
                      className={classes.ButtonCari}
                      color="secondary"
                      variant="contained"
                      onClick={handleSearch}
                    >
                      Cari
                    </Button>
                  </Grid>

                  <Grid item>
                    <FormControl
                      className={classes.formControl}
                      style={{ marginLeft: 20 }}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={filter.province}
                        displayEmpty
                        disableUnderline
                        onChange={handleChangeProvince}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          <em>Provinsi</em>
                        </MenuItem>
                        {state.provinces &&
                          state.provinces.map((res, index) => (
                            <MenuItem key={index} value={res}>
                              {res.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={filter.city}
                        displayEmpty
                        disableUnderline
                        onChange={handleChangeCity}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          <em>Kab/Kota</em>
                        </MenuItem>
                        {state.cities &&
                          state.cities.map((res, index) => (
                            <MenuItem key={index} value={res}>
                              {(res.type === "Kabupaten"
                                ? res.type.slice(0, 3) + ". "
                                : res.type + " ") + res.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          <Checkbox
                            onChange={(e) => onCheckedAll(e.target.checked)}
                            checked={isCheckedAll}
                          />
                        </StyledTableCell>
                        <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell>Nama</StyledTableCell>
                        <StyledTableCell align="center" style={{ width: 100 }}>
                          No. Telepon
                        </StyledTableCell>
                        <StyledTableCell align="center">Aktif</StyledTableCell>
                        <StyledTableCell align="center">Alamat</StyledTableCell>
                        <StyledTableCell align="center">
                          Dibuat pada
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Affiliater
                        </StyledTableCell>
                        <StyledTableCell align="center">Kelola</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {state.filteredSupplier &&
                      state.suppliers.data.length !== 0 && (
                        <Fragment>
                          <TableBody>
                            {rows().map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                  {row.checkbox}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row.id}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {row.nama}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.telepon || "Tidak tersedia"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.aktif}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.alamat}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {moment(row.joined_at).fromNow()}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.affiliater ? row.affiliater.name : "-"}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row.kelola}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Fragment>
                      )}
                  </Table>
                </TableContainer>

                {state.suppliers && state.suppliers.data.length === 0 && (
                  <div className={classes.notFound}>
                    <div className={classes.notFoundChild}>
                      <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        Oops!
                      </Typography>
                      <Typography>Data tidak ditemukan.</Typography>
                      {(filter.province.length !== 0 ||
                        filter.city.length !== 0) && (
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.notFoundButton}
                          onClick={handleResetFilter}
                        >
                          Reset Filter
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {state.filteredSupplier && state.suppliers.data.length !== 0 && (
                  <div className={classes.Pagination}>
                    <Pagination
                      count={state.suppliers.meta.last_page}
                      defaultPage={filter.page}
                      variant="outlined"
                      onChange={handleChangePage}
                      color="secondary"
                    />
                  </div>
                )}
              </Paper>
            </Fragment>
          )}
        </Fragment>
      )}
      <div className={classes.floatingButton}>
        {!isEmpty(selectedSupplier) && (
          <Fab
            color="secondary"
            aria-label="delete"
            variant="extended"
            onClick={() => setIsOpenDialogDelete(!isOpenDialogDelete)}
          >
            <DeleteIcon /> Delete ({selectedSupplier.length})
          </Fab>
        )}
      </div>
      {DialogDelete()}
      {DialogCommision()}
      {DialogDetail()}
    </Fragment>
  );
});

export default Supplier;
