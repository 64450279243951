import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import DetailInfo from "./components/detail-info";
import EditInfo from "./components/edit-info";

const Info = withStyles(style)((props) => {
  const [state, setState] = useState({
    openEditInfo: false,
  });

  const handleEditInfo = () => {
    setState({ ...state, openEditInfo: !state.openEditInfo });
  };

  return (
    <Fragment>
      {state.openEditInfo ? (
        <EditInfo />
      ) : (
        <DetailInfo handleEditInfo={handleEditInfo} />
      )}
    </Fragment>
  );
});

export default Info;
