import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  IconButton,
  CardMedia,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  getECourseCategories,
  getECourses,
  deleteECourse,
} from "../../../services/axios";
import swal from "sweetalert";
import style from "./ListStyle";

const ListProduct = withStyles(style)(
  ({ classes, setActiveState, setSelectedProduct }) => {
    const [search, setSearch] = React.useState("");
    const [categoryId, setCategoryId] = React.useState("all");
    const [categories, setCategories] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDraft, setIsDraft] = React.useState(false);
    const open = Boolean(anchorEl);
    const selectedItemSlugRef = React.useRef("");

    const getCategories = async () => {
      const res = await getECourseCategories();

      if (res.data) {
        setCategories(res.data);
      }
    };

    const getProducts = async (categoryId, isDraft) => {
      const params = {
        "filter[slug]": search,
        include: "image,category",
        "filter[is_draft]": isDraft ? "1" : "0",
      };

      if (categoryId !== "all") {
        params["filter[category.id]"] = categoryId;
      }

      setLoading(true);

      const res = await getECourses(params);
      if (res.data) {
        setProducts(res.data);
      }

      setLoading(false);
    };

    const handleDeleteProduct = async () => {
      const res = await deleteECourse(selectedItemSlugRef.current);
      if (res.deleted) {
        swal("Success", "Produk berhasil dihapus", "success").then(() => {
          getProducts(categoryId, isDraft);
        });
      } else {
        swal("Success", "Produk gagal dihapus", "warning");
      }
    };

    const handleEditProduct = () => {
      const selected = products.find(
        (product) => product.slug === selectedItemSlugRef.current
      );

      if (selected) {
        setSelectedProduct(selected);
        setActiveState("edit");
      }
    };

    const renderCategories = () => {
      const items = [];

      categories.forEach((category) => {
        items.push(
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        );
      });

      return items;
    };

    React.useEffect(() => {
      getCategories();
      getProducts(categoryId, isDraft);
    }, []);

    let renderProducts = null;

    if (loading) {
      renderProducts = (
        <Box
          display="flex"
          height="80vh"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <CircularProgress color="primary" size={40} />
          <Typography component="div">Loading...</Typography>
        </Box>
      );
    } else {
      if (products.length === 0) {
        renderProducts = (
          <Box
            display="flex"
            height="80vh"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography component="div" variant="h4">
              Produk tidak di temukan
            </Typography>
          </Box>
        );
      } else if (products.length > 0) {
        renderProducts = (
          <Grid container alignItems="center" spacing={4}>
            {products.map((product) => (
              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={product.image.url}
                  />
                  <CardContent>
                    <Typography variant="subtitle1" component="div">
                      {product.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      style={{ color: "#8C8484" }}
                    >
                      {product.category.name}
                    </Typography>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        onClick={(e) => {
                          selectedItemSlugRef.current = product.slug;
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        );
      }
    }

    return (
      <>
        <Box my={2}>
          <Grid container alignItems="center" spacing={4}>
            <Grid item md={6}>
              <TextField
                fullWidth
                placeholder="Cari e-course"
                className={classes.searchField}
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton
                      onClick={() => getProducts(categoryId, isDraft)}
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item md={3}>
              <Select
                id="grouped-select"
                onChange={(e) => {
                  setCategoryId(e.target.value);
                  getProducts(e.target.value, isDraft);
                }}
                value={categoryId}
                variant="outlined"
                fullWidth
              >
                <MenuItem value="all">Kategori</MenuItem>
                {renderCategories()}
              </Select>
            </Grid>
            <Grid item md={3}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>Draft</Grid>
                <Grid item>
                  <Switch
                    checked={isDraft}
                    onChange={(e) => {
                      setIsDraft(e.target.checked);
                      getProducts(categoryId, e.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>{renderProducts}</Box>
        <Popover
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List component="nav">
            <ListItem button onClick={handleEditProduct}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Data Produk" />
            </ListItem>
            <ListItem button onClick={handleDeleteProduct}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Hapus Produk" />
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }
);

export default ListProduct;
