import React, { Fragment } from "react";
import { Doughnut } from "react-chartjs-2";
import { Paper, Typography, Grid } from "@material-ui/core";

const CardReport = ({reportSummary}) => {
  const data = {
    labels: ["Laba Kotor", "Laba Bersih"],
    datasets: [
      {
        data: [reportSummary.order_by_state && reportSummary.order_by_state.failed, reportSummary.order_by_state && reportSummary.order_by_state.delivered],
        backgroundColor: ["#FF6564", "#3DDC97"],
        hoverBackgroundColor: ["#FF6564", "#3DDC97"],
      },
    ],
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 1
  })

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Fragment>
      <Paper style={{ padding: 20, display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item md={5} sm={12} xs={12} style={{ display: "flex" }}>
            <div style={{ width: 200 }}>
              <Doughnut data={data} options={options} />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                {data.labels.map((label, index) => (
                  <Typography
                    style={{ color: data.datasets[0].backgroundColor[index] }}
                  >
                    {Math.round((data.datasets[0].data[index] /
                      data.datasets[0].data.reduce((a, b) => a + b, 0)) *
                      100)}
                    % {label}
                  </Typography>
                ))}
              </div>
            </div>
          </Grid>

          <Grid item md={2} sm={12} xs={12} style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Typography variant="h6">
              <b>{ formatter.format(reportSummary.platform_gross_income_idr) }</b>
              </Typography>
              <Typography style={{ color: "#a2a2a2" }}>Gross Sales</Typography>
            </div>
          </Grid>
          <Grid item md={2} sm={12} xs={12} style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Typography variant="h6">
              <b>{ formatter.format(reportSummary.platform_income_idr) }</b>
              </Typography>
              <Typography style={{ color: "#a2a2a2" }}>Net Sales</Typography>
            </div>
          </Grid>
          {/* <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Typography variant="h6">
                <b>Rp. {data.datasets[0].data.reduce((a, b) => a + b, 0)}</b>
              </Typography>
              <Typography style={{ color: "#a2a2a2" }}>
                Expensee Sales
              </Typography>
            </div>
          </Grid> */}
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default CardReport;
