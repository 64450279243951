import { axiosInstance } from "../config/fetchAxios";

export const GET_MARKETPLACE_FORMS = (params) => {
  const url = `/api/v2/shopchannels/forms`;

  return axiosInstance.get(url, {
    params,
  });
};

export const UPDATE_MARKETPLACE_FORM = (id, data) => {
  const url = `/api/v2/shopchannels/forms/${id}`;

  return axiosInstance.put(url, data);
};
