import { withStyles  } from "@material-ui/core/styles";
import React from 'react'
import style from "./style";

const AnalyticDropship = withStyles(style)((props) => {
    const iframeUrl = 'https://embed.lottiefiles.com/animation/113096';

    return (
        <div>
            {/* analytic dropship */}
            <div 
                style={{
                    width: '100%',
                    height: 'calc(100vh - 120px)'
                    }}
            >
                <iframe
                    title='Analitycdropship'
                    src={iframeUrl}
                    width='100%'
                    height='100%'
                    frameborder="0"
                    allowtransparency
                    allowfullscreen
                />
            </div>
        </div>
    )
})

export default AnalyticDropship