import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import Dialog from "@material-ui/core/Dialog";
import DialogAction from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import _ from "lodash";
import { getPreSupplier } from "services/suppliers.service";
import moment from "moment";
import SelectStatus from "../SelectStatus";

const headCells = [
  { id: "brand_name", label: "Nama Brand" },
  { id: "fullname", label: "Nama Lengkap" },
  { id: "affiliate_code", label: "Kode affiliate" },
  { id: "whatsapp", label: "No whatsapp" },
  { id: "status", label: "Status" },
  { id: "created_at", label: "Dibuat pada" },
  { id: "action", label: "Action" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  photo: {
    marginRight: 10,
    borderRadius: "50%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { filter } = props;
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const cancelTokenRef = React.useRef();
  const [selectedRow, setSelectedRow] = React.useState();
  const [isOpenModalDetail, setIsOpenModalDetail] = React.useState(false);

  const getData = useCallback(
    _.debounce(({ rowsPerPage, page, filter }) => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

      const cancelToken = axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      getPreSupplier(
        {
          "filter[id]": filter.id,
          "filter[brand_name]": filter.brand_name,
          "filter[fullname]": filter.fullname,
          "filter[whatsapp]": filter.whatsapp,
          "filter[status]": filter.status === "all" ? "" : filter.status,
          include: filter.include,
          paginate: rowsPerPage,
          page: page + 1,
        },
        {
          cancelToken: cancelToken.token,
        }
      )
        .then(({ data, meta }) => {
          setRows(data);
          setMeta(meta);
          cancelTokenRef.current = null;
        })
        .catch((err) => {});
    }, 500),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [filter]);

  React.useEffect(() => {
    getData({ rowsPerPage, page, filter, getData });
  }, [rowsPerPage, page, filter, getData]);

  React.useEffect(() => {
    if (selectedRow) {
      setIsOpenModalDetail(true);
    } else {
      setIsOpenModalDetail(false);
    }
  }, [selectedRow]);

  const reFetch = () => {
    getData({ rowsPerPage, page, filter, getData });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {rows.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>{row.brand_name}</TableCell>
                <TableCell>{row.fullname}</TableCell>
                <TableCell>{row.affiliate_code}</TableCell>
                <TableCell>{row.whatsapp}</TableCell>
                <TableCell>
                  <SelectStatus id={row.id} defaultStatus={row.status} />
                </TableCell>
                <TableCell>
                  {moment(row.created_at).format("DD MMM YYYY")}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => setSelectedRow(row)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      window.open(
                        `https://wa.me/${row.whatsapp.replace(
                          /^0*/,
                          "62"
                        )}?&text=Halo%20kak%20kami%20dari%20Idejualan%20mau%20memverifikasi%20calon%20supplier%2C%20%0AApakah%20Brand%2FProduk%20yg%20di%20daftarkan%20di%20Aplikasi%20Idejualan%20Produksi%20Sendiri%20%3F%0AApakah%20Foto%20Produk%20yg%20di%20daftarkan%20di%20Aplikasi%20Idejualan%20Foto%20Produk%20Sendiri%20%3F`,
                        "_blank"
                      )
                    }
                  >
                    <WhatsappIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Dialog
        open={isOpenModalDetail}
        maxWidth="sm"
        onClose={() => setSelectedRow(undefined)}
        fullWidth
      >
        <DialogContent>
          {selectedRow && (
            <>
              <Box mb={2}>
                <Typography>Nama Brand</Typography>
                <Typography variant={"caption"}>
                  {selectedRow.brand_name}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography>Nama Lengkap</Typography>
                <Typography variant={"caption"}>
                  {selectedRow.fullname}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography>Kode affiliate</Typography>
                <Typography variant={"caption"}>
                  {selectedRow.affiliate_code}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography>Whatsapp</Typography>
                <Typography variant={"caption"}>
                  {selectedRow.whatsapp}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography>Status</Typography>
                <Typography variant={"caption"}>
                  {selectedRow.status}
                </Typography>
              </Box>
              {selectedRow.instagram && (
                <Box mb={2}>
                  <Typography>Instagram</Typography>
                  <Typography variant={"caption"}>
                    {selectedRow.instagram}
                  </Typography>
                </Box>
              )}
              {selectedRow.facebook && (
                <Box mb={2}>
                  <Typography>Facebook</Typography>
                  <Typography variant={"caption"}>
                    {selectedRow.facebook}
                  </Typography>
                </Box>
              )}
              {selectedRow.tiktok && (
                <Box mb={2}>
                  <Typography>TikTok</Typography>
                  <Typography variant={"caption"}>
                    {selectedRow.tiktok}
                  </Typography>
                </Box>
              )}
              {selectedRow.tokopedia && (
                <Box mb={2}>
                  <Typography>Tokopedia</Typography>
                  <Typography variant={"caption"}>
                    {selectedRow.tokopedia}
                  </Typography>
                </Box>
              )}
              {selectedRow.lazada && (
                <Box mb={2}>
                  <Typography>Lazada</Typography>
                  <Typography variant={"caption"}>
                    {selectedRow.lazada}
                  </Typography>
                </Box>
              )}
              {selectedRow.shopee && (
                <Box mb={2}>
                  <Typography>Shopee</Typography>
                  <Typography variant={"caption"}>
                    {selectedRow.shopee}
                  </Typography>
                </Box>
              )}
              {selectedRow.product_examples && (
                <Box mb={2}>
                  <Grid container spacing={2}>
                    {selectedRow.product_examples.map((product) => (
                      <Grid item key={product.id}>
                        <img width={150} height={150} src={product.image} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              <Box>
                <Typography>Dibuat pada</Typography>
                <Typography variant={"caption"}>
                  {moment(selectedRow.created_at).format("DD MMM YYYY")}
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogAction>
          <Button onClick={() => setSelectedRow(undefined)}>Tutup</Button>
        </DialogAction>
      </Dialog>
    </div>
  );
}
