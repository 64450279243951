import React,{useRef, useState, useEffect} from 'react'
import DraggableList from "react-draggable-list";
import MenuIcon from '@material-ui/icons/Menu';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { AddIcon } from '@material-ui/data-grid';
import { editCourierRecomendation, getCourierRecomendation } from 'services/courierRecomendation';
import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { getCouriers } from 'services/axios';
import ModalAddEkspedisi from './components/Modal';
import swal from 'sweetalert';

const StyledButton = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.green.main,
        "&:hover": {
            backgroundColor: "#33b17a",
        },
        minWidth: 0,
        padding: 10,
    },
}))(Button);

const Item = ({ item, itemSelected, dragHandleProps, commonProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const { list, setlist } = commonProps;

    const handleDeleteData = () => {
        const newList = JSON.parse(JSON.stringify(list))
        newList.splice(item.id, 1)
        setlist(
            newList
            .map((res, index) => ({'item': res.item, id: index }))
        )
    }

    return (
        <div
            className="disable-select"
            style={{
                border: "1px solid black",
                display: "flex",
                justifyContent: "space-between",
                alignItems: 'center',
                background: "#fff",
                userSelect: "none",
                borderRadius: 8,
                margin: 10
            }}
        >
            <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                <div style={{marginLeft: 10, marginRight: 20, width: 10}}>{item.id+1}</div>

                <MenuIcon
                    className="disable-select dragHandle"
                    style={{padding: 10, background: 'orange'}}
                    onTouchStart={(e) => {
                        e.preventDefault();
                        console.log("touchStart");
                        e.target.style.backgroundColor = "blue";
                        document.body.style.overflow = "hidden";
                        e.target.style.cursor = "default";
                        onTouchStart(e);
                    }}
                    onMouseDown={(e) => {
                        console.log("mouseDown");
                        document.body.style.overflow = "hidden";
                        e.target.style.cursor = "grabbing";
                        onMouseDown(e);
                    }}
                    onTouchEnd={(e) => {
                        e.target.style.backgroundColor = "black";
                        document.body.style.overflow = "visible";
                        e.target.style.cursor = "default";
                    }}
                    onMouseUp={(e) => {
                        document.body.style.overflow = "visible";
                        e.target.style.cursor = "default";
                    }}
                />

                <div style={{marginLeft: 10, textTransform: 'capitalize'}}>{item.item}</div>
            </div>

            <div style={{marginRight: 5}}>
                <Tooltip title="Hapus" placement="bottom">
                    <StyledButton
                    onClick={() => {handleDeleteData()}}
                    >
                        <BinIcon />
                    </StyledButton>
                </Tooltip>
            </div>
        </div>
    );
};

const EkspedisiSettings = () => {
    const [list, setlist] = useState();
    const [allDataCourier, setallDataCourier] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const containerRef = useRef();

    useEffect(() => {
        const getAllDataCouriersRecomendation = async() => {
            const response = await getCourierRecomendation()
            setlist(
                response.data
                .map((item, index) => ({item, id: index }))
            )
        }

        const getAllDataCourier = async() => {
            const response = await getCouriers()
            setallDataCourier(response.data)
        }

        getAllDataCourier()
        getAllDataCouriersRecomendation()
    }, [])
    
    const _onListChange = (newList) => {
        setlist(newList);
    };

    const handleSubmitData = async() => {
        setisLoading(true)
        let allDataCourierToUpload = []
        
        for (let i = 0; i < list.length; i++) {
            allDataCourierToUpload.push(list[i].item)
        }

        const dataToUpload = {
            "couriers": allDataCourierToUpload
        }

        try {
            await editCourierRecomendation(dataToUpload)
            swal("Data berhasil diupdate!", { icon: "success" });

            // refresh data
            const response = await getCourierRecomendation()
            setlist(
                response.data
                .map((item, index) => ({item, id: index }))
            )
            setisLoading(false)
        } catch (error) {
            swal("Data gagal diupdate!", { icon: "error" });
            setisLoading(false)
        }
    }

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Ubah Rekomendasi Ekspedisi</div>
                <div>
                    <Button
                        onClick={() => {
                            setModalOpen(true)
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                    >
                        Tambah Data
                    </Button>
                </div>
            </div>
            <br/>
            <div
                ref={containerRef}
                style={{ touchAction: "pan-y", background: "beige", padding: 8, borderRadius: 8 }}
            >
                {
                    list &&
                    isLoading === false &&
                    <DraggableList
                        itemKey="id"
                        template={Item}
                        list={list}
                        onMoveEnd={(newList) => _onListChange(newList)}
                        container={() => containerRef.current}
                        commonProps={{
                            list: list,
                            setlist: setlist
                        }}
                    />
                }
            </div>

            <br/>

            <div style={{display: 'flex', justifyContent: 'end'}}>
                {
                    list &&
                    <Button
                        onClick={() => {
                            handleSubmitData()
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                }
            </div>

            <ModalAddEkspedisi
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dataCourier={allDataCourier}
                list={list}
                setlist={setlist}
            />
        </div>
    );
}

export default EkspedisiSettings