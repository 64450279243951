import {
    Button,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    withStyles,
    Backdrop,
    CircularProgress,
    Grid,
  } from "@material-ui/core";
  import swal from "sweetalert";
  import AddIcon from "@material-ui/icons/Add";
  import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
  import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
  import React, { useEffect, useState } from "react";
  import {
    createImportantInformation,
    deleteImportantInformation,
    getImportantInformations,
    editImportantInformation,
  } from "services/axios";
  import Modal from "./components/Modal";
  
  const useStyles = makeStyles({
    backdrop: {
      zIndex: 100,
      color: "#fff",
    },
  });
  
  const StyledButton = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.green.main,
      "&:hover": {
        backgroundColor: "#33b17a",
      },
      minWidth: 0,
      padding: 10,
    },
  }))(Button);
  
  export default function ImportantInfoPage() {
    const classes = useStyles();
  
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
  
    const handleInput = async (item) => {
      setIsLoading(true);
      const form = Object.entries(item).reduce(
        (a, [k, v]) => (v !== null ? ((a[k] = v), a) : a),
        {}
      );
      const formData = new FormData();
      for (var key in form) {
        formData.append(key, form[key]);
      }
      if (item.method === "add") {
        const response = await createImportantInformation(formData);
        setDataList([...dataList, response.data]);
        swal("Banner berhasil ditambah!", { icon: "success" });
      } else if (item.method === "edit") {
        const response = await editImportantInformation(item.id, formData);
        if (response.message) {
          const data = response.data;
          setDataList((prevState) =>
            prevState.map((item) => (item.id !== data.id ? item : data))
          );
          swal("Banner berhasil diupdate!", { icon: "success" });
        } else {
          swal("Banner gagal diupdate!", { icon: "error" });
        }
      }
      setIsLoading(false);
    };
  
    const handleDelete = (id) => {
      swal({
        title: "Apakah kamu yakin?",
        text: "Banner yang dihapus tidak akan bisa dikembalikan!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const response = await deleteImportantInformation(id);
          if (response.message) {
            swal("Banner telah dihapus!", { icon: "success" });
            setDataList((prevState) =>
              prevState.filter((item) => item.id !== id)
            );
          } else {
            swal("Banner gagal dihapus!", { icon: "error" });
          }
        }
      });
    };
  
    useEffect(() => {
      async function fetchData() {
        const data = await getImportantInformations();
        setDataList(data.data);
      }
      fetchData();
    }, []);
  
    const createRow = (item) => {
      console.log(item)
      return (
        <TableRow key={item.id}>
          <TableCell>
            <img src={item.image} alt={item.name} width="100%" />
          </TableCell>
          <TableCell>
            <b>{item.name}</b>
          </TableCell>
          <TableCell>{item.link || "-"}</TableCell>
          <TableCell align="center">
            <Grid container spacing={1}>
              <Grid item>
                <Tooltip title="Edit" placement="bottom">
                  <StyledButton
                    onClick={() => {
                      setSelectedItem(item);
                      setModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </StyledButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Hapus" placement="bottom">
                  <StyledButton onClick={() => handleDelete(item.id)}>
                    <BinIcon />
                  </StyledButton>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    };
  
    return (
      <div>
        <Button
                    onClick={() => {
                      setSelectedItem(false);
                      setModalOpen(true);
                    }}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddIcon />}
                  >
                    Tambah Informasi Penting
                  </Button><br></br><br></br>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="400">Gambar</TableCell>
                <TableCell>Judul</TableCell>
                <TableCell>Link</TableCell>
                <TableCell width="150" align="center">
                  Aksi
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.map((item) => {
                return createRow(item);
              })}
            </TableBody>
          </Table>
        </TableContainer>
  
        <Modal
          open={modalOpen}
          selectedItem={selectedItem}
          onClose={() => setModalOpen(false)}
          onSubmit={handleInput}
        />
  
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
  