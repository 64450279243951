import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from "@material-ui/core";
import styles from "./styles";

const Modal = withStyles(styles)((props) => {
  const { open, onClose, onSubmit, price } = props;

  const [state, setState] = useState({
    payload: {
      price: "",
    },
  });

  const handleChange = (event) => {
    const target = event.target;
    setState({
      ...state,
      payload: {
        ...state.payload,
        [target.name]: target.value,
      },
    });
  };

  const submit = (event) => {
    onClose();
    onSubmit(state.payload);
  };

  useEffect(() => {
    if (!open) return;
    setState({
      payload: {
        price: price,
      },
    });
  }, [open, price]);

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-required"
            fullWidth
            label="Deskripsi"
            value={state.payload.price}
            variant="outlined"
            name="price"
            onChange={handleChange}
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button onClick={onClose} color="secondary">
            Batal
          </Button>
          <Button variant="contained" color="secondary" onClick={submit}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default Modal;
