const constants = {
  COLOR: {
    RED: '#FF6564',
    YELLOW: '#FDCA40',
    GREEN: '#29C0C7',
    GREY: '#787878',
    BLACK : '#000000'
  }
}

export const resellerLevels = [
    {id: 1, name: 'Seller'},
    {id: 2, name: 'Superseller'},
    {id: 3, name: 'Superseller VIP'},
    {id: 4, name: 'Marketer'},
    {id: 5, name: 'Reseller Invitation'},
    {id: 'subscription-3', name: 'Superseller 3 Bulan'},
    {id: 'subscription-6', name: 'Superseller 6 Bulan'}
]

export default constants
