import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PaymentTable from "./PaymentTable/PaymentTable";

const useStyles = makeStyles({
  title: {
    marginBottom: "30px",
  },
});

const Bank = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        Pembayaran
      </Typography>

      <PaymentTable />
    </div>
  );
};

export default Bank;
