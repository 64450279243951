import {
    Fade,
    FormControl,
    Modal,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    OutlinedInput,
  } from "@material-ui/core";
  import { Typography, CircularProgress } from "@material-ui/core"
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getAllProducts } from "services/axios";
import { ButtonCustom } from "components";
import _, { isEmpty } from "lodash";
import { TrainRounded } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    modal: {
        top: "5%",
        position: "relative",
        maxWidth: 820,
        height: 600,
        backgroundColor: "#FFF",
        margin: "0 auto",
        padding: 16,
        borderRadius: 10,
        outline: "none",
        overflowY: "auto",
        padding: 40,
    },
    input: {
        width: "100%",
        marginBottom: 20,
    },
    option: {
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
    }
}));


const ModalSelectProduct = ({open, handleClose, handleSubmit, product}) => {
    const classes = useStyles();
    const [search, setSearch] = React.useState("");
    const [products, setProducts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({
        selectedProducts: [],
    });
    const [isDataEmpty, setIsDataEmpty] = React.useState(false)

    const getProducts = async (categoryId, isDraft) => {
        const params = {
            "filter[name]": search,
            "paginate": 5,
        };
        setLoading(true);
        const res = await getAllProducts(params);
        if (res.data) {
            setProducts(res.data);
            setIsDataEmpty(false)
            if(res.data.length == 0) {
                setIsDataEmpty(true)
            }
        } 
        setLoading(false);
    };

    const handlePressSearch = (event) => {
        if (event.key === "Enter") {
            getProducts();
        }
    };

    const handlePressSearchBtn = () => {
        getProducts();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.modal}>
                    <Typography variant="h5" style={{ marginBottom: 20 }}>
                        Pilih Produk
                    </Typography>
                    <FormControl variant="standard" className={classes.input}>
                        <OutlinedInput
                        placeholder="Cari produk"
                        value={search}
                        autoFocus
                        onChange={(event) => setSearch(event.target.value)}
                        onKeyPress={handlePressSearch}
                        />
                    </FormControl>
                    {loading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                        {products.length > 0 ? (
                        <>
                        <FormLabel  id="demo-radio-buttons-group-label">Produk</FormLabel>
                            <RadioGroup 
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                >
                                {products.length > 0 && (
                                    products.map((product, index) => (
                                        <FormControlLabel 
                                            value={`${product.slug}`} 
                                            control={<Radio />} 
                                            label={
                                                <div className={classes.option}>
                                                <img src={product.image_url} width="80px" height="auto" style={{ marginRight: "5px" }} />
                                                    {product.name}
                                                </div>
                                                } 
                                            onChange={(event) => {
                                                setState({
                                                    selectedProducts: event.target.value,
                                                });
                                            }}
                                        />
                                    ))
                                )}
                            </RadioGroup>
                            
                            <ButtonCustom
                                bgColor="red"
                                isDisabled={isEmpty(state.selectedProducts)}
                                onClick={() => handleSubmit(state.selectedProducts)}
                                >
                                Pilih
                            </ButtonCustom>
                            </>
                        ): isDataEmpty ? 'data tidak ditemukan' : ''
                        }
                        </>
                    )}
                                
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalSelectProduct