import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";
import { ArrowDropDown } from "@material-ui/icons";

const useTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    marginLeft: "10px",
    minWidth: "200px",
  },
});

const filters = [
  { key: "name", label: "Nama" },
  { key: "email", label: "Email" },
  { key: "phone", label: "Phone" },
  { key: "shop_domain", label: "Shop Domain" },
];

const Table = () => {
  const classes = useTableStyles();
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState("all");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFilterIndex, setSelectedFilterIndex] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectFilter = (index) => {
    setAnchorEl(null);

    setSelectedFilterIndex(index);
  };

  return (
    <Paper elevation={3}>
      <Grid
        container
        className={classes.container}
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleClick}
            style={{
              height: "56px",
              borderRadius: "4px 0 0 4px",
            }}
          >
            {filters[selectedFilterIndex].label}
            <ArrowDropDown />
          </Button>
        </Grid>

        <Grid item>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {filters.map((filter, index) => (
              <MenuItem onClick={() => handleSelectFilter(index)}>
                {filter.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari..."
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              style={{
                borderRadius: "0 4px 4px 0",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            select
            label="Status"
            variant="outlined"
            className={classes.filterStatus}
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          >
            <MenuItem value="all">Semua</MenuItem>
            <MenuItem value="not_processed">Belum diproses</MenuItem>
            <MenuItem value="processed">Sudah diproses</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {/* Table */}
      <TableContent
        search={search}
        filter={filters[selectedFilterIndex]}
        status={status !== "all" ? status : null}
      />
    </Paper>
  );
};

export default Table;
