import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useEffect, useState } from "react";
import { GET_PIC, UPDATE_PIC } from "services/pic.service";
import swal from "sweetalert";
import styles from "./style.module.css";

const Edit = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    id: "",
    name: "",
    phone: "",
    images: null,
  });

  const [image, setImage] = useState(null);

  useEffect(() => {
    GET_PIC(match.params.id).then((item) => {
      if (!item) {
        swal("Data tidak ditemukan", "", "error");
        return;
      }

      setData((data) => ({
        ...data,
        ...item,
        images: null,
      }));

      setImage(item.images);
    });
  }, [match.params.id]);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    setImage(url);

    setData({ ...data, images: file });
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const form_data = new FormData();
    for (let key in data) {
      if (data[key]) {
        form_data.append(key, data[key]);
      }
    }

    UPDATE_PIC(data.id, form_data)
      .then((result) => {
        setIsLoading(false);
        swal("Success", "Berhasil merubah PIC", "success").then(() => {
          window.history.back();
        });
      })
      .catch((error) => {
        setIsLoading(false);
        swal("Error", error.response.data.message, "error");
      });
  };

  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Ubah PIC</b>
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <div className={styles.photo}>
              {image ? (
                <img src={image} alt="Preview" />
              ) : (
                <>
                  <div>Upload Photo</div>
                  <Add />
                </>
              )}
              <input
                name="images"
                accept="image/*"
                type="file"
                onChange={handlePhotoChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Nama"
              variant="outlined"
              style={{ width: "100%" }}
              value={data.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="phone"
              label="No Telp"
              variant="outlined"
              style={{ width: "100%" }}
              value={data.phone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Simpan
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Edit;
