import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";

import Logo from "../../assets/images/Logo-Horizontal.png";
import ExampleImage from "../../assets/images/mukena-tabziya.jpeg";

import styles from "./styles";
import Chart from "./components/Chart";

const mostBuyProduct = [
  {
    name: "Mukena Tabziya by Mukena Tabziya official",
    image: ExampleImage,
    price: "Rp.3,18M",
  },
  {
    name: "Mukena Tabziya by Mukena Tabziya official",
    image: ExampleImage,
    price: "Rp.3,18M",
  },
  {
    name: "Mukena Tabziya by Mukena Tabziya official",
    image: ExampleImage,
    price: "Rp.3,18M",
  },
  {
    name: "Mukena Tabziya by Mukena Tabziya official",
    image: ExampleImage,
    price: "Rp.3,18M",
  },
  {
    name: "Mukena Tabziya by Mukena Tabziya official",
    image: ExampleImage,
    price: "Rp.3,18M",
  },
];

const Counter = withStyles(styles)(({ classes }) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb={4}
        >
          <Box display="flex" alignItems="center" gridGap={1}>
            {/* <Box
              borderRadius="50%"
              bgcolor="#fff"
              height={12}
              width={12}
              marginRight="4px"
            /> */}
            {/* <Typography variant="caption">Bisnis saya</Typography> */}
          </Box>
          <Box display="flex" alignItems="center">
            <Box height={2} bgcolor="#fff" width="100px" />
            <img src={Logo} alt="idejualan" className={classes.headerLogo} />
            <Box height={2} bgcolor="#fff" width="100px" />
          </Box>
          <Box display="flex" alignItems="center" gridGap={1}>
            {/* <Box
              borderRadius="50%"
              bgcolor="#fff"
              height={12}
              width={12}
              marginRight="4px"
            /> */}
            {/* <Typography variant="caption">Bisnis saya</Typography> */}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h5">
            <Box fontWeight={600}>Penjualan Hari Ini (WIB)</Box>
          </Typography>
          <Box mt={1} bgcolor="#CDA025">
            <Typography variant="subtitle2">
              <Box fontWeight={600} px={2}>
                12-04-2022 20:41:22 (GMT+07)
              </Box>
            </Typography>
          </Box>
        </Box>
        <div className={classes.headerOverlay}>
          <Typography variant="h2" color="primary">
            <Box fontWeight={600} px={2}>
              Rp 58.845.609
            </Box>
          </Typography>
        </div>
      </div>
      <div className={classes.body}>
        <Grid container spacing={2} className={classes.height100}>
          <Grid item lg={3} md={12} sm={12} xs={12}>
            <div className={classes.card}>
              <Grid container className={classes.height100}>
                <Grid item xs={12}>
                  <Box p={2} borderBottom="1px solid #e1e1e1">
                    <Typography variant="subtitle1">Kriteria utama</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} className={classes.height100Min57}>
                  <Grid
                    container
                    direction="column"
                    className={classes.height100}
                  >
                    <Grid
                      item
                      xs
                      className={`${classes.cardCriteriaItem} ${classes.rightBorder}`}
                    >
                      <Typography variant="body2">Total Pengunjung</Typography>
                      <Typography variant="body1">14.309</Typography>
                    </Grid>
                    <Grid
                      item
                      xs
                      className={`${classes.cardCriteriaItem} ${classes.rightBorder}`}
                    >
                      <Typography variant="body2">Pesanan</Typography>
                      <Typography variant="body1">242</Typography>
                    </Grid>
                    <Grid
                      item
                      xs
                      className={`${classes.cardCriteriaItem} ${classes.rightBorder}`}
                    >
                      <Typography variant="body2">Total Pembeli</Typography>
                      <Typography variant="body1">235</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.height100Min57}>
                  <Grid
                    container
                    direction="column"
                    className={classes.height100}
                  >
                    <Grid item xs className={classes.cardCriteriaItem}>
                      <Typography variant="body2">Produk dilihat</Typography>
                      <Typography variant="body1">46.630</Typography>
                    </Grid>
                    <Grid item xs className={classes.cardCriteriaItem}>
                      <Typography variant="body2">Produk</Typography>
                      <Typography variant="body1">333</Typography>
                    </Grid>
                    <Grid item xs className={classes.cardCriteriaItem}>
                      <Typography variant="body2">Tingkat Konversi</Typography>
                      <Typography variant="body1">1,64%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={classes.height100}
          >
            <div className={classes.card}>
              <Box p={2}>
                <Typography variant="subtitle1">Tren penjualan</Typography>
              </Box>
              <Box p={1}>
                <Chart />
              </Box>
              <Box px={1}>
                <Typography variant="caption">
                  Penjual yang menggunakan iklan idejualan rata-rata mendapatkan
                  50% lebih banyak pesanan, buat iklan kamu
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            lg={3}
            md={12}
            sm={12}
            xs={12}
            className={classes.height100}
          >
            <div className={classes.card}>
              <Box p={2}>
                <Typography variant="subtitle1">5 Produk terlaris</Typography>
              </Box>
              <Grid container className={classes.height100Min57}>
                {mostBuyProduct.map((product, i) => (
                  <Grid item xs className={classes.mostBuyProductItem}>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Typography variant="caption">{i + 1}</Typography>
                      </Grid>
                      <Grid item>
                        <img
                          src={product.image}
                          style={{ width: 24, heigth: 24 }}
                        />
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Typography noWrap variant="body2">
                          {product.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {product.price}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
});

export default Counter;
