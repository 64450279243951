import React, { useCallback } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Divider, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { GET_ALL_BANK_ACCOUNTS } from "services/bank.service";
import { UPDATE_BANK_ACCOUNT_NOTES } from "services/bank.service";
import {
  GET_ALL_PAYMENTS,
  UPDATE_PAYMENT_STATUS,
} from "services/payment.service";
import axios from "axios";
import _ from "lodash";
import StatusMenu from "./StatusMenu";
import DocumentsDialog from "./DocumentsDialog";
import { formatRupiah } from "../../../utils/currencyFormat";
import swal from "sweetalert";

const headCells = [
  { id: "id", label: "No" },
  { id: "name", label: "Nama Reseller" },
  { id: 'order_id', label: 'Order Id'},
  { id: "order", label: "Order" },
  { id: "status", label: "Status" },
  { id: "total", label: "Total" },
  { id: "aksi", label: "Aksi" },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Pembayaran
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const {
    filterId,
    filterStatus,
    filterAmount,
    filterName,
    filterEmail,
    filterPhone,
  } = props;
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isOpenOrderDialog, setIsOpenOrderDialog] = React.useState(false);
  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
    React.useState(false);
  const cancelTokenRef = React.useRef();
  const selectedDataIndex = React.useRef();

  const summaryOrder =
    rows[selectedDataIndex.current] &&
    rows[selectedDataIndex.current]["summary"];

  const getData = useCallback(
    _.debounce(
      ({
        rowsPerPage,
        page,
        filterId,
        filterAmount,
        filterStatus,
        filterName,
        filterEmail,
        filterPhone,
      }) => {
        if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

        const cancelToken = axios.CancelToken.source();
        cancelTokenRef.current = cancelToken;

        GET_ALL_PAYMENTS(
          {
            paginate: rowsPerPage,
            page: page + 1,
            "filter[id]": filterId,
            "filter[amount]": filterAmount,
            "filter[status]": filterStatus,
            "filter[reseller.name]": filterName,
            "filter[reseller.email]": filterEmail,
            "filter[reseller.phone]": filterPhone,
            "filter[payment_type]": "vendorbank",
          },
          {
            cancelToken: cancelToken.token,
          }
        )
          .then(({ data, meta }) => {
            setRows(data);
            setMeta(meta);
            cancelTokenRef.current = null;
          })
          .catch((err) => {});
      },
      500
    ),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [
    filterId,
    filterStatus,
    filterAmount,
    filterName,
    filterEmail,
    filterPhone,
  ]);

  React.useEffect(() => {
    getData({
      rowsPerPage,
      page,
      filterId,
      filterStatus,
      filterAmount,
      filterName,
      filterEmail,
      filterPhone,
      getData,
    });
  }, [
    rowsPerPage,
    page,
    filterId,
    filterStatus,
    filterAmount,
    filterName,
    filterEmail,
    filterPhone,
    getData,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateTransaction = async (id, status) => {
    try {
      // setIsLoadingUpdateStatus(true);
      const res = await UPDATE_PAYMENT_STATUS(id, status);

      getData({
        rowsPerPage,
        page,
        filterId,
        filterStatus,
        filterAmount,
        filterName,
        filterEmail,
        filterPhone,
        getData,
      });

      // setIsLoadingUpdateStatus(false);
      swal("Success", "Status berhasil di update", "success");
    } catch (error) {
      // setIsLoadingUpdateStatus(false);
      swal("Error", "Terjadi kesalahan", "error");
    }
  };

  const handleStatusName = (status) => {
    if (status === "settlement") {
      return "Sudah dibayar";
    } else if (status === "checkout") {
      return "Menunggu Pembayaran";
    } else if (status === "canceled") {
      return "Batal";
    } else if (status === "expire") {
      return "Kadaluarsa";
    } else return "Selesai";
  };

  return (
    <div className={classes.root}>
      <TableToolbar />

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {rows.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell component="th" scope="row">
                  {row.reseller ? row.reseller.name : "-"}
                </TableCell>
                <TableCell>
                  {
                    row.summary.carts.map((item) => item.orders.map((item) => item.id).join(', ')).join(', ')
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  <Button
                    onClick={() => {
                      selectedDataIndex.current = index;
                      setIsOpenOrderDialog(true);
                    }}
                    variant="contained"
                  >
                    Lihat order
                  </Button>
                </TableCell>
                <TableCell component="th" scope="row">
                  {handleStatusName(row.status)}
                </TableCell>
                <TableCell component="th" scope="row">
                  Rp.{formatRupiah(row.amount)}
                </TableCell>
                <TableCell>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() =>
                          handleUpdateTransaction(row.id, "completed")
                        }
                        variant="contained"
                        color="primary"
                        disabled={row.status !== "checkout"}
                      >
                        {isLoadingUpdateStatus ? "Loading" : "Selesai"}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() =>
                          handleUpdateTransaction(row.id, "canceled")
                        }
                        variant="contained"
                        color="secondary"
                        disabled={row.status !== "checkout"}
                      >
                        {isLoadingUpdateStatus ? "Loading" : "Batal"}
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={isOpenOrderDialog}
        onClose={() => setIsOpenOrderDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          {(rows[selectedDataIndex.current]
            ? rows[selectedDataIndex.current]["summary"]["carts"]
            : []
          ).map((cart) => {
            return cart.orders.map((order) => {
              return order.items.map((item) => {
                let renderItems;

                if (item.sku || item.custom) {
                  renderItems = (
                    <Grid container justify="space-between">
                      <Grid item>
                        {item.sku
                          ? item.sku.product.name
                          : item.custom.product.name}{" "}
                        x {item.qty}
                      </Grid>
                      <Grid item>
                        Rp.{formatRupiah(item.reseller_price_idr)}
                      </Grid>
                    </Grid>
                  );
                } else if (item.bundling) {
                  renderItems = item.bundling.items.map((bundle) => (
                    <Grid container justify="space-between">
                      <Grid item>{bundle.name}</Grid>
                      <Grid item>
                        Rp.{formatRupiah(bundle.consumer_price_idr)}
                      </Grid>
                    </Grid>
                  ));
                }

                return <Box mt={2}>{renderItems}</Box>;
              });
            });
          })}
          {summaryOrder && (
            <>
              <Box>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <h4>Ongkos Kirim</h4>
                  </Grid>
                  <Grid item>
                    Rp.{formatRupiah(summaryOrder.shipping_cost)}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <h4>Koin</h4>
                  </Grid>
                  <Grid item>Rp.{formatRupiah(summaryOrder.coin)}</Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <h4>Diskon</h4>
                  </Grid>
                  <Grid item>
                    Rp.{formatRupiah(summaryOrder.voucher_discount)}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <h4>Biaya Admin</h4>
                  </Grid>
                  <Grid item>Rp.{formatRupiah(summaryOrder.admin_fee)}</Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <h4>Dompet Jualan</h4>
                  </Grid>
                  <Grid item>Rp.{formatRupiah(summaryOrder.dompetjualan)}</Grid>
                </Grid>
              </Box>
              <Box my={1}>
                <Divider />
              </Box>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <h4>Total</h4>
                </Grid>
                <Grid item>Rp.{formatRupiah(summaryOrder.amount)}</Grid>
              </Grid>
              <Box mb={2}></Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
