import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import { SearchCustomWrap } from "./style";
import iconSearch from "../../assets/icons/ic-search.svg";

const SearchCustom = ({ onChange, onSearch, withIcon = true }) => {
  let value = '';

  const change = (event) => {
    value = event.target.value;
    if (onChange) onChange(event);
  }

  const search = () => {
    onSearch(value)
  }

  return (
    <SearchCustomWrap>
      <TextField
        id="search"
        variant="outlined"
        placeholder="Cari berdasarkan Nama/Email"
        fullWidth
        onChange={change}
        InputProps={
          withIcon && {
            endAdornment: (
              <InputAdornment position="end">
                <img
                  src={iconSearch}
                  alt="search"
                  className="search"
                  onClick={search}
                />
              </InputAdornment>
            ),
          }
        }
      />
    </SearchCustomWrap>
  );
};

export default SearchCustom;
