import _, { isEmpty } from "lodash";
import { deleteProducts } from "services/axios";

export const onCheckedProduct = (
  checked,
  product,
  filteredProduct,
  setFilteredProduct,
  selectedProduct,
  setSelectedProduct
) => {
  let dataProducts = filteredProduct;
  const foundIndex = filteredProduct.findIndex((x) => x.slug === product.slug);
  dataProducts[foundIndex] = {
    ...product,
    checked,
  };

  setFilteredProduct(dataProducts);
  if (!isEmpty(selectedProduct)) {
    _.pullAllBy(selectedProduct, dataProducts, "slug");
    setSelectedProduct([
      ...selectedProduct,
      ...dataProducts.filter((x) => x.checked === true),
    ]);
  } else {
    setSelectedProduct(dataProducts.filter((x) => x.checked === true));
  }
};

export const onCheckedAll = (
  checked,
  isCheckedAll,
  setIsCheckedAll,
  filteredProduct,
  setFilteredProduct,
  selectedProduct,
  setSelectedProduct
) => {
  setIsCheckedAll(!isCheckedAll);
  let dataProducts = filteredProduct;
  filteredProduct.map((product) => {
    const foundIndex = filteredProduct.findIndex(
      (x) => x.slug === product.slug
    );
    dataProducts[foundIndex] = {
      ...product,
      checked,
    };
    return "";
  });

  setFilteredProduct(dataProducts);
  if (!isEmpty(selectedProduct)) {
    _.pullAllBy(selectedProduct, dataProducts, "slug");
    setSelectedProduct([
      ...selectedProduct,
      ...dataProducts.filter((x) => x.checked === true),
    ]);
  } else {
    setSelectedProduct(dataProducts.filter((x) => x.checked === true));
  }
};


export const handleDeleteSelectedProduct = async (selectedProduct, setSelectedProduct, setFilteredProduct, isOpenDialogDelete, setIsOpenDialogDelete, setLoading, setIsRefetch) => {
  setLoading(true)
  await selectedProduct.map((product) => {
    deleteProducts(product.slug);
  });
  setFilteredProduct(null)
  setSelectedProduct([]);
  setIsRefetch(true);
  setIsOpenDialogDelete(!isOpenDialogDelete);
  return ''
}