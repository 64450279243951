import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, Select } from '@material-ui/core';
import { UPDATE_BANK_ACCOUNT_STATUS } from 'services/bank.service';

export default function StatusMenu({ data, onUpdate }) {
  const { id, verification_status } = data;

  const handleChange = (event) => {
    UPDATE_BANK_ACCOUNT_STATUS(id, event.target.value).then((response) => {
      data = response.data
      onUpdate(data);
    });
  }

  return (
    <FormControl variant="outlined">
      <Select style={{ width: 200 }} value={verification_status} onChange={handleChange}>
        <MenuItem value="not_verified">
          Belum verifikasi
        </MenuItem>
        <MenuItem value="under_review">
          Sedang tahap review
        </MenuItem>
        <MenuItem value="verified">
          Sudah terverifikasi
        </MenuItem>
        <MenuItem value="rejected">
          Ditolak
        </MenuItem>
      </Select>
    </FormControl>
  );
}