const style = (theme) => ({
  containerContent: {
    width: (props) => (props.desktop ? 400 : "100%"),
    display: "flex",
    alignItems: "center",
    margin: "auto",
    height: "100%",
  },
  textField: {
    width: "100%",
  },
  textFieldPassword: {
    width: "100%",
    marginTop: 30,
  },
  inputGroup: {
    padding: (props) => (props.desktop ? "40px 0px 10px" : "20px 0 10px"),
  },
  checkboxRememberMe: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  forgotPassword: {
    justifyContent: "flex-end",
    display: "flex",
  },
  loginButton: {
    margin: "10px 0 20px",
    fontWeight: "bold",
    backgroundColor: theme.palette.yellow.primary,
    textTransform: 'capitalize'
  },
  icon: {
    height: 20,
  },
  haventAccount: {
    textAlign: "center",
    color: "#999999",
    marginBottom: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  container: {
    height: "100vh",
  },
  containerImg: {
    height: "100vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background:
      "linear-gradient(90deg, rgba(13,106,255,0) 0%, rgba(241,91,204,0) 51%, rgba(247,210,221,1) 58%)",
  },
  vector: {
    height: 300,
    position: "absolute",
    marginLeft: 130,
  },
  logo: {
    position: "absolute",
    marginLeft: 55,
    marginTop: 22,
  },
});

export default style;
