import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    TextField,
    Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";

const useTableStyles = makeStyles({
    container: {
        padding: "20px",
    },
    filtertype: {
        minWidth: "200px",
    },
});

const SearchTable = ({ filterValue, type, settype, setfilterValue }) => {
    const classes = useTableStyles();
    return (
        <div>
            <div className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item>
                        <FormControl variant="standard">
                            <OutlinedInput
                                placeholder="Search"
                                value={filterValue}
                                onChange={(event) => setfilterValue(event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <TextField
                            select
                            label="type"
                            variant="outlined"
                            className={classes.filtertype}
                            value={type}
                            onChange={(event) => settype(event.target.value)}
                        >
                            <MenuItem value="">semua</MenuItem>
                            <MenuItem value="discount">discount</MenuItem>
                            <MenuItem value="cashback">cashback</MenuItem>
                            <MenuItem value="ongkir">ongkir</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default SearchTable