import { axiosInstance } from "../config/fetchAxios";

export const GET_ALL_BANK_ACCOUNTS = (params, options) => {
  const url = '/api/v2/bank-accounts'

  const response = axiosInstance.get(url, { params, ...options })

  return response
}

export const UPDATE_BANK_ACCOUNT_STATUS = (id, status) => {
  const url = `/api/v2/bank-accounts/${id}`

  const response = axiosInstance.put(url, {
    status
  })

  return response
}

export const UPDATE_BANK_ACCOUNT_NOTES = (id, notes) => {
  const url = `/api/v2/bank-accounts/${id}`

  const response = axiosInstance.put(url, {
    notes
  })

  return response
}

// supported bank
export const GetAllSupportedBanks = () => {
  const response = axiosInstance.get('api/v2/supported_banks')
  return response
}