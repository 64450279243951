import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  Typography,
  Button,
  TextField,
  Input,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { addECategory } from "../../../../services/axios";
import swal from "sweetalert";

const TambahKategori = withStyles(style)((props) => {
  const { classes, category, onClose } = props;
  const [state, setState] = useState({
    name: "",
    icon_b64: "",
    is_root_node: 1,
    parent_id: "",
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChangePhoto = async (event) => {
      console.log(event.target.files[0].type)
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/svg+xml" ||
      event.target.files[0].type === "image/png"
    ) {
      const getData = await toBase64(event.target.files[0])
      setState({ ...state, icon_b64: getData.split(',')[1] });
    } else alert("Gunakan File dengan exstensi JPG/SVG/PNG");
  };

  const handleSimpan = () => {
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("icon_b64", state.icon_b64);
    state.is_root_node === 1 && formData.append("is_root_node", state.is_root_node);
    state.is_root_node === 0 && formData.append("parent_id", state.parent_id)
    if (state.name.length === 0){
      swal("Oops!", "Input Nama Kategori", "warning") 
    }else if ( state.icon_b64.length === 0){
      swal("Oops!", "Input Icon Kategori", "warning")
    }else {
      addECategory(formData);
      swal("Success!", "Kategori berhasil ditambahkan", "success").then(() => onClose())
    }
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Typography>
          <b>Tambah Kategori E-Produk</b>
        </Typography>
        <div style={{ marginTop: 20 }}>
          <Button
            style={{ textTransform: "capitalize" }}
            variant={state.is_root_node === 1 ? "contained" : "outlined"}
            color={state.is_root_node === 1 ? "primary" : "#000"}
            onClick={() => setState({ ...state, is_root_node: 1 })}
          >
            Kategori Utama
          </Button>
          <Button
            style={{ marginLeft: 10, textTransform: "capitalize" }}
            variant={state.is_root_node === 0 ? "contained" : "outlined"}
            color={state.is_root_node === 0 ? "primary" : "#000"}
            onClick={() => setState({ ...state, is_root_node: 0 })}
          >
            Subcategory
          </Button>
        </div>
        <div style={{ paddingTop: 20 }}>
          <Typography variant="body2" style={{ paddingLeft: 10 }}>
            Nama Kategori
          </Typography>
          <div className={classes.inputStyle}>
            <TextField
              InputProps={{ disableUnderline: true }}
              fullWidth={true}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </div>
        </div>

        <div style={{ paddingTop: 20, display: "flex" }}>
          {!state.is_root_node && (
            <div style={{ width: "50%" }}>
              <Typography variant="body2" style={{ paddingLeft: 10 }}>
                Pilih Kategori Utama
              </Typography>
              <FormControl
                className={classes.inputStyle}
                style={{ width: "100%" }}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  disableUnderline
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {category.map((res, index) => (
                    <MenuItem
                      key={index}
                      value={res.name}
                      onClick={() => setState({ ...state, parent_id: res.id })}
                    >
                      {res.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div style={{ width: "50%", marginLeft: !state.is_root_node && 50 }}>
            <Typography variant="body2" style={{ paddingLeft: 10 }}>
              Upload Icon
            </Typography>
            <div>
              <Input type="file" disableUnderline onChange={(event) => handleChangePhoto(event) } />
            </div>
          </div>
        </div>

        <div className={classes.divButton}>
          <Button className={classes.buttonGreen} onClick={handleSimpan}>Simpan</Button>
        </div>
      </div>
    </Fragment>
  );
});

export default TambahKategori;
