import styled from "styled-components";

export const WrapVariantPromo = styled.div`
  padding: 24px;
  border-radius: 5px;
  border: 2px solid #f7f7f7;
  cursor: pointer;
  .icon {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
    }
    float: left;
  }
  .text {
    position: relative;
    left: 20px;
    p {
      margin: 0;
      padding: 0;
      color: #666;
      font-size: 13px;
      margin-top: 4px;
    }
  }
`;
