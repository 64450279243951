const style = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  orderan: {
    backgroundColor: "#E0FFF2",
    borderRadius: 5,
    padding: 20,
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputStyle: {
    backgroundColor: "#E0FFF2",
    borderRadius: 30,
    paddingLeft: 10,
    width: 200
  },
  updateButtonDiv: {
    textAlign: 'end',
    paddingTop: 20
  },
  updateButton: {
    borderRadius: 30,
    textTransform: 'capitalize',
    color: "#fff"
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export default style;
