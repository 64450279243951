import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import swal from "sweetalert";
import moment from "moment";
import { updateAnnoucements } from "../../../services/axios";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const EditData = withStyles(style)((props) => {
  const { classes, onClose, open, data } = props;
  const [payload, setPayload] = useState({
    title: data.title,
    message: data.message,
    scope: data.scope,
    tags: data.tags,
    link: data.link,
    scheduled_time: moment(data.scheduled_time).format("YYYY-MM-DDTHH:mm"),
  });
  const [showSchedule, setShowSchedule] = useState(
    data.scheduled_time ? true : false
  );

  const handleChangePayload = (valueState, value) => {
    setPayload({ ...payload, [valueState]: value });
  };

  const handleTambah = async () => {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("message", payload.message);
    formData.append("scope[]", payload.scope);
    if (payload.tags) {
      formData.append("tags[]", payload.tags);
    }
    if (payload.link) {
      formData.append("link", payload.link);
    }
    if (payload.scheduled_time && showSchedule) {
      formData.append("scheduled_time", payload.scheduled_time);
    }
    formData.append("_method", "PATCH");
    const response = await updateAnnoucements(data.id, formData);
    response.data &&
      swal("Success", "Data updated", "success").then(() => onClose());
  };
  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Pengumuman</DialogTitle>
        <DialogContent>
          <div style={{ width: 500 }}>
            <div>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Title"
                onChange={(e) => handleChangePayload("title", e.target.value)}
                value={payload.title}
                variant="outlined"
              />
            </div>

            <div className={classes.range}>
              <TextField
                required
                id="outlined-required"
                fullWidth
                label="Message"
                onChange={(e) => handleChangePayload("message", e.target.value)}
                value={payload.message}
                variant="outlined"
              />
            </div>

            <div className={classes.range}>
              <FormControl
                variant="outlined"
                style={{ minWidth: 120, marginTop: 10 }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Scope
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={(e) => handleChangePayload("scope", e.target.value)}
                  value={payload.scope}
                  label="Scope"
                >
                  <MenuItem value="" disabled>
                    <em>Pilih Role</em>
                  </MenuItem>
                  <MenuItem value={0} disabled>
                    Supplier
                  </MenuItem>
                  <MenuItem value={1}>Seller</MenuItem>
                  <MenuItem value={2}>SuperSeller</MenuItem>
                  <MenuItem value={3}>SuperSeller Gold</MenuItem>
                  <MenuItem value={4}>SuperSeller & SuperSeller Gold</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.range}>
              <TextField
                id="outlined-required"
                fullWidth
                label="Tags"
                onChange={(e) =>
                  handleChangePayload(
                    "tags",
                    e.target.value.replace(" ", "").split(",")
                  )
                }
                value={payload.tags}
                placeholder="Pisah antar tag dengan koma"
                variant="outlined"
              />
            </div>
            <div className={classes.range}>
              <TextField
                id="outlined-required"
                fullWidth
                label="Link"
                onChange={(e) =>
                  handleChangePayload(
                    "link",
                    e.target.value.replace(" ", "").split(",")
                  )
                }
                value={payload.link}
                placeholder="Link Menuju Toko / Produk (opsional)"
                variant="outlined"
              />
            </div>
            <div className={classes.range}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showSchedule}
                    onClick={(e) => setShowSchedule(e.target.checked)}
                  />
                }
                label="Jadwal"
              />
              {showSchedule && (
                <TextField
                  id="datetime-local"
                  label="Jadwal"
                  type="datetime-local"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: new Date().toISOString().slice(0, 16),
                  }}
                  value={payload.scheduled_time}
                  onChange={(e) =>
                    handleChangePayload("scheduled_time", e.target.value)
                  }
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingBottom: 24 }}>
          <Button variant="contained" color="secondary" onClick={handleTambah}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});

export default EditData;
