import styled from 'styled-components'

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: #050505;
  font-size: 16px;
  font-family: 'DMSans-Bold';
`

export const Button = styled.button`
  outline: 0;
  padding: 16px 92px;
  color: #787878;
  font-size: 16px;
  font-family: 'DMSans-Bold';
  letter-spacing: 0.02em;
  background: #FFF4D6;
  border: none;
  border-radius: 10px;
  :hover {
    cursor: pointer;
  }
`

export const Card = styled.div`
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  p{margin:0;padding:0;color:#787878;}
  .black{color: #000; font-family: 'DMSans-Bold'}
`

export const BankSection = styled.div`
  position: relative;
  margin-top: 16px;

  .title {
    font-size: 13px;
    color: #000;
  }
  
  .cardBank {
    border-radius: 5px;
    background: #F7F7F7;
    border: ${props => props.borderColor ? `1px solid ${props.borderColor }` : `none` };
    margin-top: 8px;
    margin-bottom: 32px;
    padding: 16px;
    .title {
      font-size: 20px;
      color: #333;
      letter-spacing: 0.02em;
    }
    .number{
      font-size: 26px;
      font-family: 'DMSans-Bold';
      font-weight: 700;
      color: #333;
      letter-spacing: 0.02em;
    }
    .cardHolder {
      letter-spacing: 0.02em;
      font-size: 16px;
      color: #888;
    }
    .editRekening {
      background: #FFF4D6;
      padding: 16px;
      text-align: center;
      color: #787878;
      font-size: 20px;
      border-radius: 10px;
      :hover {
        cursor: pointer;
      }
    }
  }

  button {
    outline: 0;
    border: 0;
    background: #FBBD1C;
    border-radius: 5px;
    font-size: 20px;
    color: #FFF;
    font-family: 'DMSans-Bold';
    letter-spacing: 0.02em;
    padding: 17px;
    text-align: center;
    width: 100%;
  }
`

export const LabelPeriode = styled.button`
  position: absolute;
  font-size: 8px;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid #666;
  color: #666;
  font-family: 'DMSans-Bold';
  outline: none;
  background: none;
  letter-spacing: 0.02em;
  :focus {
    outline: none;
  }
  ${({ type }) => type === "progress" && `
    border: 1px solid #FDCA40;
    color: #FDCA40;
  `}
  ${({ type }) => type === "soon" && `
    border: 1px solid #FF6564;
    color: #FF6564;
  `}
  ${({ type }) => type === "completed" && `
    border: 1px solid #29C0C7;
    color: #29C0C7;
  `}
`