import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Dialog,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import styles from "./styles";
import { ReactComponent as PesananIcon } from "../../../../assets/icons/checklist.svg";
import { ReactComponent as PemasukanIcon } from "../../../../assets/icons/dollar.svg";
import { ReactComponent as PengeluaranIcon } from "../../../../assets/icons/interface1.svg";
import { getReportSummary } from "../../../../services/axios";
import numberSeparator from "../../../../utils/numberSeparator";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DateRange, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import { GET_REPORTS_SUMMARY_FILTER } from "services/reports.service";

const SummaryPenjualan = withStyles(styles)((props) => {
  const { classes } = props;
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [reportSummary, setReportSummary] = useState({});
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);

  const onApplyFilter = async () => {
    setIsOpenDate(!isOpenDate);
    const report = await GET_REPORTS_SUMMARY_FILTER(
      moment(selectionRange[0].startDate).format("YYYY-MM-DD"),
      moment(selectionRange[0].endDate).format("YYYY-MM-DD")
    );
    setReportSummary(report);
  };

  useEffect(() => {
    const fetch = async () => {
      const report = await getReportSummary();
      setReportSummary(report);
    };
    fetch();
  }, []);

  const data = [
    {
      icon: <PesananIcon />,
      text: "Total Pesanan",
      count: reportSummary.order_count,
    },
    {
      icon: <PemasukanIcon />,
      text: "Pemasukan",
      count: reportSummary.platform_income_idr,
    },
    {
      icon: <PemasukanIcon />,
      text: "Total Ongkir",
      count: reportSummary.supplier_shipping_idr
        ? reportSummary.supplier_shipping_idr
        : 0,
    },
    {
      icon: <PengeluaranIcon />,
      text: "Total Produk idejualan",
      count: reportSummary.product_count,
    },
  ];
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div className={classes.title} style={{ float: "left" }}>
            <b>Summary Penjualan</b>
          </div>
          <div style={{ position: "relative", top: 22, left: 16 }}>
            <Button
              className={classes.filter}
              onClick={() => setIsOpenDate(!isOpenDate)}
            >
              <Typography>Filter</Typography>
              <ArrowDropDownIcon />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          {isOpenDate && !isMdDown && (
            <div className={classes.calendar}>
              <DateRangePicker
                onChange={(item) => setSelectionRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={selectionRange}
                direction="vertical"
              />
              <Button
                className={classes.filterApply}
                onClick={() => onApplyFilter()}
              >
                <Typography>Apply</Typography>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {data.map((res, i) => (
          <Grid item xs={12} sm={12} lg={6} xl={3} key={i}>
            <Paper className={classes.wrapper}>
              <div className={classes.bgIcon}>{res.icon}</div>
              <div style={{ paddingLeft: 20 }}>
                <Typography variant="caption">{res.text}</Typography>
                <Typography variant="h5">
                  {numberSeparator(parseInt(res.count))}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {isMdDown && (
        <Dialog
          fullScreen
          open={isOpenDate}
          onClose={() => setIsOpenDate(false)}
        >
          <DateRange
            onChange={(item) => setSelectionRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={selectionRange}
            direction="vertical"
          />
          <Button
            className={classes.filterApply}
            onClick={() => onApplyFilter()}
          >
            <Typography>Apply</Typography>
          </Button>
        </Dialog>
      )}
    </Fragment>
  );
});

export default SummaryPenjualan;
