import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import { Typography } from "@material-ui/core";
import Content from "./content";
import StatusOrder from "./status-order";
import { getAllOrders } from "../../services/axios";
import Loading from "../../components/loading";

const Order = withStyles(style)((props) => {
  const { classes } = props;
  const [state, setState] = useState({
    openUbahPesanan: false,
    dataEdit: null,
    order: null,
    filteredOrder: [],
    selectedSupplier: null,
    search: "",
    loadingSearch: false,
    listSupplier: null,
  });
  const slug = state.selectedSupplier ? state.selectedSupplier.slug : "";

  useEffect(() => {
    const fetch = async () => {
      const response = await getAllOrders();
      setState({
        ...state,
        order: response,
        filteredOrder: response.data,
      });
    };
    fetch();
  }, []);

  const handleUbahPesanan = (data) => {
    setState({ ...state, openUbahPesanan: true, dataEdit: data });
  };

  const handleCloseStatusOrder = async (res) => {
    if (res && res.data) {
      const orderIndex = state.filteredOrder.findIndex(
        (order) => order.id === res.data.id
      );

      if (orderIndex >= 0) {
        const newFilteredOrder = [...state.filteredOrder];

        newFilteredOrder[orderIndex] = {
          ...newFilteredOrder[orderIndex],
          ...res.data,
        };

        newFilteredOrder[orderIndex].histories = res.data.status_history;

        setState({
          ...state,
          openUbahPesanan: false,
          filteredOrder: newFilteredOrder,
        });
      }
    } else {
      setState({
        ...state,
        openUbahPesanan: false,
      });
    }
  };

  const handleChangeSupplier = () => {
    setState({ ...state, selectedSupplier: null });
  };

  return (
    <Fragment>
      {state.openUbahPesanan ? (
        <Fragment>
          <StatusOrder
            data={state.dataEdit}
            order={state.order}
            filteredOrder={state.filteredOrder}
            onClose={handleCloseStatusOrder}
          />
        </Fragment>
      ) : (
        <Fragment>
          {state.selectedSupplier && !state.order ? (
            <Fragment>
              <Loading />
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes.header}>
                <Typography variant="h6">
                  <b>Order</b>
                </Typography>
              </div>

              <div style={{ paddingTop: 20 }}>
                <Content
                  handleUbahPesanan={handleUbahPesanan}
                  order={state.order}
                  filteredOrder={state.filteredOrder}
                  selectedSupplier={state.selectedSupplier}
                  handleChangeSupplier={handleChangeSupplier}
                  handleOrderData={(response) =>
                    setState({
                      ...state,
                      order: response,
                      filteredOrder: response.data,
                    })
                  }
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

export default Order;
