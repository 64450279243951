import React from "react";
import moment from "moment";

import {
  Grid,
  Box,
  CircularProgress,
  Paper,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import DateRangeIcon from "@material-ui/icons/DateRange";
import XLSIcon from "@material-ui/icons/Description";
import { DateRange } from "react-date-range";

import {
  ORDER_UPGRADE,
  EXPORT_EXCEL_ORDER_UPGRADE,
} from "../../services/orders.service";

import CardOrder from "./components/CardOrder";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const paymentTypeFilter = [
  {
    id: "manual",
    label: "Manual",
    isSelected: false,
  },
  {
    id: "danamon_online",
    label: "Danamon online",
    isSelected: false,
  },
  {
    id: "gopay",
    label: "GoPay",
    isSelected: false,
  },
  {
    id: "bank_transfer",
    label: "Bank transfer",
    isSelected: false,
  },
  {
    id: "echannel",
    label: "E-channel",
    isSelected: false,
  },
  {
    id: "cstore",
    label: "C-store",
    isSelected: false,
  },
  {
    id: "shopeepay",
    label: "ShopeePay",
    isSelected: false,
  },
  {
    id: "playstore",
    label: "Playstore",
    isSelected: false,
  },
  {
    id: "appstore",
    label: "Appstore",
    isSelected: false,
  },
];

const OrderUpgrade = () => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [paymentType, setPaymentType] = React.useState([]);
  const [selectionRange, setSelectionRange] = React.useState([
    {
      startDate: '',
      endDate: '',
      key: "selection",
    },
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickDate = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDate = () => {
    setAnchorEl(null);
  };

  const isOpenDate = Boolean(anchorEl);
  const formatedStartDate = moment(selectionRange[0].startDate).format(
    "YYYY-MM-DD"
  );
  const formatedEndDate = moment(selectionRange[0].endDate).format(
    "YYYY-MM-DD"
  );

  React.useEffect(() => {
    getOrderUpgrade();
  }, [page, paymentType, selectionRange]);

  const handleChangePaymentType = (e, payment) => {
    const {
      target: { value },
    } = e;
    setPaymentType(typeof value === "string" ? value.split(",") : value);
  };

  const getOrderUpgrade = async () => {
    try {
      setIsLoading(true);
      const res = await ORDER_UPGRADE({
        page,
        "filter[payment_type]": paymentType.join(","),
        "filter[date_between]": selectionRange[0].startDate === '' ? '' : `${formatedStartDate},${formatedEndDate}`,
        paginate: 5,
      });
      setData(res.data.data);
      setLastPage(res.data.meta.last_page);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleExportExcel = async () => {
    const res = await EXPORT_EXCEL_ORDER_UPGRADE({
      page,
      "filter[payment_type]": paymentType.join(","),
      "filter[date_between]": selectionRange[0].startDate === '' ? '' : `${formatedStartDate},${formatedEndDate}`,
      paginate: 5,
    });

    const url = window.URL.createObjectURL(
      new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Excel Order Upgrade ${selectionRange[0].startDate === '' ? '' : `${formatedStartDate} - ${formatedEndDate}`}.xlsx`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const renderCard = () => {
    if (isLoading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={60} />
        </Box>
      );
    } else {
      if (!data.length) {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <h3>Data tidak ditemukan</h3>
          </Box>
        );
      } else {
        return data.map((item, i) => <CardOrder key={i} data={item} />);
      }
    }
  };

  return (
    <div>
      <Box component="h3" mb={2}>
        Order Ugrade
      </Box>
      <Paper style={{ padding: 30 }}>
        <Box
          mb={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container spacing={2}>
            <Grid item md={3} sm={12} xs={12}>
              <Typography variant="caption">Payment type</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={paymentType}
                  onChange={handleChangePaymentType}
                  renderValue={(selected) => selected.join(", ")}
                  input={<OutlinedInput />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                    variant: "menu",
                    getContentAnchorEl: null,
                  }}
                >
                  {paymentTypeFilter.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      <Checkbox checked={paymentType.indexOf(type.id) > -1} />
                      <ListItemText primary={type.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Typography variant="caption">Date</Typography>
              <TextField
                value={selectionRange[0].startDate === '' ? '' : `${formatedStartDate} s/d ${formatedEndDate}`}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickDate}>
                        <DateRangeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            startIcon={<XLSIcon />}
            variant="contained"
            style={{
              backgroundColor: "green",
              color: "#fff",
              textDecoration: "none",
              textTransform: "none",
              width: 200,
            }}
            onClick={handleExportExcel}
          >
            Export Excel
          </Button>
        </Box>
        {renderCard()}
        <Box display="flex" justifyContent="flex-end">
          <Pagination
            count={lastPage}
            variant="outlined"
            onChange={(e, value) => setPage(value)}
            color="secondary"
          />
        </Box>
      </Paper>
      <Popover
        open={isOpenDate}
        anchorEl={anchorEl}
        onClose={handleCloseDate}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRange
          onChange={(item) => setSelectionRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={selectionRange}
          direction="horizontal"
        />
      </Popover>
    </div>
  );
};

export default OrderUpgrade;
