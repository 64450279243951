const styles = (theme) => ({
  tambahGambar: {
    border: `1px solid ${theme.palette.green.main}`,
    height: 125,
    width: "90%",
    cursor: "pointer",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto 20px",
  },
  error: {
    borderColor: "#f44336",
  },
  inputGambar: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  tambahGambar1: {
    height: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 50,
    color: theme.palette.green.main,
  },
  tambahGambar2: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.green.main,
  },
});

export default styles;
