import {
  Checkbox,
  Fade,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ButtonCustom, SearchCustom } from "components";
import React from "react";
import { formatRupiah } from "utils/currencyFormat";
import { ModalTableProductWrap } from "./style";
import { isEmpty } from "lodash";
import { Pagination } from "@material-ui/lab";
import Loading from "components/loading";
import { getAllCategories, getAllProducts } from "services/axios";

class ModalTableProduct extends React.Component {
  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem("DATA"));
    this.state = {
      isOpen: props.isOpen,
      categories: [],
      products: [],
      selectedProducts: props.selectedProducts ?? [],
      filters: {
        paginate: 4,
        page: 1,
        name: "",
        sort: "none",
        category: "none",
      },
      totalPage: 0,
      isLoading: true,
    };
  }

  componentDidMount() {
    // Fetch categories
    getAllCategories().then(({ data }) => {
      this.setState({
        categories: data,
      });
    });

    // Fetch products
    getAllProducts({
      paginate: 4,
    }).then(({ data, meta }) => {
      this.setState((state) => ({
        products: data,
        filters: {
          ...state.filters,
        },
        isLoading: false,
      }));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isOpen !== this.state.isOpen ||
      (this.props.selectedProducts &&
        this.props.selectedProducts !== this.state.selectedProducts)
    ) {
      this.setState({
        isOpen: this.props.isOpen,
        selectedProducts: this.props.selectedProducts ?? [],
      });
    }

    if (prevState.filters !== this.state.filters) {
      const state = this.state.filters;

      const query = {
        page: state.page,
        paginate: state.paginate,
      };

      if (state.name) query["filter[name]"] = state.name;
      if (state.category !== "none")
        query["filter[category.id]"] = state.category;
      if (state.sort !== "none") query["sort"] = state.sort;

      // Fetch products
      this.setState({ isLoading: true });
      getAllProducts(query).then(({ data, meta }) => {
        this.setState({
          products: data,
          totalPage: meta.last_page,
          isLoading: false,
        });
      });
    }
  }

  onChangePagination(number) {
    this.setState((state) => {
      return {
        filters: {
          ...state.filters,
          page: number,
        },
      };
    });
  }

  render() {
    const { onClose, handleSubmit } = this.props;

    const { filters, isLoading, totalPage } = this.state;

    const _mappingCategories = () => {
      const item = [];

      this.state.categories.forEach((category) => {
        item.push(
          <MenuItem value={category.id} key={category.id}>
            <em>{category.name}</em>
          </MenuItem>
        );
      });

      return item;
    };

    const CustomPagination = () => {
      return (
        <Pagination
          color="primary"
          page={filters.page}
          count={totalPage}
          onChange={(event, value) => this.onChangePagination(value)}
        />
      );
    };

    const onSearch = (value) => {
      this.setState((state) => ({
        filters: {
          ...state.filters,
          name: value,
        },
      }));
    };

    const onCheckedAll = (checked) => {
      this.state.products.forEach((product) => {
        onCheckedRow(checked, product);
      });
    };

    const onCheckedRow = (checked, row) => {
      const selected = this.state.selectedProducts;
      if (checked) {
        if (selected.indexOf(row) === -1) {
          selected.push(row);
        }
      } else {
        const index = selected.indexOf(row);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      }

      this.setState({
        selectedProducts: selected,
      });
    };

    const _table = () => (
      <ModalTableProductWrap
        style={{ maxHeight: 320, marginBottom: 20, overflow: "auto" }}
      >
        <TableContainer>
          <Table aria-label="table product" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" width={50}>
                  <Checkbox
                    onChange={(e) => onCheckedAll(e.target.checked)}
                    checked={this.state.products.every((product) =>
                      this.state.selectedProducts.some(
                        (selected) => selected.slug === product.slug
                      )
                    )}
                  />
                </TableCell>
                <TableCell align="left">Pilih Produk</TableCell>
                <TableCell align="left" width={100}>
                  Harga
                </TableCell>
                <TableCell align="left">Stok</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow key="loading">
                  <TableCell align="center" colSpan={4}>
                    <Loading style={{ height: 250 }} />
                  </TableCell>
                </TableRow>
              ) : (
                this.state.products.map((row) => (
                  <TableRow key={row.slug}>
                    <TableCell align="left">
                      <Checkbox
                        onChange={(e) => onCheckedRow(e.target.checked, row)}
                        checked={this.state.selectedProducts.some(
                          (item) => item.slug === row.slug
                        )}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={row.image_url}
                          alt={row.name}
                          width="40"
                          style={{ padding: 4 }}
                        />
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      Rp {formatRupiah(row.skus[0].price.reseller)}
                    </TableCell>
                    <TableCell align="left">{row.skus[0].stock}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalTableProductWrap>
    );

    return (
      <Modal
        open={this.state.isOpen}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={this.state.isOpen}>
          <div
            style={{
              top: "5%",
              position: "relative",
              maxWidth: 820,
              height: 600,
              backgroundColor: "#FFF",
              margin: "0 auto",
              padding: 16,
              borderRadius: 10,
              outline: "none",
              overflowY: "auto",
            }}
          >
            <h2 id="transition-modal-title">Pilih Produk</h2>
            <div style={{ marginBottom: 24 }}>
              <Grid container spacing={2}>
                <Grid item md={8} sm={12} xs={12}>
                  <SearchCustom onSearch={onSearch} withIcon />
                </Grid>
                <Grid item md={2} sm={12} xs={12}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={filters.sort}
                      onChange={(e) =>
                        this.setState((state) => ({
                          filters: { ...state.filters, sort: e.target.value },
                        }))
                      }
                    >
                      <MenuItem value="none">
                        <em>Urutkan</em>
                      </MenuItem>
                      <MenuItem value="-ref_price">
                        <em>Termahal</em>
                      </MenuItem>
                      <MenuItem value="ref_price">
                        <em>Termurah</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2} sm={12} xs={12}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={filters.category}
                      onChange={(e) =>
                        this.setState((state) => ({
                          filters: {
                            ...state.filters,
                            category: e.target.value,
                          },
                        }))
                      }
                    >
                      <MenuItem value="none">
                        <em>Kategori</em>
                      </MenuItem>
                      {_mappingCategories()}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>{_table()}</div>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                {this.state.selectedProducts.length ?? 0} Produk dipilih.
              </Grid>
              <Grid item md={6} sm={12} xs={12} style={{ textAlign: "right" }}>
                <div>{CustomPagination()}</div>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              spacing={2}
              style={{ marginBottom: 20 }}
            >
              <Grid item>
                <ButtonCustom withBorder color={"#929292"} onClick={onClose}>
                  Batal
                </ButtonCustom>
              </Grid>
              <Grid item>
                <ButtonCustom
                  bgColor="red"
                  isDisabled={isEmpty(this.state.selectedProducts)}
                  onClick={() => handleSubmit(this.state.selectedProducts)}
                >
                  Tambah ke Promo
                </ButtonCustom>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default ModalTableProduct;
