import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["00", "02", "04", "06", "08", "10", "12"];

export const data = {
  labels,
  datasets: [
    {
      label: "Hari ini",
      data: [1000, 5000, 7000, 6000, 2000, 9000, 8000],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "11-04-2022",
      data: [5000, 3000, 1000, 2000, 1000, 7000, 4000],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

console.log(data);

const Chart = () => {
  return <Line options={options} data={data} />;
};

export default Chart;
