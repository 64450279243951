import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";

const useTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    minWidth: "200px",
  },
});

const PaymentTable = () => {
  const classes = useTableStyles();
  const [filterId, setFilterId] = React.useState("");
  const [filterAmount, setFilterAmount] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState("");
  const [filterName, setFilterName] = React.useState("");
  const [filterEmail, setFilterEmail] = React.useState("");
  const [filterPhone, setFilterPhone] = React.useState("");

  return (
    <Paper elevation={3}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item>
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Cari Id"
                value={filterId}
                onChange={(event) => setFilterId(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Cari Jumlah"
                value={filterAmount}
                onChange={(event) => setFilterAmount(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item>
            <TextField
              select
              label="Status"
              variant="outlined"
              className={classes.filterStatus}
              value={filterStatus}
              onChange={(event) => setFilterStatus(event.target.value)}
            >
              <MenuItem value="all">Semua</MenuItem>
              <MenuItem value="settlement">Sudah dibayar</MenuItem>
              <MenuItem value="checkout">Menunggu pembayaran</MenuItem>
              <MenuItem value="canceled">Batal</MenuItem>
              <MenuItem value="expire">Kadaluarsa</MenuItem>
              <MenuItem value="completed">Selesai</MenuItem>
            </TextField>
          </Grid>

          <Grid item>
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Cari nama reseller"
                value={filterName}
                onChange={(event) => setFilterName(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Cari email reseller"
                value={filterEmail}
                onChange={(event) => setFilterEmail(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Cari no.hp reseller"
                value={filterPhone}
                onChange={(event) => setFilterPhone(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      {/* Table */}
      <TableContent
        filterId={filterId}
        filterStatus={filterStatus !== "all" ? filterStatus : null}
        filterAmount={filterAmount}
        filterName={filterName}
        filterEmail={filterEmail}
        filterPhone={filterPhone}
      />
    </Paper>
  );
};

export default PaymentTable;
