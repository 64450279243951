import React, { useCallback } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { GET_SHOP_CHANNELS } from "services/shop-channel.service";
import axios from "axios";
import _ from "lodash";
import DocumentsDialog from "./DocumentsDialog";

const headCells = [
  { id: "shop_name", label: "Nama toko" },
  { id: "shop_url", label: "URL toko" },
  { id: "channel", label: "Channel" },
  { id: "shop", label: "Toko" },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Shop channel
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function TableHeader() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableContent(props) {
  const classes = useStyles();
  const { shopName, shopUrl, channel, ownerEmail, ownerName } = props;
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [meta, setMeta] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const cancelTokenRef = React.useRef();

  const getData = useCallback(
    _.debounce(
      ({
        rowsPerPage,
        page,
        shopName,
        shopUrl,
        channel,
        ownerEmail,
        ownerName,
      }) => {
        if (cancelTokenRef.current) cancelTokenRef.current.cancel("canceled");

        const cancelToken = axios.CancelToken.source();
        cancelTokenRef.current = cancelToken;

        GET_SHOP_CHANNELS(
          {
            "filter[shop_name]": shopName,
            "filter[shop_url]": shopUrl,
            "filter[channel]": channel,
            "filter[owner.email]": ownerEmail,
            "filter[owner.name]": ownerName,
            paginate: rowsPerPage,
            page: page + 1,
          },
          {
            cancelToken: cancelToken.token,
          }
        )
          .then(({ data, meta }) => {
            setRows(data);
            setMeta(meta);
            cancelTokenRef.current = null;
          })
          .catch((err) => {});
      },
      500
    ),
    []
  );

  React.useEffect(() => {
    setPage(0);
  }, [shopName, shopUrl, channel, ownerEmail, ownerName]);

  React.useEffect(() => {
    getData({
      rowsPerPage,
      page,
      shopName,
      shopUrl,
      channel,
      ownerEmail,
      ownerName,
    });
  }, [rowsPerPage, page, shopName, shopUrl, channel, ownerEmail, ownerName]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <TableToolbar />

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader />

          <TableBody>
            {rows.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>{row.shop_name}</TableCell>
                <TableCell component="th" scope="row">
                  {row.shop_url}
                </TableCell>
                <TableCell>{row.channel}</TableCell>
                <TableCell>
                  {row.owner ? (
                    <DocumentsDialog data={row.owner} />
                  ) : (
                    "Toko tidak ditemukan"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
