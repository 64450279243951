import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";

import {
  Grid,
  Button,
  Paper,
  Box,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/garbage.svg";

const CardCustomProduct = withStyles(style)(
  ({
    classes,
    data,
    image,
    handleChangePhoto,
    handleChangeCustomVariant,
    handleAddCustomVariant,
    handleRemoveCustomVariant,
  }) => {
    return (
      <div>
        <Grid container style={{ marginBottom: 18 }}>
          <Grid item md={3}>
            Gambar Produk
          </Grid>
          <Grid item xs>
            <div
              className={classes.tambahGambar}
              style={{ backgroundImage: `url(${image})` }}
            >
              {image === null && (
                <Fragment>
                  <Typography className={classes.tambahGambar1}>+</Typography>
                </Fragment>
              )}
              <input
                type="file"
                className={classes.inputGambar}
                onChange={(event) => handleChangePhoto(event, data.code)}
              />
            </div>
          </Grid>
        </Grid>
        {data.map((variant, i) => {
          return (
            <>
              <Grid container key={i} style={{ marginBottom: 18 }}>
                <Grid item md={3}>
                  Custom Variasi {i + 1}
                </Grid>
                <Grid item xs>
                  <Paper variant="outlined">
                    <Box p={3}>
                      {i !== 0 && (
                        <Grid container justify="flex-end">
                          <Grid item>
                            <IconButton
                              onClick={() => handleRemoveCustomVariant(i)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      <br />
                      <Grid container>
                        <Grid item md={3}>
                          Nama Variasi
                        </Grid>
                        <Grid item xs>
                          <TextField
                            placeholder="Masukan nama variasi, contoh: Panjang, Lebar"
                            value={data[i]}
                            defaultValue={variant}
                            className={classes.inputStyle}
                            InputProps={{ disableUnderline: true }}
                            fullWidth
                            onChange={(event) =>
                              handleChangeCustomVariant(event, i)
                            }
                          />
                          <Typography variant="caption">
                            <i>
                              *jika bisa diukur, sediakan satuan di akhir nama
                              variasi. cth: ... (meter)
                            </i>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </>
          );
        })}
        <Grid container>
          <Grid item md={3}>
            Custom Variasi {data.length + 1}
          </Grid>
          <Grid item xs>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              style={{
                border: "2px dashed #29C0C7",
                borderRadius: 5,
                color: "#29C0C7",
                textTransform: "none",
                fontWeight: 600,
              }}
              startIcon={<AddIcon />}
              onClick={handleAddCustomVariant}
            >
              Tambah Custom Variasi
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
);

export default CardCustomProduct;
