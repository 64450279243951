import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TableContent from "./TableContent";
import { getResellerLevels } from "../../../services/axios";

const useBankTableStyles = makeStyles({
  container: {
    padding: "20px",
  },
  filterStatus: {
    marginLeft: "10px",
    minWidth: "200px",
  },
});

const BankTable = () => {
  const classes = useBankTableStyles();
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState("all");
  const [level, setLevel] = React.useState("all");
  const [levelList, setLevelList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const data = await getResellerLevels();
      setLevelList(data.data);
    })();
  }, []);

  return (
    <Paper elevation={3}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item>
          <FormControl variant="standard">
            <OutlinedInput
              placeholder="Cari reseller"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            select
            label="Status"
            variant="outlined"
            className={classes.filterStatus}
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          >
            <MenuItem value="all">Semua</MenuItem>
            <MenuItem value="not_verified">Belum verifikasi</MenuItem>
            <MenuItem value="under_review">Sedang tahap review</MenuItem>
            <MenuItem value="verified">Sudah terverifikasi</MenuItem>
            <MenuItem value="rejected">Ditolak</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            select
            label="Level"
            variant="outlined"
            className={classes.filterStatus}
            value={level}
            onChange={(event) => setLevel(event.target.value)}
          >
            <MenuItem value="all">Semua</MenuItem>

            {levelList.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* Table */}
      <TableContent
        search={search}
        status={status !== "all" ? status : null}
        level={level !== "all" ? level : null}
      />
    </Paper>
  );
};

export default BankTable;
