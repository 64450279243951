import React from "react";
import iconDelete from "../../assets/icons/ic-delete.svg";
import iconEdit from "../../assets/icons/ic-edit.svg";
import iconView from "../../assets/icons/ic-view.svg";
import { ActionButtonWrap } from "./style";

const ActionButtonComponent = ({ type = "view", style, onClick, circleBorder }) => {
  const _icon = () => {
    let icon = "";
    if (type === "view") {
      icon = iconView;
    } else if (type === "edit") {
      icon = iconEdit;
    } else {
      icon = iconDelete;
    }
    return icon;
  };
  return (
    <ActionButtonWrap style={style} onClick={onClick} circleBorder={circleBorder}>
      <div className="icon">
        <img src={_icon()} alt={`icon-${type}`} />
      </div>
    </ActionButtonWrap>
  );
};

export default ActionButtonComponent;
