import { axiosInstance } from "../config/fetchAxios";

export const GET_ALL_PICS = (params, options) => {
  const url = "/api/v2/pic";
  return axiosInstance.get(url, { params, ...options });
};

export const GET_PIC = (id) => {
  const url = `/api/v2/pic`;
  return axiosInstance
    .get(url, {
      params: {
        "filter[id]": id,
      },
    })
    .then(({ data }) => data[0]);
};

export const CREATE_PIC = (body) => {
  const url = "/api/v2/pic";
  return axiosInstance.post(url, body);
};

export const UPDATE_PIC = (id, body) => {
  const url = "/api/v2/pic/" + id;

  if (body instanceof FormData) {
    body.append("_method", "PUT");
  } else {
    body["_method"] = "PUT";
  }

  return axiosInstance.post(url, body);
};

export const DELETE_PIC = (id) => {
  const url = "/api/v2/pic/" + id;
  return axiosInstance.delete(url);
};
