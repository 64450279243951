import { axiosInstance } from "../config/fetchAxios";

export const GET_SUPPLIER_WALLET_TRANSACTIONS = async (status, page, slug) => {
  const url = `/api/v2/suppliers/${slug}/wallet?include=order&filter[status]=${status}&page=${page}`;
  const data = axiosInstance.get(url);
  return data;
};

export const GET_RESELLER_WALLET_TRANSACTIONS = async (status, page, id) => {
  const url = `/api/v2/resellers/${id}/wallet?filter[status]=${status}&page=${page}`;
  const data = axiosInstance.get(url);
  return data;
};

export const GET_WALLET_TOPUP_HISTORY = (params, options) => {
  const url = "/api/v2/wallet/topup";

  const response = axiosInstance.get(url, { params, ...options });

  return response;
};
