import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "../style";
import {
  Avatar,
  Typography,
  Button,
  Paper,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Menu,
  Grid,
  Badge,
  Chip,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Pagination from "@material-ui/lab/Pagination";
import {
  getReseller,
  deleteReseller,
  editReseller,
  getCity,
} from "../../../services/axios";
import swal from "sweetalert";
import _ from "lodash";

import { ReactComponent as BinIcon } from "../../../assets/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as AddCoinIcon } from "../../../assets/icons/ic-add-coins.svg";
import { ReactComponent as MinusCoinIcon } from "../../../assets/icons/ic-minus-coins.svg";
import moment from "moment";
import ModalAddCoins from "../modalAddCoins";
import ModalMinusCoins from "../modalMinusCoins";
import ResellerLevelMenu from "./ResellerLevelMenu";
import PicMenu from "./PicMenu";
import { fetchPic } from "utils/picData";
import ModalAddWallet from "../modalAddWallet";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.yellow.tertiary,
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const Reseller = withStyles(style)((props) => {
  const {
    classes,
    resellers,
    provinces,
    pics,
    filteredResellers,
    handleEditData,
    handleRefreshData,
    handleUpdateData,
    handleDateBetween,
    handleRole,
  } = props;
  const [filter, setFilter] = useState({
    page: 1,
    city: "",
    province: "",
    pic: "",
    role: "",
    created_between: "",
  });
  const [state, setState] = useState({
    filteredResellers: filteredResellers,
    resellers: resellers,
    provinces: provinces,
    pics: pics,
    cities: null,
    openEditData: false,
    dataEdit: null,
    search: "",
    created_between: `${moment()
      .add(-30, "days")
      .format("YYYY-MM-DD 00:00:00")},${moment().format(
      "YYYY-MM-DD 00:00:00"
    )}`,
    created_start_between: moment()
      .add(-30, "days")
      .format("YYYY-MM-DD 00:00:00"),
    created_end_between: moment().format("YYYY-MM-DD 00:00:00"),
  });
  const [isOpenModalAddCoins, setIsOpenModalAddCoins] = useState(false);
  const [isOpenModalMinusCoins, setIsOpenModalMinusCoins] = useState(false);
  const [isOpenModalAddWallet, setIsOpenModalAddWallet] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const { page, city, province, pic, role, created_between } = filter;

  const handleFetch = async () => {
    const res = await getReseller(
      `?filter[name]=${state.search}&page=${page}&filter[city.id]=${
        city.id || ""
      }&filter[pic_id]=${pic || ""}&filter[level]=${
        role || ""
      }&filter[province.id]=${province || ""}&filter[created_between]=${
        created_between || ""
      }`
    );
    setState({
      ...state,
      filteredResellers: res.data,
      resellers: res,
    });
  };

  useEffect(() => {
    if (state.resellers !== null && state.filteredResellers !== null) {
      handleUpdateData(state.resellers, state.filteredResellers);
    }
  }, [state.resellers, state.filteredResellers]);

  useEffect(() => {
    handleFetch();
  }, [filter.pic, filter.role, filter.province]);

  const handleChangeState = (valueState, value) => {
    setState({ ...state, [valueState]: value });
  };

  const handleSearch = (keyword) => {
    handleChangeState("search", keyword);
  };

  // const handlePressSearch = async (e) => {
  //   //console.log(e.key);
  //   if (e.key === "Enter") {
  //     const fetch = async () => {
  //       const reseller = await getReseller(
  //         `?filter[name]=${state.search}&page=${page}&filter[city.id]=${
  //           city.id || ""
  //         }&filter[pic_id]=${pic || ""}&filter[level]=${
  //           role || ""
  //         }&filter[province.id]=${province || ""}&filter[created_between]=${
  //           created_between || ""
  //         }`
  //       );
  //       setState({
  //         ...state,
  //         filteredResellers: reseller.data,
  //         resellers: reseller,
  //       });
  //     };
  //     fetch();
  //   }
  // };

  const handleSearchName = async () => {
    const fetch = async () => {
      const reseller = await getReseller(
        `?filter[name]=${state.search}&page=${page}&filter[city.id]=${
          city.id || ""
        }&filter[pic_id]=${pic || ""}&filter[level]=${
          role || ""
        }&filter[province.id]=${province || ""}&filter[created_between]=${
          created_between || ""
        }`
      );
      setState({
        ...state,
        filteredResellers: reseller.data,
        resellers: reseller,
      });
    };
    fetch();
  };

  const handlePressSearchBtn = async (e) => {
    const fetch = async () => {
      const reseller = await getReseller(
        `?filter[email]=${state.search}&page=${page}&filter[city.id]=${
          city.id || ""
        }&filter[pic_id]=${pic || ""}&filter[level]=${
          role || ""
        }&filter[province.id]=${province || ""}&filter[created_between]=${
          created_between || ""
        }`
      );
      setState({
        ...state,
        filteredResellers: reseller.data,
        resellers: reseller,
      });
    };
    fetch();
  };

  const handleFilterPic = (e) => {
    setFilter({ ...filter, pic: e.target.value });
  };

  const handleFilterRole = (e) => {
    setFilter({ ...filter, role: e.target.value });
  };

  const handleChangePage = (e, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetch = async () => {
      const response = await getReseller(
        `?page=${value}&filter[province.id]=${
          filter.province.id || ""
        }&filter[city.id]=${filter.city.id || ""}&filter[name]=${
          state.search || ""
        }&filter[pic_id]=${filter.pic || ""}&filter[level]=${
          filter.role || ""
        }&filter[created_between]=${
          filter.created_between || ""
        }`
      );
      setState({
        ...state,
        filteredResellers: response.data,
        resellers: response,
      });
    };
    fetch();
  };

  const handleChangeProvince = (e) => {
    setFilter({ ...filter, province: e.target.value });
  };

  const handleChangeCity = (e) => {
    const fetch = async () => {
      const resReseller = await getReseller(
        `?page=${filter.page}&filter[province.id]=${
          filter.province.id || ""
        }&filter[city.id]=${e.target.value.id || ""}`
      );
      setState({
        ...state,
        resellers: resReseller,
        filteredResellers: resReseller.data,
      });
    };
    fetch();
  };

  const handleResetFilter = () => {
    const fetch = async () => {
      const response = await getReseller();
      setState({
        ...state,
        search: "",
        created_between: `${moment()
          .add(-30, "days")
          .format("YYYY-MM-DD 00:00:00")},${moment().format(
          "YYYY-MM-DD 00:00:00"
        )}`,
        created_start_between: moment()
          .add(-30, "days")
          .format("YYYY-MM-DD 00:00:00"),
        created_end_between: moment().format("YYYY-MM-DD 00:00:00"),
        filteredResellers: response.data,
        resellers: response,
      });

      setFilter({
        page: 1,
        city: "",
        province: "",
        pic: "",
        role: "",
        created_between: "",
      });
    };
    fetch();
  };

  const handleDeleteProduct = (id) => {
    swal({
      title: "Are you sure?",
      text: "Reseller akan dihapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteReseller(id);
        swal("Reseller dihapus!", {
          icon: "success",
        }).then(() => {
          handleResetFilter();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      } else {
        swal("Reseller batal dihapus");
      }
    });
  };

  const sendWhatsappMessageReseller = (data) => {
    const message =
      "Hai kak " +
      data.name +
      ", selamat datang di idejualan. Perkenalkan saya CS Support idejualan yang akan membantu kakak. Apabila kakak punya pertanyaan mengenai produk, promo, atau kendala lain bisa kakak tanyakan ke saya yaa😊";

    window.open(
      `https://api.whatsapp.com/send/?phone=` + data.phone + `&text=` + message,
      "_blank"
    );
  };

  function createData(nama, telepon, alamat, aktif, pic, kelola, coins, res) {
    return {
      ...res,
      nama,
      telepon,
      alamat,
      aktif,
      pic,
      kelola,
      coins,
    };
  }

  const handleClickAddCoins = (id) => {
    setSelectedId(id);
    setIsOpenModalAddCoins(true);
  };
  const handleClickMinusCoins = (id) => {
    setSelectedId(id);
    setIsOpenModalMinusCoins(true);
  };
  const handleClickAddWallet = (id) => {
    setSelectedId(id);
    setIsOpenModalAddWallet(true);
  };

  const Kelola = ({ data }) => {
    return (
      <div style={{ width: 350, textAlign: "center" }}>
        <Tooltip title="Edit" placement="bottom">
          <StyledButton onClick={() => handleEditData(data, "reseller")}>
            <EditIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Add Coins" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleClickAddCoins(data.id)}
          >
            <AddCoinIcon style={{ width: 15, height: 15 }} />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Minus Coins" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleClickMinusCoins(data.id)}
          >
            <MinusCoinIcon style={{ width: 15, height: 15 }} />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Topup Dompet Jualan" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleClickAddWallet(data.id)}
          >
            <AccountBalanceWalletIcon style={{ fontSize: 16 }} />
          </StyledButton>
        </Tooltip>
        <Tooltip title="Hapus" placement="bottom">
          <StyledButton
            style={{ marginLeft: 10 }}
            onClick={() => handleDeleteProduct(data.id)}
          >
            <BinIcon />
          </StyledButton>
        </Tooltip>
      </div>
    );
  };

  const Nama = ({ img, name, email, level, subs_month, reseller }) => {
    return (
      <div className={classes.flex}>
        <Badge badgeContent={level} color="primary">
          <Avatar src={img} />
        </Badge>
        <div style={{ paddingLeft: 10 }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {name}
          </Typography>
          <Typography variant="caption" style={{ color: "#a2a2a2" }}>
            {email}
          </Typography>
          <br />
          {subs_month && <Chip color="primary" label={subs_month + " Bulan"} />}
        </div>
      </div>
    );
  };

  const rows = () => {
    let returnData = [];
    state.filteredResellers.forEach((res) => {
      returnData = [
        ...returnData,
        createData(
          <Nama
            img={res.avatar}
            name={res.name}
            email={res.email}
            reseller={res}
            subs_month={res.subscription_month}
            level={res.omzet_level}
          />,
          res.phone,
          (res.location.address ? res.location.address + ", " : "") +
            (res.location.city.length !== 0
              ? res.location.city.name + " " + res.location.city.postal_code
              : "Belum Mengisi data"),
          <ResellerLevelMenu
            reseller={res}
            onSave={() => handleRefreshData()}
          />,
          <PicMenu reseller={res} />,
          <Kelola data={res} />,
          res.coins,
          res
        ),
      ];
    });
    return returnData;
  };

  const onCloseModal = () => {
    handleResetFilter();
    handleRefreshData();
    setIsOpenModalAddCoins(false);
    setIsOpenModalMinusCoins(false);
    setIsOpenModalAddWallet(false);
  };

  const handleDateCreatedStartChange = (e) => {
    const date =
      moment(e).format("YYYY-MM-DD 00:00:00") +
      "," +
      state.created_end_between;
    setFilter({ ...filter, created_between: date });
    handleDateBetween(date);
    const fetch = async () => {
      const resPics = await getReseller(
        `?page=${filter.page}&filter[city.id]=${
          filter.city.id || ""
        }&filter[level]=${filter.role || ""}&filter[created_between]=${
          date || ""
        }`
      );
      setState({
        ...state,
        created_start_between: moment(e).format("YYYY-MM-DD 00:00:00"),
        created_between: date,
        resellers: resPics,
        filteredResellers: resPics.data,
      });
    };
    fetch();
    // setState({
    //   ...state,
    //   created_start_between: moment(e).format("YYYY-MM-DD 00:00:00"),
    // });
  };

  const handleDateCreatedEndChange = (e) => {
    const date =
      state.created_start_between +
      "," +
      moment(e).format("YYYY-MM-DD 00:00:00");
    setFilter({ ...filter, created_between: date });
    handleDateBetween(date);
    const fetch = async () => {
      const resPics = await getReseller(
        `?page=${filter.page}&filter[city.id]=${
          filter.city.id || ""
        }&filter[level]=${filter.role || ""}&filter[created_between]=${
          date || ""
        }`
      );
      setState({
        ...state,
        created_end_between: moment(e).format("YYYY-MM-DD 00:00:00"),
        created_between: date,
        resellers: resPics,
        filteredResellers: resPics.data,
      });
    };
    fetch();
  };

  return (
    <Fragment>
      <Paper style={{ marginTop: 20 }}>
        <Grid container spacing={2} style={{ padding: 20 }}>
          <Grid item>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => handleSearch(e.target.value)}
                // onKeyPress={handlePressSearch}
              />
            </div>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonCari}
              onClick={handlePressSearchBtn}
            >
              Cari Email
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonCari}
              onClick={handleSearchName}
            >
              Cari Nama
            </Button>
          </Grid>
          <Grid item>
            <FormControl
              className={classes.formControl}
              style={{ marginLeft: 20 }}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={"PIC"}
                displayEmpty
                disableUnderline
                onChange={handleFilterPic}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="PIC" disabled>
                  <em>PIC</em>
                </MenuItem>
                <MenuItem value={"nothing"}>
                  <em>Belum Dapat PIC</em>
                </MenuItem>
                {state.pics.map((res, index) => (
                  <MenuItem key={index} value={res.id}>
                    {res.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              className={classes.formControl}
              style={{ marginLeft: 20 }}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={"ROLE"}
                displayEmpty
                disableUnderline
                onChange={handleFilterRole}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="ROLE" disabled>
                  <em>Role</em>
                </MenuItem>
                <MenuItem value={"1"}>
                  <em>Seller</em>
                </MenuItem>
                <MenuItem value={"2"}>
                  <em>SuperSeller</em>
                </MenuItem>
                <MenuItem value={"3"}>
                  <em>SuperSeller VIP</em>
                </MenuItem>
                <MenuItem value={"4"}>
                  <em>Marketer</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              className={classes.formControl}
              style={{ marginLeft: 20 }}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filter.province}
                displayEmpty
                disableUnderline
                onChange={handleChangeProvince}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  <em>Provinsi</em>
                </MenuItem>
                {state.provinces.map((res, index) => (
                  <MenuItem key={index} value={res.id}>
                    {res.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <div style={{ marginLeft: 30 }}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="tanggal Daftar awal"
              format="MM/dd/yyyy"
              value={state.created_start_between}
              onChange={(e) => handleDateCreatedStartChange(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Tanggal Daftar akhir"
              format="MM/dd/yyyy"
              value={state.created_end_between}
              onChange={(e) => handleDateCreatedEndChange(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell>Nama</StyledTableCell>
                  <StyledTableCell align="center">No. Telepon</StyledTableCell>
                  <StyledTableCell align="center">Alamat</StyledTableCell>
                  <StyledTableCell align="center">Level</StyledTableCell>
                  <StyledTableCell align="center">PIC</StyledTableCell>
                  <StyledTableCell align="center">Nominal</StyledTableCell>
                  <StyledTableCell align="center">Dibuat pada</StyledTableCell>
                  <StyledTableCell align="center">
                    Aktivitas Terakhir
                  </StyledTableCell>
                  <StyledTableCell align="center">Total Order</StyledTableCell>
                  <StyledTableCell align="center">Kelola</StyledTableCell>
                </TableRow>
              </TableHead>

              {state.resellers.data.length !== 0 && (
                <Fragment>
                  <TableBody>
                    {rows().map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.nama}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.telepon ? (
                            <Button
                              variant="outlined"
                              onClick={() => sendWhatsappMessageReseller(row)}
                              style={{
                                backgroundColor: "#FDCA40",
                                marginLeft: 10,
                              }}
                            >
                              {row.telepon}
                            </Button>
                          ) : (
                            "tidak ada WA"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.alamat}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.aktif}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.pic}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          DJ : {row.wallets}
                          <br></br>
                          CJ : {row.coins}
                          <br></br>
                          OZ : {row.total_omzet}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(row.created_at).fromNow()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.last_active_at
                            ? moment(row.last_active_at).fromNow()
                            : "belum ada rekaman"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.orders_count}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.kelola}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Fragment>
              )}
            </Table>
          </TableContainer>

          {state.resellers.data.length === 0 && (
            <div className={classes.notFound}>
              <div className={classes.notFoundChild}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  Oops!
                </Typography>
                <Typography>Data tidak ditemukan.</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.notFoundButton}
                  onClick={handleResetFilter}
                >
                  Reset Filter
                </Button>
              </div>
            </div>
          )}

          {state.resellers.data.length !== 0 && (
            <div className={classes.Pagination}>
              <Pagination
                count={state.resellers.meta.last_page}
                variant="outlined"
                onChange={handleChangePage}
                color="secondary"
              />
            </div>
          )}
        </Grid>
      </Paper>
      <ModalAddCoins
        isOpen={isOpenModalAddCoins}
        onClose={onCloseModal}
        selectedId={selectedId}
      />
      <ModalMinusCoins
        isOpen={isOpenModalMinusCoins}
        onClose={onCloseModal}
        selectedId={selectedId}
      />
      <ModalAddWallet
        isOpen={isOpenModalAddWallet}
        onClose={onCloseModal}
        selectedId={selectedId}
      />
    </Fragment>
  );
});

export default Reseller;
