import React, { Fragment } from "react";
import { Typography, CircularProgress } from "@material-ui/core"

const Loading = (props) => {

  const style = {
    loadingDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: window.screen.height - 79
    },
    loading: {
      textAlign: 'center'
    },
  }
  return(
    <Fragment>
      <div style={{ ...style.loadingDiv, ...props.style }}>
          <div style={style.loading}>
            <CircularProgress />
            <Typography>Loading...</Typography>
          </div>
        </div>
    </Fragment>
  )
}

export default Loading;