import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, withStyles } from "@material-ui/core";
import { editReseller } from "services/axios";
import { fetchPic } from "utils/picData";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#33b17a",
    },
    minWidth: 0,
    padding: 10,
  },
}))(Button);

const PicMenu = ({ reseller }) => {
  const [resellerAnchorEl, setResellerAnchoreEl] = useState(null);
  const [pic, setPic] = useState(reseller.pic);
  const [pics, setPics] = useState([]);

  useEffect(() => {
    fetchPic().then((data) => setPics(data));
  }, []);

  const updateResellerPic = (id) => {
    const formData = new FormData();
    formData.append("pic_id", id);
    formData.append("_method", "PATCH");
    editReseller(reseller.id, formData).then(({ data }) => {
      reseller.pic = data.pic;

      setPic(data.pic);
      setResellerAnchoreEl(null);
    });
  };

  return (
    <div>
      <StyledButton
        onClick={(event) => setResellerAnchoreEl(event.currentTarget)}
        style={{ width: "fit-content" }}
      >
        {pic ? pic.name : "Tidak ada PIC"}
      </StyledButton>

      <Menu
        id="reseller-level-menu"
        anchorEl={resellerAnchorEl}
        keepMounted
        open={Boolean(resellerAnchorEl)}
        onClose={() => setResellerAnchoreEl(null)}
      >
        <MenuItem onClick={() => updateResellerPic(null)}>
          Tidak ada PIC
        </MenuItem>
        {pics.map(({ name, id }) => (
          <MenuItem key={id} onClick={() => updateResellerPic(id)}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

PicMenu.propTypes = {
  reseller: PropTypes.object,
};

export default PicMenu;
