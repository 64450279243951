import axios from "axios";
import qs from "querystring";

export const EXPORT_EXCEL = (filter) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/export_orders${filter}`;
  const response = axios.get(url, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("TOKEN"),
    },
  });
  return response;
};

export const FINISH_ORDER = (id) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v1/orders/done`;
  let config = {
    method: "put",
    url: url,
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("TOKEN"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      order_id: id,
    }),
  };
  const response = axios(config);
  return response;
};

export const ORDER_UPGRADE = (params) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v2/upgrade-accounts`;
  const response = axios.get(url, { params: params });
  return response;
};

export const EXPORT_EXCEL_ORDER_UPGRADE = (filter) => {
  const url = `${process.env.REACT_APP_BASE_URL}/api/v2/upgrade-accounts/export`;
  const response = axios.get(url, {
    params: filter,
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("TOKEN"),
    },
  });
  return response;
};
