import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    TextField,
    Grid,
} from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";

const useTableStyles = makeStyles({
    container: {
        padding: "20px",
    },
    filterStatus: {
        minWidth: "200px",
    },
});

const SearchTable = ({ filterValue, status, setstatus, setfilterValue }) => {
    const classes = useTableStyles();
    return (
        <div>
            <div className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item>
                        <FormControl variant="standard">
                            <OutlinedInput
                                placeholder="Search"
                                value={filterValue}
                                onChange={(event) => setfilterValue(event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <TextField
                            select
                            label="Status"
                            variant="outlined"
                            className={classes.filterStatus}
                            value={status}
                            onChange={(event) => setstatus(event.target.value)}
                        >
                            <MenuItem value="">Semua</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="paid">Paid</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default SearchTable