import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React, { useState } from "react";
import { CREATE_PIC } from "services/pic.service";
import swal from "sweetalert";
import styles from "./style.module.css";

const Create = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    phone: "",
    images: null,
  });

  const [image, setImage] = useState(null);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    setImage(url);

    setData({ ...data, images: file });
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form_data = new FormData();
    for (let key in data) {
      form_data.append(key, data[key]);
    }

    setIsLoading(true);

    CREATE_PIC(form_data)
      .then((result) => {
        swal("Success", "Berhasil menambahkan PIC", "success").then(() => {
          window.history.back();
        });
        setIsLoading(false);
      })
      .catch((error) => {
        swal("Error", error.response.data.message, "error");
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBack
          style={{ paddingRight: 10, cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        <b>Tambah PIC</b>
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <div className={styles.photo}>
              {image ? (
                <img src={image} alt="Preview" />
              ) : (
                <>
                  <div>Upload Photo</div>
                  <Add />
                </>
              )}
              <input
                name="images"
                accept="image/*"
                type="file"
                onChange={handlePhotoChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Nama"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="phone"
              label="No Telp"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Tambah
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Create;
